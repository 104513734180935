import TapNovelRestApi from '../../data_stores/net/TapNovelRestApi';

import UserSceneTapsBatch from '../../../domain/value_objects/consumer/UserSceneTapsBatch';

import UserSceneTapsBatchesRepository from '../../../domain/repositories/consumer/UserSceneTapsBatchesRepository';

import UserSceneTapsBatchMapper, {
  UserSceneTapsBatchrData,
} from '../../value_objects/mappers/consumer/UserSceneTapsBatchMapper';

export default class NetUserSceneTapsBatchesRepository
  implements UserSceneTapsBatchesRepository
{
  public create(
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<UserSceneTapsBatch> {
    return TapNovelRestApi.post<UserSceneTapsBatchrData>(
      '/api/consumer/user_scene_taps_batch',
      params,
      options,
    ).then(obj => {
      return new UserSceneTapsBatchMapper().map(obj.body);
    });
  }
}
