import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import CloseIcon from '../icons/CloseIcon';

interface Props {
  onPressFinish?: () => void;
}

const FooterCloseButton: React.FunctionComponent<Props> = props => {
  const {onPressFinish} = props;
  return (
    <Pressable onPress={onPressFinish}>
      <View style={styles.button}>
        <CloseIcon size={11} />
        <Text style={styles.text}>プレビューを閉じる</Text>
      </View>
    </Pressable>
  );
};

const propsAreEqual = () => true;

export default React.memo(FooterCloseButton, propsAreEqual);

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .8)',
    flexDirection: 'row',
    height: 40,
    justifyContent: 'center',
    width: '100%',
  } as ViewStyle,
  text: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
    marginLeft: 5,
  } as TextStyle,
});
