import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import WithClassName from '../../../shared/WithClassName';

import {colors} from '../../../../styles/variables';

interface Props {
  fail: boolean;
  finished: boolean;
  onPress: () => void;
  onPressBack: () => void;
  onPressRetry: () => void;
}

const Footer: React.FunctionComponent<Props> = props => {
  const {fail, finished, onPress, onPressBack, onPressRetry} = props;
  return (
    <>
      <View style={styles.separator} />
      <View style={styles.footer}>
        {fail ? (
          <View style={styles.error}>
            <Pressable style={styles.backButton} onPress={onPressBack}>
              <Text style={styles.readButtonText}>戻る</Text>
            </Pressable>
            <Pressable style={styles.retryButton} onPress={onPressRetry}>
              <Text style={styles.readButtonText}>再実行</Text>
            </Pressable>
          </View>
        ) : finished ? (
          <Pressable style={styles.readButton} onPress={onPress}>
            <WithClassName className={'read-start-button'}>
              <Text style={styles.readButtonText}>読む</Text>
            </WithClassName>
          </Pressable>
        ) : (
          <Text style={styles.loadingText}>しばらくお待ちください</Text>
        )}
      </View>
    </>
  );
};

const propsAreEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.fail === nextProps.fail &&
    prevProps.finished === nextProps.finished
  );
};

export default React.memo(Footer, propsAreEqual);

const styles = StyleSheet.create({
  backButton: {
    alignItems: 'center',
    backgroundColor: colors.powderGray,
    borderBottomLeftRadius: 5,
    flex: 1,
    justifyContent: 'center',
    width: '100%',
  } as ViewStyle,
  footer: {
    alignItems: 'center',
    height: 46,
    justifyContent: 'center',
    width: '100%',
  } as ViewStyle,
  loadingText: {
    color: colors.lightGray,
    fontSize: 17,
    paddingTop: 2,
  } as TextStyle,
  readButton: {
    alignItems: 'center',
    backgroundColor: colors.orange,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    flex: 1,
    justifyContent: 'center',
    width: '100%',
  } as ViewStyle,
  readButtonText: {
    color: 'white',
    fontSize: 17,
    fontWeight: 'bold',
    paddingTop: 2,
  } as TextStyle,
  retryButton: {
    alignItems: 'center',
    backgroundColor: colors.orange,
    borderBottomRightRadius: 5,
    flex: 1,
    justifyContent: 'center',
    width: '100%',
  } as ViewStyle,
  separator: {
    width: '100%',
    backgroundColor: colors.paleGray,
    height: StyleSheet.hairlineWidth,
  } as ViewStyle,
  error: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
  } as ViewStyle,
});
