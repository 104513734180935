import * as React from 'react';
import {Image, ImageResizeMode} from 'react-native';

import GLFilter from './GLFilter';
import GLImage from './GLImage';

import {Filter} from '../../../domain/value_objects/command_options/BackgroundCommandOptions';

const uriToSize: {[key: string]: {width: number; height: number}} = {};

const cache = 'force-cache';

interface Size {
  height: number;
  width: number;
}

interface Props {
  size: Size;
  uri: string;
  filter?: Filter;
  resizeMode?: ImageResizeMode;
  onLoad?: () => void;
}

interface State {
  imageSize: Size | null;
}

export default class GLImageFilter extends React.PureComponent<Props, State> {
  private mounted = false;

  constructor(props: Props) {
    super(props);
    const uri = this.props.uri;
    this.state = {
      imageSize: uriToSize[uri] ? uriToSize[uri] : null,
    };
  }

  public componentDidMount() {
    this.mounted = true;
    if (!this.state.imageSize) {
      const {uri} = this.props;
      Image.getSize(
        uri,
        (width: number, height: number) => {
          uriToSize[uri] = {width, height};
          if (this.mounted) {
            this.setState({imageSize: {width, height}});
          }
        },
        (error: any) => {
          throw error;
        },
      );
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {size, uri, filter, resizeMode, onLoad} = this.props;
    const {imageSize} = this.state;
    const {height, width} = size;
    if (!imageSize) {
      return null;
    }
    if (!filter || filter === 'normal') {
      return (
        <Image
          style={size}
          source={{cache, uri}}
          resizeMode={resizeMode}
          fadeDuration={0}
          onLoad={onLoad}
        />
      );
    }
    return (
      <GLFilter size={size} filter={filter} onLoad={onLoad}>
        <GLImage
          width={width}
          height={height}
          imageSize={imageSize}
          source={uri}
          resizeMode={resizeMode}
        />
      </GLFilter>
    );
  }
}
