import * as React from 'react';
import Surface from './GLSurface';

import Blur from './gl_image_filters/Blur';
import ContrastSaturationBrightness from './gl_image_filters/ContrastSaturationBrightness';
import Sepia from './gl_image_filters/Sepia';
import RGBEmphasis from './gl_image_filters/RGBEmphasis';
// import Temperature from "./gl_image_filters/Temperature";
// import Hue from "./gl_image_filters/Hue";

import {Filter} from '../../../domain/value_objects/command_options/BackgroundCommandOptions';

interface Size {
  height: number;
  width: number;
}

interface Props extends React.PropsWithChildren {
  size: Size;
  filter?: Filter;
  autoRedraw?: boolean;
  pixelRatio?: number;
  surfaceRef?: any;
  useForceGL?: boolean;
  onLoad?: () => void;
}

export default class GLImageFilter extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      size,
      filter,
      autoRedraw,
      pixelRatio,
      surfaceRef,
      useForceGL,
      children,
      onLoad,
    } = this.props;
    const {height, width} = size;
    if ((!filter || filter === 'normal') && !useForceGL) {
      return children;
    }
    return (
      <Surface
        ref={surfaceRef}
        width={width}
        height={height}
        autoRedraw={autoRedraw}
        pixelRatio={pixelRatio}
        backgroundColor={'transparent'}
        onLoad={onLoad}>
        {this.renderGLNode()}
      </Surface>
    );
  }

  private renderGLNode(): React.ReactNode {
    const {size, filter, children} = this.props;
    const {height, width} = size;
    switch (filter) {
      case 'dream':
        return (
          <ContrastSaturationBrightness brightness={1.4}>
            <Blur factor={0.3} passes={4} height={height} width={width}>
              {children}
            </Blur>
          </ContrastSaturationBrightness>
        );
      case 'monochrome':
        return (
          <ContrastSaturationBrightness saturation={0}>
            {children}
          </ContrastSaturationBrightness>
        );
      case 'mosaic':
        return (
          <Blur factor={0.8} passes={4} height={height} width={width}>
            {children}
          </Blur>
        );
      case 'sepia':
        return <Sepia sepia={1.0}>{children}</Sepia>;
      case 'red':
        return (
          <RGBEmphasis red={1}>
            <ContrastSaturationBrightness saturation={0}>
              {children}
            </ContrastSaturationBrightness>
          </RGBEmphasis>
        );
      case 'blue':
        return (
          <RGBEmphasis blue={1}>
            <ContrastSaturationBrightness saturation={0}>
              {children}
            </ContrastSaturationBrightness>
          </RGBEmphasis>
        );
      case 'green':
        return (
          <RGBEmphasis green={1}>
            <ContrastSaturationBrightness saturation={0}>
              {children}
            </ContrastSaturationBrightness>
          </RGBEmphasis>
        );
      default:
        return (
          <ContrastSaturationBrightness>
            {children}
          </ContrastSaturationBrightness>
        );
    }
  }
}
