import * as React from 'react';

import Icon from '../../icons/Icon';

interface Props {
  size?: number;
  color?: string;
}

const HamburgerIcon: React.FunctionComponent<Props> = props => {
  return <Icon name={'hamburgerViewer'} {...props} />;
};

export default React.memo(HamburgerIcon);
