enum Position {
  Left,
  Center,
  Right,
}

export default Position;

export class PositionValueMapper {
  public static map(position: Position): string {
    switch (position) {
      case Position.Left:
        return 'left';
      case Position.Center:
        return 'center';
      case Position.Right:
        return 'right';
      default:
        throw new Error('Unsupported position error!');
    }
  }
}
