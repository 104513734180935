import Command from './Command';

export default class CompositeParallelCommand extends Command {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public sceneId: number,
    public commands: Command[],
  ) {
    super(id, sceneId);
    commands.forEach((command, id) => {
      command.parent = this;
    });
  }
}
