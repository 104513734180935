import * as React from 'react';
import {Animated, Platform, StyleProp} from 'react-native';

import GLImageFilter from '../../../../shared/GLImageFilter';

import {
  backgroundImageUrl,
  BACKGROUND_FILTER_KEY_TO_BLOB,
} from '../../../../../helpers/images';

import BackgroundShowCommand from '../../../../../../domain/entities/commands/BackgroundShowCommand';

import {animatedViewStyle} from '../../../../../styles/variables';

import {isAndroid} from '../../../../../../data/data_stores/net/UserAgent';

interface Size {
  height: number;
  width: number;
}

export interface Props {
  command: BackgroundShowCommand | null;
  size: Size;
  onAfterRenderBackground: (command: BackgroundShowCommand) => void;
}

interface State {
  command: BackgroundShowCommand | null;
  prevCommand: BackgroundShowCommand | null;
}

export default class BackgroundShowCommandViewBase extends React.Component<
  Props,
  State
> {
  public static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State,
  ): Partial<State> | null {
    if (nextProps.command !== prevState.command) {
      return {command: nextProps.command, prevCommand: prevState.command};
    }
    return null;
  }

  protected animatedViewRef = React.createRef<HTMLDivElement>();
  protected containerStyle: StyleProp<any>;

  private animating = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      command: props.command,
      prevCommand: null,
    };
    this.containerStyle = [props.size, animatedViewStyle];
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    if (!prevState.prevCommand && this.state.prevCommand) {
      this.fadeOut();
    }
  }

  public render(): React.ReactNode {
    const {size} = this.props;
    const command = this.getCommand();
    if (!command) {
      return null;
    }
    const {background, options} = command;
    const filteredUri =
      BACKGROUND_FILTER_KEY_TO_BLOB[`${background.id}/${options.filter}`];
    const uri = backgroundImageUrl(background, size);
    return (
      <Animated.View
        ref={this.animatedViewRef as any}
        key={background.id}
        style={this.containerStyle}>
        <GLImageFilter
          key={command.getKey()}
          size={size}
          uri={filteredUri || uri}
          filter={filteredUri ? undefined : options.filter}
          onLoad={this.fadeIn}
        />
      </Animated.View>
    );
  }

  protected animate(
    from: number,
    to: number,
    duration: number,
    callback?: () => void,
  ) {}

  protected stopAnimation = () => {};

  private getCommand(): BackgroundShowCommand | null {
    const {command, prevCommand} = this.state;
    return prevCommand || command;
  }

  private fadeIn = () => {
    if (this.animating) {
      this.stopAnimation();
    }
    this.animating = true;
    const {command, onAfterRenderBackground} = this.props;
    const to =
      Platform.OS === 'web' && isAndroid && command?.options.filter ? 0.99 : 1;
    this.animate(0, to, 200, () => {
      if (command) {
        this.animating = false;
        onAfterRenderBackground(command);
      }
    });
  };

  private fadeOut = () => {
    this.animate(1, 0, 200, () => {
      this.setState({prevCommand: null});
    });
  };
}
