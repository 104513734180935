import {camelizeKeys} from 'humps';

import TextFrameResourceOptions from '../../../../domain/value_objects/resource_options/TextFrameResourceOptions';

export interface TextFrameResourceOptionsData {
  container_style?: {[key: string]: string};
  text_style?: {[key: string]: string};
  wide_text_style?: {[key: string]: string};
  voice_icon_style?: {[key: string]: string};
}

export default class TextFrameResourceOptionsMapper {
  public map(obj: TextFrameResourceOptionsData): TextFrameResourceOptions {
    return new TextFrameResourceOptions(
      obj.container_style ? camelizeKeys(obj.container_style) : undefined,
      obj.text_style ? camelizeKeys(obj.text_style) : undefined,
      obj.wide_text_style ? camelizeKeys(obj.wide_text_style) : undefined,
      obj.voice_icon_style ? camelizeKeys(obj.voice_icon_style) : undefined,
    );
  }
}
