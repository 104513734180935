import Entity from '../Entity';

export default class UserEpisodeBrowsingLog implements Entity {
  constructor(
    public id: number,
    public episodeId: number,
    public storyId: number,
    public startedAt: Date,
    public finishedAt: Date | null,
    public exitedAt: Date | null,
    public tapCount: number,
    public createdAt: Date,
    public updatedAT: Date,
  ) {}
}
