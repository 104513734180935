import * as React from 'react';
import {
  Dimensions,
  EmitterSubscription,
  Modal,
  ScaledSize,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

const animationType = 'none';

interface Props extends React.PropsWithChildren {
  visible: boolean;
  fullScreenWidth: number;
  fullScreenHeight: number;
  renderTop?: (width: number) => React.ReactNode;
  onShow?: () => void;
}

interface State {
  window: ScaledSize;
}

export default class BaseModal extends React.PureComponent<Props, State> {
  private changeWindowSizeEmitterSubscription: EmitterSubscription | null =
    null;

  constructor(props: Props) {
    super(props);
    this.state = {
      window: Dimensions.get('window'),
    };
  }

  public componentDidMount() {
    this.changeWindowSizeEmitterSubscription = Dimensions.addEventListener(
      'change',
      this.handleChangeWindowSize,
    );
  }

  public componentWillUnmount(): void {
    this.changeWindowSizeEmitterSubscription?.remove();
  }

  public render(): React.ReactNode {
    const {
      visible,
      fullScreenWidth,
      fullScreenHeight,
      children,
      renderTop,
      onShow,
    } = this.props;
    const {height, width} = this.state.window;
    const w = fullScreenWidth * (260 / 320);
    return (
      <Modal
        animationType={animationType}
        transparent={true}
        visible={visible}
        onRequestClose={this.handleRequestClose}
        onShow={onShow}>
        <View style={[styles.overlay, {height, width}]}>
          <View
            style={{
              alignItems: 'center',
              alignSelf: 'center',
              justifyContent: 'center',
              backgroundColor: 'black',
              width: fullScreenWidth,
              height: fullScreenHeight,
            }}>
            {renderTop && <View style={[styles.ad]}>{renderTop(w)}</View>}
            <View style={[styles.container, {width: w}]}>{children}</View>
          </View>
        </View>
      </Modal>
    );
  }

  private handleChangeWindowSize = ({
    window,
    screen,
  }: {
    window: ScaledSize;
    screen: ScaledSize;
  }) => {
    this.setState({window});
  };

  private handleRequestClose = () => {
    /* DO NOTHING */
  };
}

const styles = StyleSheet.create({
  ad: {
    alignSelf: 'center',
    borderRadius: 5,
    marginBottom: 32,
  } as ViewStyle,
  container: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: 'white',
    borderRadius: 5,
  } as ViewStyle,
  overlay: {
    justifyContent: 'center',
    position: 'absolute',
  } as ViewStyle,
});
