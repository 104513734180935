import EntityMapper from '../EntityMapper';

import FullScreenIllustrationShowCommand from '../../../../domain/entities/commands/FullScreenIllustrationShowCommand';
import ResourcesSet from '../../../../domain/entities/resources/ResourcesSet';

import FullScreenIllustrationCommandOptionsMapper, {
  FullScreenIllustrationCommandOptionsData,
} from '../../../value_objects/mappers/command_options/FullScreenIllustrationCommandOptionsMapper';
import SoundEffectCommandOptionsMapper, {
  SoundEffectCommandOptionsData,
} from '../../../value_objects/mappers/command_options/SoundEffectCommandOptionsMapper';

export interface FullScreenIllustrationShowCommandData {
  id: number;
  scene_id: number;
  type: 'full_screen_illustration_show';
  full_screen_illustration_id: number;
  sound_id?: number;
  options?: FullScreenIllustrationCommandOptionsData;
  sound_options?: SoundEffectCommandOptionsData;
}

export default class FullScreenIllustrationShowCommandMapper
  implements
    EntityMapper<
      FullScreenIllustrationShowCommandData,
      FullScreenIllustrationShowCommand
    >
{
  private fullScreenIllustrationCommandOptionsMapper: FullScreenIllustrationCommandOptionsMapper;
  private soundEffectCommandOptionsMapper: SoundEffectCommandOptionsMapper;

  constructor(private resourcesSet: ResourcesSet) {
    this.fullScreenIllustrationCommandOptionsMapper =
      new FullScreenIllustrationCommandOptionsMapper();
    this.soundEffectCommandOptionsMapper =
      new SoundEffectCommandOptionsMapper();
  }

  public map(
    obj: FullScreenIllustrationShowCommandData,
  ): FullScreenIllustrationShowCommand {
    const options = this.fullScreenIllustrationCommandOptionsMapper.map(
      obj.options || {},
    );
    const soundOptions = this.soundEffectCommandOptionsMapper.map(
      obj.sound_options || {},
    );
    return new FullScreenIllustrationShowCommand(
      obj.id,
      obj.scene_id,
      this.resourcesSet.getFullScreenIllustrationResource(
        obj.full_screen_illustration_id,
      ),
      obj.sound_id ? this.resourcesSet.getSoundResource(obj.sound_id) : null,
      options,
      soundOptions,
    );
  }
}
