import EntityMapper from '../EntityMapper';

import UserEpisodeBrowsingLog from '../../../../domain/entities/consumer/UserEpisodeBrowsingLog';

export interface UserEpisodeBrowsingLogData {
  id: number;
  episode_id: number;
  story_id: number;
  started_at: Date;
  finished_at: Date | null;
  exited_at: Date | null;
  tap_count: number;
  created_at: string;
  updated_at: string;
}

export default class UserEpisodeBrowsingLogMapper
  implements EntityMapper<UserEpisodeBrowsingLogData, UserEpisodeBrowsingLog>
{
  public map(obj: UserEpisodeBrowsingLogData): UserEpisodeBrowsingLog {
    return new UserEpisodeBrowsingLog(
      obj.id,
      obj.episode_id,
      obj.story_id,
      new Date(obj.started_at),
      obj.finished_at ? new Date(obj.finished_at) : null,
      obj.exited_at ? new Date(obj.exited_at) : null,
      obj.tap_count,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
