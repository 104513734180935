import Command from './Command';

import SoundResource from '../resources/SoundResource';

import BackgroundMusicCommandOptions from '../../value_objects/command_options/BackgroundMusicCommandOptions';

export default class BackgroundMusicShowCommand extends Command {
  constructor(
    public id: number,
    public sceneId: number,
    public sound: SoundResource,
    public options: BackgroundMusicCommandOptions,
  ) {
    super(id, sceneId);
  }
}
