import EntityMapper from '../EntityMapper';

import UserStoryBrowsingHistory from '../../../../domain/entities/consumer/UserStoryBrowsingHistory';

export interface UserStoryBrowsingHistoryData {
  id: number;
  story: {id: number};
  browsing: boolean;
  created_at: string;
  updated_at: string;
}

export default class UserStoryBrowsingHistoryMapper
  implements
    EntityMapper<UserStoryBrowsingHistoryData, UserStoryBrowsingHistory>
{
  public map(obj: UserStoryBrowsingHistoryData): UserStoryBrowsingHistory {
    return new UserStoryBrowsingHistory(
      obj.id,
      obj.story.id,
      obj.browsing,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
