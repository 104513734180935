import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const GL = require('gl-react');

const NORM = Math.sqrt(2) / 2;

const directionForPassDefault = (
  p: number,
  factor: number,
  total: number,
): number[] => {
  const f = (factor * 2 * Math.ceil(p / 2)) / total;
  switch ((p - 1) % 4) {
    case 0:
      return [f, 0];
    case 1:
      return [0, f];
    case 2:
      return [f * NORM, f * NORM];
    case 3:
      return [f * NORM, -f * NORM];
    default:
      return [];
  }
};

const shaders = GL.Shaders.create({
  blur: {
    frag: `
            precision highp float;
            varying vec2 uv;
            uniform sampler2D t;
            uniform vec2 direction, resolution;

            vec4 blur9(sampler2D image, vec2 uv, vec2 resolution, vec2 direction) {
                vec4 color = vec4(0.0);
                vec2 off1 = vec2(1.3846153846) * direction;
                vec2 off2 = vec2(3.2307692308) * direction;
                color += texture2D(image, uv) * 0.2270270270;
                color += texture2D(image, uv + (off1 / resolution)) * 0.3162162162;
                color += texture2D(image, uv - (off1 / resolution)) * 0.3162162162;
                color += texture2D(image, uv + (off2 / resolution)) * 0.0702702703;
                color += texture2D(image, uv - (off2 / resolution)) * 0.0702702703;
                return color;
            }

            void main () {
                gl_FragColor = blur9(t, uv, resolution, direction);
            }
    `,
  },
});

interface Props {
  width: number;
  height: number;
  factor: number;
  passes: number;
  pixelRatio?: number;
  directionForPass?: (pass: number, factor: number, passes: number) => number[];
  children?: React.ReactNode;
}

const Blur = GL.createComponent((props: Props) => {
  const {
    width,
    height,
    pixelRatio,
    factor,
    children,
    passes,
    directionForPass,
  } = props;
  const directionForPassFunc = directionForPass || directionForPassDefault;
  const rec = (pass: number) =>
    pass <= 0 ? (
      children
    ) : (
      <GL.Node
        shader={shaders.blur}
        width={width}
        height={height}
        pixelRatio={pixelRatio}
        uniforms={{
          direction: directionForPassFunc(pass, factor, passes),
          resolution: [width, height],
          t: rec(pass - 1),
        }}
      />
    );
  return rec(passes);
}) as React.FunctionComponent<Props>;

Blur.defaultProps = {
  directionForPass: directionForPassDefault,
  passes: 2,
};

export default Blur;
