import * as React from 'react';
import {
  LayoutChangeEvent,
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import Footer from './download_progress/Footer';
import ProgressBar from './download_progress/ProgressBar';
import SoundToggleSwitch from './download_progress/SoundToggleSwitch';
import AutoPlayToggleSwitch from './download_progress/AutoPlayToggleSwitch';

import {colors} from '../../../styles/variables';

import ViewerLayoutManager from '../../../view_models/ViewerLayoutManager';

import SceneScript from '../../../../domain/entities/SceneScript';

import {enableNoSleep, disableNoSleep} from '../../shared/NoSleep';

interface Props {
  sceneScript: SceneScript;
  layoutManager: ViewerLayoutManager;
  visible: boolean;
  fullScreenWidth: number;
  enabledSound: boolean;
  autoPlaySpeed: 0 | 1 | 1.5 | 2;
  preloadAll?: boolean;
  renderTop?: (width: number) => React.ReactNode;
  onPress: () => void;
  onPressRetry: () => void;
  onPressBack: () => void;
  onReady: () => void;
  onLoadFail: (error?: any) => void;
  onShow?: () => void;
  onEnabledSoundChange?: (enabledSound: boolean) => void;
  onAutoPlaySpeedChange?: (value: 0 | 1 | 1.5 | 2) => void;
}

interface State {
  width: number;
  finished: boolean;
  fail: boolean;
}

const FAIL_MESSAGE = '読み込みに失敗しました';
const FINISHED_MESSAGE = '読み込みが完了しました';
const LOADING_MESSAGE = '読み込み中…';

export default class DownloadProgress extends React.Component<Props, State> {
  private progressModalInner: StyleProp<ViewStyle>;
  private barProgress: StyleProp<ViewStyle>;

  constructor(props: Props) {
    super(props);
    const width = 0;
    this.state = {
      finished: false,
      width: 0,
      fail: false,
    };
    this.progressModalInner = [
      styles.progressModalInner,
      {width: props.fullScreenWidth * (260 / 320)},
    ];
    this.barProgress = [styles.barProgress, {width}];
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    return !(
      this.props.visible === nextProps.visible &&
      this.props.enabledSound === nextProps.enabledSound &&
      this.props.autoPlaySpeed === nextProps.autoPlaySpeed &&
      this.state.width === nextState.width &&
      this.state.fail === nextState.fail &&
      this.state.finished === nextState.finished
    );
  }

  public render(): React.ReactNode {
    const {
      sceneScript,
      visible,
      layoutManager,
      enabledSound,
      autoPlaySpeed,
      preloadAll,
      onPressBack,
      renderTop,
      onReady,
      onEnabledSoundChange,
      onAutoPlaySpeedChange,
    } = this.props;
    const {finished, fail, width} = this.state;
    return (
      <View style={[styles.container, visible ? null : {opacity: 0}]}>
        {renderTop && <View style={styles.ad}>{renderTop(width)}</View>}
        <View style={styles.progressModal}>
          <View style={this.progressModalInner} onLayout={this.handleLayout}>
            <View style={styles.header}>
              <Text style={styles.progressText}>
                {fail
                  ? FAIL_MESSAGE
                  : finished
                  ? FINISHED_MESSAGE
                  : LOADING_MESSAGE}
              </Text>
              <View style={styles.barWrapper}>
                <View style={styles.bar}>
                  <View style={this.barProgress} />
                  {width ? (
                    <ProgressBar
                      sceneScript={sceneScript}
                      layoutManager={layoutManager}
                      fail={fail}
                      width={width}
                      preloadAll={preloadAll}
                      onFinish={this.handleFinish}
                      onReady={onReady}
                      onLoadFail={this.handleLoadFail}
                    />
                  ) : null}
                </View>
              </View>
            </View>
            <Footer
              fail={fail}
              finished={finished}
              onPress={this.handlePressRead}
              onPressBack={onPressBack}
              onPressRetry={this.handlePressRetry}
            />
          </View>
          {sceneScript.hasSound() ? (
            <SoundToggleSwitch
              enabled={enabledSound}
              onValueChange={onEnabledSoundChange}
            />
          ) : null}
          <AutoPlayToggleSwitch
            autoPlaySpeed={autoPlaySpeed}
            onAutoPlaySpeedChange={onAutoPlaySpeedChange}
          />
        </View>
      </View>
    );
  }

  private handleLayout = (event: LayoutChangeEvent) => {
    const {width} = event.nativeEvent.layout;
    this.barProgress = [styles.barProgress, {width}];
    this.setState({width});
  };

  private handleFinish = () => {
    this.setState({finished: true});
  };

  private handlePressRead = () => {
    const {autoPlaySpeed, onPress} = this.props;
    if (autoPlaySpeed === 0) {
      disableNoSleep();
    } else {
      enableNoSleep();
    }
    onPress();
  };

  private handleLoadFail = (error?: any) => {
    const {onLoadFail} = this.props;
    this.setState({fail: true}, () => {
      onLoadFail(error);
    });
  };

  private handlePressRetry = () => {
    const {onPressRetry} = this.props;
    this.setState({fail: false}, onPressRetry);
  };
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'black',
  } as ViewStyle,
  bar: {
    backgroundColor: colors.powderGray,
    borderRadius: 5,
    height: 10,
    overflow: 'hidden',
    width: '90%',
  } as ViewStyle,
  barProgress: {
    backgroundColor: colors.orange,
    height: 10,
    position: 'absolute',
    top: 0,
  } as ViewStyle,
  barWrapper: {
    alignItems: 'center',
    marginBottom: 20,
    marginTop: 15,
    width: '100%',
  } as ViewStyle,
  ad: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: Platform.select({web: undefined, default: 'center'}),
  } as ViewStyle,
  progressModal: {
    width: '100%',
    height: 300,
  } as ViewStyle,
  progressModalInner: {
    alignSelf: 'center',
    width: '90%',
    backgroundColor: 'white',
    borderRadius: 5,
    marginTop: 32,
    marginBottom: 14,
    maxWidth: 400,
  } as ViewStyle,
  header: {
    alignItems: 'center',
    height: 86,
    justifyContent: 'center',
    width: '100%',
  } as ViewStyle,
  progressText: {
    color: colors.textColor,
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 22,
  } as TextStyle,
});
