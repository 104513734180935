import EntityMapper from '../EntityMapper';

import IllustrationShowCommand from '../../../../domain/entities/commands/IllustrationShowCommand';
import ResourcesSet from '../../../../domain/entities/resources/ResourcesSet';

import IllustrationCommandOptionsMapper, {
  IllustrationCommandOptionsData,
} from '../../../value_objects/mappers/command_options/IllustrationCommandOptionsMapper';
import SoundEffectCommandOptionsMapper, {
  SoundEffectCommandOptionsData,
} from '../../../value_objects/mappers/command_options/SoundEffectCommandOptionsMapper';

export interface IllustrationShowCommandData {
  id: number;
  scene_id: number;
  type: 'illustration_show';
  illustration_id: number;
  sound_id?: number;
  options?: IllustrationCommandOptionsData;
  sound_options?: SoundEffectCommandOptionsData;
}

export default class IllustrationShowCommandMapper
  implements EntityMapper<IllustrationShowCommandData, IllustrationShowCommand>
{
  private illustrationCommandOptionsMapper: IllustrationCommandOptionsMapper;
  private soundEffectCommandOptionsMapper: SoundEffectCommandOptionsMapper;

  constructor(private resourcesSet: ResourcesSet) {
    this.illustrationCommandOptionsMapper =
      new IllustrationCommandOptionsMapper();
    this.soundEffectCommandOptionsMapper =
      new SoundEffectCommandOptionsMapper();
  }

  public map(obj: IllustrationShowCommandData): IllustrationShowCommand {
    const options = this.illustrationCommandOptionsMapper.map(
      obj.options || {},
    );
    const soundOptions = this.soundEffectCommandOptionsMapper.map(
      obj.sound_options || {},
    );
    return new IllustrationShowCommand(
      obj.id,
      obj.scene_id,
      this.resourcesSet.getIllustrationResource(obj.illustration_id),
      obj.sound_id ? this.resourcesSet.getSoundResource(obj.sound_id) : null,
      options,
      soundOptions,
    );
  }
}
