enum NameLabelColor {
  Blue = 'blue',
  Pink = 'pink',
  Green = 'green',
  Black = 'black',
  LIGHT_BLUE = 'light_blue',
  BRIGHT_BLUE = 'bright_blue',
  RED_PURPLE = 'red_purple',
  RED = 'red',
  BRIGHT_PINK = 'bright_pink',
  ORANGE = 'orange',
  YELLOW = 'yellow',
  YELLOW_GREEN = 'yellow_green',
  BRIGHT_GREEN = 'bright_green',
  BROWN = 'brown',
  BEIGE = 'beige',
  GRAY = 'gray',
}

export default NameLabelColor;

export const DEFAULT_COLOR = NameLabelColor.Green;

export const ALL_NAME_LABEL_COLORS = [
  NameLabelColor.Blue,
  NameLabelColor.Pink,
  NameLabelColor.Green,
  NameLabelColor.Black,
  NameLabelColor.LIGHT_BLUE,
  NameLabelColor.BRIGHT_BLUE,
  NameLabelColor.RED_PURPLE,
  NameLabelColor.RED,
  NameLabelColor.BRIGHT_PINK,
  NameLabelColor.ORANGE,
  NameLabelColor.YELLOW,
  NameLabelColor.YELLOW_GREEN,
  NameLabelColor.BRIGHT_GREEN,
  NameLabelColor.BROWN,
  NameLabelColor.BEIGE,
  NameLabelColor.GRAY,
];

export const NAME_LABEL_COLOR_TO_FILL_COLOR: {[key: string]: string} = {
  blue: '#2B7494',
  pink: '#9B3A68',
  green: '#3B7C74',
  black: '#383838',
  light_blue: '#2E85B9',
  bright_blue: '#0043AD',
  red_purple: '#7C3F97',
  red: '#CB1D1D',
  bright_pink: '#C9478E',
  orange: '#DD670C',
  yellow: '#E3A300',
  yellow_green: '#6E9D29',
  bright_green: '#2A7A2A',
  brown: '#894B31',
  beige: '#D19772',
  gray: '#757575',
};

export const NAME_LABEL_COLOR_TO_BORDER_COLOR: {[key: string]: string} = {
  blue: '#0D5270',
  pink: '#7E1B4B',
  green: '#28554F',
  black: '#222222',
  light_blue: '#206086',
  bright_blue: '#002E77',
  red_purple: '#572C6B',
  red: '#861212',
  bright_pink: '#9E376F',
  orange: '#A14A07',
  yellow: '#AB7A00',
  yellow_green: '#50721E',
  bright_green: '#1E5C1E',
  brown: '#643723',
  beige: '#9B6F53',
  gray: '#4B4B4B',
};

export function toggleBrightnessColor(
  color: string,
  rate: number,
  active?: boolean,
) {
  if (active) {
    return color;
  }
  return convertBrightnessColor(color, rate);
}

export function convertBrightnessColor(color: string, rate: number) {
  return rgb2hex(hex2rgb(color).map(i => Math.floor(i * rate)));
}

function hex2rgb(hex: string) {
  if (hex.slice(0, 1) == '#') hex = hex.slice(1);
  if (hex.length == 3)
    hex =
      hex.slice(0, 1) +
      hex.slice(0, 1) +
      hex.slice(1, 2) +
      hex.slice(1, 2) +
      hex.slice(2, 3) +
      hex.slice(2, 3);
  return [hex.slice(0, 2), hex.slice(2, 4), hex.slice(4, 6)].map(function (
    str,
  ) {
    return parseInt(str, 16);
  });
}

function rgb2hex(rgb: Array<number>) {
  return (
    '#' +
    rgb
      .map(function (value) {
        return ('0' + value.toString(16)).slice(-2);
      })
      .join('')
  );
}
