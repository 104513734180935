import EntityMapper from '../EntityMapper';

import DescriptiveTextShowCommand from '../../../../domain/entities/commands/DescriptiveTextShowCommand';
import ResourcesSet from '../../../../domain/entities/resources/ResourcesSet';

import DescriptiveTextCommandOptionsMapper, {
  DescriptiveTextCommandOptionsData,
} from '../../../value_objects/mappers/command_options/DescriptiveTextCommandOptionsMapper';
import SoundEffectCommandOptionsMapper, {
  SoundEffectCommandOptionsData,
} from '../../../value_objects/mappers/command_options/SoundEffectCommandOptionsMapper';

export interface DescriptiveTextShowCommandData {
  id: number;
  scene_id: number;
  type: 'descriptive_text_show';
  text_frame_id: number;
  text: string;
  voice_id?: number | null;
  sound_id?: number;
  options?: DescriptiveTextCommandOptionsData;
  sound_options?: SoundEffectCommandOptionsData;
}

export default class DescriptiveTextShowCommandMapper
  implements
    EntityMapper<DescriptiveTextShowCommandData, DescriptiveTextShowCommand>
{
  private descriptiveTextCommandOptionsMapper: DescriptiveTextCommandOptionsMapper;
  private soundEffectCommandOptionsMapper: SoundEffectCommandOptionsMapper;

  constructor(private resourcesSet: ResourcesSet) {
    this.descriptiveTextCommandOptionsMapper =
      new DescriptiveTextCommandOptionsMapper();
    this.soundEffectCommandOptionsMapper =
      new SoundEffectCommandOptionsMapper();
  }

  public map(obj: DescriptiveTextShowCommandData): DescriptiveTextShowCommand {
    const options = this.descriptiveTextCommandOptionsMapper.map(
      obj.options || {},
    );
    const soundOptions = this.soundEffectCommandOptionsMapper.map(
      obj.sound_options || {},
    );
    return new DescriptiveTextShowCommand(
      obj.id,
      obj.scene_id,
      this.resourcesSet.getTextFrameResource(obj.text_frame_id),
      obj.text,
      obj.voice_id ? this.resourcesSet.getVoiceResource(obj.voice_id) : null,
      obj.sound_id ? this.resourcesSet.getSoundResource(obj.sound_id) : null,
      options,
      soundOptions,
    );
  }
}
