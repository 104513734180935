import EntityMapper from '../EntityMapper';

import FullScreenIllustrationResource from '../../../../domain/entities/resources/FullScreenIllustrationResource';

export interface FullScreenIllustrationResourceData {
  id: number;
  type: 'full_screen_illustration';
  scene_ids?: number[];
  image_url: string;
}

export default class FullScreenIllustrationResourceMapper
  implements
    EntityMapper<
      FullScreenIllustrationResourceData,
      FullScreenIllustrationResource
    >
{
  public map(
    obj: FullScreenIllustrationResourceData,
  ): FullScreenIllustrationResource {
    return new FullScreenIllustrationResource(
      obj.id,
      obj.scene_ids,
      obj.image_url,
    );
  }
}
