import Position from '../../../domain/value_objects/Position';

export type PositionData = 'left' | 'center' | 'right';

export default class PositionMapper {
  public map(obj: PositionData): Position {
    switch (obj) {
      case 'left':
        return Position.Left;
      case 'center':
        return Position.Center;
      case 'right':
        return Position.Right;
      default:
        throw Error('PositionData to Position Error');
    }
  }
}
