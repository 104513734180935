import * as React from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

import StageCaption from './stage/StageCaption';
import StageEffect from './stage/StageEffect';
import StateModal from './stage/StageModal';
import StagePosition from './stage/StagePosition';
import StageSet from './stage/StageSet';
import StageSetEffect from './stage/StageSetEffect';
import SoundAnimationIcon from './stage/SoundAnimationIcon';
import Logo from './stage/Logo';

import TouchableView from '../../shared/TouchableView';

import Frame from '../../../view_models/Frame';
import ViewerLayoutManager from '../../../view_models/ViewerLayoutManager';

import BackgroundShowCommand from '../../../../domain/entities/commands/BackgroundShowCommand';
import CharacterHideCommand from '../../../../domain/entities/commands/CharacterHideCommand';
import CharacterShowCommand from '../../../../domain/entities/commands/CharacterShowCommand';
import CharacterUpdateCommand from '../../../../domain/entities/commands/CharacterUpdateCommand';
import CompositeParallelCommand from '../../../../domain/entities/commands/CompositeParallelCommand';
import Command from '../../../../domain/entities/commands/Command';
import DescriptiveTextShowCommand from '../../../../domain/entities/commands/DescriptiveTextShowCommand';
import IllustrationShowCommand from '../../../../domain/entities/commands/IllustrationShowCommand';
import SpeechTextShowCommand from '../../../../domain/entities/commands/SpeechTextShowCommand';
import EffectShowCommand from '../../../../domain/entities/commands/EffectShowCommand';

import Position from '../../../../domain/value_objects/Position';

interface Props {
  style?: StyleProp<ViewStyle>;
  frame: Frame;
  currentCommand: Command | null;
  layoutManager: ViewerLayoutManager;
  skipRenderText: boolean;
  playingSound: boolean;
  orientation: 'horizontal' | 'vertical';
  watermarked?: boolean;
  onTouch: (point: {x: number; y: number}) => void;
  onAfterRenderBackground: (command: BackgroundShowCommand) => void;
  onAfterRenderCharacter: (
    command:
      | CharacterShowCommand
      | CharacterUpdateCommand
      | CharacterHideCommand,
  ) => void;
  onBeforeRenderIllustration: (command: IllustrationShowCommand) => void;
  onAfterRenderIllustration: (command: IllustrationShowCommand) => void;
  onFinishPlay: (command: IllustrationShowCommand | EffectShowCommand) => void;
  onBeforeRenderText: (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => void;
  onAfterRenderText: (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => void;
  onFinishPlayVoice: (
    command: SpeechTextShowCommand | DescriptiveTextShowCommand,
  ) => void;
  onFinishPlaySound: (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => void;
  onBeforeRenderEffect: (command: EffectShowCommand) => void;
  onAfterRenderEffect: (command: EffectShowCommand) => void;
}

interface State {
  positions: Position[];
}

const updatePositions = (
  currentPositions: Position[],
  prevState: Readonly<State>,
): Partial<State> => {
  const {positions} = prevState;
  const nextPositions = [
    ...positions.filter(position => !currentPositions.includes(position)),
    ...currentPositions,
  ];
  return {
    positions: nextPositions,
  };
};

const STATE_CONTAINER_KEY = 'stage-container';

const BACKGROUND_ORDER = 'background';
const FOREGROUND_ORDER = 'foreground';

export default class Stage extends React.Component<Props, State> {
  private style: StyleProp<ViewStyle>;

  public static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State,
  ): Partial<State> | null {
    const {currentCommand} = nextProps;
    if (currentCommand instanceof SpeechTextShowCommand) {
      const ps = currentCommand.speechBalloon.getPositions();
      const currentPositions = prevState.positions.filter(position =>
        ps.includes(position),
      );
      return updatePositions(currentPositions, prevState);
    } else if (currentCommand instanceof CharacterShowCommand) {
      return updatePositions([currentCommand.position], prevState);
    } else if (currentCommand instanceof CharacterUpdateCommand) {
      return updatePositions([currentCommand.position], prevState);
    } else if (currentCommand instanceof CompositeParallelCommand) {
      const characterCommands = currentCommand.commands.filter(subCommand => {
        return (
          subCommand instanceof CharacterShowCommand ||
          subCommand instanceof CharacterUpdateCommand
        );
      }) as Array<CharacterShowCommand | CharacterUpdateCommand>;
      const currentPositions = characterCommands.map(
        characterCommand => characterCommand.position,
      );
      return updatePositions(currentPositions, prevState);
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      positions: [Position.Left, Position.Center, Position.Right],
    };
    this.style = [props.style, props.layoutManager.getStageSize()];
  }

  public shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    return !(
      nextProps.currentCommand === this.props.currentCommand &&
      nextProps.playingSound === this.props.playingSound &&
      nextProps.skipRenderText === this.props.skipRenderText &&
      arraysEqual(nextState.positions, this.state.positions)
    );
  }

  public render(): React.ReactNode {
    const {onTouch} = this.props;
    return (
      <TouchableView style={this.style} onTouch={onTouch}>
        {this.renderContainer()}
      </TouchableView>
    );
  }

  private renderContainer(): React.ReactNode {
    const {
      frame,
      currentCommand,
      layoutManager,
      skipRenderText,
      playingSound,
      orientation,
      watermarked,
      onAfterRenderBackground,
      onBeforeRenderIllustration,
      onAfterRenderIllustration,
      onFinishPlay,
      onBeforeRenderText,
      onAfterRenderText,
      onFinishPlayVoice,
      onFinishPlaySound,
      onBeforeRenderEffect,
      onAfterRenderEffect,
    } = this.props;
    const {positions} = this.state;
    return (
      <View key={STATE_CONTAINER_KEY} style={styles.container}>
        <StageSet
          style={styles.fill}
          command={frame.background}
          size={layoutManager.getStageSize()}
          onAfterRenderBackground={onAfterRenderBackground}
        />
        {frame.background?.positionedEffect &&
        frame.background.effectOptions?.layerOrder === BACKGROUND_ORDER ? (
          <StageSetEffect
            style={styles.fill}
            command={frame.background}
            size={layoutManager.getStageSize()}
            orientation={orientation}
          />
        ) : null}
        <View style={styles.stagePositions}>
          {positions.map(position =>
            this.renderStagePosition(position, layoutManager),
          )}
        </View>
        {frame.background?.positionedEffect &&
        frame.background.effectOptions?.layerOrder === FOREGROUND_ORDER ? (
          <StageSetEffect
            style={styles.fill}
            command={frame.background}
            size={layoutManager.getStageSize()}
            orientation={orientation}
          />
        ) : null}
        <StateModal
          style={styles.fill}
          command={frame.illustration}
          size={layoutManager.getIllustrationSize()}
          stageSize={layoutManager.getStageSize()}
          onBeforeRenderIllustration={onBeforeRenderIllustration}
          onAfterRenderIllustration={onAfterRenderIllustration}
          onFinishPlay={onFinishPlay}
        />
        <StageCaption
          style={styles.fill}
          command={currentCommand}
          size={layoutManager.getStageSize()}
          skipRenderText={skipRenderText}
          onBeforeRenderText={onBeforeRenderText}
          onAfterRenderText={onAfterRenderText}
          onFinishPlayVoice={onFinishPlayVoice}
          onFinishPlaySound={onFinishPlaySound}
        />
        <StageEffect
          style={styles.fill}
          command={
            currentCommand instanceof EffectShowCommand ? currentCommand : null
          }
          size={layoutManager.getStageSize()}
          orientation={orientation}
          onBeforeRenderEffect={onBeforeRenderEffect}
          onAfterRenderEffect={onAfterRenderEffect}
          onFinishPlay={onFinishPlay}
        />
        {playingSound ? <SoundAnimationIcon orientation={orientation} /> : null}
        {watermarked ? (
          <Logo
            width={layoutManager.getStageSize().width}
            orientation={orientation}
          />
        ) : null}
      </View>
    );
  }

  private renderStagePosition(
    position: Position,
    layoutManager: ViewerLayoutManager,
  ): React.ReactNode {
    const {currentCommand, orientation, onAfterRenderCharacter} = this.props;
    const active = this.activeFor(position);
    const {characterCommand} = this.getStagePositionCommands(position);
    return (
      <StagePosition
        key={position}
        position={position}
        characterCommand={characterCommand}
        characterSize={layoutManager.getCharacterSize()}
        markSize={layoutManager.getMarkSize()}
        currentSpeechBalloonPositions={
          currentCommand instanceof SpeechTextShowCommand
            ? currentCommand.speechBalloon.getPositions().length > 1
              ? currentCommand.speechBalloon.getPositions()
              : null
            : null
        }
        active={!characterCommand && active === undefined ? false : active}
        scaleUp={orientation === 'horizontal'}
        onAfterRenderCharacter={onAfterRenderCharacter}
        {...this.getStagePositionStyle(position, layoutManager)}
      />
    );
  }

  private getStagePositionCommands(position: Position) {
    const {frame} = this.props;
    switch (position) {
      case Position.Left:
        return {
          characterCommand: frame.leftCharacter,
        };
      case Position.Center:
        return {
          characterCommand: frame.centerCharacter,
        };
      case Position.Right:
        return {
          characterCommand: frame.rightCharacter,
        };
      default:
        throw new Error('Does not match Position');
    }
  }

  private getStagePositionStyle(
    position: Position,
    layoutManager: ViewerLayoutManager,
  ): {left: number; right: number} {
    const {frame, orientation} = this.props;
    const {width: fullWidth} = layoutManager.getStageSize();
    const count = frame.visibleCharacterCount();
    const width =
      fullWidth / (count > 0 ? count : 3) -
      (orientation === 'horizontal'
        ? count >= 2
          ? ((520 / fullWidth) * fullWidth - fullWidth / 3) / (count - 1)
          : 0
        : 0);
    const leftWidth =
      count === 0 || frame.visibleCharacter(Position.Left) ? width : 0;
    const centerWidth =
      count === 0 || frame.visibleCharacter(Position.Center) ? width : 0;
    const rightWidth =
      count === 0 || frame.visibleCharacter(Position.Right) ? width : 0;
    switch (position) {
      case Position.Left:
        return {
          left: 0,
          right: centerWidth + rightWidth,
        };
      case Position.Center:
        return {
          left: leftWidth,
          right: rightWidth,
        };
      case Position.Right:
        return {
          left: leftWidth + centerWidth,
          right: 0,
        };
      default:
        throw new Error('Does not match Position');
    }
  }

  private activeFor(position: Position): boolean | undefined {
    const {currentCommand} = this.props;
    if (currentCommand instanceof SpeechTextShowCommand) {
      if (currentCommand.options.overrideCharacterName) {
        return false;
      }
      return currentCommand.speechBalloon.getPositions().includes(position);
    } else if (currentCommand instanceof CharacterUpdateCommand) {
      return position === currentCommand.position;
    } else {
      return undefined;
    }
  }
}

const arraysEqual = (a: Position[], b: Position[]) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

const fill = {
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0,
} as ViewStyle;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
  } as ViewStyle,
  fill,
  stagePositions: {
    ...fill,
    flex: 1,
    flexDirection: 'row',
  } as ViewStyle,
});
