import Entity from '../Entity';

export default class UserEpisodeBrowsingHistory implements Entity {
  constructor(
    public id: number,
    public episodeId: number,
    public storyId: number,
    public createdAt: Date,
    public updatedAT: Date,
  ) {}
}
