import Resource from './Resource';

import ImageUrlHelper, {Options} from '../../helpers/ImageUrlHelper';

import CharacterResourceOptions from '../../value_objects/resource_options/CharacterResourceOptions';

export default class CharacterResource implements Resource {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public sceneIds: number[] | undefined,
    public name: string,
    public imageUrls: {[key: string]: string},
    public options: CharacterResourceOptions,
  ) {}

  public getImageUrlOf(face: string, options: Options): string {
    return ImageUrlHelper.getImageUrl(this.imageUrls[face], options);
  }
}
