import BackgroundCommandEffectOptions, {
  LayerOrder,
} from '../../../../domain/value_objects/command_options/BackgroundCommandEffectOptions';

export interface BackgroundCommandEffectOptionsData {
  layer_order?: LayerOrder;
}

export default class BackgroundCommandOptionsMapper {
  public map(
    obj: BackgroundCommandEffectOptionsData,
  ): BackgroundCommandEffectOptions {
    return new BackgroundCommandEffectOptions(obj.layer_order);
  }
}
