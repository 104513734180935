import FullScreenIllustrationCommandOptions, {
  Effect,
} from '../../../../domain/value_objects/command_options/FullScreenIllustrationCommandOptions';

export interface FullScreenIllustrationCommandOptionsData {
  effect?: Effect;
}

export default class FullScreenIllustrationCommandOptionsMapper {
  public map(
    obj: FullScreenIllustrationCommandOptionsData,
  ): FullScreenIllustrationCommandOptions {
    return new FullScreenIllustrationCommandOptions(obj.effect);
  }
}
