import Command from './Command';

import BackgroundResource from '../resources/BackgroundResource';
import PositionedEffectResource from '../resources/PositionedEffectResource';

import BackgroundCommandOptions from '../../value_objects/command_options/BackgroundCommandOptions';
import BackgroundCommandEffectOptions from '../../value_objects/command_options/BackgroundCommandEffectOptions';

export default class BackgroundShowCommand extends Command {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public sceneId: number,
    public background: BackgroundResource,
    public positionedEffect: PositionedEffectResource | undefined | null,
    public options: BackgroundCommandOptions,
    public effectOptions: BackgroundCommandEffectOptions | undefined | null,
  ) {
    super(id, sceneId);
  }
}
