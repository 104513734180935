import * as React from 'react';

import * as Immutable from 'immutable';

import CharacterHideCommand from '../../../../../../domain/entities/commands/CharacterHideCommand';
import CharacterShowCommand from '../../../../../../domain/entities/commands/CharacterShowCommand';
import CharacterUpdateCommand from '../../../../../../domain/entities/commands/CharacterUpdateCommand';

import Position from '../../../../../../domain/value_objects/Position';

interface Size {
  height: number;
  width: number;
}

export interface Props {
  position: Position;
  characterCommand:
    | CharacterShowCommand
    | CharacterUpdateCommand
    | CharacterHideCommand
    | null;
  characterSize: Size;
  markSize: Size;
  currentSpeechBalloonPositions: Position[] | null;
  active?: boolean;
  setCallback: (callback: () => void) => void;
  scaleUp?: boolean;
  onAfterRenderCharacter: (
    command:
      | CharacterShowCommand
      | CharacterUpdateCommand
      | CharacterHideCommand,
  ) => void;
}

export default class CharacterWithMarkViewBase extends React.Component<Props> {
  protected bounding = false;

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !(
      this.props.position === nextProps.position &&
      this.props.characterCommand === nextProps.characterCommand &&
      Immutable.is(
        this.props.currentSpeechBalloonPositions,
        nextProps.currentSpeechBalloonPositions,
      ) &&
      this.props.active === nextProps.active
    );
  }

  public componentDidMount() {
    const {characterCommand} = this.props;
    if (!characterCommand) {
      return;
    }
    if (characterCommand.options.waiting) {
      return;
    }
    if (!characterCommand.mark) {
      return;
    }
    if (this.bounding) {
      return;
    }
    this.bounding = true;
    this.props.setCallback(this.bounce);
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (!this.shouldBound(prevProps)) {
      return;
    }
    if (this.bounding) {
      return;
    }
    this.bounding = true;
    this.props.setCallback(this.bounce);
  }

  public bounce = () => {};

  private shouldBound(prevProps: Props): boolean {
    if (
      !prevProps.characterCommand &&
      this.props.characterCommand &&
      this.props.characterCommand.options.waiting
    ) {
      return false;
    }
    if (!this.props.characterCommand?.mark) {
      return false;
    }
    return !!(
      (!prevProps.characterCommand?.mark && this.props.characterCommand.mark) ||
      (prevProps.characterCommand?.mark &&
        this.props.characterCommand.mark &&
        prevProps.characterCommand.mark.id !==
          this.props.characterCommand.mark.id)
    );
  }
}
