import MobileDetect from 'mobile-detect';

const mobileDetect = new MobileDetect(window.navigator.userAgent);

const os = mobileDetect.os();

const isIOS = os === 'iOS';
const isAndroid = os === 'AndroidOS';

const isMobile = !!mobileDetect.mobile();

const userAgent = window.navigator.userAgent.toLowerCase();

const isChrome = userAgent.indexOf('chrome') != -1;

const isSafari =
  !(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) &&
  !(userAgent.indexOf('edge') != -1) &&
  !(userAgent.indexOf('chrome') != -1) &&
  userAgent.indexOf('safari') != -1;

const SAFE_AREA_TOP_HEIGHT = 0;

const getUserAgent = async () => {
  return new Promise((resolve, reject) => {
    resolve(navigator.userAgent);
  });
};

export {
  isAndroid,
  isIOS,
  isMobile,
  isChrome,
  isSafari,
  SAFE_AREA_TOP_HEIGHT,
  getUserAgent,
};
