import * as React from 'react';
import {Platform, StyleSheet, TextStyle, View, ViewStyle} from 'react-native';

import TypewriterEffectText from '../../scenario/TypewriterEffectText';

import EnabledSound from '../../../../shared/EnabledSound';

import {isMobile} from '../../../../../../data/data_stores/net/UserAgent';

import DescriptiveTextShowCommand from '../../../../../../domain/entities/commands/DescriptiveTextShowCommand';

interface Props {
  command: DescriptiveTextShowCommand;
  width: number;
  skipRenderText: boolean;
  textSpeed?: 'slow' | 'normal' | 'fast' | 'no_effect';
  onLoad?: () => void;
  onBeforeChangeHeight?: () => void;
  onAfterChangeHeight?: () => void;
  onBeforeRenderText: (command: DescriptiveTextShowCommand) => void;
  onAfterRenderText: (command: DescriptiveTextShowCommand) => void;
  onFinishPlayVoice: (command: DescriptiveTextShowCommand) => void;
  onFinishPlaySound: (command: DescriptiveTextShowCommand) => void;
}

export default class TextFrameView extends React.Component<Props> {
  private containerStyle: ViewStyle;
  private textStyle: TextStyle;

  constructor(props: Props) {
    super(props);
    this.containerStyle = StyleSheet.flatten<ViewStyle>([
      styles.container,
      {width: props.width},
    ]);
    this.textStyle = StyleSheet.flatten<TextStyle>([
      styles.text,
      props.command.textFrame.options.textStyle,
      {width: props.width},
      props.width > 1000 ? {fontSize: 35} : null,
    ]);
  }

  public componentDidMount() {
    const {onLoad} = this.props;
    if (onLoad) {
      onLoad();
    }
  }

  public render(): React.ReactNode {
    const {command, skipRenderText, textSpeed} = this.props;
    const {text} = command;
    return (
      <View style={this.containerStyle}>
        <TypewriterEffectText
          style={this.textStyle}
          text={text}
          skipRenderText={skipRenderText}
          active={true}
          textSpeed={textSpeed}
          onBeforeRenderText={this.handleBeforeRenderText}
          onAfterRenderText={this.handleAfterRenderText}
        />
        {command.voice ? (
          <EnabledSound
            uri={command.voice.audioUrl}
            onFinishPlay={this.handleFinishPlayVoice}
          />
        ) : null}
        {command.sound ? (
          <EnabledSound
            uri={command.getAudioUrl()}
            onFinishPlay={this.handleFinishPlaySound}
          />
        ) : null}
      </View>
    );
  }

  private handleBeforeRenderText = () => {
    const {command, onBeforeRenderText} = this.props;
    onBeforeRenderText(command);
  };

  private handleAfterRenderText = () => {
    const {command, onAfterRenderText} = this.props;
    onAfterRenderText(command);
  };

  private handleFinishPlayVoice = () => {
    const {command, onFinishPlayVoice} = this.props;
    onFinishPlayVoice(command);
  };

  private handleFinishPlaySound = () => {
    const {command, onFinishPlaySound} = this.props;
    onFinishPlaySound(command);
  };
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: 'black',
    flex: 1,
    justifyContent: 'center',
    opacity: 0.8,
    padding: 30,
  } as ViewStyle,
  text: {
    backgroundColor: 'transparent',
    color: 'white',
    ...(isMobile ? {} : {fontFamily: 'sans-serif'}),
    fontSize: 14,
    lineHeight: Platform.select({
      android: 21,
      ios: 18,
    }),
    paddingHorizontal: 10,
    paddingVertical: 10,
    //    position: "absolute",
    textAlign: 'center',
  } as TextStyle,
});
