import * as React from 'react';
import {Platform} from 'react-native';
import BaseEffectImageSequence from './BaseEffectImageSequence';

import {URL_TO_BLOB} from '../../helpers/images';

import preloadEffectImages from '../../view_models/preloadEffectImages';

const isIos = Platform.OS === 'ios';
const isAndroid = Platform.OS === 'android';

interface Size {
  height: number;
  width: number;
}

interface Props {
  urls: string[];
  size: Size;
  loop?: boolean;
  repeatCount?: number;
  pause?: boolean;
  onStart?: () => void;
  onFinish: () => void;
}

const EffectShowCommandView: React.FunctionComponent<Props> = props => {
  const {urls, onStart} = props;
  const [loaded, setLoaded] = React.useState(urls.length === 0);
  React.useEffect(() => {
    setLoaded(false);
    const prefetch = async () => {
      preloadEffectImages(urls, {
        afterAction: () => setLoaded(true),
      });
    };
    prefetch();
  }, [urls]);
  React.useEffect(() => {
    if (loaded && onStart) {
      onStart();
    }
  }, [loaded]);
  if (!loaded) {
    return null;
  }
  return <BaseEffectImageSequence {...props} sources={converSources(urls)} />;
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.pause === nextProps.pause &&
    prevProps.onFinish == nextProps.onFinish
  );
};

export default React.memo(EffectShowCommandView, areEqual);

const converSources = (urls: string[]) => {
  return urls.map(url => {
    const uri = URL_TO_BLOB[url] || url;
    return {
      uri:
        isIos && uri.startsWith('/')
          ? `file://${uri}`
          : isAndroid && uri.startsWith('file://')
          ? uri.replace(/^file:\/\//, '')
          : uri,
    };
  });
};
