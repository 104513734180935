import AccessToken from '../storage/AccessToken';

import {getUserAgent} from './UserAgent';

export interface Options {
  multipart?: boolean;
}

export interface Result<T> {
  headers: Headers;
  body: T;
  status: number;
}

export default class RestApi {
  public static async get<T>(
    url: string,
    params?: {[key: string]: any} | null,
  ): Promise<Result<T>> {
    const urlWithQuery = this.generateGetUrl(url, params);
    const headers = await this.headers();
    const response = await fetch(urlWithQuery, {
      headers,
      credentials: 'include',
    });
    return this.convertResponse(response);
  }

  public static async post<T>(
    url: string,
    params?: {[key: string]: any} | null,
    options?: Options,
  ): Promise<Result<T>> {
    const headers = await this.headers(options);
    const response = await fetch(url, {
      body: this.generateBody(params, options),
      headers,
      credentials: 'include',
      method: 'POST',
    });
    return this.convertResponse(response);
  }

  public static async patch<T>(
    url: string,
    params?: {[key: string]: any} | null,
    options?: Options,
  ): Promise<Result<T>> {
    const headers = await this.headers(options);
    const response = await fetch(url, {
      body: this.generateBody(params, options),
      headers,
      credentials: 'include',
      method: 'PATCH',
    });
    return this.convertResponse(response);
  }

  public static async delete(
    url: string,
    params?: {[key: string]: any} | null,
    options?: Options,
  ): Promise<void> {
    const headers = await this.headers(options);
    const response = await fetch(url, {
      body: this.generateBody(params, options),
      headers,
      credentials: 'include',
      method: 'DELETE',
    });
    if (response.status >= 200 && response.status < 300) {
      return;
    } else {
      const body = {};
      const ret = {
        body,
        headers: response.headers,
        status: response.status,
      };
      throw ret;
    }
  }

  private static generateGetUrl(
    url: string,
    params?: {[key: string]: any} | null,
  ): string {
    const getParams = params ? params : {};
    const queryString = this.generateQueryString(getParams);
    if (queryString.length === 0) {
      return url;
    }
    return url + (url.indexOf('?') === -1 ? '?' : '&') + queryString;
  }

  private static generateQueryString(params: {[key: string]: any}): string {
    return Object.keys(params)
      .filter(k => params[k] !== undefined)
      .map(k => {
        const v = params[k];
        return (
          encodeURIComponent(k) +
          '=' +
          (v !== null ? encodeURIComponent(v) : '')
        );
      })
      .join('&');
  }

  private static async headers(options?: Options): Promise<any> {
    const accessToken = await AccessToken.get();
    const userAgent = await getUserAgent();
    if (options && options.multipart) {
      return {
        Accept: 'application/json',
        Authorization: accessToken,
        'Content-Type': 'multipart/form-data',
        'User-Agent': userAgent,
      };
    } else {
      return {
        Accept: 'application/json',
        Authorization: accessToken,
        'Content-Type': 'application/json',
        'User-Agent': userAgent,
      };
    }
  }

  private static async convertResponse<T>(
    response: Response,
  ): Promise<Result<T>> {
    const body = response.status === 204 ? {} : await response.json();
    const ret = {
      body,
      headers: response.headers,
      status: response.status,
    };
    if (response.status >= 200 && response.status < 300) {
      return ret;
    } else {
      throw ret;
    }
  }

  private static generateBody(
    params?: {[key: string]: any} | null,
    options?: Options,
  ): FormData | string {
    const postParams = params ? params : {};
    if (options && options.multipart) {
      const formData = new FormData();
      RestApi.buildFormData(postParams, formData);
      return formData;
    } else {
      return JSON.stringify(postParams);
    }
  }

  private static buildFormData(
    postParams: {[key: string]: any},
    formData: FormData,
    namespace?: string,
  ) {
    Object.keys(postParams).forEach(key => {
      const formKey = Array.isArray(postParams)
        ? `${namespace}[]`
        : namespace
        ? `${namespace}[${key}]`
        : key;
      const value = postParams[key];
      if (value && typeof value === 'object' && !RestApi.isFileObject(value)) {
        RestApi.buildFormData(value, formData, formKey);
      } else {
        if (value === undefined) {
          return;
        } else if (value === null) {
          formData.append(formKey, '');
        } else if (RestApi.isFileObject(value)) {
          formData.append(formKey, value);
        } else {
          formData.append(formKey, value);
        }
      }
    });
  }

  private static isFileObject(obj: any): boolean {
    if (!obj) {
      return false;
    }
    if (!(typeof obj.uri === 'string')) {
      return false;
    }
    if (!(typeof obj.type === 'string')) {
      return false;
    }
    if (!(typeof obj.name === 'string')) {
      return false;
    }
    return true;
  }
}
