import EntityMapper from '../EntityMapper';

import IllustrationResource from '../../../../domain/entities/resources/IllustrationResource';

export interface IllustrationResourceData {
  id: number;
  type: 'illustration';
  scene_ids?: number[];
  image_url: string;
  full_screen: boolean;
}

export default class IllustrationResourceMapper
  implements EntityMapper<IllustrationResourceData, IllustrationResource>
{
  public map(obj: IllustrationResourceData): IllustrationResource {
    return new IllustrationResource(obj.id, obj.scene_ids, obj.image_url);
  }
}
