import Entity from './Entity';

export default class Area implements Entity {
  constructor(
    public id: number,
    public code: string,
    public name: string,
    public nameRuby: string,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}
}
