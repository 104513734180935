import * as React from 'react';

import BaseShow, {CommonProps, commonPropsAreEqual} from '../base_viewers/Show';

import NetEpisodeScriptsRepository from '../../../data/repositories/NetEpisodeScriptsRepository';
import NetAdminEpisodeScriptsRepository from '../../../data/repositories/admin/NetEpisodeScriptsRepository';

export interface Props extends CommonProps {
  episodeId: number;
  admin?: boolean;
}

const Show: React.FunctionComponent<Props> = props => {
  const {episodeId, admin} = props;
  const sceneScriptGetTask = React.useMemo(
    () =>
      (admin
        ? new NetAdminEpisodeScriptsRepository()
        : new NetEpisodeScriptsRepository()
      ).find(episodeId),
    [episodeId],
  );
  return (
    <BaseShow
      key={episodeId}
      sceneScriptGetTask={sceneScriptGetTask}
      {...props}
    />
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.episodeId === nextProps.episodeId &&
    commonPropsAreEqual(prevProps, nextProps)
  );
};

export default React.memo(Show, areEqual);
