import EntityMapper from './EntityMapper';

import Area from '../../../domain/entities/Area';

export interface AreaData {
  id: number;
  code: string;
  name: string;
  name_ruby: string;
  created_at: string;
  updated_at: string;
}

export default class AreaMapper implements EntityMapper<AreaData, Area> {
  public map(obj: AreaData): Area {
    return new Area(
      obj.id,
      obj.code,
      obj.name,
      obj.name_ruby,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
