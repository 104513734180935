import EntityMapper from '../EntityMapper';

import SoundResourceOptionsMapper, {
  SoundResourceOptionsData,
} from '../../../value_objects/mappers/resource_options/SoundResourceOptionsMapper';

import SoundResource from '../../../../domain/entities/resources/SoundResource';

export interface SoundResourceData {
  id: number;
  type: 'sound';
  scene_ids?: number[];
  name: string;
  audio_url: string;
  trimmed_audio_url?: string;
  options?: SoundResourceOptionsData;
}

export default class SoundResourceMapper
  implements EntityMapper<SoundResourceData, SoundResource>
{
  private soundResourceOptionsMapper: SoundResourceOptionsMapper;

  constructor() {
    this.soundResourceOptionsMapper = new SoundResourceOptionsMapper();
  }

  public map(obj: SoundResourceData): SoundResource {
    const options = this.soundResourceOptionsMapper.map(obj.options || {});
    return new SoundResource(
      obj.id,
      obj.scene_ids,
      obj.name,
      obj.audio_url,
      obj.trimmed_audio_url,
      options,
    );
  }
}
