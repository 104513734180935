let callbacks: Array<(timestamp: number) => void> = [];

let timerId: any = null;

export function registerAnimationFrame(callback: (timestamp: number) => void) {
  callbacks.push(callback);
  if (timerId) {
    clearTimeout(timerId);
  }
  timerId = setTimeout(reserveAnimationFrame, 10);
  return timerId;
}

export function deregisterAnimationFrame(
  callback: (timestamp: number) => void,
) {
  callbacks = callbacks.filter(c => c !== callback);
}

export function reserveAnimationFrame() {
  const cs = callbacks;
  callbacks = [];
  requestAnimationFrame((timestamp: number) => {
    cs.forEach(callback => callback(timestamp));
  });
}
