import EntityMapper from '../EntityMapper';

import UserEpisodeBrowsingHistory from '../../../../domain/entities/consumer/UserEpisodeBrowsingHistory';

export interface UserEpisodeBrowsingHistoryData {
  id: number;
  episode_id: number;
  story_id: number;
  created_at: string;
  updated_at: string;
}

export default class UserEpisodeBrowsingHistoryMapper
  implements
    EntityMapper<UserEpisodeBrowsingHistoryData, UserEpisodeBrowsingHistory>
{
  public map(obj: UserEpisodeBrowsingHistoryData): UserEpisodeBrowsingHistory {
    return new UserEpisodeBrowsingHistory(
      obj.id,
      obj.episode_id,
      obj.story_id,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
