import * as React from 'react';
import {
  Animated,
  Easing,
  ImageStyle,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import IllustrationShowCommand from '../../../../../../domain/entities/commands/IllustrationShowCommand';

import {illustrationImageUrl} from '../../../../../helpers/images';

const RESIZE_MODE = 'contain';

const cache = 'force-cache';

const animationConfig = {
  duration: 150,
  easing: Easing.linear,
  toValue: 1,
  useNativeDriver: true,
};

interface Size {
  height: number;
  width: number;
}

export interface Props {
  command: IllustrationShowCommand;
  size: Size;
  onAfterRenderIllustration: (command: IllustrationShowCommand) => void;
}

export default class IllustrationShowCommandViewBase extends React.Component<Props> {
  protected animatedImageRef = React.createRef<HTMLDivElement>();
  protected style: any;

  private timerId: any | null = null;

  constructor(props: Props) {
    super(props);
    this.style = [styles.image, props.size];
  }

  public componentDidMount() {
    const {command, onAfterRenderIllustration} = this.props;
    this.animated(() => {
      this.timerId = setTimeout(() => {
        if (this.timerId) {
          clearTimeout(this.timerId);
          this.timerId = null;
        }
        onAfterRenderIllustration(command);
      }, 200);
    });
  }

  public componentWillUnmount() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  public render(): React.ReactNode {
    const {command, size} = this.props;
    const uri = illustrationImageUrl(command.illustration, size);
    return (
      <View style={styles.container}>
        <Animated.Image
          ref={this.animatedImageRef as any}
          style={this.style}
          source={{cache, uri}}
          resizeMode={RESIZE_MODE}
          fadeDuration={0}
        />
      </View>
    );
  }

  protected animated = (callback: () => void) => {};
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
  image: {
    flex: 1,
    overflow: 'visible',
    shadowColor: 'rgba(255, 255, 255, 0.4)',
    shadowOffset: {
      height: 0,
      width: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 10,
  } as ImageStyle,
});
