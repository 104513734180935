import * as React from 'react';
import {StyleSheet, View} from 'react-native';

import Props from './ActivatableElasticTextBoxWithPromptProps';
import ActivatableElasticTextBox from './ActivatableElasticTextBox';
import {RENDERING_STRAT_TIME} from './TypewriterEffectText';

import Prompt from '../../../shared/Prompt';

import {animatedViewStyle} from '../../../../styles/variables';

const keyFrames = [{transform: 'scale(0)'}, {transform: 'scale(1)'}];

const keyFrameAnimationOptions = {
  duration: RENDERING_STRAT_TIME,
  easing: 'cubic-bezier(0, 0.1, 0.5, 1)',
};

const ActivatableElasticTextBoxWithPrompt: React.FunctionComponent<
  Props
> = props => {
  const {
    style,
    text,
    active,
    visiblePrompt,
    skipRenderText,
    windowWidth,
    onBeforeChangeHeight,
    onAfterRenderText,
  } = props;
  const ref = React.useRef<HTMLDivElement>(null);
  const [rendered, setRendered] = React.useState(skipRenderText);
  const promptTop = React.useRef<number | undefined>(undefined);
  React.useEffect(() => {
    if (onBeforeChangeHeight) {
      onBeforeChangeHeight();
    }
    const elem = ref.current;
    if (!elem) {
      return;
    }
    if (active) {
      const animation = elem.animate(keyFrames, keyFrameAnimationOptions);
      animation.addEventListener('finish', () => {
        elem.style.transform = 'scale(1)';
      });
    } else {
      elem.style.transform = 'scale(1)';
    }
  }, []);

  const handleAfterRenderText = React.useCallback(() => {
    setRendered(true);
    if (active) {
      onAfterRenderText();
    }
  }, [active]);
  const containerStyle = React.useMemo(() => {
    return StyleSheet.flatten<any>([
      {transformOrigin: '50% 50%', display: 'block'},
      style,
      animatedViewStyle,
    ]);
  }, []);
  const onChangeTopSize = React.useCallback(
    (topSize: {width: number; height: number}) => {
      promptTop.current = -topSize.height + 16;
    },
    [],
  );
  return (
    <div ref={ref} style={containerStyle}>
      <ActivatableElasticTextBox
        {...props}
        text={text.replaceAll ? text.replaceAll('️', '') : text}
        onAfterRenderText={handleAfterRenderText}
        onChangeTopSize={onChangeTopSize}
      />
      {visiblePrompt && active && rendered && (
        <View
          style={
            windowWidth >= 1280
              ? {right: 150, top: promptTop.current}
              : undefined
          }>
          <Prompt />
        </View>
      )}
    </div>
  );
};

export default ActivatableElasticTextBoxWithPrompt;
