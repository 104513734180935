import * as React from 'react';

import {animatedViewStyle} from '../../styles/variables';

import arrowUri from '../../../../../assets/images/viewer/arrow.png';

const keyFrames = [
  {transform: 'translateY(-5px)'},
  {transform: 'translateY(0px)'},
  {transform: 'translateY(-5px)'},
];

const keyFrameAnimationOptions = {
  duration: 1200,
  easing: 'ease',
  iterations: Infinity,
};

interface Props {}

const Prompt: React.FunctionComponent<Props> = props => {
  const ref = React.useRef<HTMLImageElement>(null);
  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.animate(keyFrames, keyFrameAnimationOptions);
  }, []);
  return <img ref={ref} src={arrowUri} style={style} />;
};

const style = {
  position: 'absolute',
  bottom: -20,
  right: 20,
  height: 22,
  width: 30,
  transform: 'translateY(-5px)',
  ...animatedViewStyle,
};

export default React.memo(Prompt);
