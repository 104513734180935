import * as React from 'react';
import {
  Animated,
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import FullScreenIllustrationShowCommandView from '../command_views/FullScreenIllustrationShowCommandView';
import {ShowStatus} from '../command_views/FullScreenIllustrationShowCommandViewBase';

import FullScreenIllustrationShowCommand from '../../../../../../domain/entities/commands/FullScreenIllustrationShowCommand';

interface Size {
  height: number;
  width: number;
}

export interface Props {
  command: FullScreenIllustrationShowCommand;
  size: Size;
  visiblePrompt?: boolean;
  showTap: (point: {x: number; y: number}) => void;
  onTouch: (point: {x: number; y: number}) => void;
  onBeforeRenderFullScreenIllustration: (
    command: FullScreenIllustrationShowCommand,
  ) => void;
  onRenderFullScreenIllustration: (
    command: FullScreenIllustrationShowCommand,
    success: () => void,
  ) => void;
  onAfterRenderFullScreenIllustration: (
    command: FullScreenIllustrationShowCommand,
  ) => void;
}

interface State {
  showStatus: ShowStatus;
}

export default class FullScreenIllustrationViewBase extends React.Component<
  Props,
  State
> {
  protected animatedViewRef = React.createRef<HTMLDivElement>();
  protected style: any;

  private mounted = false;
  private timerId: any | null = null;
  private hideStarted = false;

  constructor(props: Props) {
    super(props);
    this.state = {showStatus: ShowStatus.SHOW_OVERLAY_BEGIN};
    this.style = [styles.overlay, styles.whole];
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    return !(
      this.props.command === nextProps.command &&
      this.state.showStatus === nextState.showStatus
    );
  }

  public componentDidMount() {
    this.mounted = true;
    if (this.state.showStatus !== ShowStatus.SHOW_OVERLAY_BEGIN) {
      return;
    }
    const {command, onBeforeRenderFullScreenIllustration} = this.props;
    onBeforeRenderFullScreenIllustration(command);
    this.fadeIn();
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    const {
      command,
      onRenderFullScreenIllustration,
      onAfterRenderFullScreenIllustration,
    } = this.props;
    if (this.state.showStatus !== prevState.showStatus) {
      switch (this.state.showStatus) {
        case ShowStatus.SHOW_OVERLAY_END:
        case ShowStatus.HIDE_IMAGE_END:
          this.delayUpdateShowStatus(50);
          break;
        case ShowStatus.HIDE_OVERLAY_BEGIN:
          this.fadeOut();
          break;
        case ShowStatus.HIDE_OVERLAY_END:
          onAfterRenderFullScreenIllustration(command);
          break;
        case ShowStatus.SHOW_IMAGE_END:
          onRenderFullScreenIllustration(command, this.updateShowStatus);
          break;
        default:
        // noop
      }
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  public render(): React.ReactNode {
    const {command, size, visiblePrompt} = this.props;
    const {showStatus} = this.state;
    return (
      <Pressable style={styles.container} onPress={this.handlePress}>
        {showStatus !== ShowStatus.HIDE_OVERLAY_END && (
          <React.Fragment>
            <Animated.View
              ref={this.animatedViewRef as any}
              style={this.style}
            />
            <View style={styles.whole}>
              <FullScreenIllustrationShowCommandView
                command={command}
                size={size}
                showStatus={showStatus}
                visiblePrompt={visiblePrompt}
                updateShowStatus={this.updateShowStatus}
                delayUpdateShowStatus={this.delayUpdateShowStatus}
              />
            </View>
          </React.Fragment>
        )}
      </Pressable>
    );
  }

  public updateShowStatus = () => {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
    const showStatus = this.getNextShowStatus();
    if (showStatus) {
      if (this.mounted) {
        this.setState({showStatus});
      }
    }
  };

  private handlePress = (event: GestureResponderEvent) => {
    const point = {
      x: event.nativeEvent?.pageX || 20,
      y: event.nativeEvent?.pageY || 20,
    };
    if (this.state.showStatus === ShowStatus.HIDE_OVERLAY_END) {
      this.props.onTouch(point);
    } else {
      this.props.showTap(point);
    }
    if (this.hideStarted) {
      return;
    }
    if (this.state.showStatus === ShowStatus.SHOW_IMAGE_END) {
      this.hideStarted = true;
      this.updateShowStatus();
    }
  };

  private delayUpdateShowStatus = (timeout: number) => {
    this.timerId = setTimeout(this.updateShowStatus, timeout);
  };

  private getNextShowStatus(): ShowStatus | null {
    switch (this.state.showStatus) {
      case ShowStatus.SHOW_OVERLAY_BEGIN:
        return ShowStatus.SHOW_OVERLAY_END;
      case ShowStatus.SHOW_OVERLAY_END:
        return ShowStatus.SHOW_IMAGE_BEGIN;
      case ShowStatus.SHOW_IMAGE_BEGIN:
        return ShowStatus.SHOW_IMAGE_END;
      case ShowStatus.SHOW_IMAGE_END:
        return ShowStatus.HIDE_IMAGE_BEGIN;
      case ShowStatus.HIDE_IMAGE_BEGIN:
        return ShowStatus.HIDE_IMAGE_END;
      case ShowStatus.HIDE_IMAGE_END:
        return ShowStatus.HIDE_OVERLAY_BEGIN;
      case ShowStatus.HIDE_OVERLAY_BEGIN:
        return ShowStatus.HIDE_OVERLAY_END;
      case ShowStatus.HIDE_OVERLAY_END:
        return null;
      default:
        return null;
    }
  }

  private fadeIn() {
    this.animate(1);
  }

  private fadeOut() {
    this.animate(0);
  }

  protected animate(toValue: number) {}
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    flex: 1,
  } as ViewStyle,
  overlay: {
    backgroundColor: 'black',
  } as ViewStyle,
  whole: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  } as ViewStyle,
});
