import * as React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';

import EffectBackgroundShowCommandView from './command_views/EffectBackgroundShowCommandView';

import BackgroundShowCommand from '../../../../../domain/entities/commands/BackgroundShowCommand';

interface Size {
  height: number;
  width: number;
}

interface Props {
  style?: StyleProp<ViewStyle>;
  command: BackgroundShowCommand;
  size: Size;
  orientation: 'horizontal' | 'vertical';
}

const StageSet: React.FunctionComponent<Props> = props => {
  const {style, command, size, orientation} = props;
  return (
    <View style={style}>
      <EffectBackgroundShowCommandView
        command={command}
        size={size}
        orientation={orientation}
      />
    </View>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.command === nextProps.command;
};

export default React.memo(StageSet, areEqual);
