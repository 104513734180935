import * as React from 'react';
import {InteractionManager} from 'react-native';

import GLCapturedSequentialImages from '../../../shared/GLCapturedSequentialImages';

import ImagePreloader from '../../../../view_models/ImagePreloader';
import ViewerLayoutManager from '../../../../view_models/ViewerLayoutManager';

import ClearCommand from '../../../../../domain/entities/commands/ClearCommand';
import BackgroundShowCommand from '../../../../../domain/entities/commands/BackgroundShowCommand';
import CompositeParallelCommand from '../../../../../domain/entities/commands/CompositeParallelCommand';
import CompositeSequenceCommand from '../../../../../domain/entities/commands/CompositeSequenceCommand';
import Command from '../../../../../domain/entities/commands/Command';
import SceneScript from '../../../../../domain/entities/SceneScript';

import {
  backgroundImageUrl,
  BACKGROUND_FILTER_KEY_TO_BLOB,
} from '../../../../helpers/images';

interface FilterImage {
  key: string;
  uri: string;
  filter: any;
}

export interface Props {
  sceneScript: SceneScript;
  layoutManager: ViewerLayoutManager;
  fail: boolean;
  width: number;
  preloadAll?: boolean;
  onFinish: () => void;
  onReady: () => void;
  onLoadFail: (error?: any) => void;
}

interface State {
  loadedNeedFilterImages: boolean;
}

export default class ProgressBar extends React.Component<Props, State> {
  protected progressRate = 0;
  protected animatedFirst = false;

  private mounted = false;

  private filterImages: Array<FilterImage>;

  constructor(props: Props) {
    super(props);
    this.state = {
      loadedNeedFilterImages: false,
    };
    this.filterImages = this.needFilterImages();
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    return !(
      this.props.fail === nextProps.fail &&
      this.state.loadedNeedFilterImages === nextState.loadedNeedFilterImages
    );
  }

  public componentDidMount() {
    this.mounted = true;
    this.initAnimationStart();
    this.startDownload();
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    if (this.props.fail !== prevProps.fail && !this.props.fail) {
      this.initAnimationRestart();
      this.startDownload();
    }
    if (!this.animatedFirst) {
      return;
    }
    this.animateSecond();
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {layoutManager} = this.props;
    return (
      <>
        {this.renderProgressBar()}
        <GLCapturedSequentialImages
          items={this.filterImages}
          size={layoutManager.getStageSize()}
          onLoad={this.handleLoadFilterImage}
        />
      </>
    );
  }

  protected renderProgressBar = (): React.ReactNode => {
    return null;
  };

  protected initAnimationStart = () => {};

  protected initAnimationRestart = () => {};

  protected animateFirst = () => {};

  protected animateSecond = () => {};

  protected finishProgressAnimation = (state: {value: number}) => {
    const {onFinish} = this.props;
    const {loadedNeedFilterImages} = this.state;
    if (this.mounted && state.value >= 1 && loadedNeedFilterImages) {
      onFinish();
    }
  };

  private startDownload = () => {
    this.preloadImages(this.props.layoutManager, this.props.sceneScript);
    setTimeout(() => {
      InteractionManager.runAfterInteractions(this.animateFirst);
    }, 150);
  };

  private preloadImages = (
    layoutManager: ViewerLayoutManager,
    sceneScript: SceneScript,
  ) => {
    const sceneScripts = sceneScript.splitScenes();
    const {head: headSceneScripts} =
      this.devideHeadAndTailSceneScripts(sceneScripts);

    ImagePreloader.preload(headSceneScripts, layoutManager, progressRate => {
      if (this.mounted && progressRate > this.progressRate) {
        this.progressRate = progressRate;
      }
    })
      .then(() => {
        this.props.onReady();
      })
      .catch(e => {
        if (this.mounted) {
          this.props.onLoadFail(e);
        }
      });
  };

  private devideHeadAndTailSceneScripts = (sceneScripts: SceneScript[]) => {
    const {preloadAll} = this.props;
    const head: SceneScript[] = [];
    const tail: SceneScript[] = [];
    let commands: Command[] = [];
    sceneScripts.forEach(sceneScript => {
      if (commands.length <= 10 || preloadAll) {
        head.push(sceneScript);
      } else {
        tail.push(sceneScript);
      }
      commands = commands.concat(
        sceneScript.commands.filter(
          command => !(command instanceof ClearCommand),
        ),
      );
    });
    return {head, tail};
  };

  private needFilterImages = () => {
    const {sceneScript, layoutManager} = this.props;
    return sceneScript.commands
      .flatMap(command => this.getFilterBackgroundShowCommands(command))
      .map(command => {
        return {
          key: `${command.background.id}/${command.options.filter}`,
          uri: backgroundImageUrl(
            command.background,
            layoutManager.getStageSize(),
          ),
          filter: command.options.filter as any,
        };
      });
  };

  private getFilterBackgroundShowCommands = (
    command: Command,
  ): Array<BackgroundShowCommand> => {
    if (
      command instanceof BackgroundShowCommand &&
      command.options.filter &&
      command.options.filter !== 'normal'
    ) {
      return [command];
    } else if (
      command instanceof CompositeSequenceCommand ||
      command instanceof CompositeParallelCommand
    ) {
      return command.commands.flatMap(subCommand => {
        return this.getFilterBackgroundShowCommands(subCommand);
      });
    } else {
      return [];
    }
  };

  private handleLoadFilterImage = (data: {[key: string]: string}) => {
    Object.keys(data).forEach(key => {
      const blob = data[key];
      BACKGROUND_FILTER_KEY_TO_BLOB[key] = blob;
    });
    this.setState({loadedNeedFilterImages: true}, () => {
      if (this.progressRate === 1) {
        setTimeout(() => {
          this.finishProgressAnimation({value: this.progressRate});
        }, 1200);
      }
    });
  };
}
