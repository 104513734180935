import * as React from 'react';
import {
  Platform,
  StyleSheet,
  Switch,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import TextSpeedSlider from './TextSpeedSlider';

import {colors} from '../../../../styles/variables';

interface Props {
  visibleProgressBar: boolean;
  textSpeed: 'slow' | 'normal' | 'fast' | 'no_effect';
  width: number;
  disabledTextSpeedSlider?: boolean;
  onVisibleProgressBarChange?: (value: boolean) => void;
  onTextSpeedChange?: (
    textSpeed: 'slow' | 'normal' | 'fast' | 'no_effect',
  ) => void;
}

interface State {}

export default class SettingsList extends React.Component<Props, State> {
  public render(): React.ReactNode {
    const {
      visibleProgressBar,
      textSpeed,
      width,
      disabledTextSpeedSlider,
      onTextSpeedChange,
      onVisibleProgressBarChange,
    } = this.props;
    const trackColor =
      Platform.OS === 'android' ? 'rgba(255, 143, 19, .5)' : colors.orange;
    const thumbColor =
      Platform.OS === 'ios'
        ? ''
        : visibleProgressBar
        ? colors.orange
        : colors.whiteGray;
    return (
      <View style={styles.container}>
        <View style={styles.setting}>
          <View style={styles.progressBar}>
            <Text style={styles.progressBarLabel}>プログレスバー</Text>
            <Switch
              value={visibleProgressBar}
              trackColor={{
                false: colors.powderGray,
                true: trackColor,
              }}
              thumbColor={thumbColor}
              onValueChange={onVisibleProgressBarChange}
              {...({activeThumbColor: 'white'} as any)}
            />
          </View>
        </View>
        <View style={styles.separator} />
        <View style={styles.setting}>
          <View style={styles.speed}>
            <Text style={styles.speedLabel}>文字の表示スピード</Text>
            <Text style={styles.speedValue}>
              {textSpeed === 'slow'
                ? 'おそい'
                : textSpeed === 'normal'
                ? 'ふつう'
                : textSpeed === 'fast'
                ? 'はやい'
                : 'なし'}
            </Text>
          </View>
          <TextSpeedSlider
            textSpeed={textSpeed}
            width={width}
            disabled={disabledTextSpeedSlider}
            onTextSpeedChange={onTextSpeedChange}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {} as ViewStyle,
  separator: {
    width: '100%',
    height: 1,
    backgroundColor: '#cbcbcb',
  } as ViewStyle,
  setting: {} as ViewStyle,
  progressBar: {
    height: 53,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 16,
    alignItems: 'center',
  } as ViewStyle,
  progressBarLabel: {
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  progressBarValue: {} as TextStyle,
  speed: {
    height: 50,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  } as ViewStyle,
  speedLabel: {
    alignSelf: 'center',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  speedValue: {
    alignSelf: 'center',
    color: colors.orange,
    fontSize: 17,
    fontWeight: 'bold',
  } as TextStyle,
});
