export type Effect = 'appear' | 'fade_in';
export type Filter =
  | 'normal'
  | 'sepia'
  | 'monochrome'
  | 'mosaic'
  | 'dream'
  | 'red'
  | 'blue'
  | 'green';

export default class BackgroundCommandOptions {
  // eslint-disable-next-line no-useless-constructor
  constructor(public effect?: Effect, public filter?: Filter) {}
}
