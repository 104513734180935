import * as React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import ActivatableElasticTextBoxWithPrompt from './ActivatableElasticTextBoxWithPrompt';

import {speechBalloonImageUrl} from '../../../../../presentation/helpers/images';

import SpeechTextShowCommand from '../../../../../domain/entities/commands/SpeechTextShowCommand';

import AdjustableSpeechBalloon from '../../../../view_models/AdjustableSpeechBalloon';
import NameLabelColor from '../../../../../domain/value_objects/NameLabelColor';

const textPaddingStyle = {paddingTop: 15, paddingBottom: 15};

interface Props {
  style?: StyleProp<ViewStyle>;
  command: SpeechTextShowCommand;
  width: number;
  windowWidth: number;
  orientation: 'horizontal' | 'vertical';
  name?: string;
  nameLabelColor?: NameLabelColor;
  active?: boolean;
  adjustableSpeechBalloon: AdjustableSpeechBalloon;
  skipRenderText: boolean;
  alignTop?: boolean;
  visiblePrompt?: boolean;
  textSpeed?: 'slow' | 'normal' | 'fast' | 'no_effect';
  hasVoice?: boolean;
  onLoad?: () => void;
  onBeforeChangeHeight?: () => void;
  onAfterChangeHeight?: () => void;
  onBeforeRenderText: () => void;
  onAfterRenderText: () => void;
}

const TOP_IMAGE_KEY = 'top';
const MIDDLE_IMAGE_KEY = 'middle';
const BOTTOM_IMAGE_KEY = 'bottom';

const ElasticSpeechBalloon: React.FunctionComponent<Props> = props => {
  const {
    style,
    command,
    width,
    windowWidth,
    orientation,
    name,
    nameLabelColor,
    adjustableSpeechBalloon,
    skipRenderText,
    active,
    alignTop,
    visiblePrompt,
    textSpeed,
    hasVoice,
    onLoad,
    onBeforeChangeHeight,
    onAfterChangeHeight,
    onBeforeRenderText,
    onAfterRenderText,
  } = props;
  const {text, speechBalloon} = command;
  const top = React.useMemo(
    () =>
      speechBalloonImageUrl(
        TOP_IMAGE_KEY,
        adjustableSpeechBalloon,
        width,
        orientation,
      ),
    [],
  );
  const middle = React.useMemo(
    () =>
      speechBalloonImageUrl(
        MIDDLE_IMAGE_KEY,
        adjustableSpeechBalloon,
        width,
        orientation,
      ),
    [],
  );
  const bottom = React.useMemo(
    () =>
      speechBalloonImageUrl(
        BOTTOM_IMAGE_KEY,
        adjustableSpeechBalloon,
        width,
        orientation,
      ),
    [],
  );
  return (
    <ActivatableElasticTextBoxWithPrompt
      style={style}
      text={text}
      active={active}
      name={name}
      nameLabelColor={nameLabelColor}
      top={top}
      middle={middle}
      bottom={bottom}
      textStyle={speechBalloon.options.textStyle}
      wideTextStyle={speechBalloon.options.wideTextStyle}
      textPaddingStyle={textPaddingStyle}
      middleStyle={name ? speechBalloon.options.containerStyle : undefined}
      voiceIconStyle={speechBalloon.options.voiceIconStyle}
      width={width}
      windowWidth={windowWidth}
      alignTop={alignTop}
      visiblePrompt={visiblePrompt}
      skipRenderText={skipRenderText}
      textSpeed={textSpeed}
      hasVoice={hasVoice}
      onLoad={onLoad}
      onBeforeChangeHeight={onBeforeChangeHeight}
      onAfterChangeHeight={onAfterChangeHeight}
      onBeforeRenderText={onBeforeRenderText}
      onAfterRenderText={onAfterRenderText}
    />
  );
};

export default ElasticSpeechBalloon;

// MEMO 吹き出しごとのスタイル指定（データベース管理）
// const styles = StyleSheet.create({
//     middle: {
//         // 吹き出し3/10
//         // marginTop: -2,

//         // 吹き出し4
//         // marginTop: -8,

//         // 吹き出し5
//         // marginTop: -14,

//         // 吹き出し6
//         // marginTop: -11,

//         // 吹き出し7
//         // marginTop: -5,

//         // 吹き出し8
//         // marginTop: -4,
//     } as ViewStyle,
//     text: {
//         // 吹き出し1/2
//         // paddingBottom: 10,

//         // 吹き出し3
//         // paddingBottom: 1,

//         // 吹き出し7
//         // paddingBottom: 8,

//         // 吹き出し8
//         // color: "white",
//         // paddingBottom: 6,

//         // 吹き出し9
//         // color: "white",
//         // paddingBottom: 10,

//         // 吹き出し10
//         // color: "white",
//     } as TextStyle,
// });
