import {Platform, TextStyle} from 'react-native';

import {isMobile} from '../../data/data_stores/net/UserAgent';

import FontSize from '../../domain/value_objects/FontSize';
import FontColor from '../../domain/value_objects/FontColor';

export const colors = {
  brown: '#341E05',
  lightGray: '#999',
  orange: '#FF8F13',
  paleGray: '#D6D6D6',
  powderGray: '#E5E5E5',
  textColor: '#222',
  whiteGray: '#FAFAFA',
};

const width235 = {
  ...(isMobile
    ? {
        fontSize: 13,
        lineHeight: 14.8,
        paddingHorizontal: '8%',
      }
    : {
        fontSize: 9.8,
        lineHeight: 14.8,
        paddingHorizontal: '8%',
      }),
} as TextStyle;

const width291 = {
  ...(isMobile
    ? {
        fontSize: 13.2,
        lineHeight: 17.2,
        paddingHorizontal: '8%',
      }
    : {
        fontSize: 12.2,
        lineHeight: 17.2,
        paddingHorizontal: '8%',
      }),
} as TextStyle;

const width320 = {
  fontSize: isMobile ? 14 : 13,
  lineHeight: Platform.select({
    android: 21,
    ios: 18,
    web: 19,
  }),
  paddingHorizontal: '9%',
} as TextStyle;

const width360 = {
  fontSize: 15,
  lineHeight: Platform.select({
    android: 23,
    ios: 20,
    web: 23,
  }),
  paddingHorizontal: '8%',
} as TextStyle;

const width375 = {
  fontSize: isMobile ? 17 : 15.8,
  lineHeight: Platform.select({
    android: 25,
    ios: 22,
    web: 23,
  }),
  paddingHorizontal: '7.5%',
} as TextStyle;

const width411 = {
  fontSize: Platform.select({web: 18, default: 16}),
  lineHeight: Platform.select({
    android: 26,
    ios: 23,
    web: 24,
  }),
  paddingHorizontal: '8%',
} as TextStyle;

const width414 = {
  fontSize: 18.5,
  lineHeight: Platform.select({
    android: 26.5,
    ios: 23.5,
    web: 24.5,
  }),
  paddingHorizontal: '8%',
} as TextStyle;

const width1000 = {
  fontSize: 40,
  lineHeight: Platform.select({
    android: 52,
    ios: 52,
    web: 52,
  }),
  paddingHorizontal: '8%',
} as TextStyle;

const width1280 = {
  fontSize: 40,
  lineHeight: Platform.select({
    android: 52,
    ios: 52,
    web: 52,
  }),
  paddingHorizontal: '18%',
} as TextStyle;

const getTextEachSize = (width: number): TextStyle => {
  return width >= 1280
    ? width1280
    : width >= 1000
    ? width1000
    : width >= 414
    ? width414
    : width >= 411
    ? width411
    : width >= 375
    ? width375
    : width >= 360
    ? width360
    : width >= 320
    ? width320
    : width >= 291
    ? width291
    : width235;
};

export const getViewerTextBox = (width: number) => {
  return {
    color: '#222',
    ...(isMobile ? {} : {fontFamily: 'sans-serif'}),
    fontWeight: 'bold',
    ...getTextEachSize(width),
  } as TextStyle;
};

export const SCROLLBAR_WIDTH = 20;

const smallFontSize = Platform.select({
  web: 11.95,
  android: 10.4,
  default: 11.5,
});
const middleFontSize = Platform.select({
  web: 17.5,
  android: 15.8,
  default: 17.5,
});
const largeFontSize = Platform.select({
  web: 25,
  android: 21,
  default: 25,
});

export const convertFontSize = (fontSize?: FontSize | null) => {
  switch (fontSize) {
    case FontSize.Small:
      return smallFontSize;
    case FontSize.Large:
      return largeFontSize;
    default:
      return middleFontSize;
  }
};

const standardDeviceWidth = 390;

export function responsiveFontSize(value: {
  fontSize: number;
  lineHeight: number;
  width: number;
}): TextStyle {
  const {fontSize, lineHeight, width} = value;
  const percent = width / standardDeviceWidth;
  return {
    fontSize: Number.parseFloat((fontSize * percent).toFixed(1)),
    lineHeight: Number.parseFloat((lineHeight * percent).toFixed(1)),
  };
}

export const convertFontColor = (fontColor?: FontColor | null) => {
  if (!fontColor) {
    return '#222';
  }
  switch (fontColor) {
    case FontColor.White:
      return '#ffffff';
    case FontColor.Black:
      return '#1e1102';
    case FontColor.Red:
      return '#b70900';
    case FontColor.Pink:
      return '#ff2773';
    case FontColor.Orange:
      return '#ff8f13';
    case FontColor.Yellow:
      return '#f3e600';
    case FontColor.Green:
      return '#5faf5a';
    case FontColor.LightBlue:
      return '#16bed5';
    case FontColor.Blue:
      return '#3e85e0';
    case FontColor.Purple:
      return '#5a4182';
    case FontColor.Gray:
      return '#e5e5e5';
    case FontColor.SystemDefault:
      return undefined;
  }
};

export const convertStrokeFontColor = (fontColor?: FontColor | null) => {
  const color = convertFontColor(fontColor);
  if (!color) {
    return undefined;
  }
  const [r, g, b] = hex2rgb(color);
  return rgb2hex([255 - r, 255 - g, 255 - b]);
};

function hex2rgb(hex: string) {
  if (hex.slice(0, 1) == '#') hex = hex.slice(1);
  if (hex.length == 3)
    hex =
      hex.slice(0, 1) +
      hex.slice(0, 1) +
      hex.slice(1, 2) +
      hex.slice(1, 2) +
      hex.slice(2, 3) +
      hex.slice(2, 3);

  return [hex.slice(0, 2), hex.slice(2, 4), hex.slice(4, 6)].map(function (
    str,
  ) {
    return parseInt(str, 16);
  });
}

function rgb2hex(rgb: Array<number>) {
  return (
    '#' +
    rgb
      .map(function (value) {
        return ('0' + value.toString(16)).slice(-2);
      })
      .join('')
  );
}

export const animatedViewStyle: any =
  Platform.OS === 'web'
    ? {
        'will-change': 'transform',
        'backface-visibility': 'hidden',
        '-webkit-backface-visibility': 'hidden',
      }
    : null;
