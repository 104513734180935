abstract class Command {
  public parent?: Command;
  private cachedKey?: string;

  constructor(public id: number, public sceneId: number) {}

  public getKey(): string {
    if (this.cachedKey) {
      return this.cachedKey;
    }
    this.cachedKey = `${this.parent ? `${this.parent.getKey()}/` : ''}${
      this.id
    }`;
    return this.cachedKey;
  }

  public getAudioUrl(): string | null {
    return null;
  }
}

export default Command;
