import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import UserEpisodeReadingFinishTimeMapper, {
  UserEpisodeReadingFinishTimeData,
} from '../../entities/mappers/consumer/UserEpisodeReadingFinishTimeMapper';

import UserEpisodeReadingFinishTime from '../../../domain/entities/consumer/UserEpisodeReadingFinishTime';
import UserEpisodeReadingFinishTimesRepository from '../../../domain/repositories/consumer/UserEpisodeReadingFinishTimesRepository';

export default class NetUserEpisodeReadingFinishTimesRepository
  extends NetResourcesRepository<
    UserEpisodeReadingFinishTime,
    UserEpisodeReadingFinishTimeData
  >
  implements UserEpisodeReadingFinishTimesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/consumer/user_episode_reading_finish_times',
        new UserEpisodeReadingFinishTimeMapper(),
      ),
    );
  }
}
