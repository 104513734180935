import {ImageSourcePropType} from 'react-native';

const convertImageSource = (uri: string | number): ImageSourcePropType => {
  if (typeof uri === 'number') {
    return uri;
  }
  return {uri};
};

export default convertImageSource;
