import EntityMapper from '../EntityMapper';

import Command from '../../../../domain/entities/commands/Command';
import ResourcesSet from '../../../../domain/entities/resources/ResourcesSet';

import BackgroundShowCommandMapper, {
  BackgroundShowCommandData,
} from './BackgroundShowCommandMapper';
import CharacterHideCommandMapper, {
  CharacterHideCommandData,
} from './CharacterHideCommandMapper';
import CharacterShowCommandMapper, {
  CharacterShowCommandData,
} from './CharacterShowCommandMapper';
import CharacterUpdateCommandMapper, {
  CharacterUpdateCommandData,
} from './CharacterUpdateCommandMapper';
import ClearCommandMapper, {ClearCommandData} from './ClearCommandMapper';
import DescriptiveTextShowCommandMapper, {
  DescriptiveTextShowCommandData,
} from './DescriptiveTextShowCommandMapper';
import FullScreenIllustrationShowCommandMapper, {
  FullScreenIllustrationShowCommandData,
} from './FullScreenIllustrationShowCommandMapper';
import IllustrationShowCommandMapper, {
  IllustrationShowCommandData,
} from './IllustrationShowCommandMapper';
import NopCommandMapper, {NopCommandData} from './NopCommandMapper';
import SpeechTextShowCommandMapper, {
  SpeechTextShowCommandData,
} from './SpeechTextShowCommandMapper';
import CompositeParallelCommandMapper, {
  CompositeParallelCommandData,
} from './CompositeParallelCommandMapper';
import CompositeSequenceCommandMapper, {
  CompositeSequenceCommandData,
} from './CompositeSequenceCommandMapper';
import EffectShowCommandMapper, {
  EffectShowCommandData,
} from './EffectShowCommandMapper';
import SoundEffectShowCommandMapper, {
  SoundEffectShowCommandData,
} from './SoundEffectShowCommandMapper';
import BackgroundMusicShowCommandMapper, {
  BackgroundMusicShowCommandData,
} from './BackgroundMusicShowCommandMapper';
import BackgroundMusicHideCommandMapper, {
  BackgroundMusicHideCommandData,
} from './BackgroundMusicHideCommandMapper';

export type CommandData =
  | BackgroundShowCommandData
  | CharacterHideCommandData
  | CharacterShowCommandData
  | CharacterUpdateCommandData
  | ClearCommandData
  | DescriptiveTextShowCommandData
  | FullScreenIllustrationShowCommandData
  | IllustrationShowCommandData
  | NopCommandData
  | SpeechTextShowCommandData
  | CompositeParallelCommandData
  | CompositeSequenceCommandData
  | EffectShowCommandData
  | SoundEffectShowCommandData
  | BackgroundMusicShowCommandData
  | BackgroundMusicHideCommandData;

export default class CommandMapper
  implements EntityMapper<CommandData, Command>
{
  private backgroundShowCommandMapper: BackgroundShowCommandMapper;
  private characterHideCommandMapper: CharacterHideCommandMapper;
  private characterShowCommandMapper: CharacterShowCommandMapper;
  private characterUpdateCommandMapper: CharacterUpdateCommandMapper;
  private clearCommandMapper: ClearCommandMapper;
  private descriptiveTextShowCommandMapper: DescriptiveTextShowCommandMapper;
  private fullScreenIllustrationShowCommandMapper: FullScreenIllustrationShowCommandMapper;
  private illustrationShowCommandMapper: IllustrationShowCommandMapper;
  private nopCommandMapper: NopCommandMapper;
  private speechTextShowCommandMapper: SpeechTextShowCommandMapper;
  private compositeParallelCommandMapper: CompositeParallelCommandMapper;
  private compositeSequenceCommandMapper: CompositeSequenceCommandMapper;
  private effectShowCommandMapper: EffectShowCommandMapper;
  private soundEffectShowCommandMapper: SoundEffectShowCommandMapper;
  private backgroundMusicShowCommandMapper: BackgroundMusicShowCommandMapper;
  private backgroundMusicHideCommandMapper: BackgroundMusicHideCommandMapper;

  constructor(private resourcesSet: ResourcesSet) {
    this.backgroundShowCommandMapper = new BackgroundShowCommandMapper(
      resourcesSet,
    );
    this.characterHideCommandMapper = new CharacterHideCommandMapper(
      resourcesSet,
    );
    this.characterShowCommandMapper = new CharacterShowCommandMapper(
      resourcesSet,
    );
    this.characterUpdateCommandMapper = new CharacterUpdateCommandMapper(
      resourcesSet,
    );
    this.clearCommandMapper = new ClearCommandMapper(resourcesSet);
    this.descriptiveTextShowCommandMapper =
      new DescriptiveTextShowCommandMapper(resourcesSet);
    this.fullScreenIllustrationShowCommandMapper =
      new FullScreenIllustrationShowCommandMapper(resourcesSet);
    this.illustrationShowCommandMapper = new IllustrationShowCommandMapper(
      resourcesSet,
    );
    this.nopCommandMapper = new NopCommandMapper(resourcesSet);
    this.speechTextShowCommandMapper = new SpeechTextShowCommandMapper(
      resourcesSet,
    );
    this.compositeParallelCommandMapper = new CompositeParallelCommandMapper(
      resourcesSet,
    );
    this.compositeSequenceCommandMapper = new CompositeSequenceCommandMapper(
      resourcesSet,
    );
    this.effectShowCommandMapper = new EffectShowCommandMapper(resourcesSet);
    this.soundEffectShowCommandMapper = new SoundEffectShowCommandMapper(
      resourcesSet,
    );
    this.backgroundMusicShowCommandMapper =
      new BackgroundMusicShowCommandMapper(resourcesSet);
    this.backgroundMusicHideCommandMapper =
      new BackgroundMusicHideCommandMapper(resourcesSet);
  }

  public map(obj: CommandData): Command {
    switch (obj.type) {
      case 'background_show':
        return this.backgroundShowCommandMapper.map(obj);
      case 'character_hide':
        return this.characterHideCommandMapper.map(obj);
      case 'character_show':
        return this.characterShowCommandMapper.map(obj);
      case 'character_update':
        return this.characterUpdateCommandMapper.map(obj);
      case 'clear':
        return this.clearCommandMapper.map(obj);
      case 'descriptive_text_show':
        return this.descriptiveTextShowCommandMapper.map(obj);
      case 'full_screen_illustration_show':
        return this.fullScreenIllustrationShowCommandMapper.map(obj);
      case 'illustration_show':
        return this.illustrationShowCommandMapper.map(obj);
      case 'nop':
        return this.nopCommandMapper.map(obj);
      case 'speech_text_show':
        return this.speechTextShowCommandMapper.map(obj);
      case 'composite_parallel':
        return this.compositeParallelCommandMapper.map(obj);
      case 'composite_sequence':
        return this.compositeSequenceCommandMapper.map(obj);
      case 'effect_show':
        return this.effectShowCommandMapper.map(obj);
      case 'sound_effect_show':
        return this.soundEffectShowCommandMapper.map(obj);
      case 'background_music_show':
        return this.backgroundMusicShowCommandMapper.map(obj);
      case 'background_music_hide':
        return this.backgroundMusicHideCommandMapper.map(obj);
      default:
        throw new Error('Unknown command type');
    }
  }
}
