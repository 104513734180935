import * as React from 'react';
import {StyleSheet, ViewStyle} from 'react-native';

import DescriptiveTextShowCommand from '../../../../../domain/entities/commands/DescriptiveTextShowCommand';
import SpeechTextShowCommand from '../../../../../domain/entities/commands/SpeechTextShowCommand';
import CharacterResource from '../../../../../domain/entities/resources/CharacterResource';

import ElasticSpeechBalloon from './ElasticSpeechBalloon';

import EnabledSound from '../../../shared/EnabledSound';

import Frame from '../../../../view_models/Frame';

import AdjustablePosition from '../../../../../domain/value_objects/AdjustablePosition';
import Gender from '../../../../../domain/value_objects/Gender';
import Position from '../../../../../domain/value_objects/Position';

import AdjustableSpeechBalloon from '../../../../view_models/AdjustableSpeechBalloon';
import NameLabelColor from '../../../../../domain/value_objects/NameLabelColor';

interface Props {
  command: SpeechTextShowCommand;
  frame: Frame;
  width: number;
  windowWidth: number;
  orientation: 'horizontal' | 'vertical';
  active?: boolean;
  skipRenderText: boolean;
  textSpeed?: 'slow' | 'normal' | 'fast' | 'no_effect';
  alignTop?: boolean;
  visiblePrompt?: boolean;
  visibleVoiceIcon?: boolean;
  onBeforeRenderText: (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => void;
  onAfterRenderText: (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => void;
  onFinishPlayVoice: (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => void;
  onFinishPlaySound: (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => void;
}

export default class TypewriterEffectSpeechBalloon extends React.Component<Props> {
  private adjustableSpeechBalloon: AdjustableSpeechBalloon;
  private characterName: string | undefined;
  private nameLabelColor: NameLabelColor | undefined;

  constructor(props: Props) {
    super(props);
    this.adjustableSpeechBalloon = new AdjustableSpeechBalloon(
      props.command.speechBalloon,
      this.getAdjustablePosition(this.props),
    );
    this.characterName = this.getCharacterName(this.props);
    this.nameLabelColor = this.getCharacterNameLabelColor(this.props);
  }

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (!this.props.active && !nextProps.active) {
      return false;
    }
    return !(
      this.props.command === nextProps.command &&
      this.props.active === nextProps.active &&
      this.props.textSpeed === nextProps.textSpeed &&
      this.props.skipRenderText === nextProps.skipRenderText
    );
  }

  public render(): React.ReactNode {
    const {
      command,
      width,
      windowWidth,
      orientation,
      active,
      skipRenderText,
      textSpeed,

      alignTop,
      visiblePrompt,
      visibleVoiceIcon,
    } = this.props;
    return (
      <>
        <ElasticSpeechBalloon
          style={styles.container}
          command={command}
          width={width}
          windowWidth={windowWidth}
          orientation={orientation}
          name={this.characterName}
          nameLabelColor={this.nameLabelColor}
          adjustableSpeechBalloon={this.adjustableSpeechBalloon}
          active={active}
          alignTop={alignTop}
          visiblePrompt={visiblePrompt}
          skipRenderText={skipRenderText}
          textSpeed={textSpeed}
          hasVoice={visibleVoiceIcon && !!command.voice}
          onBeforeRenderText={this.handleBeforeRenderText}
          onAfterRenderText={this.handleAfterRenderText}
        />
        {command.voice && active ? (
          <EnabledSound
            uri={command.voice.audioUrl}
            onFinishPlay={this.handleFinishPlayVoice}
          />
        ) : null}
        {command.sound && active ? (
          <EnabledSound
            uri={command.getAudioUrl()}
            onFinishPlay={this.handleFinishPlaySound}
          />
        ) : null}
      </>
    );
  }

  private handleBeforeRenderText = () => {
    const {command, onBeforeRenderText} = this.props;
    onBeforeRenderText(command);
  };

  private handleAfterRenderText = () => {
    const {command, onAfterRenderText} = this.props;
    onAfterRenderText(command);
  };

  private handleFinishPlayVoice = () => {
    const {command, onFinishPlayVoice} = this.props;
    onFinishPlayVoice(command);
  };

  private handleFinishPlaySound = () => {
    const {command, onFinishPlaySound} = this.props;
    onFinishPlaySound(command);
  };

  private getCharacterName = (props: Props): string | undefined => {
    const {command} = props;
    const characters = this.getCharacters(props);
    if (command.options.overrideCharacterName) {
      if (command.options.characterName) {
        return command.options.characterName;
      } else {
        return undefined;
      }
    }
    if (command.speechBalloon.getPositions().length > 1) {
      return undefined;
    }
    if (characters.length > 1) {
      return undefined;
    }
    return characters.map(this.characterToName)[0];
  };

  private characterToName = (character: CharacterResource) => character.name;

  private getCharacterNameLabelColor = (
    props: Props,
  ): NameLabelColor | undefined => {
    const {command} = props;
    if (command.options.characterNameLabelColor) {
      return command.options.characterNameLabelColor;
    }
    if (command.options.overrideCharacterName) {
      return NameLabelColor.Black;
    }
    if (command.speechBalloon.getPositions().length > 1) {
      return undefined;
    }
    const characters = this.getCharacters(props);
    if (characters) {
      if (characters.length === 1) {
        const character = characters[0];
        if (character.options.nameLabelColor) {
          return character.options.nameLabelColor;
        }
        switch (character.options.gender) {
          case Gender.Male:
            return NameLabelColor.Blue;
          case Gender.Female:
            return NameLabelColor.Pink;
          case Gender.Other:
            return NameLabelColor.Green;
          default:
          // noop
        }
      } else {
        return NameLabelColor.Green;
      }
    } else {
      return undefined;
    }
  };

  private getCharacters = (props: Props): CharacterResource[] => {
    const {command, frame} = props;
    const ret: CharacterResource[] = [];
    command.speechBalloon.getPositions().forEach(pos => {
      if (!frame.visibleCharacter(pos)) {
        return;
      }
      switch (pos) {
        case Position.Left:
          if (frame.leftCharacter) {
            ret.push(frame.leftCharacter.character);
          }
          return null;
        case Position.Center:
          if (frame.centerCharacter) {
            ret.push(frame.centerCharacter.character);
          }
          return null;
        case Position.Right:
          if (frame.rightCharacter) {
            ret.push(frame.rightCharacter.character);
          }
          return null;
        default:
        // noop
      }
    });
    return ret;
  };

  private notFoundCharacterCount = (props: Props): number => {
    const {command, frame} = props;
    const positions = command.speechBalloon.getPositions();
    let count = 0;

    if (positions.includes(Position.Left)) {
      if (!frame.visibleCharacter(Position.Left)) {
        count += 1;
      }
    }
    if (positions.includes(Position.Center)) {
      if (!frame.visibleCharacter(Position.Center)) {
        count += 1;
      }
    }
    if (positions.includes(Position.Right)) {
      if (!frame.visibleCharacter(Position.Right)) {
        count += 1;
      }
    }
    return count;
  };

  private getAdjustablePosition = (props: Props): AdjustablePosition => {
    const {command, frame} = props;
    const notFoundCount = this.notFoundCharacterCount(props);
    const count = frame.visibleCharacterCount() + notFoundCount;
    if (count === 1) {
      if (notFoundCount === 1) {
        if (command.speechBalloon.matchesPositions([Position.Left])) {
          return AdjustablePosition.Left;
        } else if (command.speechBalloon.matchesPositions([Position.Right])) {
          return AdjustablePosition.Right;
        }
      }
      return AdjustablePosition.Center;
    }
    if (count >= 3 || count <= 0) {
      if (command.speechBalloon.matchesPositions([Position.Left])) {
        return AdjustablePosition.Left;
      } else if (command.speechBalloon.matchesPositions([Position.Center])) {
        return AdjustablePosition.Center;
      } else if (command.speechBalloon.matchesPositions([Position.Right])) {
        return AdjustablePosition.Right;
      } else if (
        command.speechBalloon.matchesPositions([Position.Left, Position.Center])
      ) {
        return AdjustablePosition.Left_Center;
      } else if (
        command.speechBalloon.matchesPositions([
          Position.Center,
          Position.Right,
        ])
      ) {
        return AdjustablePosition.Center_Right;
      } else if (
        command.speechBalloon.matchesPositions([Position.Left, Position.Right])
      ) {
        return AdjustablePosition.Left_Right;
      } else if (
        command.speechBalloon.matchesPositions([
          Position.Left,
          Position.Center,
          Position.Right,
        ])
      ) {
        return AdjustablePosition.Left_Center_Right;
      } else {
        throw Error('Does not match positions');
      }
    }
    if (command.speechBalloon.matchesPositions([Position.Left])) {
      return AdjustablePosition.CenterLeft;
    } else if (command.speechBalloon.matchesPositions([Position.Center])) {
      if (frame.visibleCharacter(Position.Left)) {
        return AdjustablePosition.CenterRight;
      } else {
        return AdjustablePosition.CenterLeft;
      }
    } else if (command.speechBalloon.matchesPositions([Position.Right])) {
      return AdjustablePosition.CenterRight;
    } else if (
      command.speechBalloon.matchesPositions([Position.Left, Position.Center])
    ) {
      if (frame.visibleCharacter(Position.Left)) {
        if (frame.visibleCharacter(Position.Center)) {
          return AdjustablePosition.CenterLeft_CenterRight;
        }
        return AdjustablePosition.Center_Right;
      } else if (frame.visibleCharacter(Position.Center)) {
        return AdjustablePosition.Left_Center;
      }
      return AdjustablePosition.Left_Center;
    } else if (
      command.speechBalloon.matchesPositions([Position.Center, Position.Right])
    ) {
      if (frame.visibleCharacter(Position.Center)) {
        if (frame.visibleCharacter(Position.Right)) {
          return AdjustablePosition.CenterLeft_CenterRight;
        }
        return AdjustablePosition.Center_Right;
      } else if (frame.visibleCharacter(Position.Right)) {
        return AdjustablePosition.Left_Center;
      }
      return AdjustablePosition.Center_Right;
    } else if (
      command.speechBalloon.matchesPositions([Position.Left, Position.Right])
    ) {
      if (frame.visibleCharacter(Position.Left)) {
        if (frame.visibleCharacter(Position.Right)) {
          return AdjustablePosition.CenterLeft_CenterRight;
        }
        return AdjustablePosition.Center_Right;
      } else if (frame.visibleCharacter(Position.Right)) {
        return AdjustablePosition.Left_Center;
      }
      return AdjustablePosition.Left_Right;
    } else {
      throw Error('Does not match positions');
    }
  };
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 5,
  } as ViewStyle,
});
