import * as React from 'react';
import {AppState} from 'react-native';

import SoundPlayer from './SoundPlayer';

export interface Props {
  uri?: string | null;
  loop?: boolean;
  fade?: boolean;
  mute?: boolean;
  volumeHalved?: boolean;
  onBeforePlay?: () => void;
  onAfterPlay?: () => void;
  onFinishPlay?: () => void;
}

const SoundPlayable: React.FunctionComponent<Props> = props => {
  const {
    uri,
    loop,
    fade,
    mute,
    volumeHalved,
    onBeforePlay,
    onAfterPlay,
    onFinishPlay,
  } = props;
  const soundPlayerRef = React.useRef(
    new SoundPlayer({loop, mute, initialVolume: volumeHalved ? 0.5 : 1}),
  );
  const stopSound = React.useCallback(() => {
    if (soundPlayerRef.current.isPlaying()) {
      if (fade && !mute) {
        soundPlayerRef.current.fadeOut();
      } else {
        soundPlayerRef.current.stop();
      }
    }
  }, [mute, soundPlayerRef.current, fade]);
  React.useEffect(() => {
    if (mute !== undefined) {
      if (mute) {
        soundPlayerRef.current.mute();
      } else {
        soundPlayerRef.current.unmute();
      }
    }
  }, [mute]);
  React.useEffect(() => {
    if (mute) {
      return;
    }
    if (volumeHalved !== undefined) {
      if (volumeHalved) {
        soundPlayerRef.current.setVolumne(0.5);
      } else {
        soundPlayerRef.current.setVolumne(1);
      }
    }
  }, [mute, volumeHalved]);
  React.useEffect(() => {
    if (uri) {
      onBeforePlay && onBeforePlay();
      if (soundPlayerRef.current.isPlaying()) {
        soundPlayerRef.current.stop();
      }
      soundPlayerRef.current = new SoundPlayer({
        loop,
        mute,
        initialVolume: volumeHalved ? 0.5 : 1,
      });
      soundPlayerRef.current.play(uri, () => {
        onAfterPlay && onAfterPlay();
        onFinishPlay && onFinishPlay();
      });
    } else {
      stopSound();
    }
  }, [uri]);
  React.useEffect(() => {
    const subscription = AppState.addEventListener('change', nextAppState => {
      if (soundPlayerRef.current.isPlaying()) {
        if (nextAppState === 'active') {
          soundPlayerRef.current.resume();
        } else {
          soundPlayerRef.current.pause();
        }
      }
    }) as any;
    return () => {
      stopSound();
      subscription?.remove();
    };
  }, []);
  return null;
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.uri === nextProps.uri &&
    prevProps.volumeHalved === nextProps.volumeHalved &&
    prevProps.mute === nextProps.mute
  );
};

export default React.memo(SoundPlayable, areEqual);
