import * as React from 'react';
import {
  FlatList,
  ImageBackground,
  ImageStyle,
  ListRenderItemInfo,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import ClearCommand from '../../../../../domain/entities/commands/ClearCommand';
import Command from '../../../../../domain/entities/commands/Command';
import DescriptiveTextShowCommand from '../../../../../domain/entities/commands/DescriptiveTextShowCommand';
import SpeechTextShowCommand from '../../../../../domain/entities/commands/SpeechTextShowCommand';

import TypewriterEffectSpeechBalloon from './TypewriterEffectSpeechBalloon';
import TypewriterEffectTextFrame from './TypewriterEffectTextFrame';

import Prompt from '../../../shared/Prompt';
import TouchableView from '../../../shared/TouchableView';

import Frame from '../../../../view_models/Frame';
import ViewerLayoutManager from '../../../../view_models/ViewerLayoutManager';

import convertImageSource from '../../../../helpers/convertImageSource';

import {colors} from '../../../../styles/variables';

import tapBackgroundUri from '../../../../../../../assets/images/viewer/tap_background.png';
import tapBackgroundLargeUri from '../../../../../../../assets/images/viewer/tap_background_large.png';

const tapBackgroundSource = convertImageSource(tapBackgroundUri);
const tapBackgroundLargeSource = convertImageSource(tapBackgroundLargeUri);

const Footer = () => <View style={{width: '100%', height: 50}} />;
const propsAreEqual = () => true;
const ListFooterComponent = React.memo(Footer, propsAreEqual);

interface Item {
  command: SpeechTextShowCommand | DescriptiveTextShowCommand;
  frame: Frame;
  active: boolean;
}

interface Props {
  commands: Command[];
  frame: Frame;
  layoutManager: ViewerLayoutManager;
  skipRenderText: boolean;
  pausing: boolean;
  orientation: 'horizontal' | 'vertical';
  textSpeed?: 'slow' | 'normal' | 'fast' | 'no_effect';
  visiblePrompt?: boolean;
  visibleVoiceIcon?: boolean;
  onTouch: (point: {x: number; y: number}) => void;
  onBeforeRenderText: (
    command: SpeechTextShowCommand | DescriptiveTextShowCommand,
  ) => void;
  onAfterRenderText: (
    command: SpeechTextShowCommand | DescriptiveTextShowCommand,
  ) => void;
  onFinishPlayVoice: (
    command: SpeechTextShowCommand | DescriptiveTextShowCommand,
  ) => void;
  onFinishPlaySound: (
    command: SpeechTextShowCommand | DescriptiveTextShowCommand,
  ) => void;
  onScrolling: () => void;
  onScrolledToTop: () => void;
}

interface State {
  data: Item[];
  commandLength: number;
  scrolledToTop: boolean;
}

const RESIZE_MODE = 'repeat';

const RESIZE_METHOD = 'resize';

export default class ScenarioVertical extends React.Component<Props, State> {
  private scrollViewRef = React.createRef<FlatList<Item>>();

  private mounted = false;

  private extraData: any = null;

  public static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State,
  ): Partial<State> | null {
    if (prevState.commandLength === nextProps.commands.length) {
      return null;
    }
    const commandLength = nextProps.commands.length;
    const command = ScenarioVertical.getLastCommand(nextProps);
    const data = command instanceof ClearCommand ? [] : [...prevState.data];
    const currentLastItem = data[0];
    if (prevState.commandLength === nextProps.commands.length - 1) {
      if (command && currentLastItem?.command !== command) {
        if (currentLastItem) {
          currentLastItem.active = false;
        }
        const {frame} = nextProps;
        ScenarioVertical.buildData(data, Frame.copy(frame), command);
        return {data, commandLength};
      } else {
        return null;
      }
    } else {
      const data: Item[] = [];
      let frame = new Frame();
      ScenarioVertical.filteredCommands(nextProps).forEach(command => {
        if (data[0]) {
          data[0].active = false;
        }
        frame.update(command);
        ScenarioVertical.buildData(data, frame, command);
        frame = Frame.copy(frame);
      });
      return {data, commandLength};
    }
  }

  private static filteredCommands(nextProps: Readonly<Props>): Command[] {
    const {commands} = nextProps;
    const lastIndex = commands
      .map(command => command instanceof ClearCommand)
      .lastIndexOf(true);
    return commands.slice(Math.max(lastIndex, 0));
  }

  private static buildData = (data: Item[], frame: Frame, command: Command) => {
    if (
      command instanceof DescriptiveTextShowCommand &&
      !command.textFrame.caption
    ) {
      const item = {
        active: true,
        command,
        frame,
      };
      data.unshift(item);
    } else if (command instanceof SpeechTextShowCommand) {
      const item = {
        active: true,
        command,
        frame,
      };
      data.unshift(item);
    }
  };

  private static getLastCommand(nextProps: Readonly<Props>): Command | null {
    const {commands} = nextProps;
    const lastCommand = commands[commands.length - 1];
    if (lastCommand) {
      return lastCommand;
    } else {
      return null;
    }
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      data: [],
      commandLength: 0,
      scrolledToTop: true,
    };
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    if (
      !(
        nextProps.pausing === this.props.pausing &&
        nextProps.textSpeed === this.props.textSpeed &&
        nextProps.skipRenderText === this.props.skipRenderText &&
        nextState.scrolledToTop === this.state.scrolledToTop &&
        nextState.data.length === this.state.data.length &&
        nextState.commandLength === this.state.commandLength
      )
    ) {
      if (
        !(
          nextProps.textSpeed === this.props.textSpeed &&
          nextProps.skipRenderText === this.props.skipRenderText
        )
      ) {
        this.extraData = [nextProps.skipRenderText, nextProps.textSpeed];
      }
      return true;
    } else {
      return false;
    }
  }

  public render(): React.ReactNode {
    const {layoutManager, pausing, visiblePrompt} = this.props;
    const {data} = this.state;
    return (
      <View style={styles.container}>
        <ImageBackground
          style={styles.image}
          resizeMode={RESIZE_MODE}
          resizeMethod={RESIZE_METHOD}
          source={
            layoutManager.getSize().width >= 1000
              ? tapBackgroundLargeSource
              : tapBackgroundSource
          }>
          <TouchableView
            style={styles.scrollView as StyleProp<ViewStyle>}
            onTouch={this.handleTouch}>
            {visiblePrompt && pausing && data.length === 0 && (
              <View style={styles.prompt}>
                <Prompt />
              </View>
            )}
            <FlatList
              ref={this.scrollViewRef}
              data={data}
              onScroll={this.handleScroll}
              keyExtractor={this.keyExtractor}
              renderItem={this.renderItem}
              ListFooterComponent={ListFooterComponent}
              contentContainerStyle={styles.contentContainerStyle}
              showsVerticalScrollIndicator={false}
              windowSize={1}
              extraData={this.extraData}
            />
          </TouchableView>
        </ImageBackground>
      </View>
    );
  }

  private keyExtractor = (item: Item, index: number): string => {
    return item.command.getKey();
  };

  private renderItem = (
    info: ListRenderItemInfo<Item>,
  ): React.ReactElement<any> | null => {
    const {
      layoutManager,
      skipRenderText,
      orientation,
      textSpeed,
      visiblePrompt,
      visibleVoiceIcon,
      onBeforeRenderText,
      onAfterRenderText,
      onFinishPlayVoice,
      onFinishPlaySound,
    } = this.props;
    if (!layoutManager) {
      return null;
    }
    const {active, command, frame} = info.item;
    if (command instanceof DescriptiveTextShowCommand) {
      if (command.textFrame.caption) {
        return null;
      }
      return (
        <TypewriterEffectTextFrame
          command={command}
          frame={frame}
          width={layoutManager.getTextFrameSize().width}
          windowWidth={layoutManager.getSize().width}
          orientation={orientation}
          active={active}
          skipRenderText={active ? skipRenderText : false}
          visiblePrompt={visiblePrompt}
          visibleVoiceIcon={visibleVoiceIcon}
          onBeforeRenderText={onBeforeRenderText}
          onAfterRenderText={onAfterRenderText}
          onFinishPlayVoice={onFinishPlayVoice}
          onFinishPlaySound={onFinishPlaySound}
          textSpeed={textSpeed}
        />
      );
    } else {
      return (
        <TypewriterEffectSpeechBalloon
          command={command}
          frame={frame}
          width={layoutManager.getSpeechBalloonSize().width}
          windowWidth={layoutManager.getSize().width}
          orientation={orientation}
          active={active}
          skipRenderText={active ? skipRenderText : false}
          visiblePrompt={visiblePrompt}
          visibleVoiceIcon={visibleVoiceIcon}
          onBeforeRenderText={onBeforeRenderText}
          onAfterRenderText={onAfterRenderText}
          onFinishPlayVoice={onFinishPlayVoice}
          onFinishPlaySound={onFinishPlaySound}
          textSpeed={textSpeed}
        />
      );
    }
  };

  private handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    const {onScrolledToTop, onScrolling} = this.props;
    if (this.mounted) {
      const nextScrolledToTop = event.nativeEvent.contentOffset.y === 0;
      if (this.state.scrolledToTop !== nextScrolledToTop) {
        this.setState({scrolledToTop: nextScrolledToTop}, () => {
          if (nextScrolledToTop) {
            onScrolledToTop();
          } else {
            onScrolling();
          }
        });
      }
    }
  };

  private handleTouch = (point: {x: number; y: number}) => {
    const {onTouch} = this.props;
    if (this.scrollViewRef.current) {
      if (this.state.scrolledToTop) {
        onTouch(point);
      } else {
        if (this.state.data.length > 0) {
          this.scrollViewRef.current.scrollToIndex({
            viewPosition: 0,
            index: 0,
          });
        }
      }
    } else {
      onTouch(point);
    }
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  image: {
    backgroundColor: colors.brown,
    flex: 1,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  } as ImageStyle,
  prompt: {
    position: 'absolute',
    right: 0,
    top: 10,
  } as ViewStyle,
  scrollView: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  } as ViewStyle,
  contentContainerStyle: {
    paddingBottom: 10,
  } as ViewStyle,
});
