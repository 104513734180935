import {TextStyle, ViewStyle} from 'react-native';

export default class SpeechBalloonResourceOptions {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public containerStyle?: ViewStyle,
    public textStyle?: TextStyle,
    public wideTextStyle?: TextStyle,
    public voiceIconStyle?: ViewStyle,
    public adjustable?: {[key: string]: {[key: string]: string}},
    public wideAdjustable?: {[key: string]: {[key: string]: string}},
  ) {}
}
