import * as React from 'react';
import {Pressable, StyleSheet, View, ViewStyle} from 'react-native';

import ListModal from './header/ListModal';
import Menu from './header/Menu';
import Settings from './header/Settings';
import SettingsList from './header/SettingsList';

import Command from '../../../../domain/entities/commands/Command';

interface Props {
  width: number;
  command?: Command | null;
  disableNextScene?: boolean;
  disablePrevScene?: boolean;
  visibleProgressBar: boolean;
  textSpeed: 'slow' | 'normal' | 'fast' | 'no_effect';
  disabledTextSpeedSlider?: boolean;
  onPressNextScene?: () => void;
  onPressPrevScene?: () => void;
  renderMenuCommands?: (options?: {
    command?: Command | null;
    disableNextScene?: boolean;
    disablePrevScene?: boolean;
    onPressNextScene?: () => void;
    onPressPrevScene?: () => void;
  }) => React.ReactNode;
  onVisibleProgressBarChange?: (value: boolean) => void;
  onTextSpeedChange?: (
    textSpeed: 'slow' | 'normal' | 'fast' | 'no_effect',
  ) => void;
  onToggleMenu: (visible: boolean, requiredRestart?: boolean) => void;
}

interface State {
  visibleMenu: boolean;
  visibleSettings: boolean;
}

export default class Header extends React.Component<Props, State> {
  private mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      visibleMenu: false,
      visibleSettings: false,
    };
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    return !(
      this.state.visibleMenu === nextState.visibleMenu &&
      this.state.visibleSettings === nextState.visibleSettings &&
      this.props.disableNextScene === nextProps.disableNextScene &&
      this.props.disablePrevScene === nextProps.disablePrevScene &&
      this.props.visibleProgressBar === nextProps.visibleProgressBar &&
      this.props.textSpeed === nextProps.textSpeed
    );
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {
      width,
      command,
      disableNextScene,
      disablePrevScene,
      visibleProgressBar,
      textSpeed,
      disabledTextSpeedSlider,
      renderMenuCommands,
      onVisibleProgressBarChange,
      onTextSpeedChange,
    } = this.props;
    const {visibleMenu, visibleSettings} = this.state;
    return (
      <>
        <View style={styles.container}>
          <View style={styles.icons}>
            <Settings onPress={this.handlePressSettings} />
            {renderMenuCommands && <Menu onPress={this.handlePressMenu} />}
          </View>
        </View>
        {visibleMenu && renderMenuCommands && (
          <View style={styles.modalWrapper}>
            <ListModal onCloseModal={this.handlePressCloseButton}>
              {renderMenuCommands({
                command,
                disableNextScene,
                disablePrevScene,
                onPressNextScene: this.handlePressNextScene,
                onPressPrevScene: this.handlePressPrevScene,
              })}
            </ListModal>
            <Pressable
              style={styles.background}
              onPress={this.handlePressCloseButton}
            />
          </View>
        )}
        {visibleSettings && (
          <View style={styles.modalWrapper}>
            <ListModal onCloseModal={this.handlePressCloseButton}>
              <SettingsList
                visibleProgressBar={visibleProgressBar}
                textSpeed={textSpeed}
                width={width}
                disabledTextSpeedSlider={disabledTextSpeedSlider}
                onVisibleProgressBarChange={onVisibleProgressBarChange}
                onTextSpeedChange={onTextSpeedChange}
              />
            </ListModal>
            <Pressable
              style={styles.background}
              onPress={this.handlePressCloseButton}
            />
          </View>
        )}
      </>
    );
  }

  private handlePressMenu = () => {
    const {onToggleMenu} = this.props;
    if (this.mounted) {
      onToggleMenu(true);
      this.setState({visibleMenu: true, visibleSettings: false});
    }
  };

  private handlePressSettings = () => {
    const {onToggleMenu} = this.props;
    if (this.mounted) {
      onToggleMenu(true);
      this.setState({visibleMenu: false, visibleSettings: true});
    }
  };

  private handlePressCloseButton = () => {
    this.handleCloseMenuModal(true);
  };

  private handleCloseMenuModal = (requiredRestart: boolean) => {
    const {onToggleMenu} = this.props;
    if (this.mounted) {
      onToggleMenu(false, requiredRestart);
      this.setState({visibleMenu: false, visibleSettings: false});
    }
  };

  private handlePressNextScene = () => {
    const {onPressNextScene} = this.props;
    if (onPressNextScene) {
      onPressNextScene();
      this.handleCloseMenuModal(false);
    }
  };

  private handlePressPrevScene = () => {
    const {onPressPrevScene} = this.props;
    if (onPressPrevScene) {
      onPressPrevScene();
      this.handleCloseMenuModal(false);
    }
  };
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    right: 0,
    top: 0,
  } as ViewStyle,
  modalWrapper: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  } as ViewStyle,
  background: {
    flex: 1,
  } as ViewStyle,
  icons: {
    flexDirection: 'row',
    flex: 1,
  } as ViewStyle,
});
