import * as React from 'react';
import {GestureResponderEvent} from 'react-native';

export interface Props {
  onTouch: (point: {x: number; y: number}) => void;
}

export default class TouchableBaseView<T> extends React.PureComponent<
  Props & T
> {
  private pressInLocation: {
    pageX: number;
    pageY: number;
  } | null = null;

  protected handleTouchStart = (event: GestureResponderEvent) => {
    const {pageX, pageY} = event.nativeEvent;
    this.pressInLocation = {pageX, pageY};
  };

  protected handleTouchEnd = (event: GestureResponderEvent) => {
    const {onTouch} = this.props;
    const {pageX: aX, pageY: aY} = event.nativeEvent;
    if (this.pressInLocation) {
      const {pageX: bX, pageY: bY} = this.pressInLocation;
      if (this.getDistanceBetweenPoints(aX, aY, bX, bY) > 10) {
        return;
      }
    }
    onTouch({x: aX, y: aY});
  };

  private getDistanceBetweenPoints = (
    aX: number,
    aY: number,
    bX: number,
    bY: number,
  ): number => {
    const deltaX = aX - bX;
    const deltaY = aY - bY;
    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  };
}
