import TapNovelRestApi from '../data_stores/net/TapNovelRestApi';

import EntityMapper from '../entities/mappers/EntityMapper';

import Entity from '../../domain/entities/Entity';
import ResourceRepository from '../../domain/repositories/ResourceRepository';

export default class NetResourceRepositoryAdapter<E extends Entity, D>
  implements ResourceRepository<E>
{
  constructor(private endPoint: string, public mapper: EntityMapper<D, E>) {}

  public find(): Promise<E> {
    const path = `${this.endPoint}`;
    return TapNovelRestApi.get<D>(path).then(obj => {
      return this.mapper.map(obj.body, obj.headers);
    });
  }

  public create(
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<E> {
    const path = this.endPoint;
    return TapNovelRestApi.post<D>(path, params, options).then(obj => {
      return this.mapper.map(obj.body, obj.headers);
    });
  }

  public update(
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<E> {
    const path = `${this.endPoint}`;
    return TapNovelRestApi.patch<D>(path, params, options).then(obj => {
      return this.mapper.map(obj.body, obj.headers);
    });
  }

  public destroy(): Promise<void> {
    const path = `${this.endPoint}`;
    return TapNovelRestApi.delete(path).then(() => {
      return;
    });
  }
}
