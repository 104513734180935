import React, {Component} from 'react';
import {
  Dimensions,
  EmitterSubscription,
  ScaledSize,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import querystring from 'querystring';

import MenuCommands from './presentation/components/shared/viewer/MenuCommands';
import DownloadProgressModalTop from './presentation/components/shared/viewer/DownloadProgressModalTop';
import Footer from './presentation/components/shared/viewer/Footer';

import createConsumerTemporaryUser from './presentation/actions/consumer/temporary_users/registration/create';

import createConsumerUserEpisodeBrowsingHistoryAndReadingTime from './presentation/actions/consumer/user_episode_browsing_history_and_reading_times/create';
import createConsumerUserEpisodeReadingFinishTime from './presentation/actions/consumer/user_episode_reading_finish_times/create';
import createConsumerUserSceneTapsBatch, {
  Params as ConsumerUserSceneTapsBatchCreateParams,
} from './presentation/actions/consumer/user_scene_taps_batch/create';
import updateConsumerUserEpisodeBrowsingLog from './presentation/actions/consumer/user_episode_browsing_logs/update';
import createEpisodeViewerErrorLog from './presentation/actions/episode_viewer_error_logs/create';

import ConsumerCurrentUser from './domain/entities/consumer/CurrentUser';
import ConsumerUserEpisodeBrowsingHistoryAndReadingTime from './domain/entities/consumer/UserEpisodeBrowsingHistoryAndReadingTime';

import StorageAccessTokenRepository from './data/repositories/StorageAccessTokenRepository';
import StorageViewerSettingsRepository from './data/repositories/StorageViewerSettingsRepository';

import {
  EpisodeViewer,
  SceneScript,
  SceneTapForm,
} from './vendor/react-native-tapnovel-viewer';

interface Props {}

interface State {
  accessToken: string | null;
  consumerCurrentUser: ConsumerCurrentUser | null;
  window: ScaledSize;
  visibleProgressBar: boolean;
  textSpeed: 'slow' | 'normal' | 'fast' | 'no_effect';
  autoPlaySpeed: 0 | 1 | 1.5 | 2;
  enabledSound: boolean;
}

class App extends Component<Props, State> {
  private consumerUserSceneTaps: ConsumerUserSceneTapsBatchCreateParams | null;
  private tapCount = 0;

  private timerId: any | null = null;
  private lastTapCommandIndex: number | null = null;
  private updateLastTapCommandIndexTimerId: any | null = null;

  private viewerSettingsRepository: StorageViewerSettingsRepository =
    new StorageViewerSettingsRepository();

  private consumerUserEpisodeBrowsingHistoryAndReadingTime: ConsumerUserEpisodeBrowsingHistoryAndReadingTime | null =
    null;

  private dimensionsChangeEmitterSubscription: EmitterSubscription | null =
    null;

  constructor(props: Props) {
    super(props);
    this.state = {
      accessToken: null,
      consumerCurrentUser: null,
      window: Dimensions.get('window'),
      visibleProgressBar: true,
      textSpeed: 'fast',
      enabledSound: true,
      autoPlaySpeed: 0,
    };
    this.consumerUserSceneTaps = null;
  }

  public async componentDidMount() {
    this.receiveViewerEvent();
    this.fireViewerEvent('getAccessToken');
    this.dimensionsChangeEmitterSubscription = Dimensions.addEventListener(
      'change',
      this.handleChangeWindowSize,
    );
    this.consumerUserSceneTaps = null;
    this.consumerUserSceneTaps = {
      consumerUserSceneTaps: [],
    };
    this.tapCount = 0;
    const viewerSetting = await this.viewerSettingsRepository.find();
    const visibleProgressBar = viewerSetting.visibleProgressBar;
    const textSpeed = viewerSetting.textSpeed;
    const enabledSound =
      viewerSetting.enabledSound === undefined
        ? true
        : viewerSetting.enabledSound;
    const autoPlaySpeed =
      viewerSetting.autoPlaySpeed === undefined
        ? 1
        : viewerSetting.autoPlaySpeed;
    this.setState({
      visibleProgressBar,
      textSpeed,
      enabledSound,
      autoPlaySpeed,
    });
  }

  public componentWillUnmount(): void {
    this.dimensionsChangeEmitterSubscription?.remove();
  }

  public render() {
    const {height, width} = this.state.window;
    const {accessToken, consumerCurrentUser, visibleProgressBar, textSpeed} =
      this.state;
    const episodeId = this.getId();
    const admin = this.getAdmin();
    const autoPlaySpeed = this.getAutoPlaySpeed();
    const forceTextSpeed = this.getForceTextSpeed();
    const enabledSound = this.getEnabledSound();
    const initialCommandId = this.getInitialCommandId();
    const forceForward = this.getForceForward();
    const forceCurrentIndex = this.getForceCurrentIndex();
    const mode = this.getMode();
    const orientation = this.getOrientation();
    const preloadAll = this.getPreloadAll();
    const hiddenDownloadProgress = this.getHiddenDownloadProgress();
    const watermarked = this.getWatermarked();
    if (!episodeId || episodeId < 0) {
      return null;
    }
    if (admin) {
      if (!this.useSessionCookie() && !accessToken) {
        return null;
      }
    } else if (this.isTracking()) {
      if (!accessToken) {
        return null;
      }
      if (!consumerCurrentUser) {
        return null;
      }
    }
    return (
      <View key={orientation} style={[styles.container, {height, width}]}>
        <EpisodeViewer.default
          episodeId={episodeId}
          admin={admin}
          visibleProgressBar={
            orientation === 'horizontal' ? false : visibleProgressBar
          }
          textSpeed={textSpeed}
          autoPlaySpeed={autoPlaySpeed}
          forceTextSpeed={forceTextSpeed}
          enabledSound={enabledSound}
          visibleInterceptForStarting={true}
          initialCommandId={initialCommandId}
          forceForward={forceForward}
          forceCurrentIndex={forceCurrentIndex}
          mode={mode}
          orientation={orientation}
          preloadAll={preloadAll}
          hiddenDownloadProgress={hiddenDownloadProgress}
          watermarked={watermarked}
          renderMenuCommands={this.renderMenuCommands}
          renderDownloadProgressModalTop={this.renderDownloadProgressModalTop}
          renderFooter={this.renderFooter}
          onRequestClose={this.handleRequestClose}
          onVisibleProgressBarChange={this.handleVisibleProgressBarChange}
          onTextSpeedChange={this.handleTextSpeedChange}
          onAutoPlaySpeedChange={this.handleAutoPlaySpeedChange}
          onEnabledSoundChange={this.handleEnabledSoundChange}
          onStart={this.handleStart}
          onFinish={this.handleFinish}
          onLoadFail={this.handleLoadFail}
          onTouch={this.handleTouch}
          onCommandIndexChange={this.handleCommandIndexChange}
        />
      </View>
    );
  }

  private handleChangeWindowSize = ({
    window,
    screen,
  }: {
    window: ScaledSize;
    screen: ScaledSize;
  }) => {
    this.setState({window});
  };

  private renderMenuCommands = (options?: {
    command?: {id: number; sceneId: number} | null;
    disableNextScene?: boolean;
    disablePrevScene?: boolean;
    onPressNextScene?: () => void;
    onPressPrevScene?: () => void;
  }): React.ReactNode => {
    return (
      <MenuCommands
        onPressFinish={this.handleRequestClose}
        disableNextScene={options ? options.disableNextScene : undefined}
        disablePrevScene={options ? options.disablePrevScene : undefined}
        onPressNextScene={options ? options.onPressNextScene : undefined}
        onPressPrevScene={options ? options.onPressPrevScene : undefined}
      />
    );
  };

  private renderDownloadProgressModalTop = (width: number): React.ReactNode => {
    const {consumerCurrentUser} = this.state;
    if (!consumerCurrentUser) {
      return null;
    }
    if (
      consumerCurrentUser.enabledPaidSubscriber ||
      consumerCurrentUser.adBlocking
    ) {
      return null;
    }
    return <DownloadProgressModalTop />;
  };

  private renderFooter = (): React.ReactNode => {
    const {consumerCurrentUser} = this.state;
    if (
      consumerCurrentUser?.enabledPaidSubscriber ||
      consumerCurrentUser?.adBlocking
    ) {
      return null;
    }
    if (this.getOrientation() === 'horizontal') {
      return null;
    }
    return (
      <Footer admin={this.getAdmin()} onPressFinish={this.handleRequestClose} />
    );
  };

  private handleStart = (sceneScript: SceneScript) => {
    if (document.body) {
      document.body.dataset['version'] = `${sceneScript.version}`;
      document.body.dataset['story_id'] = `${sceneScript.storyId}`;
    }
    this.fireViewerEvent('startViewer');
    if (!this.isTracking()) {
      return;
    }
    this.createTemporaryUser(() => {
      this.startEpisodeBrowsing(sceneScript);
    });
  };

  private handleFinish = async (sceneScript: SceneScript) => {
    if (!this.isTracking()) {
      this.fireViewerEvent('finishViewer');
      return;
    }
    if (this.consumerUserSceneTaps) {
      if (this.consumerUserSceneTaps.consumerUserSceneTaps.length > 0) {
        try {
          await this.sendConsumerUserSceneTap();
        } catch {
          //
        }
      }
    }
    if (this.consumerUserEpisodeBrowsingHistoryAndReadingTime) {
      const consumerUserEpisodeReadingStartTimeId =
        this.consumerUserEpisodeBrowsingHistoryAndReadingTime
          .consumerUserEpisodeReadingStartTime.id;
      const episodeBrowsingLogsCount =
        this.consumerUserEpisodeBrowsingHistoryAndReadingTime
          .episodeBrowsingLogsCount;
      try {
        await createConsumerUserEpisodeReadingFinishTime({
          consumerUserEpisodeReadingStartTimeId,
        }).then(consumerUserEpisodeReadingFinishTime => {
          this.fireViewerEvent(
            `createEpisodeReadingFinishTime/${consumerUserEpisodeReadingFinishTime.id}/${episodeBrowsingLogsCount}`,
          );
        });
      } catch {
        //
      }
    }
    this.fireViewerEvent('finishViewer');
  };

  private handleLoadFail = (error: any) => {
    const episodeId = this.getId();
    createEpisodeViewerErrorLog({
      episodeId,
      errorUrl: `${this.getTargetOrigin()}/episodes/${episodeId}/viewer`,
      errorInfo: {
        name: error.name,
        message: error.message,
        stack: error.stack,
      },
      platform: 'web',
    });
    this.fireViewerEvent('loadFail');
  };

  private handleRequestClose = async () => {
    if (!this.isTracking()) {
      this.fireViewerEvent('closeViewer');
      return;
    }
    if (
      this.consumerUserEpisodeBrowsingHistoryAndReadingTime &&
      this.consumerUserEpisodeBrowsingHistoryAndReadingTime
        .consumerUserEpisodeBrowsingLog
    ) {
      const id =
        this.consumerUserEpisodeBrowsingHistoryAndReadingTime
          .consumerUserEpisodeBrowsingLog.id;
      const tapCount = this.getTapCount();
      await updateConsumerUserEpisodeBrowsingLog(id, {
        exitedAt: new Date(),
        tapCount,
        ...(this.lastTapCommandIndex
          ? {lastTapCommandIndex: this.lastTapCommandIndex}
          : {}),
      });
    }
    this.fireViewerEvent('closeViewer');
  };

  private handleTouch = (sceneTapForm: SceneTapForm) => {
    if (!this.isTracking()) {
      return;
    }
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    if (this.consumerUserSceneTaps) {
      this.consumerUserSceneTaps.consumerUserSceneTaps.push(sceneTapForm);
      this.tapCount += 1;
      if (this.consumerUserSceneTaps.consumerUserSceneTaps.length >= 10) {
        this.sendConsumerUserSceneTap();
      } else {
        this.timerId = setTimeout(() => {
          this.sendConsumerUserSceneTap();
        }, 2500);
      }
    }
  };

  private handleCommandIndexChange = (
    lastTapCommandIndex: number,
    lastCommandIndex: number | null,
    timeout = 2000,
  ) => {
    if (!this.isTracking()) {
      return;
    }
    this.lastTapCommandIndex = lastTapCommandIndex;
    if (this.updateLastTapCommandIndexTimerId) {
      clearTimeout(this.updateLastTapCommandIndexTimerId);
    }
    if (
      this.consumerUserEpisodeBrowsingHistoryAndReadingTime &&
      this.consumerUserEpisodeBrowsingHistoryAndReadingTime
        .consumerUserEpisodeBrowsingLog
    ) {
      const id =
        this.consumerUserEpisodeBrowsingHistoryAndReadingTime
          .consumerUserEpisodeBrowsingLog.id;
      const tapCount = this.getTapCount();
      if (
        lastCommandIndex !== null &&
        lastTapCommandIndex >= lastCommandIndex
      ) {
        this.updateLastTapCommandIndexTimerId = null;
        updateConsumerUserEpisodeBrowsingLog(id, {
          finishedAt: new Date(),
          tapCount,
          lastTapCommandIndex: lastCommandIndex,
        });
      } else {
        this.updateLastTapCommandIndexTimerId = setTimeout(
          async () => {
            await updateConsumerUserEpisodeBrowsingLog(id, {
              tapCount,
              lastTapCommandIndex,
            });
          },
          lastTapCommandIndex && lastTapCommandIndex % 10 === 0 ? 0 : timeout,
        );
      }
    }
  };

  private handleVisibleProgressBarChange = (visibleProgressBar: boolean) => {
    this.setState({visibleProgressBar});
    this.viewerSettingsRepository.update({visibleProgressBar});
  };

  private handleTextSpeedChange = (
    textSpeed: 'slow' | 'normal' | 'fast' | 'no_effect',
  ) => {
    this.setState({textSpeed});
    this.viewerSettingsRepository.update({textSpeed});
  };

  private handleAutoPlaySpeedChange = (autoPlaySpeed: 0 | 1 | 1.5 | 2) => {
    this.setState({autoPlaySpeed: autoPlaySpeed});
    this.viewerSettingsRepository.update({autoPlaySpeed});
    this.fireViewerEvent(
      `updateAutoPlaySpeed/${this.getId()}/${autoPlaySpeed}`,
    );
  };

  private handleEnabledSoundChange = (enabledSound: boolean) => {
    this.setState({enabledSound});
    this.viewerSettingsRepository.update({enabledSound});
    this.fireViewerEvent(
      `updateEnabledSound/${this.getId()}/${enabledSound ? 'on' : 'off'}`,
    );
  };

  private startEpisodeBrowsing = (sceneScript: SceneScript) => {
    const episodeId = this.getId();
    createConsumerUserEpisodeBrowsingHistoryAndReadingTime({
      episodeId,
      episodeVersion: sceneScript.version,
      episodeCommandsLength: sceneScript.commands.length,
      platform: 'web',
    }).then(consumerUserEpisodeBrowsingHistoryAndReadingTime => {
      this.consumerUserEpisodeBrowsingHistoryAndReadingTime =
        consumerUserEpisodeBrowsingHistoryAndReadingTime;
      const episodeBrowsingLogsCount =
        this.consumerUserEpisodeBrowsingHistoryAndReadingTime
          .episodeBrowsingLogsCount;
      this.fireViewerEvent(
        `createEpisodeReadingStartTime/${consumerUserEpisodeBrowsingHistoryAndReadingTime.consumerUserEpisodeReadingStartTime.id}/${episodeBrowsingLogsCount}`,
      );
    });
  };

  private getTapCount = () => {
    return this.tapCount;
  };

  private fireViewerEvent = (eventName: string) => {
    if (window === window.parent) {
      window.dispatchEvent(new Event(eventName));
      return;
    }
    const targetOrigin = this.getTargetOrigin();
    if (!targetOrigin) {
      return;
    }
    const targetWindow = this.getTargetWindow();
    if (!targetWindow) {
      return;
    }
    targetWindow.postMessage(eventName, targetOrigin);
  };

  private getTargetWindow = (): Window | undefined => {
    return Object.hasOwnProperty.call(window.parent, 'postMessage')
      ? window.parent
      : Object.hasOwnProperty.call(window.parent.window, 'postMessage')
      ? window.parent.window
      : window.parent;
  };

  private getTargetOrigin = () => {
    return process.env.REACT_APP_TAPNOVEL_API_ENDPOINT;
  };

  private receiveViewerEvent = () => {
    window.addEventListener('message', event => {
      if (
        typeof event.data === 'string' &&
        event.data.startsWith('sendAccessToken')
      ) {
        const token = event.data.split(',')[1];
        new StorageAccessTokenRepository().update(token);
        this.setState({accessToken: token}, () => {
          this.createTemporaryUser(() => {});
        });
      }
    });
  };

  private getId = () => {
    const params = this.getParams();
    return Number(params.episode_id);
  };

  private getInitialCommandId = () => {
    const params = this.getParams();
    return Number(params.initial_command_id || 0);
  };

  private getForceForward = () => {
    const params = this.getParams();
    return ['true', '1', 'on'].includes(String(params.force_forward)) || false;
  };

  private getForceCurrentIndex = () => {
    const params = this.getParams();
    if (this.lastTapCommandIndex) {
      return this.lastTapCommandIndex + 1;
    }
    if (params.force_current_index) {
      return Number(params.force_current_index);
    } else {
      return undefined;
    }
  };

  private getOrientation = () => {
    const {width} = this.state.window;
    if (!width) {
      return 'vertical';
    }
    if (width >= 1280) {
      return 'horizontal';
    } else {
      return 'vertical';
    }
  };

  private getAutoPlaySpeed = () => {
    const params = this.getParams();
    switch (Number(params.auto_play_speed)) {
      case 1:
        return 1;
      case 1.5:
        return 1.5;
      case 2:
        return 2;
      default:
        return this.state.autoPlaySpeed;
    }
  };

  private getEnabledSound = () => {
    const params = this.getParams();
    const value = params.enabled_sound;
    if (value === '1' || value === 'true') {
      return true;
    } else if (value === '0' || value === 'false') {
      return false;
    } else {
      return this.state.enabledSound;
    }
  };

  private getForceTextSpeed = ():
    | 'slow'
    | 'normal'
    | 'fast'
    | 'no_effect'
    | undefined => {
    const params = this.getParams();
    const value = params.force_text_speed;
    if (
      value === 'slow' ||
      value === 'normal' ||
      value === 'fast' ||
      value === 'no_effect'
    ) {
      return value;
    }
    return undefined;
  };

  private getMode = () => {
    const params = this.getParams();
    switch (params.mode) {
      case 'normal':
        return 'normal';
      case 'video':
        return 'video';
      default:
        return 'normal';
    }
  };

  private getPreloadAll = () => {
    const params = this.getParams();
    switch (params.preload) {
      case 'partial':
        return false;
      case 'all':
        return true;
      default:
        return false;
    }
  };

  private getHiddenDownloadProgress = () => {
    const params = this.getParams();
    const value = params.hidden_download_progress;
    if (value === '1' || value === 'true') {
      return true;
    }
    return false;
  };

  private getAdmin = () => {
    const params = this.getParams();
    const value = params.admin;
    if (value === '1' || value === 'true') {
      return true;
    }
    return false;
  };

  private getWatermarked = () => {
    const params = this.getParams();
    const value = params.watermarked;
    if (value === '1' || value === 'true') {
      return true;
    }
    if (this.getOrientation() == 'horizontal') {
      return true;
    }
    return false;
  };

  private getParams = () => {
    return querystring.parse(window.location.search.slice(1));
  };

  private isTracking = () => {
    return this.getMode() === 'normal' && !this.getAdmin();
  };

  private useSessionCookie = () => {
    const params = this.getParams();
    const value = params.use_session_cookie;
    if (value === '1' || value === 'true') {
      return true;
    }
    return false;
  };

  private createTemporaryUser = async (
    callback: (consumerCurrentUser: ConsumerCurrentUser) => void,
  ): Promise<void> => {
    if (!this.isTracking()) {
      return;
    }
    const {consumerCurrentUser} = this.state;
    if (!consumerCurrentUser) {
      try {
        await createConsumerTemporaryUser().then(val => {
          this.setState({consumerCurrentUser: val}, () => {
            callback(val);
          });
        });
      } catch {
        //
      }
    } else {
      callback(consumerCurrentUser);
    }
  };

  private sendConsumerUserSceneTap = async () => {
    if (!this.consumerUserSceneTaps) {
      this.consumerUserSceneTaps = {consumerUserSceneTaps: []};
    }
    const consumerUserSceneTaps = this.consumerUserSceneTaps;
    this.consumerUserSceneTaps = {
      consumerUserSceneTaps: [],
    };
    const currentTapCount = consumerUserSceneTaps.consumerUserSceneTaps.length;
    try {
      await this.createTemporaryUser(() => {});
      await createConsumerUserSceneTapsBatch(consumerUserSceneTaps).then(
        sceneTapsBatch => {
          this.fireViewerEvent(
            `createSceneTapsBatch/${sceneTapsBatch.jobId}/${currentTapCount}`,
          );
        },
      );
    } catch {
      //
    }
  };
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'black',
    margin: '0 auto',
    userSelect: 'none',
  } as ViewStyle,
});

export default App;
