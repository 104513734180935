import EntityMapper from './EntityMapper';

import EpisodeViewerErrorLog from '../../../domain/entities/EpisodeViewerErrorLog';

export interface EpisodeViewerErrorLogData {
  id: number;
}

export default class EpisodeViewerErrorLogMapper
  implements EntityMapper<EpisodeViewerErrorLogData, EpisodeViewerErrorLog>
{
  public map(obj: EpisodeViewerErrorLogData): EpisodeViewerErrorLog {
    return new EpisodeViewerErrorLog(obj.id);
  }
}
