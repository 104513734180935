import * as React from 'react';
import {GestureResponderEvent} from 'react-native';

import InterceptForStartingBase from './InterceptForStartingBase';

export default class InterceptForStarting extends InterceptForStartingBase {
  protected handlePress = () => {
    const {onPress} = this.props;
    if (this.pressed) {
      return;
    }
    this.pressed = true;
    this.transform(
      this.letsTapRef,
      {opacity: '0', scale: '1.6'},
      {duration: 250, easing: 'cubic-bezier(.98,-0.67,.53,.96)'},
      onPress,
    );
  };

  protected handlePressIn = (event: GestureResponderEvent) => {
    if (this.pressed) {
      return;
    }
    this.setStateIfMounted({pressing: true}, () => {
      this.transform(
        this.letsTapRef,
        {opacity: '1', scale: '0.9'},
        {duration: 250, easing: 'linear'},
      );
      this.transform(
        this.handTappedRef,
        {opacity: '1', scale: '0.9'},
        {duration: 34, easing: 'linear'},
      );
    });
  };

  protected handlePressOut = (event: GestureResponderEvent) => {
    setTimeout(() => {
      if (this.pressed) {
        return;
      }
      this.setStateIfMounted({pressing: false}, () => {
        this.transform(
          this.letsTapRef,
          {opacity: '1', scale: '1'},
          {duration: 250, easing: 'linear'},
        );
        this.transform(
          this.handDefaultRef,
          {opacity: '1', scale: '1'},
          {duration: 34, easing: 'linear'},
        );
      });
    }, 100);
  };

  private transform = (
    ref: React.RefObject<HTMLDivElement>,
    style: {opacity: string; scale: string},
    options: KeyframeAnimationOptions,
    callback?: () => void,
  ) => {
    const elem = ref.current;
    if (!elem) {
      return;
    }
    const {opacity, scale} = style;
    elem.animate([{opacity, scale}], options).addEventListener('finish', () => {
      elem.style.opacity = opacity;
      elem.style.scale = scale;
      callback && callback();
    });
  };
}
