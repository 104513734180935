import AccessTokenRepository from '../../repositories/AccessTokenRepository';

import CurrentUser from '../../entities/consumer/CurrentUser';

import CurrentUserRepository from '../../repositories/consumer/CurrentUserRepository';
import RegistrationRepository from '../../repositories/consumer/users/RegistrationRepository';

export default class UserAuthentication {
  private accessToken: string | null = null;

  constructor(
    private accessTokenRepository: AccessTokenRepository,
    private registrationRepository: RegistrationRepository,
    private userRepository: CurrentUserRepository,
  ) {}

  public isAuthenticated(): boolean {
    return this.accessToken !== null;
  }

  public async authenticate(
    params: {[key: string]: any} = {},
  ): Promise<CurrentUser> {
    if (this.accessToken) {
      return new Promise(() => {
        return this.accessToken;
      });
    }
    const accessToken = await this.accessTokenRepository.find();
    if (accessToken === null || accessToken === '') {
      return this.registrationRepository.create(params).then(currentUser => {
        if (!currentUser.accessToken) {
          throw new Error('Authrication Error!');
        }
        this.accessToken = currentUser.accessToken;
        this.accessTokenRepository.update(this.accessToken);
        return currentUser;
      });
    } else {
      this.accessToken = accessToken;
      return this.userRepository.find().then(ret => {
        return ret;
      });
    }
  }
}
