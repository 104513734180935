import * as React from 'react';
import {Animated, StyleSheet, View, ViewStyle} from 'react-native';

import IllustrationShowCommandView from '../command_views/IllustrationShowCommandView';

import EnabledSound from '../../../../shared/EnabledSound';

import IllustrationShowCommand from '../../../../../../domain/entities/commands/IllustrationShowCommand';

interface Size {
  height: number;
  width: number;
}

export interface Props {
  command: IllustrationShowCommand;
  size: Size;
  onBeforeRenderIllustration: (command: IllustrationShowCommand) => void;
  onAfterRenderIllustration: (command: IllustrationShowCommand) => void;
  onFinishPlay: (command: IllustrationShowCommand) => void;
}

interface State {
  completed: boolean;
}

export default class IllustrationViewBase extends React.Component<
  Props,
  State
> {
  protected animatedViewRef = React.createRef<HTMLDivElement>();
  protected innerStyle: any;

  private mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      completed: false,
    };
    this.innerStyle = [styles.overlay, styles.whole];
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    return !(
      this.props.command === nextProps.command &&
      this.state.completed === nextState.completed
    );
  }

  public componentDidMount() {
    const {command, onBeforeRenderIllustration} = this.props;
    this.mounted = true;
    onBeforeRenderIllustration(command);
    this.animated(() => {
      if (this.mounted) {
        this.setState({completed: true});
      }
    });
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {command, size, onAfterRenderIllustration} = this.props;
    const {completed} = this.state;
    return (
      <View style={styles.container}>
        <Animated.View
          ref={this.animatedViewRef as any}
          style={this.innerStyle}
        />
        <View style={styles.whole}>
          {completed && (
            <IllustrationShowCommandView
              command={command}
              size={size}
              onAfterRenderIllustration={onAfterRenderIllustration}
            />
          )}
        </View>
        {command.sound ? (
          <EnabledSound
            uri={command.getAudioUrl()}
            onFinishPlay={this.handleFinishPlay}
          />
        ) : null}
      </View>
    );
  }

  protected animated = (callback: () => void) => {};

  private handleFinishPlay = () => {
    const {command, onFinishPlay} = this.props;
    onFinishPlay(command);
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  overlay: {
    backgroundColor: 'black',
  } as ViewStyle,
  whole: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  } as ViewStyle,
});
