import EffectCommandOptions from '../../../../domain/value_objects/command_options/EffectCommandOptions';

export interface EffectCommandOptionsData {
  loop?: boolean;
  repeat_count?: number;
  start_index?: number;
  end_index?: number;
}

export default class EffectCommandOptionsMapper {
  public map(obj: EffectCommandOptionsData): EffectCommandOptions {
    return new EffectCommandOptions(
      obj.loop,
      obj.repeat_count,
      obj.start_index,
      obj.end_index,
    );
  }
}
