import * as React from 'react';

import FullScreenIllustrationViewBase, {
  Props,
} from './FullScreenIllustrationViewBase';

const keyFrameAnimationOptions = {
  duration: 50,
  easing: 'linear',
};

export default class FullScreenIllustrationView extends FullScreenIllustrationViewBase {
  constructor(props: Props) {
    super(props);
    this.style = [this.style, {opacity: 0}];
  }

  protected animate(toValue: number) {
    const animatedView = this.animatedViewRef.current;
    if (!animatedView) {
      return;
    }
    const opacity = `${toValue}`;
    animatedView
      .animate([{opacity}], keyFrameAnimationOptions)
      .addEventListener('finish', () => {
        animatedView.style.opacity = opacity;
        this.updateShowStatus();
      });
  }
}
