import EntityMapper from '../EntityMapper';

import BackgroundResource from '../../../../domain/entities/resources/BackgroundResource';

export interface BackgroundResourceData {
  id: number;
  type: 'background';
  scene_ids?: number[];
  image_url: string;
}

export default class BackgroundResourceMapper
  implements EntityMapper<BackgroundResourceData, BackgroundResource>
{
  public map(obj: BackgroundResourceData): BackgroundResource {
    return new BackgroundResource(obj.id, obj.scene_ids, obj.image_url);
  }
}
