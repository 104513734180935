import {audioContext} from './AudioContextInitializer.web';
import {URL_TO_BLOB} from '../helpers/images';

const ua = navigator.userAgent.toLowerCase();
const isIE = ua.indexOf('msie') != -1 || ua.indexOf('trident') != -1;
const isChrome = ua.indexOf('chrome') != -1;

export const IMAGE_URL_TO_DECODED: {[key: string]: boolean} = {};

export const AUDIO_URL_TO_DECODED: {[key: string]: AudioBuffer} = {};

export const decodedAsset = (url: string) => {
  return decodedImage(url) || decodedAudio(url);
};

export const decodeAsset = (url: string): Promise<any> => {
  if (
    url.endsWith('.png') ||
    url.endsWith('.jpg') ||
    url.endsWith('.jpeg') ||
    url.includes('/actor_character_faces/images/')
  ) {
    return decodeImage(url);
  } else if (url.endsWith('.mp3')) {
    return decodeAudio(url);
  } else {
    return new Promise((resolve, reject) => resolve(null));
  }
};

export const decodedImage = (url: string) => Boolean(IMAGE_URL_TO_DECODED[url]);

export const decodeImage = (url: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const blobUrl = URL_TO_BLOB[url];
    if (URL_TO_BLOB[url]) {
      return resolve(blobUrl);
    }
    createBlobUrl(url).then(resolve).catch(reject);
  });
};

export const decodedAudio = (url: string) => Boolean(AUDIO_URL_TO_DECODED[url]);

export const decodeAudio = (url: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(isChrome ? `${url}?t=${new Date().getTime()}` : url, {mode: 'cors'})
      .then(response => response.arrayBuffer())
      .then(audioData => {
        if (!audioContext) {
          return resolve(null);
        }
        audioContext.decodeAudioData(audioData, (audioBuffer: AudioBuffer) => {
          AUDIO_URL_TO_DECODED[url] = audioBuffer;
          resolve(audioBuffer);
        });
      })
      .catch(() => {
        reject();
      });
  });
};

const headers = {
  Accept: 'image/webp,image/apng,*/*',
};

const fetchOptions = isIE
  ? {}
  : {
      headers,
    };

const createBlobUrl = async (url: string) => {
  const blob = await (await fetch(url, fetchOptions)).blob();
  const blobUrl = URL.createObjectURL(blob);
  URL_TO_BLOB[url] = blobUrl;
  IMAGE_URL_TO_DECODED[blobUrl] = true;
  return blobUrl;
};
