import Command from './Command';

import CharacterResource from '../resources/CharacterResource';
import MarkResource from '../resources/MarkResource';

import CharacterCommandOptions from '../../value_objects/command_options/CharacterCommandOptions';

import Position from '../../value_objects/Position';

export default class CharacterShowCommand extends Command {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public sceneId: number,
    public character: CharacterResource,
    public position: Position,
    public face: string,
    public mark: MarkResource | null,
    public options: CharacterCommandOptions,
  ) {
    super(id, sceneId);
  }
}
