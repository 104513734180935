import * as React from 'react';

import {positionedEffectImageUrls} from '../../../../../helpers/images';
import EffectImageSequence from '../../../../shared/EffectImageSequence';

import EffectShowCommand from '../../../../../../domain/entities/commands/EffectShowCommand';

interface Size {
  height: number;
  width: number;
}

interface Props {
  command: EffectShowCommand;
  size: Size;
  orientation: 'horizontal' | 'vertical';
  onLoad: () => void;
  onBeforeRenderEffect: (command: EffectShowCommand) => void;
  onAfterRenderEffect: (command: EffectShowCommand) => void;
}

const EffectShowCommandView: React.FunctionComponent<Props> = props => {
  const {
    command,
    size,
    orientation,
    onLoad,
    onBeforeRenderEffect,
    onAfterRenderEffect,
  } = props;
  React.useEffect(() => {
    onBeforeRenderEffect(command);
  }, []);
  const onStart = React.useCallback(() => {
    onLoad();
  }, [command]);
  const onFinish = React.useCallback(() => {
    onAfterRenderEffect(command);
  }, [command]);
  return (
    <EffectImageSequence
      urls={sliceUrls(
        positionedEffectImageUrls(command.positionedEffect, size, orientation),
        command.options.startIndex,
        command.options.endIndex,
      )}
      size={size}
      onStart={onStart}
      onFinish={onFinish}
    />
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.command === nextProps.command;
};

export default React.memo(EffectShowCommandView, areEqual);

const sliceUrls = (urls: string[], startIndex?: number, endIndex?: number) => {
  if (!endIndex) {
    return urls;
  }
  return urls.slice(startIndex, endIndex + 1);
};
