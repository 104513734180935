import * as React from 'react';
import {
  Image,
  ImageSourcePropType,
  ImageStyle,
  LayoutChangeEvent,
  PixelRatio,
  Platform,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import FrameEdgeImage from './FrameEdgeImage';
import FrameMiddleImage from './FrameMiddleImage';

import NameLabel from '../../../shared/NameLabel';

import NameLabelColor from '../../../../../domain/value_objects/NameLabelColor';

import {animatedViewStyle} from '../../../../styles/variables';

import {
  isAndroid,
  isSafari,
} from '../../../../../data/data_stores/net/UserAgent';

const isWeb = Platform.OS === 'web';

const uriToSize: {[key: string]: {width: number; height: number}} = {};

interface Props {
  text: string;
  name?: string;
  nameLabelColor?: NameLabelColor;
  active?: boolean;
  top: string;
  middle: string;
  bottom: string;
  width: number;
  windowWidth: number;
  textStyle?: StyleProp<TextStyle>;
  wideTextStyle?: StyleProp<TextStyle>;
  textPaddingStyle?: TextStyle;
  middleStyle?: ViewStyle;
  voiceIconStyle?: ViewStyle;
  alignTop?: boolean;
  skipRenderText: boolean;
  textSpeed?: 'slow' | 'normal' | 'fast' | 'no_effect';
  hasVoice?: boolean;
  onLoad?: () => void;
  onChangeTopSize?: (topSize: {width: number; height: number}) => void;
  onBeforeChangeHeight?: () => void;
  onAfterChangeHeight?: () => void;
  onBeforeRenderText: () => void;
  onAfterRenderText: () => void;
}

const cache = 'force-cache';

export default class ActivatableElasticTextBox extends React.Component<Props> {
  private ref = React.createRef<HTMLDivElement>();
  private mounted = false;
  private loaded = false;
  private topRatio: number | null = null;
  private middleRatio: number | null = null;
  private bottomRatio: number | null = null;
  private topSize: {width: number; height: number} | null = null;
  private middleSize: {width: number; height: number} | null = null;
  private bottomSize: {width: number; height: number} | null = null;
  private containerStyle: StyleProp<ViewStyle>;
  private middleImageStyle: StyleProp<ViewStyle> = null;
  private topImageShadowStyle: StyleProp<ImageStyle> = null;
  private middleImageShadowStyle: StyleProp<ImageStyle> = null;
  private bottomImageShadowStyle: StyleProp<ImageStyle> = null;
  private textPaddingStyle?: TextStyle;
  private nameWrapperStyle: any = null;
  private topSource: ImageSourcePropType;
  private middleSource: ImageSourcePropType;
  private bottomSource: ImageSourcePropType;

  constructor(props: Props) {
    super(props);
    const {top, middle, bottom, windowWidth} = this.props;
    this.bottomSource = {cache, uri: props.bottom};
    this.middleSource = {cache, uri: props.middle};
    this.topSource = {cache, uri: props.top};
    if (uriToSize[top]) {
      this.generateTopImageSizeState(
        uriToSize[top].width,
        uriToSize[top].height,
      );
    }
    if (uriToSize[middle]) {
      this.generateMiddleImageSizeState(
        uriToSize[middle].width,
        uriToSize[middle].height,
      );
    }
    if (uriToSize[bottom]) {
      this.generateBottomImageSizeState(
        uriToSize[bottom].width,
        uriToSize[bottom].height,
      );
    }
    this.containerStyle = [
      styles.container,
      props.alignTop ? {top: -(this.topSize?.height || 0)} : null,
    ];
    this.middleImageStyle = this.middleSize;
    this.topImageShadowStyle = imageShadowStyle;
    this.middleImageShadowStyle = [this.middleSize, imageShadowStyle];
    this.bottomImageShadowStyle = imageShadowStyle;
    this.textPaddingStyle = this.props.name
      ? {
          paddingTop: windowWidth >= 1280 ? 30 : Math.max(windowWidth / 15, 30),
        }
      : this.props.textPaddingStyle;
  }

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (!this.props.active && !nextProps.active) {
      return false;
    }
    if (
      !(
        this.props.active === nextProps.active &&
        this.props.textSpeed === nextProps.textSpeed &&
        this.props.skipRenderText === nextProps.skipRenderText
      )
    ) {
      return true;
    }
    return false;
  }

  public componentDidMount() {
    const {top, middle, bottom} = this.props;
    this.mounted = true;
    if (!this.topRatio) {
      this.getImageSize(top, this.handleTopImageSize);
    }
    if (!this.middleRatio) {
      this.getImageSize(middle, this.handleMiddleImageSize);
    }
    if (!this.bottomRatio) {
      this.getImageSize(bottom, this.handleBottomImageSize);
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {name, nameLabelColor, active, windowWidth} = this.props;
    const {topSource, middleSource, bottomSource} = this;
    if (!(this.topRatio && this.middleRatio && this.bottomRatio)) {
      return null;
    }
    return (
      <View ref={this.ref as any} style={this.containerStyle}>
        <FrameEdgeImage
          active={active}
          source={topSource}
          imageStyle={this.topSize}
          imageShadowStyle={this.topImageShadowStyle}
        />
        <FrameMiddleImage
          {...this.props}
          source={middleSource}
          imageStyle={this.middleImageStyle}
          imageShadowStyle={this.middleImageShadowStyle}
          textPaddingStyle={this.textPaddingStyle}
          voiceIconStyle={
            windowWidth >= 1280 ? {right: 190, top: 0} : undefined
          }
          onLayout={this.handleLayout}
        />
        {name && (
          <View style={this.nameWrapperStyle}>
            <NameLabel
              name={name}
              windowWidth={windowWidth}
              active={active}
              color={nameLabelColor}
            />
          </View>
        )}
        <FrameEdgeImage
          active={active}
          source={bottomSource}
          imageStyle={this.bottomSize}
          imageShadowStyle={this.bottomImageShadowStyle}
        />
      </View>
    );
  }

  private handleLayout = (e: LayoutChangeEvent) => {
    const {active, windowWidth, middleStyle} = this.props;
    if (!active) {
      return;
    }
    if (Platform.OS !== 'web') {
      return;
    }
    if (!isSafari) {
      return;
    }
    if (!this.topSize || !this.bottomSize) {
      return;
    }
    const elem = this.ref.current;
    if (!elem) {
      return;
    }
    const marginTop =
      windowWidth >= 1280 ? 12 + Number(middleStyle?.marginTop || 0) : 0;
    const newTextHeight = getRoundSize(e.nativeEvent.layout.height - marginTop);
    const height = this.topSize.height + newTextHeight + this.bottomSize.height;
    elem.style.height = `${height}px`;
  };

  private getImageSize(
    uri: string,
    callback: (width: number, height: number) => void,
    retry = 3,
  ) {
    const size = uriToSize[uri];
    if (size) {
      return callback(size.width, size.height);
    }
    Image.getSize(
      uri,
      (width, height) => {
        if (!this.mounted) {
          return;
        }
        uriToSize[uri] = {width, height};
        callback(width, height);
      },
      () => {
        if (retry > 0) {
          this.getImageSize(uri, callback, retry - 1);
        } else {
          throw Error(`Cannot get image size: ${uri}`);
        }
      },
    );
  }

  private handleTopImageSize = (imageWidth: number, imageHeight: number) => {
    this.generateTopImageSizeState(imageWidth, imageHeight);
  };

  private handleMiddleImageSize = (imageWidth: number, imageHeight: number) => {
    this.generateMiddleImageSizeState(imageWidth, imageHeight);
  };

  private handleBottomImageSize = (imageWidth: number, imageHeight: number) => {
    this.generateBottomImageSizeState(imageWidth, imageHeight);
  };

  private generateTopImageSizeState = (
    imageWidth: number,
    imageHeight: number,
  ) => {
    const {width, windowWidth, middleStyle, alignTop} = this.props;
    const topSize = {
      height: getRoundSize((width * imageHeight) / imageWidth),
      width: getRoundSize(width),
    };
    this.props.onChangeTopSize?.(topSize);

    this.topImageShadowStyle = [topSize, imageShadowStyle];
    this.topRatio = imageWidth / imageHeight;
    this.topSize = topSize;
    this.containerStyle = [
      styles.container,
      alignTop ? {top: -(this.topSize?.height || 0)} : null,
    ];
    this.nameWrapperStyle = [
      styles.name,
      {
        position: 'absolute',
        top: getRoundSize(width / this.topRatio),
      },
      windowWidth >= 1280
        ? {top: getRoundSize(width / this.topRatio) - 30, left: 100}
        : middleStyle,
    ];
    this.forceUpdateIfLoaded();
  };

  private generateMiddleImageSizeState = (
    imageWidth: number,
    imageHeight: number,
  ) => {
    this.middleRatio = imageWidth / imageHeight;
    this.forceUpdateIfLoaded();
  };

  private generateBottomImageSizeState = (
    imageWidth: number,
    imageHeight: number,
  ) => {
    const {width} = this.props;
    const bottomSize = {
      height: getRoundSize((width * imageHeight) / imageWidth),
      width: getRoundSize(width),
    };

    this.bottomImageShadowStyle = [bottomSize, imageShadowStyle];
    this.bottomRatio = imageWidth / imageHeight;
    this.bottomSize = bottomSize;
    this.forceUpdateIfLoaded();
  };

  private forceUpdateIfLoaded = () => {
    if (this.loaded) {
      return;
    }
    if (this.topRatio && this.middleRatio && this.bottomRatio) {
      this.loaded = true;
      if (this.mounted) {
        this.forceUpdate();
      }
    }
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  imageShadow: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    tintColor: 'rgba(0, 0, 0, 0.5)',
    top: 0,
  } as ImageStyle,
  name: {
    paddingHorizontal: '3.7%',
    width: '100%',
  } as ViewStyle,
});

const getRoundSize = (size: number) => {
  if (isWeb && !isAndroid) {
    return Math.floor(size);
  } else {
    return PixelRatio.roundToNearestPixel(size);
  }
};

const imageShadowWeb = isWeb
  ? {
      filter: 'brightness(25%)',
      opacity: 0.5,
      ...animatedViewStyle,
    }
  : null;

const imageShadowStyle = [styles.imageShadow, imageShadowWeb];
