import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import {colors, size} from '../../styles/variables';

const ListItemWideSeparator: React.FunctionComponent = props => {
  return <View style={styles.separator} />;
};

const areEqual = () => {
  return true;
};

export default React.memo(ListItemWideSeparator, areEqual);

const styles = StyleSheet.create({
  separator: {
    borderBottomColor: colors.paleGray,
    borderBottomWidth: size.listItemSeparator,
  } as ViewStyle,
});
