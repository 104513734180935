import * as React from 'react';
import {GestureResponderEvent, StyleSheet, ViewStyle} from 'react-native';

import Slider from '@react-native-community/slider';

import {colors} from '../../../../styles/variables';

const maximumTrackTintColor = '#e5e5e5';

interface Props {
  textSpeed: 'slow' | 'normal' | 'fast' | 'no_effect';
  width: number;
  disabled?: boolean;
  onTextSpeedChange?: (
    textSpeed: 'slow' | 'normal' | 'fast' | 'no_effect',
  ) => void;
}

const SettingsList: React.FunctionComponent<Props> = props => {
  const {textSpeed, width, disabled, onTextSpeedChange} = props;
  const slideWidth = Math.round(width * 0.8);
  const value = React.useMemo(() => convertValue(textSpeed), [textSpeed]);
  const onValueChange = React.useCallback((value: number) => {
    onTextSpeedChange && onTextSpeedChange(convertTextSpeed(value));
  }, []);
  const onResponderStart = React.useCallback((e: GestureResponderEvent) => {
    const value = Math.round((e.nativeEvent.locationX / slideWidth) * 3);
    onTextSpeedChange && onTextSpeedChange(convertTextSpeed(value));
  }, []);
  const style = React.useMemo(
    () => [styles.speedSlider, {width: slideWidth}],
    [],
  );
  return (
    <Slider
      disabled={disabled}
      style={style}
      step={1}
      minimumValue={0}
      maximumValue={3}
      value={value}
      thumbTintColor={disabled ? colors.powderGray : colors.orange}
      minimumTrackTintColor={disabled ? colors.powderGray : colors.orange}
      maximumTrackTintColor={maximumTrackTintColor}
      onValueChange={onValueChange}
      onResponderStart={disabled ? undefined : onResponderStart}
    />
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.textSpeed === nextProps.textSpeed &&
    prevProps.disabled === nextProps.disabled
  );
};

export default React.memo(SettingsList, areEqual);

const convertValue = (textSpeed: 'slow' | 'normal' | 'fast' | 'no_effect') => {
  switch (textSpeed) {
    case 'slow':
      return 0;
    case 'normal':
      return 1;
    case 'fast':
      return 2;
    case 'no_effect':
      return 3;
  }
};

const convertTextSpeed = (value: number) => {
  switch (value) {
    case 0:
      return 'slow';
    case 1:
      return 'normal';
    case 2:
      return 'fast';
    case 3:
      return 'no_effect';
    default:
      return 'normal';
  }
};

const styles = StyleSheet.create({
  speedSlider: {
    alignSelf: 'center',
    marginBottom: 10,
  } as ViewStyle,
});
