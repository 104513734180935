import Command from './Command';

import FullScreenIllustrationResource from '../resources/FullScreenIllustrationResource';
import SoundResource from '../resources/SoundResource';

import FullScreenIllustrationCommandOptions from '../../value_objects/command_options/FullScreenIllustrationCommandOptions';
import SoundEffectCommandOptions from '../../value_objects/command_options/SoundEffectCommandOptions';

export default class FullScreenIllustrationShowCommand extends Command {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public sceneId: number,
    public fullScreenIllustration: FullScreenIllustrationResource,
    public sound: SoundResource | undefined | null,
    public options: FullScreenIllustrationCommandOptions,
    public soundOptions: SoundEffectCommandOptions,
  ) {
    super(id, sceneId);
  }

  public getAudioUrl(): string | null {
    if (!this.sound) {
      return null;
    }
    return this.sound.getAudioUrl({
      satrtTime: this.soundOptions.startTime,
      endTime: this.soundOptions.endTime,
    });
  }
}
