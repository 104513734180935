import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import FullScreenIllustrationView from './parts/FullScreenIllustrationView';

import EnabledSound from '../../../shared/EnabledSound';

import FullScreenIllustrationShowCommand from '../../../../../domain/entities/commands/FullScreenIllustrationShowCommand';

interface Size {
  height: number;
  width: number;
}

interface Props {
  command: FullScreenIllustrationShowCommand;
  size: Size;
  visiblePrompt?: boolean;
  showTap: (point: {x: number; y: number}) => void;
  onTouch: (point: {x: number; y: number}) => void;
  onBeforeRenderFullScreenIllustration: (
    command: FullScreenIllustrationShowCommand,
  ) => void;
  onRenderFullScreenIllustration: (
    command: FullScreenIllustrationShowCommand,
    success: () => void,
  ) => void;
  onAfterRenderFullScreenIllustration: (
    command: FullScreenIllustrationShowCommand,
  ) => void;
  onFinishPlaySound: (
    command: FullScreenIllustrationShowCommand,
    success: () => void,
  ) => void;
}

export default class FullScreenModal extends React.Component<Props> {
  private ref = React.createRef<FullScreenIllustrationView>();

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return this.props.command !== nextProps.command;
  }

  public render(): React.ReactNode {
    const {
      command,
      size,
      visiblePrompt,
      showTap,
      onTouch,
      onBeforeRenderFullScreenIllustration,
      onRenderFullScreenIllustration,
      onAfterRenderFullScreenIllustration,
    } = this.props;
    return (
      <View key={command.getKey()} style={[styles.container, size]}>
        <FullScreenIllustrationView
          ref={this.ref}
          command={command}
          size={size}
          visiblePrompt={visiblePrompt}
          showTap={showTap}
          onTouch={onTouch}
          onBeforeRenderFullScreenIllustration={
            onBeforeRenderFullScreenIllustration
          }
          onRenderFullScreenIllustration={onRenderFullScreenIllustration}
          onAfterRenderFullScreenIllustration={
            onAfterRenderFullScreenIllustration
          }
        />
        {command.sound ? (
          <EnabledSound
            uri={command.getAudioUrl()}
            onFinishPlay={this.handleFinishPlaySound}
          />
        ) : null}
      </View>
    );
  }

  private handleFinishPlaySound = () => {
    const {command, onFinishPlaySound} = this.props;
    const success = this.ref.current?.updateShowStatus || (() => {});
    onFinishPlaySound(command, success);
  };
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
    flex: 1,
  } as ViewStyle,
});
