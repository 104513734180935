import DescriptiveTextCommandOptions from '../../../../domain/value_objects/command_options/DescriptiveTextCommandOptions';

export interface DescriptiveTextCommandOptionsData {
  speed?: number;
}

export default class DescriptiveTextCommandOptionsMapper {
  public map(
    obj: DescriptiveTextCommandOptionsData,
  ): DescriptiveTextCommandOptions {
    return new DescriptiveTextCommandOptions(obj.speed);
  }
}
