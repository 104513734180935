enum Gender {
  Male,
  Female,
  Other,
}

export default Gender;

export class GenderValueMapper {
  public static map(gender: Gender): string {
    switch (gender) {
      case Gender.Male:
        return 'male';
      case Gender.Female:
        return 'female';
      case Gender.Other:
        return 'other';
      default:
        throw new Error('Unsupported gender error!');
    }
  }
}
