import * as React from 'react';
import {
  Animated,
  ImageStyle,
  Platform,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import NoFlickerImage from '../../../../shared/no_flicker_image/NoFlickerImage';

import {characterImageUrl, markImageUrl} from '../../../../../helpers/images';
import {animatedViewStyle} from '../../../../../styles/variables';

import CharacterHideCommand from '../../../../../../domain/entities/commands/CharacterHideCommand';
import CharacterShowCommand from '../../../../../../domain/entities/commands/CharacterShowCommand';
import CharacterUpdateCommand from '../../../../../../domain/entities/commands/CharacterUpdateCommand';

const SCALE_UP_VALUE = 1.1;

const RESIZE_MODE = 'contain';

const tintColor = 'rgba(0, 0, 0, 0.5)';

interface Size {
  height: number;
  width: number;
}

export interface Props {
  command: CharacterShowCommand | CharacterUpdateCommand | CharacterHideCommand;
  characterSize: Size;
  markSize: Size;
  active?: boolean;
  scaleUp?: boolean;
  onAfterRenderCharacter: (
    command:
      | CharacterShowCommand
      | CharacterUpdateCommand
      | CharacterHideCommand,
  ) => void;
}

export default class CharacterShowOrUpdateOrHideCommandViewBase extends React.Component<Props> {
  protected animatedViewRef = React.createRef<HTMLDivElement>();
  protected containerStyle: StyleProp<any>;

  private imageSizeStyle?: ImageStyle;

  private markWrapperStyle: StyleProp<ViewStyle>;
  private markImageStyle: StyleProp<ImageStyle>;
  private markImageShadowStyle: StyleProp<ImageStyle>;

  private characterWrapperStyle: StyleProp<ViewStyle>;
  private characterImageStyle: StyleProp<ImageStyle>;
  private characterImageShadowStyle: StyleProp<ImageStyle>;

  private timerId: any | null = null;

  constructor(props: Props) {
    super(props);
    this.updateStyle(props);
  }

  public componentDidMount() {
    const {command, onAfterRenderCharacter} = this.props;
    if (command instanceof CharacterShowCommand && command.options.waiting) {
      this.setOpacityValue(1);
      onAfterRenderCharacter(this.getCommand());
    } else if (command instanceof CharacterHideCommand) {
      this.fadeOut();
    } else {
      this.fadeIn();
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const {command, onAfterRenderCharacter} = this.props;
    if (
      !(prevProps.command instanceof CharacterHideCommand) &&
      command instanceof CharacterHideCommand
    ) {
      this.fadeOut();
    } else if (
      prevProps.command.getKey() !== command.getKey() &&
      command instanceof CharacterShowCommand
    ) {
      this.setOpacityValue(0);
      this.fadeIn();
    } else if (prevProps.command.getKey() !== command.getKey()) {
      onAfterRenderCharacter(this.getCommand());
    }
  }

  public componentWillUnmount() {
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.timerId = null;
    }
  }

  public render(): React.ReactNode {
    const {command, active} = this.props;
    const imageSource = {
      uri: this.getCharacterImageUri(),
    };
    const markImageUri = this.getMarkImageUri();
    const markImageSource = markImageUri
      ? {
          uri: markImageUri,
        }
      : null;
    const inverted = command.character.options.inverted;
    const transparent = command.character.options.transparent;
    const transparentImageStyle = transparent
      ? {opacity: active ? 0.85 : 0.7}
      : null;
    return (
      <Animated.View
        ref={this.animatedViewRef as any}
        style={this.containerStyle}>
        <View style={this.markWrapperStyle}>
          {command.mark && markImageSource && (
            <>
              <NoFlickerImage
                key={command.mark.id}
                style={this.markImageStyle}
                source={markImageSource}
                resizeMode={RESIZE_MODE}
                fadeDuration={0}
              />
              {active !== undefined && !active && (
                <NoFlickerImage
                  style={this.markImageShadowStyle}
                  source={markImageSource}
                  resizeMode={RESIZE_MODE}
                  fadeDuration={0}
                  tintColor={tintColor}
                />
              )}
            </>
          )}
        </View>
        <View
          style={[
            this.characterWrapperStyle,
            inverted ? styles.inverted : null,
          ]}>
          <NoFlickerImage
            style={[this.characterImageStyle, transparentImageStyle]}
            source={imageSource}
            resizeMode={RESIZE_MODE}
            fadeDuration={0}
          />
          {active !== undefined && !active && !transparent && (
            <NoFlickerImage
              style={this.characterImageShadowStyle}
              source={imageSource}
              resizeMode={RESIZE_MODE}
              fadeDuration={0}
              tintColor={tintColor}
            />
          )}
        </View>
      </Animated.View>
    );
  }

  protected fadeIn() {
    this.animate(1);
  }

  protected fadeOut() {
    this.animate(0);
  }

  protected animate(toValue: number) {}

  protected getCommand = () => {
    const {command} = this.props;
    return command;
  };

  protected setOpacityValue = (value: number) => {};

  protected finishAnimation = (toValue: number) => {
    const {command, onAfterRenderCharacter} = this.props;
    this.timerId = setTimeout(() => {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }
      if (command.options.waiting && toValue === 1) {
        return;
      }
      onAfterRenderCharacter(this.getCommand());
    }, 200);
  };

  private getCharacterImageUri = () => {
    const {command, characterSize} = this.props;
    const {face, character} = command;
    return characterImageUrl(face, character, characterSize);
  };

  private getMarkImageUri = () => {
    const {command, markSize} = this.props;
    const {mark} = command;
    if (!mark) {
      return null;
    }
    return markImageUrl(mark, markSize);
  };

  private resizeCharacterSize = () => {
    const {scaleUp, characterSize} = this.props;
    if (scaleUp) {
      return this.getNormalizedSize({
        width: characterSize.width * SCALE_UP_VALUE,
        height: characterSize.height * SCALE_UP_VALUE,
      });
    } else {
      return this.getNormalizedSize(characterSize);
    }
  };

  private updateStyle = (props: Props) => {
    this.containerStyle = [
      styles.container,
      this.getNormalizedSize(props.characterSize),
      animatedViewStyle,
    ];
    this.markWrapperStyle = [
      styles.mark,
      this.getNormalizedSize(props.markSize),
      props.scaleUp ? styles.markScaleUp : null,
    ];
    const markPosition = {
      left: Math.round((props.characterSize.width / 167) * -40),
      top: Math.round((props.characterSize.height / 238) * 15),
    };
    this.markImageStyle = [
      styles.markImage,
      markPosition,
      this.getNormalizedSize(props.markSize),
    ];
    this.markImageShadowStyle = [
      styles.markImage,
      markPosition,
      markImageShadow,
      this.getNormalizedSize(props.markSize),
    ];
    this.characterWrapperStyle = [
      styles.character,
      props.scaleUp ? styles.characterScaleUp : null,
    ];
    this.characterImageStyle = [
      styles.image,
      this.resizeCharacterSize(),
      this.imageSizeStyle,
    ];
    this.characterImageShadowStyle = [
      styles.image,
      imageShadow,
      this.resizeCharacterSize(),
      this.imageSizeStyle,
    ];
  };

  private getNormalizedSize = (size: Size): Size => {
    return {
      width: Math.round(size.width),
      height: Math.round(size.height),
    };
  };
}

const styles = StyleSheet.create({
  character: {
    flex: 10,
  } as ViewStyle,
  characterScaleUp: {
    bottom: 80,
  } as ViewStyle,
  container: {
    alignItems: 'center',
    flex: 11,
  } as ViewStyle,
  mark: {
    top: 10,
  } as ViewStyle,
  markScaleUp: {
    top: -40,
  } as ViewStyle,
  markImage: {
    overflow: 'visible',
    left: -40,
    top: 15,
  } as ImageStyle,
  image: {
    overflow: 'visible',
  } as ImageStyle,
  imageShadow: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    tintColor: 'rgba(0, 0, 0, 0.5)',
    top: 0,
  } as ImageStyle,
  markImageShadow: {
    position: 'absolute',
    tintColor: 'rgba(0, 0, 0, 0.5)',
  } as ImageStyle,
  inverted: {
    transform: [{scaleX: -1}],
  } as ViewStyle,
});

const imageShadow = [
  styles.imageShadow,
  Platform.OS === 'web' ? {filter: 'brightness(25%)', opacity: 0.5} : null,
];

const markImageShadow = [
  styles.markImageShadow,
  Platform.OS === 'web' ? {filter: 'brightness(25%)', opacity: 0.5} : null,
];
