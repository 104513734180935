import * as React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';

import EffectView from './parts/EffectView';

import EffectShowCommand from '../../../../../domain/entities/commands/EffectShowCommand';

interface Size {
  height: number;
  width: number;
}

interface Props {
  style?: StyleProp<ViewStyle>;
  command: EffectShowCommand | null;
  size: Size;
  orientation: 'horizontal' | 'vertical';
  onBeforeRenderEffect: (command: EffectShowCommand) => void;
  onAfterRenderEffect: (command: EffectShowCommand) => void;
  onFinishPlay: (command: EffectShowCommand) => void;
}

const StageEffect: React.FunctionComponent<Props> = props => {
  const {
    style,
    command,
    orientation,
    onBeforeRenderEffect,
    onAfterRenderEffect,
    onFinishPlay,
  } = props;
  if (!command) {
    return null;
  }
  return (
    <View key={command.getKey()} style={style}>
      <EffectView
        command={command}
        size={props.size}
        orientation={orientation}
        onBeforeRenderEffect={onBeforeRenderEffect}
        onAfterRenderEffect={onAfterRenderEffect}
        onFinishPlay={onFinishPlay}
      />
    </View>
  );
};

export default StageEffect;
