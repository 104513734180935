import NoSleep from 'nosleep.js';

const noSleep = new NoSleep();

export const enableNoSleep = () => {
  try {
    if (!noSleep.isEnabled) {
      noSleep.enable();
    }
  } catch (e) {
    //
  }
};

export const disableNoSleep = () => {
  try {
    if (noSleep.isEnabled) {
      noSleep.disable();
    }
  } catch (e) {
    //
  }
};
