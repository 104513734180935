import Resource from './Resource';

import ImageUrlHelper, {Options} from '../../helpers/ImageUrlHelper';

export default class PositionedEffectResource implements Resource {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public sceneIds: number[] | undefined,
    public position:
      | 'center'
      | 'left'
      | 'right'
      | 'center_left'
      | 'center_right',
    public imageUrls: string[],
    public wideImageUrls: string[],
  ) {}

  public getImageUrls(options: Options): string[] {
    const size = {width: 640, height: 520};
    return this.imageUrls.map(url => {
      return ImageUrlHelper.getImageUrl(url, {...options, ...size});
    });
  }

  public getWideImageUrls(options: Options): string[] {
    const size = {width: 1280, height: 720};
    return this.wideImageUrls.map(url => {
      return ImageUrlHelper.getImageUrl(url, {...options, ...size});
    });
  }
}
