import * as React from 'react';
import {Image, ImageStyle, View} from 'react-native';

import convertImageSource from '../../../../helpers/convertImageSource';

import logoImageUri from '../../../../../../../assets/images/viewer/logo.png';

const logoImageSource = convertImageSource(logoImageUri);

interface Props {
  width: number;
  orientation: 'horizontal' | 'vertical';
}

const Logo: React.FunctionComponent<Props> = props => {
  const {width, orientation} = props;
  const scale = width / (orientation === 'vertical' ? 1080 : 1280);
  const style: ImageStyle =
    orientation === 'vertical'
      ? {
          position: 'absolute',
          left: 16 * scale,
          bottom: 8 * scale,
          width: 290 * scale,
          height: 95 * scale,
        }
      : {
          position: 'absolute',
          left: 0 * scale,
          bottom: -5 * scale,
          width: 160 * scale,
          height: 63 * scale,
        };
  return <Image style={style} source={logoImageSource} />;
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.width === nextProps.width;
};

export default React.memo(Logo, areEqual);
