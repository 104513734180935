export interface Options {
  satrtTime?: number;
  endTime?: number;
}

export default class SoundUrlHelper {
  public static getAudioUrl(
    url: string,
    trimmedUrl: string | undefined | null,
    options: Options,
  ): string {
    if (!trimmedUrl) {
      return url;
    } else if (
      (options.satrtTime === null || options.satrtTime === undefined) &&
      (options.endTime === null || options.endTime === undefined)
    ) {
      return url;
    } else {
      const ary = [];
      if (options.satrtTime != null && options.satrtTime != undefined) {
        ary.push(`start_time=${options.satrtTime}`);
      }
      if (options.endTime != null && options.endTime != undefined) {
        ary.push(`end_time=${options.endTime}`);
      }
      return `${trimmedUrl}?${ary.join('&')}`;
    }
  }
}
