export interface Params {
  page: number;
  [key: string]: any;
}

export default class PaginatedResult<T> {
  constructor(
    public records: T[],
    public total: number,
    public params: Params,
    public perPage: number,
    public firstPage?: number,
    public prevPage?: number,
    public nextPage?: number,
    public lastPage?: number,
  ) {}

  public append(other: PaginatedResult<T>): PaginatedResult<T> {
    return new PaginatedResult(
      [...this.records, ...other.records],
      this.total,
      other.params,
      this.perPage,
      other.firstPage,
      other.prevPage,
      other.nextPage,
      other.lastPage,
    );
  }

  public getCurrentPage(): number {
    return this.params.page;
  }

  public getObjectKey(): string {
    return JSON.stringify(this.getParamsWithoutPage());
  }

  private getParamsWithoutPage(): {[key: string]: any} {
    const paramsWithoutPage: {[key: string]: any} = {};
    Object.keys(this.params)
      .sort()
      .forEach(key => {
        if (key !== 'page') {
          paramsWithoutPage[key] = this.params[key];
        }
      });
    return paramsWithoutPage;
  }
}
