import Command from './Command';

import TextFrameResource from '../resources/TextFrameResource';
import VoiceResource from '../resources/VoiceResource';
import SoundResource from '../resources/SoundResource';

import DescriptiveTextCommandOptions from '../../value_objects/command_options/DescriptiveTextCommandOptions';
import SoundEffectCommandOptions from '../../value_objects/command_options/SoundEffectCommandOptions';

export default class DescriptiveTextShowCommand extends Command {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public sceneId: number,
    public textFrame: TextFrameResource,
    public text: string,
    public voice: VoiceResource | null,
    public sound: SoundResource | undefined | null,
    public options: DescriptiveTextCommandOptions,
    public soundOptions: SoundEffectCommandOptions,
  ) {
    super(id, sceneId);
  }

  public getAudioUrl(): string | null {
    if (!this.sound) {
      return null;
    }
    return this.sound.getAudioUrl({
      satrtTime: this.soundOptions.startTime,
      endTime: this.soundOptions.endTime,
    });
  }
}
