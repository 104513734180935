import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import ToggleSwitch from '../../../shared/ToggleSwitch';

import SpeakerIcon from '../../../shared/icons/SpeakerIcon';
import SpeakerSilentIcon from '../../../shared/icons/SpeakerSilentIcon';

interface Props {
  enabled: boolean;
  onValueChange?: (enabled: boolean) => void;
}

const SoundToggleSwitch: React.FunctionComponent<Props> = props => {
  const {enabled, onValueChange} = props;
  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        <View style={styles.icon}>
          {enabled ? <SpeakerIcon /> : <SpeakerSilentIcon />}
        </View>
        <Text style={styles.label}>サウンド</Text>
        <ToggleSwitch enabled={enabled} onValueChange={onValueChange} />
      </View>
    </View>
  );
};

export default React.memo(SoundToggleSwitch);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  inner: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  icon: {
    width: 20,
    justifyContent: 'center',
  } as ViewStyle,
  label: {
    color: '#fff',
    fontSize: 12,
    marginLeft: 4,
    marginRight: 8,
    width: 52,
  } as TextStyle,
});
