import BackgroundCommandOptions, {
  Effect,
  Filter,
} from '../../../../domain/value_objects/command_options/BackgroundCommandOptions';

export interface BackgroundCommandOptionsData {
  effect?: Effect;
  filter?: Filter;
}

export default class BackgroundCommandOptionsMapper {
  public map(obj: BackgroundCommandOptionsData): BackgroundCommandOptions {
    return new BackgroundCommandOptions(obj.effect, obj.filter);
  }
}
