import Command from './Command';

import PositionedEffectResource from '../resources/PositionedEffectResource';
import SoundResource from '../resources/SoundResource';

import EffectCommandOptions from '../../value_objects/command_options/EffectCommandOptions';
import SoundEffectCommandOptions from '../../value_objects/command_options/SoundEffectCommandOptions';

export default class EffectShowCommand extends Command {
  constructor(
    public id: number,
    public sceneId: number,
    public positionedEffect: PositionedEffectResource,
    public sound: SoundResource | undefined | null,
    public options: EffectCommandOptions,
    public soundOptions: SoundEffectCommandOptions,
  ) {
    super(id, sceneId);
  }

  public getAudioUrl(): string | null {
    if (!this.sound) {
      return null;
    }
    return this.sound.getAudioUrl({
      satrtTime: this.soundOptions.startTime,
      endTime: this.soundOptions.endTime,
    });
  }
}
