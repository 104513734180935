import * as React from 'react';
import {
  Image,
  ImageBackground,
  ImageSourcePropType,
  ImageStyle,
  LayoutChangeEvent,
  PixelRatio,
  Platform,
  StyleProp,
  StyleSheet,
  TextStyle,
  ViewStyle,
} from 'react-native';

import TypewriterEffectText, {
  RENDERING_STRAT_TIME,
} from './TypewriterEffectText';

import VoiceMicrophoneBadge from '../../../shared/VoiceMicrophoneBadge';

import {
  getViewerTextBox,
  animatedViewStyle,
} from '../../../../styles/variables';

import {isAndroid} from '../../../../../data/data_stores/net/UserAgent';

const isWeb = Platform.OS === 'web';

interface Props {
  active?: boolean;
  source: ImageSourcePropType;
  imageStyle: StyleProp<ViewStyle>;
  imageShadowStyle: StyleProp<ImageStyle>;
  text: string;
  width: number;
  windowWidth: number;
  textStyle?: StyleProp<TextStyle>;
  wideTextStyle?: StyleProp<TextStyle>;
  textPaddingStyle?: TextStyle;
  middleStyle?: ViewStyle;
  voiceIconStyle?: ViewStyle;
  skipRenderText: boolean;
  textSpeed?: 'slow' | 'normal' | 'fast' | 'no_effect';
  hasVoice?: boolean;
  onBeforeChangeHeight?: () => void;
  onAfterChangeHeight?: () => void;
  onBeforeRenderText: () => void;
  onAfterRenderText: () => void;
  onLayout?: (event: LayoutChangeEvent) => void;
}

const RESIZE_MODE = 'stretch';

const RESIZE_METHOD = 'resize';

export default class FrameMiddleImage extends React.Component<Props> {
  private divRef = React.createRef<HTMLDivElement>();
  private backgroundImageRef = React.createRef<HTMLDivElement>();
  private imageRef = React.createRef<HTMLDivElement>();
  private mounted = false;
  private containerStyle: React.CSSProperties;
  private imageStyle: StyleProp<ViewStyle> = null;
  private imageShadowStyle: StyleProp<ImageStyle> = null;
  private textStyle: TextStyle;
  private initialTextHeight = 25;
  private newTextHeight = this.initialTextHeight;
  private oldTextHeight = this.initialTextHeight;

  constructor(props: Props) {
    super(props);
    const {windowWidth, middleStyle} = this.props;
    this.containerStyle = this.generateContainerStyle();
    this.imageStyle = [
      {
        height: this.newTextHeight,
        width: getRoundSize(props.width),
      },
      this.props.imageStyle,
    ];
    this.imageShadowStyle = StyleSheet.flatten<any>([
      this.imageStyle,
      imageShadowStyle,
    ]);
    this.textStyle = StyleSheet.flatten<TextStyle>([
      getViewerTextBox(windowWidth),
      styles.text,
      props.textStyle,
      props.textPaddingStyle,
      windowWidth >= 1280 ? props.wideTextStyle : middleStyle,
      {width: getRoundSize(props.width)},
    ]);
  }

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (!this.props.active && !nextProps.active) {
      return false;
    }
    if (
      !(
        this.props.active === nextProps.active &&
        this.props.textSpeed === nextProps.textSpeed &&
        this.props.skipRenderText === nextProps.skipRenderText
      )
    ) {
      this.containerStyle = this.generateContainerStyle();
      return true;
    }
    return false;
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {
      active,
      source,
      text,
      skipRenderText,
      textSpeed,
      hasVoice,
      voiceIconStyle,
      windowWidth,
      onBeforeRenderText,
      onAfterRenderText,
    } = this.props;
    return (
      <div ref={this.divRef} style={this.containerStyle}>
        <ImageBackground
          ref={this.backgroundImageRef as any}
          style={this.imageStyle}
          source={source}
          resizeMode={RESIZE_MODE}
          resizeMethod={RESIZE_METHOD}
          fadeDuration={0}>
          <TypewriterEffectText
            style={this.textStyle}
            text={text}
            skipRenderText={skipRenderText}
            textSpeed={textSpeed}
            active={active}
            onBeforeRenderText={onBeforeRenderText}
            onAfterRenderText={onAfterRenderText}
            onLayout={this.handleLayout}
          />
          {hasVoice && (
            <VoiceMicrophoneBadge
              style={voiceIconStyle}
              size={windowWidth >= 1280 ? 15 : 11}
            />
          )}
        </ImageBackground>
        {!active && (
          <Image
            ref={this.imageRef as any}
            style={this.imageShadowStyle}
            source={source}
            resizeMode={RESIZE_MODE}
            resizeMethod={RESIZE_METHOD}
            fadeDuration={0}
          />
        )}
      </div>
    );
  }

  private handleLayout = (e: LayoutChangeEvent) => {
    const {width, windowWidth, middleStyle, onBeforeChangeHeight, onLayout} =
      this.props;
    if (onLayout) {
      onLayout(e);
    }
    this.oldTextHeight = getRoundSize(this.newTextHeight);
    const marginTop =
      windowWidth >= 1280 ? 12 + Number(middleStyle?.marginTop || 0) : 0;
    this.newTextHeight = getRoundSize(e.nativeEvent.layout.height - marginTop);
    if (!this.mounted) {
      return;
    }
    if (this.oldTextHeight >= this.newTextHeight - 5) {
      return;
    }
    if (onBeforeChangeHeight) {
      onBeforeChangeHeight();
    }
    this.imageStyle = [
      {
        height: this.newTextHeight,
        width: getRoundSize(width),
      },
      this.props.imageStyle,
    ];
    this.containerStyle = this.generateContainerStyle();
    this.imageShadowStyle = StyleSheet.flatten<any>([
      this.imageStyle,
      imageShadowStyle,
    ]);
    const height = `${this.newTextHeight}px`;
    if (this.imageRef.current) {
      this.imageRef.current.style.height = height;
      if (this.imageRef.current.children) {
        (this.imageRef.current.children[0] as any).style.height = height;
      }
    }
    if (this.backgroundImageRef.current) {
      this.backgroundImageRef.current.style.height = height;
      if (this.backgroundImageRef.current.children) {
        (this.backgroundImageRef.current.children[0] as any).style.height =
          height;
      }
    }
    if (this.divRef.current) {
      this.divRef.current.style.height = height;
    }
    setTimeout(this.finishTextHeightAnimation, RENDERING_STRAT_TIME);
  };

  private finishTextHeightAnimation = () => {
    const {onAfterChangeHeight} = this.props;
    if (onAfterChangeHeight) {
      onAfterChangeHeight();
    }
  };

  private generateContainerStyle = (): React.CSSProperties => {
    const {active, textSpeed} = this.props;
    return {
      width: getRoundSize(this.props.width),
      height: this.newTextHeight,
      overflow: 'hidden',
      position: 'inherit',
      ...(active && textSpeed !== 'no_effect'
        ? {transition: `${RENDERING_STRAT_TIME}ms ease height`}
        : {}),
    };
  };
}

const styles = StyleSheet.create({
  imageShadow: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    tintColor: 'rgba(0, 0, 0, 0.5)',
    top: 0,
  } as ImageStyle,
  text: {
    backgroundColor: 'transparent',
    position: 'absolute',
  } as TextStyle,
});

const getRoundSize = (size: number) => {
  if (isWeb && !isAndroid) {
    return Math.floor(size);
  } else {
    return PixelRatio.roundToNearestPixel(size);
  }
};

const imageShadowWeb = isWeb
  ? {
      filter: 'brightness(25%)',
      opacity: 0.5,
      ...animatedViewStyle,
    }
  : null;

const imageShadowStyle = [styles.imageShadow, imageShadowWeb];
