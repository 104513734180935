import Entity from './Entity';

import Command from './commands/Command';
import ResourcesSet from './resources/ResourcesSet';

export default class SceneScript implements Entity {
  private splitedScenes: SceneScript[] | null = null;
  private sceneIdToSceneScript: {[key: number]: SceneScript} | null = null;
  public preloaded = false;

  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public storyId: number,
    public version: number,
    public resourcesSet: ResourcesSet,
    public commands: Command[],
  ) {}

  public isAvailable = () => {
    return this.id !== -1;
  };

  public hasSound = () => {
    return this.resourcesSet.hasSound();
  };

  public splitScenes = () => {
    if (this.splitedScenes) {
      return this.splitedScenes;
    }
    if (this.commands.length === 0) {
      this.splitedScenes = [];
      return this.splitedScenes;
    }
    const sceneIdToCommands = this.generateSceneIdToCommands();
    const sceneIdToResourcesSet = this.generateSceneIdToResourcesSet();

    this.splitedScenes = this.commands
      .map(command => command.sceneId)
      .filter((sceneId, index, self) => self.indexOf(sceneId) === index)
      .map(sceneId => {
        const resourcesSet =
          sceneIdToResourcesSet[sceneId] || new ResourcesSet();
        const commands = sceneIdToCommands[sceneId] || [];
        return new SceneScript(
          sceneId,
          this.storyId,
          this.version,
          resourcesSet,
          commands,
        );
      });
    return this.splitedScenes;
  };

  public fetchBySceneId(sceneId: number) {
    if (this.sceneIdToSceneScript) {
      return this.sceneIdToSceneScript[sceneId];
    }
    const sceneIdToSceneScript: {[key: number]: SceneScript} = {};
    this.splitScenes().forEach(sceneScript => {
      sceneIdToSceneScript[sceneScript.id] = sceneScript;
    });
    this.sceneIdToSceneScript = sceneIdToSceneScript;
    return this.sceneIdToSceneScript[sceneId];
  }

  private generateSceneIdToCommands = () => {
    const sceneIdToCommands: {[key: string]: Command[]} = {};
    this.commands.forEach(command => {
      if (!sceneIdToCommands[command.sceneId]) {
        sceneIdToCommands[command.sceneId] = [];
      }
      sceneIdToCommands[command.sceneId].push(command);
    });
    return sceneIdToCommands;
  };

  private generateSceneIdToResourcesSet = () => {
    const sceneIdToResourcesSet: {[key: string]: ResourcesSet} = {};
    const firstSceneId = this.commands[0].sceneId;
    this.resourcesSet.backgroundResources.forEach(backgroundResource => {
      (backgroundResource.sceneIds || [firstSceneId]).forEach(sceneId => {
        if (!sceneIdToResourcesSet[sceneId]) {
          sceneIdToResourcesSet[sceneId] = new ResourcesSet();
        }
        sceneIdToResourcesSet[sceneId].backgroundResources.push(
          backgroundResource,
        );
      });
    });
    this.resourcesSet.characterResources.forEach(characterResource => {
      (characterResource.sceneIds || [firstSceneId]).forEach(sceneId => {
        if (!sceneIdToResourcesSet[sceneId]) {
          sceneIdToResourcesSet[sceneId] = new ResourcesSet();
        }
        sceneIdToResourcesSet[sceneId].characterResources.push(
          characterResource,
        );
      });
    });
    this.resourcesSet.fullScreenIllustrationResources.forEach(
      fullScreenIllustrationResource => {
        (fullScreenIllustrationResource.sceneIds || [firstSceneId]).forEach(
          sceneId => {
            if (!sceneIdToResourcesSet[sceneId]) {
              sceneIdToResourcesSet[sceneId] = new ResourcesSet();
            }
            sceneIdToResourcesSet[sceneId].fullScreenIllustrationResources.push(
              fullScreenIllustrationResource,
            );
          },
        );
      },
    );
    this.resourcesSet.illustrationResources.forEach(illustrationResource => {
      (illustrationResource.sceneIds || [firstSceneId]).forEach(sceneId => {
        if (!sceneIdToResourcesSet[sceneId]) {
          sceneIdToResourcesSet[sceneId] = new ResourcesSet();
        }
        sceneIdToResourcesSet[sceneId].illustrationResources.push(
          illustrationResource,
        );
      });
    });
    this.resourcesSet.markResources.forEach(markResource => {
      (markResource.sceneIds || [firstSceneId]).forEach(sceneId => {
        if (!sceneIdToResourcesSet[sceneId]) {
          sceneIdToResourcesSet[sceneId] = new ResourcesSet();
        }
        sceneIdToResourcesSet[sceneId].markResources.push(markResource);
      });
    });
    this.resourcesSet.speechBalloonResources.forEach(speechBalloonResource => {
      (speechBalloonResource.sceneIds || [firstSceneId]).forEach(sceneId => {
        if (!sceneIdToResourcesSet[sceneId]) {
          sceneIdToResourcesSet[sceneId] = new ResourcesSet();
        }
        sceneIdToResourcesSet[sceneId].speechBalloonResources.push(
          speechBalloonResource,
        );
      });
    });
    this.resourcesSet.textFrameResources.forEach(textFrameResource => {
      (textFrameResource.sceneIds || [firstSceneId]).forEach(sceneId => {
        if (!sceneIdToResourcesSet[sceneId]) {
          sceneIdToResourcesSet[sceneId] = new ResourcesSet();
        }
        sceneIdToResourcesSet[sceneId].textFrameResources.push(
          textFrameResource,
        );
      });
    });
    this.resourcesSet.voiceResources.forEach(voiceResource => {
      (voiceResource.sceneIds || [firstSceneId]).forEach(sceneId => {
        if (!sceneIdToResourcesSet[sceneId]) {
          sceneIdToResourcesSet[sceneId] = new ResourcesSet();
        }
        sceneIdToResourcesSet[sceneId].voiceResources.push(voiceResource);
      });
    });
    this.resourcesSet.soundResources.forEach(soundResource => {
      (soundResource.sceneIds || [firstSceneId]).forEach(sceneId => {
        if (!sceneIdToResourcesSet[sceneId]) {
          sceneIdToResourcesSet[sceneId] = new ResourcesSet();
        }
        sceneIdToResourcesSet[sceneId].voiceResources.push(soundResource);
      });
    });
    this.resourcesSet.positionedEffectResources.forEach(
      positionedEffectResource => {
        (positionedEffectResource.sceneIds || [firstSceneId]).forEach(
          sceneId => {
            if (!sceneIdToResourcesSet[sceneId]) {
              sceneIdToResourcesSet[sceneId] = new ResourcesSet();
            }
            sceneIdToResourcesSet[sceneId].positionedEffectResources.push(
              positionedEffectResource,
            );
          },
        );
      },
    );
    return sceneIdToResourcesSet;
  };
}
