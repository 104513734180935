import EntityMapper from '../EntityMapper';

import PositionMapper, {
  PositionData,
} from '../../../value_objects/mappers/PositionMapper';

import CharacterHideCommand from '../../../../domain/entities/commands/CharacterHideCommand';
import ResourcesSet from '../../../../domain/entities/resources/ResourcesSet';

import CharacterCommandOptionsMapper, {
  CharacterCommandOptionsData,
} from '../../../value_objects/mappers/command_options/CharacterCommandOptionsMapper';

export interface CharacterHideCommandData {
  id: number;
  scene_id: number;
  type: 'character_hide';
  character_id: number;
  position: PositionData;
  face: string;
  mark_id: number | null;
  options?: CharacterCommandOptionsData;
}

export default class CharacterHideCommandMapper
  implements EntityMapper<CharacterHideCommandData, CharacterHideCommand>
{
  private positionMapper: PositionMapper;
  private characterCommandOptionsMapper: CharacterCommandOptionsMapper;

  constructor(private resourcesSet: ResourcesSet) {
    this.positionMapper = new PositionMapper();
    this.characterCommandOptionsMapper = new CharacterCommandOptionsMapper();
  }

  public map(obj: CharacterHideCommandData): CharacterHideCommand {
    const position = this.positionMapper.map(obj.position);
    const options = this.characterCommandOptionsMapper.map(obj.options || {});
    return new CharacterHideCommand(
      obj.id,
      obj.scene_id,
      this.resourcesSet.getCharacterResource(obj.character_id),
      position,
      obj.face,
      obj.mark_id ? this.resourcesSet.getMarkResource(obj.mark_id) : null,
      options,
    );
  }
}
