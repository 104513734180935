const silentAudioUri =
  'data:video/mp4;base64,AAAAHGZ0eXBNNEEgAAACAE00QSBpc29taXNvMgAAAAhmcmVlAAAAzm1kYXTeBABMYXZjNTguMTM0LjEwMAACMEAOARggBwEYIAcBGCAHARggBwEYIAcBGCAHARggBwEYIAcBGCAHARggBwEYIAcBGCAHARggBwEYIAcBGCAHARggBwEYIAcBGCAHARggBwEYIAcBGCAHARggBwEYIAcBGCAHARggBwEYIAcBGCAHARggBwEYIAcBGCAHARggBwEYIAcBGCAHARggBwEYIAcBGCAHARggBwEYIAcBGCAHARggBwEYIAcBGCAHARggBwEYIAcAAAOvbW9vdgAAAGxtdmhkAAAAAAAAAAAAAAAAAAAD6AAAD6AAAQAAAQAAAAAAAAAAAAAAAAEAAAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAgAAAtl0cmFrAAAAXHRraGQAAAADAAAAAAAAAAAAAAABAAAAAAAAD6AAAAAAAAAAAAAAAAEBAAAAAAEAAAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAkZWR0cwAAABxlbHN0AAAAAAAAAAEAAA+gAAAEAAABAAAAAAJRbWRpYQAAACBtZGhkAAAAAAAAAAAAAAAAAAArEQAArERVxAAAAAAALWhkbHIAAAAAAAAAAHNvdW4AAAAAAAAAAAAAAABTb3VuZEhhbmRsZXIAAAAB/G1pbmYAAAAQc21oZAAAAAAAAAAAAAAAJGRpbmYAAAAcZHJlZgAAAAAAAAABAAAADHVybCAAAAABAAABwHN0YmwAAABqc3RzZAAAAAAAAAABAAAAWm1wNGEAAAAAAAAAAQAAAAAAAAAAAAIAEAAAAAArEQAAAAAANmVzZHMAAAAAA4CAgCUAAQAEgICAF0AVAAAAAAECZgAAAYMFgICABRUIVuUABoCAgAECAAAAIHN0dHMAAAAAAAAAAgAAACwAAAQAAAAAAQAAAEQAAAAcc3RzYwAAAAAAAAABAAAAAQAAAC0AAAABAAAAyHN0c3oAAAAAAAAAAAAAAC0AAAAWAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAEAAAABAAAAAQAAAAUc3RjbwAAAAAAAAABAAAALAAAABpzZ3BkAQAAAHJvbGwAAAACAAAAAf//AAAAHHNiZ3AAAAAAcm9sbAAAAAEAAAAtAAAAAQAAAGJ1ZHRhAAAAWm1ldGEAAAAAAAAAIWhkbHIAAAAAAAAAAG1kaXJhcHBsAAAAAAAAAAAAAAAALWlsc3QAAAAlqXRvbwAAAB1kYXRhAAAAAQAAAABMYXZmNTguNzYuMTAw';

export let audioContext: AudioContext | null = null;

function initAudioContext() {
  try {
    window.AudioContext =
      window.AudioContext ||
      ((window as any).webkitAudioContext as AudioContext);
    if (!audioContext) {
      audioContext = new AudioContext();
    }
  } catch (e) {
    console.log('Web Audio API is not supported in this browser');
  }
}
window.addEventListener('load', initAudioContext);

const eventName =
  typeof document.ontouchend !== 'undefined' ? 'touchend' : 'mouseup';
function resumeAudioContext() {
  document.removeEventListener(eventName, resumeAudioContext);
  if (audioContext) {
    audioContext.resume();
  }
}
document.addEventListener(eventName, resumeAudioContext);

const audioPlayCallback = () => {
  try {
    window.removeEventListener('click', audioPlayCallback);
    window.removeEventListener('touchstart', audioPlayCallback);
    if (audioContext) {
      const emptySource = audioContext.createBufferSource();
      emptySource.start();
      emptySource.stop();
    } else {
      const silentAudio = new Audio();
      silentAudio.src = silentAudioUri;
      silentAudio.play().catch(() => {});
    }
  } catch (e) {
    console.log(e);
    //
  }
};
window.addEventListener('click', audioPlayCallback);
window.addEventListener('touchstart', audioPlayCallback);
