import EntityMapper from '../EntityMapper';

import UserEpisodeBrowsingLogMapper, {
  UserEpisodeBrowsingLogData,
} from './UserEpisodeBrowsingLogMapper';

import UserEpisodeBrowsingHistoryMapper, {
  UserEpisodeBrowsingHistoryData,
} from './UserEpisodeBrowsingHistoryMapper';

import UserEpisodeReadingFinishTimeMapper, {
  UserEpisodeReadingFinishTimeData,
} from './UserEpisodeReadingFinishTimeMapper';
import UserEpisodeReadingStartTimeMapper, {
  UserEpisodeReadingStartTimeData,
} from './UserEpisodeReadingStartTimeMapper';

import UserStoryBrowsingHistoryMapper, {
  UserStoryBrowsingHistoryData,
} from './UserStoryBrowsingHistoryMapper';

import UserEpisodeBrowsingHistoryAndReadingTime from '../../../../domain/entities/consumer/UserEpisodeBrowsingHistoryAndReadingTime';

export interface UserEpisodeBrowsingHistoryAndReadingTimeData {
  id: number;
  episode_id: number;
  consumer_user_episode_browsing_log: UserEpisodeBrowsingLogData | null;
  consumer_user_episode_browsing_history: UserEpisodeBrowsingHistoryData;
  consumer_user_story_browsing_history: UserStoryBrowsingHistoryData;
  consumer_user_episode_reading_start_time: UserEpisodeReadingStartTimeData;
  episode_browsing_logs_count: number | null;
  consumer_user_episode_reading_finish_time: UserEpisodeReadingFinishTimeData | null;
}

export default class UserEpisodeBrowsingHistoryAndReadingTimeMapper
  implements
    EntityMapper<
      UserEpisodeBrowsingHistoryAndReadingTimeData,
      UserEpisodeBrowsingHistoryAndReadingTime
    >
{
  private userEpisodeBrowsingLogMapper = new UserEpisodeBrowsingLogMapper();
  private userEpisodeBrowsingHistoryMapper =
    new UserEpisodeBrowsingHistoryMapper();
  private userStoryBrowsingHistoryMapper = new UserStoryBrowsingHistoryMapper();
  private userEpisodeReadingStartTimeMapper =
    new UserEpisodeReadingStartTimeMapper();
  private userEpisodeReadingFinishTimeMapper =
    new UserEpisodeReadingFinishTimeMapper();

  public map(
    obj: UserEpisodeBrowsingHistoryAndReadingTimeData,
  ): UserEpisodeBrowsingHistoryAndReadingTime {
    const consumerUserEpisodeBrowsingLog =
      obj.consumer_user_episode_browsing_log
        ? this.userEpisodeBrowsingLogMapper.map(
            obj.consumer_user_episode_browsing_log,
          )
        : null;
    const consumerUserEpisodeBrowsingHistory =
      this.userEpisodeBrowsingHistoryMapper.map(
        obj.consumer_user_episode_browsing_history,
      );
    const consumerUserStoryBrowsingHistory =
      this.userStoryBrowsingHistoryMapper.map(
        obj.consumer_user_story_browsing_history,
      );
    const consumerUserEpisodeReadingStartTime =
      this.userEpisodeReadingStartTimeMapper.map(
        obj.consumer_user_episode_reading_start_time,
      );
    const consumerUserEpisodeReadingStartFinish =
      obj.consumer_user_episode_reading_finish_time
        ? this.userEpisodeReadingFinishTimeMapper.map(
            obj.consumer_user_episode_reading_finish_time,
          )
        : null;
    return new UserEpisodeBrowsingHistoryAndReadingTime(
      obj.id,
      obj.episode_id,
      consumerUserEpisodeBrowsingHistory,
      consumerUserStoryBrowsingHistory,
      consumerUserEpisodeReadingStartTime,
      consumerUserEpisodeBrowsingLog,
      obj.episode_browsing_logs_count,
      consumerUserEpisodeReadingStartFinish,
    );
  }
}
