import * as React from 'react';
import {StyleSheet, ViewStyle} from 'react-native';

import StagePositionBase from './StagePositionBase';

import CharacterHideCommand from '../../../../../domain/entities/commands/CharacterHideCommand';

const keyFrameAnimationOptions = {
  duration: 140,
  easing: 'ease',
};

export default class StagePosition extends StagePositionBase {
  private divRef = React.createRef<HTMLDivElement>();

  public render(): React.ReactNode {
    const {characterCommand} = this.props;
    return (
      <div
        ref={this.divRef}
        key={characterCommand ? characterCommand.sceneId : undefined}
        style={StyleSheet.flatten<any>([
          styles.container,
          characterCommand
            ? characterCommand.character.options.containerStyle
            : undefined,
          this.generatePositionStyle(),
        ])}>
        {this.renderContent()}
      </div>
    );
  }

  protected move = () => {
    const {prevLeft, prevRight} = this.state;
    const elem = this.divRef.current;
    if (!elem) {
      return;
    }
    const left = `${this.props.left}px`;
    const right = `${this.props.right}px`;
    const animation = elem.animate(
      [
        {left: `${prevLeft}px`, right: `${prevRight}px`},
        {left, right},
      ],
      keyFrameAnimationOptions,
    );
    animation.addEventListener('finish', () => {
      elem.style.left = left;
      elem.style.right = right;
      this.finishCallback();
    });
  };

  protected moveLeft = () => {
    const {prevLeft} = this.state;
    const elem = this.divRef.current;
    if (!elem) {
      return;
    }
    const left = `${this.props.left}px`;
    const animation = elem.animate(
      [{left: `${prevLeft}px`}, {left}],
      keyFrameAnimationOptions,
    );
    animation.addEventListener('finish', () => {
      elem.style.left = left;
      this.finishCallback();
    });
  };

  protected moveRight = () => {
    const {prevRight} = this.state;
    const elem = this.divRef.current;
    if (!elem) {
      return;
    }
    const right = `${this.props.right}px`;
    const animation = elem.animate(
      [{right: `${prevRight}px`}, {right}],
      keyFrameAnimationOptions,
    );
    animation.addEventListener('finish', () => {
      elem.style.right = right;
      this.finishCallback();
    });
  };

  protected resetAnimationValues = () => {};

  private generatePositionStyle = () => {
    const {characterCommand} = this.props;
    const left =
      characterCommand instanceof CharacterHideCommand
        ? this.state.prevLeft
        : this.props.left;
    const right =
      characterCommand instanceof CharacterHideCommand
        ? this.state.prevRight
        : this.props.right;
    return {left: `${left}px`, right: `${right}px`};
  };
}

const styles = StyleSheet.create({
  container: {
    bottom: 0,
    overflow: 'visible',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  } as ViewStyle,
});
