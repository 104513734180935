import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import BaseModal from '../../shared/modals/BaseModal';

import {colors} from '../../../styles/variables';

interface Props {
  visible: boolean;
  fullScreenWidth: number;
  fullScreenHeight: number;
  onShow?: () => void;
  onPressBack?: () => void;
}

const NotFoundModal: React.FunctionComponent<Props> = props => {
  const {visible, fullScreenWidth, fullScreenHeight, onShow, onPressBack} =
    props;
  return (
    <BaseModal
      visible={visible}
      fullScreenWidth={fullScreenWidth}
      fullScreenHeight={fullScreenHeight}
      onShow={onShow}>
      <View style={styles.container}>
        <Text style={styles.messageText}>エピソードが取得できませんでした</Text>
        {onPressBack && (
          <View style={styles.footer}>
            <Pressable style={styles.backButton} onPress={onPressBack}>
              <Text style={styles.backButtonText}>戻る</Text>
            </Pressable>
          </View>
        )}
      </View>
    </BaseModal>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.visible === nextProps.visible &&
    prevProps.fullScreenWidth === nextProps.fullScreenWidth
  );
};

export default React.memo(NotFoundModal, areEqual);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    width: '100%',
  } as ViewStyle,
  messageText: {
    color: colors.textColor,
    fontSize: 16,
    fontWeight: 'bold',
    margin: 22,
  } as TextStyle,
  footer: {
    alignItems: 'center',
    height: 46,
    justifyContent: 'center',
    width: '100%',
  } as ViewStyle,
  backButton: {
    alignItems: 'center',
    backgroundColor: colors.powderGray,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    flex: 1,
    justifyContent: 'center',
    width: '100%',
  } as ViewStyle,
  backButtonText: {
    color: 'white',
    fontSize: 17,
    fontWeight: 'bold',
    paddingTop: 2,
  } as TextStyle,
});
