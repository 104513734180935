import UserSceneTapsBatch from '../../../../domain/value_objects/consumer/UserSceneTapsBatch';

import NetUserSceneTapsBatchesRepository from '../../../../data/repositories/consumer/NetUserSceneTapsBatchesRepository';

interface UserSceneTapAttributes {
  code: string;
  sceneId: number;
  command: any;
  pointX: number;
  pointY: number;
  tappedAt: Date;
}

export interface Params {
  consumerUserSceneTaps: UserSceneTapAttributes[];
}

export default function action(params: Params): Promise<UserSceneTapsBatch> {
  const repository = new NetUserSceneTapsBatchesRepository();
  return repository
    .create({consumerUserSceneTapsBatch: params})
    .then(payload => {
      return payload;
    })
    .catch(err => {
      throw err;
    });
}
