import * as React from 'react';
import {
  Animated,
  Easing,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import AutoplayStopIcon from '../../shared/icons/AutoplayStopIcon';
import AutoplaySuspendIcon from '../../shared/icons/AutoplaySuspendIcon';

import {enableNoSleep, disableNoSleep} from '../../shared/NoSleep';

type Value = 0 | 1 | 1.5 | 2;

interface Props {
  value: Value;
  onValueChange: (value: Value) => void;
}

const AutoPlayButton: React.FunctionComponent<Props> = props => {
  const {value, onValueChange} = props;
  const animationValue = React.useRef(new Animated.Value(0)).current;
  const enabled = value ? 1 : 0;
  const handleChangeValue = React.useCallback((value: Value) => {
    onValueChange(value);
    if (value === 0) {
      disableNoSleep();
    } else {
      enableNoSleep();
    }
  }, []);
  const onSwitchSpeed = React.useCallback(() => {
    switch (value) {
      case 0:
        handleChangeValue(1);
        break;
      case 1:
        handleChangeValue(1.5);
        break;
      case 1.5:
        handleChangeValue(2);
        break;
      case 2:
        handleChangeValue(1);
        break;
    }
  }, [value]);
  const onTogglePlay = React.useCallback(() => {
    const value = enabled ? 0 : 1;
    handleChangeValue(value);
  }, [enabled]);
  React.useEffect(() => {
    Animated.timing(animationValue, {
      duration: 150,
      easing: Easing.elastic(1),
      toValue: value ? 1 : 0,
      useNativeDriver: true,
    }).start();
  }, [value]);
  const style = React.useMemo(() => {
    const translateX = animationValue.interpolate({
      inputRange: [0, 1],
      outputRange: [44, 0],
    });
    const opacity = animationValue;
    return [styles.speedButton, {opacity, transform: [{translateX}]}];
  }, []);
  return (
    <>
      <Animated.View style={style}>
        <TouchableOpacity style={styles.button} onPress={onSwitchSpeed}>
          <Text style={styles.speedButtonLabel}>
            x{value}
            {Number.isInteger(value) ? '.0' : ''}
          </Text>
        </TouchableOpacity>
      </Animated.View>
      <View style={styles.playButton}>
        <TouchableOpacity style={styles.button} onPress={onTogglePlay}>
          {value ? autoplaySuspendIcon : autoplayStopIcon}
          {value ? autoplaySuspendText : autoplayStopText}
        </TouchableOpacity>
      </View>
    </>
  );
};

export default React.memo(AutoPlayButton);

const styles = StyleSheet.create({
  speedButton: {
    position: 'absolute',
    bottom: Platform.select({web: 50, default: 55}) + 5 + 12,
    right: 56,
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  speedButtonLabel: {
    color: '#d6d6d6',
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: -0.5,
  } as TextStyle,
  playButton: {
    position: 'absolute',
    bottom: Platform.select({web: 50, default: 55}) + 5 + 12,
    right: 12,
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    borderRadius: 25,
  } as ViewStyle,
  playButtonLabel: {
    marginTop: 1,
    color: '#d6d6d6',
    fontWeight: 'bold',
    letterSpacing: -0.5,
    ...Platform.select({
      web: {
        fontSize: 10,
        transform: 'scale(0.7)' as any,
      },
      default: {
        fontSize: 7,
      },
    }),
  } as TextStyle,
  playButtonLabelStop: {
    color: '#999',
  } as TextStyle,
});

const autoplaySuspendIcon = <AutoplaySuspendIcon size={8} color={'#d6d6d6'} />;
const autoplayStopIcon = <AutoplayStopIcon size={10} color={'#999'} />;

const autoplaySuspendText = <Text style={styles.playButtonLabel}>AUTO</Text>;

const autoplayStopText = (
  <Text style={[styles.playButtonLabel, styles.playButtonLabelStop]}>AUTO</Text>
);
