enum NameLabelColor {
  Blue = 'blue',
  Pink = 'pink',
  Green = 'green',
  Black = 'black',
}

export default class SpeechTextRenderOptions {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public speed?: number,
    public overrideCharacterName?: boolean | null,
    public characterName?: string | null,
    public characterNameLabelColor?: NameLabelColor | null,
  ) {}
}
