import EntityMapper from '../EntityMapper';

import NopCommand from '../../../../domain/entities/commands/NopCommand';
import ResourcesSet from '../../../../domain/entities/resources/ResourcesSet';

export interface NopCommandData {
  id: number;
  scene_id: number;
  type: 'nop';
}

export default class NopCommandMapper
  implements EntityMapper<NopCommandData, NopCommand>
{
  constructor(private resourcesSet: ResourcesSet) {}

  public map(obj: NopCommandData): NopCommand {
    return new NopCommand(obj.id, obj.scene_id);
  }
}
