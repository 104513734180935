interface Command {
  id: number;
}

export default class SceneTapForm {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public code: string,
    public sceneId: number,
    public command: Command,
    public pointX: number,
    public pointY: number,
    public tappedAt: Date,
  ) {}
}
