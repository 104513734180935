import {URL_TO_BLOB} from '../../helpers/images';
import {audioContext} from '../../view_models/AudioContextInitializer.web';
import {
  AUDIO_URL_TO_DECODED,
  decodeAudio,
} from '../../view_models/AssetDecoder';

interface Options {
  loop?: boolean;
  mute?: boolean;
  initialVolume?: number;
}

export default class SoundPlayer {
  private audio: HTMLAudioElement = new Audio();
  private audioSource: AudioBufferSourceNode | null = null;
  private gainNode: GainNode | null = null;
  private options: Options;

  private playing = false;

  private stopped = false;

  private endedCallback?: () => void;

  constructor(options: Options = {loop: false, mute: false, initialVolume: 1}) {
    this.options = options;
  }

  public play(uri: string, endedCallback?: () => void) {
    this.playing = true;
    this.endedCallback = endedCallback;
    try {
      if (audioContext) {
        this.playByWebAudioApi(audioContext, uri);
      } else {
        this.playByAudioElement(uri);
      }
    } catch (e) {
      console.log(e);
      this.endedCallback && this.endedCallback();
    }
  }

  public pause() {
    audioContext?.suspend();
  }

  public resume() {
    audioContext?.resume();
  }

  public stop() {
    this.playing = false;
    this.stopped = true;
    this.audio.pause();
    if (this.audioSource) {
      this.audioSource.stop();
    } else if (this.audio) {
      this.audio.currentTime = 0;
    }
    this.executeCallback();
    this.release();
  }

  public fadeOut() {
    this.playing = false;
    if (audioContext && this.gainNode) {
      const endTimeSeconds = 1.5;
      this.gainNode.gain.linearRampToValueAtTime(1, audioContext.currentTime);
      this.gainNode.gain.linearRampToValueAtTime(
        0,
        audioContext.currentTime + endTimeSeconds,
      );
      setTimeout(() => {
        this.stop();
      }, endTimeSeconds * 1000);
    } else {
      let intervalId: any = setInterval(() => {
        if (this.audio.volume <= 0.1) {
          clearInterval(intervalId);
          intervalId = null;
          this.stop();
        } else {
          this.audio.volume = this.audio.volume - 0.1;
        }
      }, 100);
    }
  }

  public release() {
    this.audioSource?.disconnect();
    this.gainNode?.disconnect();
  }

  public isPlaying() {
    return this.playing;
  }

  public mute() {
    if (this.gainNode) {
      this.gainNode.gain.value = 0;
    }
  }

  public unmute() {
    if (this.gainNode) {
      this.gainNode.gain.value = 1;
    }
  }

  public setVolumne(val: number) {
    if (this.gainNode) {
      this.gainNode.gain.value = val;
    }
  }

  private playByWebAudioApi(ctx: AudioContext, uri: string) {
    ctx.resume();
    if (this.audioSource) {
      this.audioSource.disconnect();
    }
    fetchAudioBuffer(ctx, uri, audioBuffer => {
      this.audioSource = ctx.createBufferSource();
      this.gainNode = ctx.createGain();
      this.audioSource.buffer = audioBuffer;
      this.audioSource.connect(this.gainNode);
      this.gainNode.connect(ctx.destination);
      if (this.options.mute) {
        this.gainNode.gain.value = 0;
      } else {
        this.gainNode.gain.setValueAtTime(
          this.options.initialVolume || 1,
          ctx.currentTime,
        );
        this.gainNode.gain.linearRampToValueAtTime(
          this.options.initialVolume || 1,
          ctx.currentTime,
        );
      }
      if (this.options.loop) {
        this.audioSource.loop = true;
      }
      if (this.stopped) {
        this.release();
      } else {
        this.audioSource.start();
        this.audioSource.addEventListener('ended', this.executeCallback);
      }
    });
  }

  private playByAudioElement(uri: string) {
    this.audio.src = URL_TO_BLOB[uri] || uri;
    const promise = this.audio.play();
    this.audio.addEventListener('ended', this.executeCallback);
    if (this.options.loop) {
      this.audio.loop = true;
    }
    promise.catch(() => {
      this.audio.play().catch(() => {});
    });
  }

  private executeCallback = () => {
    const callback = this.endedCallback;
    this.endedCallback = undefined;
    callback && callback();
  };
}

const fetchAudioBuffer = (
  ctx: AudioContext,
  uri: string,
  callback: (audioBuffer: AudioBuffer) => void,
) => {
  if (AUDIO_URL_TO_DECODED[uri]) {
    callback(AUDIO_URL_TO_DECODED[uri]);
    return;
  }
  decodeAudio(uri).then(audioBuffer => {
    callback(audioBuffer);
  });
};
