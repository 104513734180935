import * as React from 'react';
import {GestureResponderEvent, Pressable, ViewProps} from 'react-native';

import TouchableBaseView, {
  Props as TouchableBaseViewProps,
} from './TouchableBaseView';

interface Props extends ViewProps, TouchableBaseViewProps {}

export default class TouchableView extends TouchableBaseView<Props> {
  public render(): React.ReactNode {
    const {style} = this.props;
    return (
      <Pressable style={style} onPress={this.handlePress}>
        {this.props.children}
      </Pressable>
    );
  }

  private handlePress = (event: GestureResponderEvent) => {
    const {onTouch} = this.props;
    onTouch({
      x: event.nativeEvent?.pageX || 20,
      y: event.nativeEvent?.pageY || 20,
    });
  };
}
