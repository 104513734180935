import {decodeImage, decodedImage} from './AssetDecoder';

export default function preloadEffectImages(
  urls: string[],
  options: {
    beforeAction?: () => void;
    afterAction?: () => void;
  } = {},
) {
  const {beforeAction, afterAction} = options;
  beforeAction && beforeAction();
  const tasks = urls
    .filter(url => !decodedImage(url) && url.match(/^http/))
    .map(async url => {
      return await decodeImage(url);
    });
  Promise.all(tasks).then(() => {
    afterAction && afterAction();
  });
}
