import * as React from 'react';

type Format = 'vertical' | 'horizontal' | 'rectangle' | 'fluid' | 'auto';

export interface CommonProps {
  style?: React.CSSProperties;
  slotId: string;
  format?: Format | Array<Format>;
  fullWidthResponsive?: boolean;
}

interface Props extends CommonProps {
  clientId: string;
}

const GoogleAds: React.FunctionComponent<Props> = props => {
  const {style, clientId, slotId, format, fullWidthResponsive} = props;
  React.useEffect(() => {
    const w = window as any;
    (w.adsbygoogle = w.adsbygoogle || []).push({});
  }, []);
  return (
    <ins
      className={'adsbygoogle'}
      style={style}
      data-ad-client={clientId}
      data-ad-slot={slotId}
      data-ad-format={
        typeof format === 'string' ? format : (format || []).join(', ')
      }
      data-full-width-responsive={fullWidthResponsive}></ins>
  );
};

export default React.memo(GoogleAds);
