import React from 'react';

import {
  registerAnimationFrame,
  deregisterAnimationFrame,
} from '../../helpers/handleAnimationFrame';

interface Props {
  style?: React.CSSProperties;
  urls: Array<string>;
  duration: number;
  loop: boolean;
  repeatCount?: number | null;
  pause?: boolean;
  onSequenceEnd?: () => void;
  onAnimationStop?: () => void;
}

export default class SequenceAnimator extends React.Component<Props> {
  private imgRef = React.createRef<HTMLImageElement>();
  private mounted = false;
  private frame: number | null = null;
  private animationStart: number | null = null;
  private restRepeatCount?: number | null = null;
  private pauseDuration = 0;
  private pausedAt: number | null = null;

  public componentDidMount() {
    this.mounted = true;
    const {repeatCount} = this.props;
    this.restRepeatCount = repeatCount ? repeatCount - 1 : null;
    this.playAnimation();
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (!prevProps.pause && this.props.pause) {
      this.pausedAt = Date.now();
      deregisterAnimationFrame(this.onAnimate);
    } else if (prevProps.pause && !this.props.pause) {
      registerAnimationFrame(this.onAnimate);
      if (this.pausedAt) {
        this.pauseDuration += Date.now() - this.pausedAt;
        this.pausedAt = null;
      }
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
    deregisterAnimationFrame(this.onAnimate);
  }

  public render() {
    const {style} = this.props;
    return <img ref={this.imgRef} style={style} />;
  }

  private playAnimation = () => {
    if (!this.mounted) {
      return;
    }
    registerAnimationFrame(this.onAnimate);
  };

  private onAnimate = (timestamp: number) => {
    if (this.pausedAt) {
      return;
    }
    const {urls, loop, duration, onSequenceEnd, onAnimationStop} = this.props;
    if (!this.animationStart) {
      this.animationStart = timestamp;
    }
    let nextFrame = Math.floor(
      ((timestamp - this.animationStart - this.pauseDuration) / duration) *
        urls.length,
    );
    if (nextFrame > urls.length - 1) {
      if (onSequenceEnd) {
        onSequenceEnd();
      }
      if (loop) {
        nextFrame %= urls.length;
        this.animationStart = timestamp;
      } else if (this.restRepeatCount) {
        this.restRepeatCount -= 1;
        nextFrame %= urls.length;
        this.animationStart = timestamp;
      } else {
        nextFrame = -1;
      }
    }
    if (nextFrame > -1) {
      if (this.frame !== nextFrame) {
        this.frame = nextFrame;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.imgRef.current?.setAttribute('src', urls[this.frame]);
      }
      this.playAnimation();
    } else if (onAnimationStop) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.imgRef.current?.setAttribute('src', '');
      onAnimationStop();
    }
  };
}
