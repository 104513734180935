import EntityMapper from '../EntityMapper';

import UserProfileMapper, {UserProfileData} from './UserProfileMapper';

import CurrentUser from '../../../../domain/entities/consumer/CurrentUser';
import UserProfile from '../../../../domain/entities/consumer/UserProfile';

export interface CurrentUserData {
  id: number;
  user_name: string;
  email: string;
  unconfirmed_email: string | null;
  confirmed: boolean;
  enabled: boolean;
  consumer_user_id: number;
  enabled_paid_subscriber: boolean;
  ad_blocking: boolean;
  consumer_user_free_ticket_count: number;
  user_profile: UserProfileData | null;
}

export default class CurrentUserMapper
  implements EntityMapper<CurrentUserData, CurrentUser>
{
  private userProfileMapper = new UserProfileMapper();

  public map(obj: CurrentUserData, headers: Headers | null): CurrentUser {
    let userProfile: UserProfile | null = null;
    if (obj.user_profile) {
      userProfile = this.userProfileMapper.map(obj.user_profile);
    }
    let accessToken: string | null = null;
    if (headers) {
      accessToken = headers.get('authorization');
    }
    return new CurrentUser(
      obj.id,
      obj.user_name,
      obj.email,
      obj.unconfirmed_email,
      obj.confirmed,
      obj.enabled,
      obj.consumer_user_id,
      obj.enabled_paid_subscriber,
      obj.ad_blocking,
      accessToken,
      obj.consumer_user_free_ticket_count,
      userProfile,
    );
  }
}
