import * as React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';

import IllustrationView from './parts/IllustrationView';

import IllustrationShowCommand from '../../../../../domain/entities/commands/IllustrationShowCommand';

interface Size {
  height: number;
  width: number;
}

interface Props {
  style?: StyleProp<ViewStyle>;
  command: IllustrationShowCommand | null;
  size: Size;
  stageSize: Size;
  onBeforeRenderIllustration: (command: IllustrationShowCommand) => void;
  onAfterRenderIllustration: (command: IllustrationShowCommand) => void;
  onFinishPlay: (command: IllustrationShowCommand) => void;
}

const StageModal: React.FunctionComponent<Props> = props => {
  const {
    style,
    size,
    stageSize,
    onBeforeRenderIllustration,
    onAfterRenderIllustration,
    onFinishPlay,
  } = props;
  const {command} = props;
  if (!command) {
    return null;
  }
  return (
    <View key={command.getKey()} style={[style, stageSize]}>
      <IllustrationView
        command={command}
        size={size}
        onBeforeRenderIllustration={onBeforeRenderIllustration}
        onAfterRenderIllustration={onAfterRenderIllustration}
        onFinishPlay={onFinishPlay}
      />
    </View>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.command === nextProps.command;
};

export default React.memo(StageModal, areEqual);
