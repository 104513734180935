import * as React from 'react';

import {positionedEffectImageUrls} from '../../../../../helpers/images';
import EffectImageSequence from '../../../../shared/EffectImageSequence';

import BackgroundShowCommand from '../../../../../../domain/entities/commands/BackgroundShowCommand';

interface Size {
  height: number;
  width: number;
}

interface Props {
  command: BackgroundShowCommand;
  size: Size;
  orientation: 'horizontal' | 'vertical';
}

const EffectBackgroundShowCommandView: React.FunctionComponent<
  Props
> = props => {
  const {command, size, orientation} = props;
  if (!command.positionedEffect) {
    return null;
  }
  const onFinish = React.useCallback(() => {}, []);
  return (
    <EffectImageSequence
      urls={positionedEffectImageUrls(
        command.positionedEffect,
        size,
        orientation,
      )}
      size={size}
      loop={true}
      onFinish={onFinish}
    />
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return true;
};

export default React.memo(EffectBackgroundShowCommandView, areEqual);
