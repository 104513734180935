import BackgroundResource from './BackgroundResource';
import CharacterResource from './CharacterResource';
import FullScreenIllustrationResource from './FullScreenIllustrationResource';
import IllustrationResource from './IllustrationResource';
import MarkResource from './MarkResource';
import Resource from './Resource';
import SpeechBalloonResource from './SpeechBalloonResource';
import TextFrameResource from './TextFrameResource';
import SoundResource from './SoundResource';
import VoiceResource from './VoiceResource';
import PositionedEffectResource from './PositionedEffectResource';

export default class ResourcesSet {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public backgroundResources: BackgroundResource[] = [],
    public characterResources: CharacterResource[] = [],
    public fullScreenIllustrationResources: FullScreenIllustrationResource[] = [],
    public illustrationResources: IllustrationResource[] = [],
    public markResources: MarkResource[] = [],
    public speechBalloonResources: SpeechBalloonResource[] = [],
    public textFrameResources: TextFrameResource[] = [],
    public soundResources: SoundResource[] = [],
    public voiceResources: VoiceResource[] = [],
    public positionedEffectResources: PositionedEffectResource[] = [],
  ) {}

  public getBackgroundResource(id: number): BackgroundResource {
    return this.getResource(this.backgroundResources, id);
  }

  public getCharacterResource(id: number): CharacterResource {
    return this.getResource(this.characterResources, id);
  }

  public getFullScreenIllustrationResource(
    id: number,
  ): FullScreenIllustrationResource {
    return this.getResource(this.fullScreenIllustrationResources, id);
  }

  public getIllustrationResource(id: number): IllustrationResource {
    return this.getResource(this.illustrationResources, id);
  }

  public getMarkResource(id: number): MarkResource {
    return this.getResource(this.markResources, id);
  }

  public getSpeechBalloonResource(id: number): SpeechBalloonResource {
    return this.getResource(this.speechBalloonResources, id);
  }

  public getTextFrameResource(id: number): TextFrameResource {
    return this.getResource(this.textFrameResources, id);
  }

  public getSoundResource(id: number): SoundResource {
    return this.getResource(this.soundResources, id);
  }

  public getVoiceResource(id: number): VoiceResource {
    return this.getResource(this.voiceResources, id);
  }

  public getPositionedEffectResource(id: number): PositionedEffectResource {
    return this.getResource(this.positionedEffectResources, id);
  }

  public hasSound = () => {
    return this.soundResources.length > 0 || this.voiceResources.length > 0;
  };
  private getResource<T extends Resource>(resources: T[], id: number): T {
    const found = resources.find(resource => resource.id === id);
    if (found) {
      return found;
    } else {
      throw new Error(`Not found resource #${id} in ${resources}`);
    }
  }
}
