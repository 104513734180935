import AdjustableSpeechBalloon from './AdjustableSpeechBalloon';
import ViewerLayoutManager from './ViewerLayoutManager';

import {decodeAsset, IMAGE_URL_TO_DECODED} from './AssetDecoder';

import AssetPreloadExecutor from './AssetPreloadExecutor';

import {
  backgroundImageUrl,
  characterImageUrl,
  fullScreenIllustrationImageUrl,
  illustrationImageUrl,
  markImageUrl,
  speechBalloonImageUrl,
  textFrameImageUrl,
  positionedEffectImageUrls,
  URL_TO_BLOB,
} from '../helpers/images';

import SceneScript from '../../domain/entities/SceneScript';
import AdjustablePosition from '../../domain/value_objects/AdjustablePosition';

export default class ImagePreloader {
  public static async preload(
    sceneScripts: SceneScript[],
    layoutManager: ViewerLayoutManager,
    callback?: (progress: number) => void,
  ): Promise<any> {
    const imageUrls: string[] = [];
    sceneScripts.forEach(sceneScript => {
      if (sceneScript.preloaded) {
        return;
      }
      sceneScript.preloaded = true;
      const commands = sceneScript.commands;
      commands.forEach(command => {
        const audioUrl = command.getAudioUrl();
        if (audioUrl) {
          imageUrls.push(audioUrl);
        }
      });
      const resourcesSet = sceneScript.resourcesSet;
      resourcesSet.backgroundResources.forEach(background => {
        imageUrls.push(
          backgroundImageUrl(background, layoutManager.getStageSize()),
        );
      });
      resourcesSet.characterResources.forEach(character => {
        Object.keys(character.imageUrls).forEach(face => {
          imageUrls.push(
            characterImageUrl(
              face,
              character,
              layoutManager.getCharacterSize(),
            ),
          );
        });
      });
      resourcesSet.markResources.forEach(mark => {
        imageUrls.push(markImageUrl(mark, layoutManager.getMarkSize()));
      });
      resourcesSet.speechBalloonResources.forEach(speechBalloon => {
        Object.keys(speechBalloon.imageUrls).forEach(key => {
          if (speechBalloon.options.adjustable) {
            Object.keys(speechBalloon.options.adjustable).forEach(pos => {
              const adjustableSpeechBalloon = new AdjustableSpeechBalloon(
                speechBalloon,
                this.converAdjustablePosition(pos),
              );
              imageUrls.push(
                speechBalloonImageUrl(
                  key,
                  adjustableSpeechBalloon,
                  layoutManager.getSpeechBalloonSize().width,
                  layoutManager.orientation,
                ),
              );
            });
          }
        });
      });
      resourcesSet.textFrameResources.forEach(textFrame => {
        Object.keys(textFrame.imageUrls).forEach(key => {
          imageUrls.push(
            textFrameImageUrl(
              key,
              textFrame,
              layoutManager.getTextFrameSize().width,
              layoutManager.orientation,
            ),
          );
        });
      });
      resourcesSet.illustrationResources.forEach(illustration => {
        imageUrls.push(
          illustrationImageUrl(
            illustration,
            layoutManager.getIllustrationSize(),
          ),
        );
      });
      resourcesSet.fullScreenIllustrationResources.forEach(
        fullScreenIllustration => {
          imageUrls.push(
            fullScreenIllustrationImageUrl(
              fullScreenIllustration,
              layoutManager.getFullScreenIllustrationSize(),
            ),
          );
        },
      );
      resourcesSet.positionedEffectResources.forEach(
        positionedEffectResource => {
          positionedEffectImageUrls(
            positionedEffectResource,
            layoutManager.getStageSize(),
            layoutManager.orientation,
          ).forEach(url => {
            imageUrls.push(url);
          });
        },
      );
      resourcesSet.voiceResources.forEach(voiceResource => {
        imageUrls.push(voiceResource.audioUrl);
      });
      resourcesSet.soundResources.forEach(soundResource => {
        imageUrls.push(soundResource.audioUrl);
      });
    });
    const regexp = /^http/;
    const filteredImageUrls = imageUrls.filter((url, i, self) => {
      return (
        self.indexOf(url) === i &&
        !URL_TO_BLOB[url] &&
        !IMAGE_URL_TO_DECODED[url] &&
        url.match(regexp)
      );
    });
    const chunkSize = 500;
    const chunkedFilteredImageUrls = chunk(filteredImageUrls, chunkSize);
    let totalFetchedCount = 0;
    if (chunkedFilteredImageUrls.length === 0) {
      callback && callback(1);
    }
    for (let i = 0; i < chunkedFilteredImageUrls.length; i++) {
      await new AssetPreloadExecutor().execute(
        chunkedFilteredImageUrls[i],
        callback
          ? (fetchedCount: number) => {
              callback(
                (fetchedCount + totalFetchedCount) / filteredImageUrls.length,
              );
            }
          : undefined,
      );
      totalFetchedCount += chunkedFilteredImageUrls[i].length;
    }
    return Promise.resolve();
  }

  private static converAdjustablePosition(pos: string): AdjustablePosition {
    switch (pos) {
      case 'left':
        return AdjustablePosition.Left;
      case 'center_left':
        return AdjustablePosition.CenterLeft;
      case 'center':
        return AdjustablePosition.Center;
      case 'center_right':
        return AdjustablePosition.CenterRight;
      case 'right':
        return AdjustablePosition.Right;
      case 'left-center':
        return AdjustablePosition.Left_Center;
      case 'center-right':
        return AdjustablePosition.Center_Right;
      case 'left-right':
        return AdjustablePosition.Left_Right;
      case 'center_left-center_right':
        return AdjustablePosition.CenterLeft_CenterRight;
      case 'left-center-right':
        return AdjustablePosition.Left_Center_Right;
      default:
        throw new Error('Unsupported adjustablePosition error!');
    }
  }
}

const chunk = (arr: string[], size: number) => {
  return arr.reduce(
    (newarr, _, i) =>
      i % size ? newarr : newarr.concat([arr.slice(i, i + size)]),
    [] as string[][],
  );
};
