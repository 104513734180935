import * as React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import ActivatableElasticTextBoxWithPrompt from './ActivatableElasticTextBoxWithPrompt';

import {textFrameImageUrl} from '../../../../helpers/images';

import DescriptiveTextShowCommand from '../../../../../domain/entities/commands/DescriptiveTextShowCommand';

interface Props {
  style?: StyleProp<ViewStyle>;
  command: DescriptiveTextShowCommand;
  width: number;
  windowWidth: number;
  orientation: 'horizontal' | 'vertical';
  active?: boolean;
  skipRenderText: boolean;
  alignTop?: boolean;
  visiblePrompt?: boolean;
  textSpeed?: 'slow' | 'normal' | 'fast' | 'no_effect';
  hasVoice?: boolean;
  onLoad?: () => void;
  onBeforeChangeHeight?: () => void;
  onAfterChangeHeight?: () => void;
  onBeforeRenderText: () => void;
  onAfterRenderText: () => void;
}

const TOP_IMAGE_KEY = 'top';
const MIDDLE_IMAGE_KEY = 'middle';
const BOTTOM_IMAGE_KEY = 'bottom';

const ElasticTextFrame: React.FunctionComponent<Props> = props => {
  const {
    style,
    command,
    width,
    windowWidth,
    orientation,
    active,
    skipRenderText,
    alignTop,
    visiblePrompt,
    textSpeed,
    hasVoice,
    onLoad,
    onBeforeChangeHeight,
    onAfterChangeHeight,
    onBeforeRenderText,
    onAfterRenderText,
  } = props;
  const {text, textFrame} = command;
  const top = React.useMemo(
    () => textFrameImageUrl(TOP_IMAGE_KEY, textFrame, width, orientation),
    [],
  );
  const middle = React.useMemo(
    () => textFrameImageUrl(MIDDLE_IMAGE_KEY, textFrame, width, orientation),
    [],
  );
  const bottom = React.useMemo(
    () => textFrameImageUrl(BOTTOM_IMAGE_KEY, textFrame, width, orientation),
    [],
  );
  return (
    <ActivatableElasticTextBoxWithPrompt
      style={style}
      text={text}
      active={active}
      top={top}
      middle={middle}
      bottom={bottom}
      textStyle={textFrame.options.textStyle}
      wideTextStyle={textFrame.options.wideTextStyle}
      voiceIconStyle={textFrame.options.voiceIconStyle}
      width={width}
      windowWidth={windowWidth}
      alignTop={alignTop}
      visiblePrompt={visiblePrompt}
      skipRenderText={skipRenderText}
      textSpeed={textSpeed}
      hasVoice={hasVoice}
      onLoad={onLoad}
      onBeforeChangeHeight={onBeforeChangeHeight}
      onAfterChangeHeight={onAfterChangeHeight}
      onBeforeRenderText={onBeforeRenderText}
      onAfterRenderText={onAfterRenderText}
    />
  );
};

export default ElasticTextFrame;

// MEMO テキストフレームごとのスタイル指定（データベース管理）
// const styles = StyleSheet.create({
//     text: {
//         // テキストフレーム1/2/7/8
//         // color: "white",
//         // paddingVertical: 15,

//         // テキストフレーム3/9
//         // color: "white",
//         // paddingVertical: 12,

//         // テキストフレーム4/10
//         // color: "white",
//         // paddingVertical: 10,

//         // テキストフレーム5/6/11/12
//         // color: "white",
//         // paddingVertical: 18,

//         // テキストフレーム13/14
//         // color: colors.brown,
//         // paddingVertical: 15,

//         // テキストフレーム15
//         // color: colors.brown,
//         // paddingVertical: 12,

//         // テキストフレーム16
//         // color: colors.brown,
//         // paddingVertical: 10,

//         // テキストフレーム17/18
//         // color: colors.brown,
//         // paddingVertical: 18,

//         // テキストフレーム19/20
//         // paddingVertical: 15,

//         // テキストフレーム21
//         // paddingVertical: 12,

//         // テキストフレーム22
//         // paddingVertical: 10,

//         // テキストフレーム23/24
//         // paddingVertical: 18,

//         // テキストフレーム25
//         // color: colors.brown,
//         // paddingVertical: 6,

//         // テキストフレーム26
//         // color: "white",
//         // paddingVertical: 20,
//         // textShadowColor: "black",
//         // textShadowOffset: { height: 2 },
//         // textShadowRadius: 4,
//     } as TextStyle,
// });
