import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import FullScreenModal from './full_screen/FullScreenModal';

import ViewerLayoutManager from '../../../view_models/ViewerLayoutManager';

import Command from '../../../../domain/entities/commands/Command';
import FullScreenIllustrationShowCommand from '../../../../domain/entities/commands/FullScreenIllustrationShowCommand';

interface Props {
  command: Command | null;
  layoutManager: ViewerLayoutManager;
  visiblePrompt?: boolean;
  showTap: (point: {x: number; y: number}) => void;
  onTouch: (point: {x: number; y: number}) => void;
  onBeforeRenderFullScreenIllustration: (
    command: FullScreenIllustrationShowCommand,
  ) => void;
  onRenderFullScreenIllustration: (
    command: FullScreenIllustrationShowCommand,
    success: () => void,
  ) => void;
  onAfterRenderFullScreenIllustration: (
    command: FullScreenIllustrationShowCommand,
  ) => void;
  onFinishPlaySound: (
    command: FullScreenIllustrationShowCommand,
    success: () => void,
  ) => void;
}

const FullScreen: React.FunctionComponent<Props> = props => {
  const {
    command,
    layoutManager,
    visiblePrompt,
    showTap,
    onTouch,
    onBeforeRenderFullScreenIllustration,
    onRenderFullScreenIllustration,
    onAfterRenderFullScreenIllustration,
    onFinishPlaySound,
  } = props;
  if (!command || !(command instanceof FullScreenIllustrationShowCommand)) {
    return null;
  }
  return (
    <View style={styles.container}>
      <FullScreenModal
        command={command}
        visiblePrompt={visiblePrompt}
        showTap={showTap}
        onTouch={onTouch}
        size={layoutManager.getFullScreenIllustrationSize()}
        onBeforeRenderFullScreenIllustration={
          onBeforeRenderFullScreenIllustration
        }
        onRenderFullScreenIllustration={onRenderFullScreenIllustration}
        onAfterRenderFullScreenIllustration={
          onAfterRenderFullScreenIllustration
        }
        onFinishPlaySound={onFinishPlaySound}
      />
    </View>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.command === nextProps.command;
};

export default React.memo(FullScreen, areEqual);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'black',
    flex: 1,
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  } as ViewStyle,
});
