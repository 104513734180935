import * as React from 'react';
import {
  Image,
  ImageSourcePropType,
  ImageStyle,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import convertImageSource from '../../../helpers/convertImageSource';

import tapBackgroundUri from '../../../../../../assets/images/viewer/tap_background.png';
import tapUri from '../../../../../../assets/images/viewer/tap.png';
import letsTapUri from '../../../../../../assets/images/viewer/lets_tap.png';
import handTappedUri from '../../../../../../assets/images/viewer/hand_tapped.png';
import handDefaultUri from '../../../../../../assets/images/viewer/hand_default.png';
import soundAnimImage01Uri from '../../../../../../assets/images/viewer/sound_anim/01.png';
import soundAnimImage02Uri from '../../../../../../assets/images/viewer/sound_anim/02.png';
import soundAnimImage03Uri from '../../../../../../assets/images/viewer/sound_anim/03.png';
import soundAnimImage04Uri from '../../../../../../assets/images/viewer/sound_anim/04.png';

const localImages = [
  convertImageSource(tapBackgroundUri),
  convertImageSource(tapUri),
  convertImageSource(letsTapUri),
  convertImageSource(handTappedUri),
  convertImageSource(handDefaultUri),
  convertImageSource(soundAnimImage01Uri),
  convertImageSource(soundAnimImage02Uri),
  convertImageSource(soundAnimImage03Uri),
  convertImageSource(soundAnimImage04Uri),
];

interface Props {}

const PreloadLocalImage: React.FunctionComponent<{
  source: ImageSourcePropType;
}> = props => {
  const {source} = props;
  const [loaded, setLoaded] = React.useState(false);
  const handleLayout = React.useCallback(() => {
    setLoaded(true);
  }, []);
  if (loaded) {
    return null;
  }
  return <Image style={styles.image} source={source} onLoad={handleLayout} />;
};

const PreloadLocalImages: React.FunctionComponent<Props> = props => {
  return (
    <View style={styles.container}>
      {localImages.map((source, i) => (
        <PreloadLocalImage key={i} source={source} />
      ))}
    </View>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return true;
};

export default React.memo(PreloadLocalImages, areEqual);

const styles = StyleSheet.create({
  container: {
    height: 0,
    position: 'absolute',
    top: 0,
    width: 0,
  } as ViewStyle,
  image: {
    opacity: 0,
  } as ImageStyle,
});
