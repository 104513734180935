import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const GL = require('gl-react');

const mixArrays = (arr1: number[], arr2: number[], m: number): number[] =>
  arr1.map((v, i) => (1 - m) * v + m * arr2[i]);

const shaders = GL.Shaders.create({
  sepia: {
    frag: `
            precision highp float;
            varying vec2 uv;
            uniform sampler2D t;
            uniform mat4 sepia;

            void main () {
                gl_FragColor = sepia * texture2D(t, uv);
            }
    `,
  },
});

interface Props {
  sepia: number;
  children?: React.ReactNode;
}

const Sepia = GL.createComponent((props: Props) => {
  const {sepia: s, children: t} = props;
  const sepia = mixArrays(
    [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
    [0.3, 0.3, 0.3, 0, 0.6, 0.6, 0.6, 0, 0.1, 0.1, 0.1, 0, 0.2, 0, -0.2, 1],
    s,
  );
  return <GL.Node shader={shaders.sepia} uniforms={{t, sepia}} />;
}) as React.FunctionComponent<Props>;

Sepia.defaultProps = {
  sepia: 0,
};

export default Sepia;
