import * as React from 'react';

import CharacterWithMarkViewBase from './CharacterWithMarkViewBase';
import CharacterShowOrUpdateOrHideCommandView from '../command_views/CharacterShowOrUpdateOrHideCommandView';

const keyFrames = [
  {transform: 'translateY(0px)'},
  {transform: 'translateY(-10px)'},
  {transform: 'translateY(0px)'},
];
const keyFrameAnimationOptions = {
  duration: 300,
  easing: 'ease',
};

const style = {alignItems: 'center', flex: 1, alignSelf: 'center'};

export default class CharacterWithMarkView extends CharacterWithMarkViewBase {
  private divRef = React.createRef<HTMLDivElement>();

  public bounce = () => {
    if (this.divRef.current) {
      this.divRef.current
        .animate(keyFrames, keyFrameAnimationOptions)
        .addEventListener('finish', () => {
          this.bounding = false;
        });
    }
  };

  public render(): React.ReactNode {
    const {
      characterCommand,
      characterSize,
      markSize,
      active,
      scaleUp,
      onAfterRenderCharacter,
    } = this.props;
    return (
      <div ref={this.divRef} style={style}>
        {characterCommand && (
          <CharacterShowOrUpdateOrHideCommandView
            command={characterCommand}
            markSize={markSize}
            characterSize={characterSize}
            active={active === false ? false : true}
            scaleUp={scaleUp}
            onAfterRenderCharacter={onAfterRenderCharacter}
          />
        )}
      </div>
    );
  }
}
