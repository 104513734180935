import {ImageStyle, Platform, StyleSheet} from 'react-native';

export const colors = {
  lightGray: '#999',
  paleGray: '#D6D6D6',
  textColor: '#222',
};

export const size = {
  listItemSeparator: Platform.select({
    android: 0.6,
    ios: StyleSheet.hairlineWidth,
    default: 0,
  }),
};
