import EntityMapper from './EntityMapper';

import AreaMapper, {AreaData} from './AreaMapper';

import Pref from '../../../domain/entities/Pref';

export interface PrefData {
  id: number;
  code: string;
  name: string;
  name_ruby: string;
  created_at: string;
  updated_at: string;
  area: AreaData;
}

export default class PrefMapper implements EntityMapper<PrefData, Pref> {
  private areaMapper = new AreaMapper();

  public map(obj: PrefData): Pref {
    return new Pref(
      obj.id,
      obj.code,
      obj.name,
      obj.name_ruby,
      new Date(obj.created_at),
      new Date(obj.updated_at),
      this.areaMapper.map(obj.area),
    );
  }
}
