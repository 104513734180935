import * as React from 'react';
import {LayoutChangeEvent, Platform, Text, TextStyle} from 'react-native';

import {
  registerAnimationFrame,
  deregisterAnimationFrame,
} from '../../../../helpers/handleAnimationFrame';

const isWeb = Platform.OS === 'web';
const isAndroid = Platform.OS === 'android';

export const RENDERING_STRAT_TIME = isWeb ? 140 : 100;

// eslint-disable-next-line no-control-regex
const CAPITAL_CHARACTERS = /^[^\x01-\x7E\uFF61-\uFF9F]+$/;
const PROHIBITED_CHARACTERS =
  /[「『（｛【＜≪［」』）｝】＞≫］ぁぃぅぇぉっゃゅょァィゥェォッャュョ・ー―-、。,.ゝ々！？：；／]/;

const FULL_WIDTH_SPACE = '　';
const HALF_WIDTH_SPACE = ' ';
const NEWLINE = '\n';

const TEXT_SPEED_NO_EFFECT = 'no_effect';
const NEXT_CHAR_COLOR = 'transparent';

const TEXT_SPEED_TO_INTERVALL: {
  no_effect: number;
  fast: number;
  normal: number;
  slow: number;
} = {
  no_effect: 0,
  fast: 30,
  normal: 40,
  slow: 55,
};

interface Props {
  style?: TextStyle;
  text: string;
  skipRenderText: boolean;
  active?: boolean;
  textSpeed?: 'slow' | 'normal' | 'fast' | 'no_effect';
  onBeforeRenderText: () => void;
  onAfterRenderText: () => void;
  onLayout?: (event: LayoutChangeEvent) => void;
}

export default class TypewriterEffectText extends React.Component<Props> {
  private mounted = false;
  private textView = React.createRef<Text>();
  private shadowTextView = React.createRef<Text>();
  private textIndex = 0;
  private animationStart = 0;
  private requestId: any | null = null;
  private suspended = false;
  private suspendedTime = 0;
  private renderedText = false;
  private textHeight = 0;
  private finished = false;
  private prevCutoffIndex = 0;
  private nextCharStyle: TextStyle;

  constructor(props: Props) {
    super(props);
    this.textIndex =
      props.textSpeed === TEXT_SPEED_NO_EFFECT ||
      props.skipRenderText ||
      !props.active
        ? props.text.length
        : 0;
    const {fontSize, fontWeight, fontFamily, lineHeight} =
      this.props.style || {};
    this.nextCharStyle = {
      fontSize,
      fontWeight,
      fontFamily,
      lineHeight,
      color: NEXT_CHAR_COLOR,
      ...(isWeb ? {textShadow: 'none'} : {}),
    };
  }

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !(
      this.props.skipRenderText === nextProps.skipRenderText &&
      this.props.textSpeed === nextProps.textSpeed &&
      this.props.active === nextProps.active
    );
  }

  public componentDidMount() {
    const {active, onBeforeRenderText} = this.props;
    this.mounted = true;
    if (!active) {
      return;
    }
    onBeforeRenderText();
    if (this.textIndex === this.props.text.length) {
      setTimeout(this.handleAfterRenderText, RENDERING_STRAT_TIME * 1.5);
    } else {
      setTimeout(this.delayRenderText, RENDERING_STRAT_TIME);
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const {text} = this.props;
    if (
      (this.props.skipRenderText && !prevProps.skipRenderText) ||
      (!this.props.active && prevProps.active)
    ) {
      this.updateText(text.length);
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
    if (this.requestId) {
      deregisterAnimationFrame(this.renderText);
    }
  }

  public render(): React.ReactNode {
    const {style, text} = this.props;
    const textIndex = this.textIndex;
    const nextChar = text[textIndex];
    return (
      <Text ref={this.textView} style={style} onLayout={this.handleLayout}>
        {this.getRenderText(textIndex)}
        {nextChar ? (
          isAndroid ? (
            nextChar.match(CAPITAL_CHARACTERS) ? (
              FULL_WIDTH_SPACE
            ) : nextChar.match(NEWLINE) ? (
              NEWLINE
            ) : (
              HALF_WIDTH_SPACE
            )
          ) : (
            <Text ref={this.shadowTextView} style={this.nextCharStyle}>
              {this.generateShadowText(textIndex)}
            </Text>
          )
        ) : null}
      </Text>
    );
  }

  private getRenderText = (textIndex: number) => {
    const {text} = this.props;
    if (!textIndex) {
      return HALF_WIDTH_SPACE;
    }
    return text.substring(0, textIndex);
  };

  private renderText = (timestamp: number) => {
    const {text, skipRenderText} = this.props;
    if (this.animationStart === 0) {
      this.animationStart = timestamp;
    }
    if (this.requestId) {
      deregisterAnimationFrame(this.renderText);
      this.requestId = null;
    }
    if (this.textIndex === text.length) {
      return;
    } else if (this.suspended || !this.mounted) {
      setTimeout(this.delayRenderText, 50);
    } else if (this.textIndex < text.length && !skipRenderText) {
      this.updateText(this.getNextIextIndex(timestamp), timestamp);
      this.delayRenderText();
    } else {
      this.updateText(text.length);
    }
  };

  private updateText = (nextTextIndex: number, timestamp?: number) => {
    const {text} = this.props;
    if (this.renderedText) {
      return;
    }
    if (this.textIndex === nextTextIndex) {
      // skip
    } else if (this.textIndex < nextTextIndex) {
      this.textIndex = nextTextIndex;
      if (timestamp) {
        this.suspendedTime = 0;
        this.animationStart = timestamp;
      }
      if (isWeb) {
        (this.textView.current as any).childNodes[0].textContent =
          this.getRenderText(this.textIndex);
        if (this.shadowTextView.current) {
          const shadowText = this.generateShadowText(this.textIndex);
          (this.shadowTextView.current as any).textContent = shadowText;
        }
      } else {
        this.forceUpdate();
      }
    }
    if (this.textIndex === text.length) {
      this.renderedText = true;
      this.handleAfterRenderText();
    }
  };

  private handleLayout = (event: LayoutChangeEvent) => {
    const {onLayout} = this.props;
    if (this.textHeight >= event.nativeEvent.layout.height) {
      return;
    }
    this.suspended = true;
    this.textHeight = event.nativeEvent.layout.height;
    onLayout && onLayout(event);
    const suspendTime = this.textIndex ? 200 : 100;
    setTimeout(this.unsuspend, suspendTime);
    this.suspendedTime += suspendTime;
  };

  private unsuspend = () => {
    this.suspended = false;
  };

  private delayRenderText = () => {
    this.requestId = registerAnimationFrame(this.renderText);
  };

  private getNextIextIndex = (timestamp: number): number => {
    const {text, textSpeed} = this.props;
    const interval = textSpeed ? TEXT_SPEED_TO_INTERVALL[textSpeed] : 40;
    const increment =
      (timestamp - this.animationStart - this.suspendedTime) / interval +
      (this.prevCutoffIndex || 0);
    const flooredIncrement = Math.floor(increment);
    this.prevCutoffIndex = increment - flooredIncrement;
    return Math.min(text.length, this.textIndex + flooredIncrement);
  };

  private handleAfterRenderText = () => {
    const {onAfterRenderText} = this.props;
    if (this.finished) {
      return;
    }
    this.finished = true;
    onAfterRenderText();
  };

  private generateShadowText = (textIndex: number) => {
    const {text} = this.props;
    const nextChar = text[textIndex];
    const nextNextChar = text[textIndex + 1];
    if (!nextChar) {
      return '';
    }
    return `${nextChar}${
      nextNextChar
        ? nextNextChar.match(PROHIBITED_CHARACTERS)
          ? nextNextChar
          : ''
        : ''
    }`;
  };
}
