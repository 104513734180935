import BackgroundMusicCommandOptions from '../../../../domain/value_objects/command_options/BackgroundMusicCommandOptions';

export interface BackgroundMusicCommandOptionsData {
  fall_through?: boolean;
}

export default class BackgroundMusicCommandOptionsMapper {
  public map(
    obj: BackgroundMusicCommandOptionsData,
  ): BackgroundMusicCommandOptions {
    return new BackgroundMusicCommandOptions(obj.fall_through || false);
  }
}
