import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import UserEpisodeBrowsingLogMapper, {
  UserEpisodeBrowsingLogData,
} from '../../entities/mappers/consumer/UserEpisodeBrowsingLogMapper';

import UserEpisodeBrowsingLog from '../../../domain/entities/consumer/UserEpisodeBrowsingLog';
import UserEpisodeBrowsingLogsRepository from '../../../domain/repositories/consumer/UserEpisodeBrowsingLogsRepository';

export default class NetUserEpisodeBrowsingHistoriesRepository
  extends NetResourcesRepository<
    UserEpisodeBrowsingLog,
    UserEpisodeBrowsingLogData
  >
  implements UserEpisodeBrowsingLogsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/consumer/user_episode_browsing_logs',
        new UserEpisodeBrowsingLogMapper(),
      ),
    );
  }
}
