import EntityMapper from '../EntityMapper';

import BackgroundShowCommand from '../../../../domain/entities/commands/BackgroundShowCommand';
import ResourcesSet from '../../../../domain/entities/resources/ResourcesSet';

import BackgroundCommandOptionsMapper, {
  BackgroundCommandOptionsData,
} from '../../../value_objects/mappers/command_options/BackgroundCommandOptionsMapper';
import BackgroundCommandEffectOptionsMapper, {
  BackgroundCommandEffectOptionsData,
} from '../../../value_objects/mappers/command_options/BackgroundCommandEffectOptionsMapper';

export interface BackgroundShowCommandData {
  id: number;
  scene_id: number;
  type: 'background_show';
  background_id: number;
  positioned_effect_id?: number;
  options?: BackgroundCommandOptionsData;
  effect_options?: BackgroundCommandEffectOptionsData;
}

export default class BackgroundShowCommandMapper
  implements EntityMapper<BackgroundShowCommandData, BackgroundShowCommand>
{
  private backgroundCommandOptionsMapper: BackgroundCommandOptionsMapper;
  private backgroundCommandEffectOptionsMapper: BackgroundCommandEffectOptionsMapper;

  constructor(private resourcesSet: ResourcesSet) {
    this.backgroundCommandOptionsMapper = new BackgroundCommandOptionsMapper();
    this.backgroundCommandEffectOptionsMapper =
      new BackgroundCommandEffectOptionsMapper();
  }

  public map(obj: BackgroundShowCommandData): BackgroundShowCommand {
    const options = this.backgroundCommandOptionsMapper.map(obj.options || {});
    const effect_options = obj.effect_options
      ? this.backgroundCommandEffectOptionsMapper.map(obj.effect_options)
      : null;
    return new BackgroundShowCommand(
      obj.id,
      obj.scene_id,
      this.resourcesSet.getBackgroundResource(obj.background_id),
      obj.positioned_effect_id
        ? this.resourcesSet.getPositionedEffectResource(
            obj.positioned_effect_id,
          )
        : null,
      options,
      effect_options,
    );
  }
}
