import * as React from 'react';

interface Props {
  className: string;
  children?: React.ReactNode;
}

const WithClassName: React.FunctionComponent<Props> = props => {
  const {className, children} = props;
  return <div className={className}>{children}</div>;
};

export default WithClassName;
