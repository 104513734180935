import * as React from 'react';
import {
  Animated,
  ImageStyle,
  Platform,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import FullScreenIllustrationShowCommand from '../../../../../../domain/entities/commands/FullScreenIllustrationShowCommand';

import Prompt from '../../../../shared/Prompt';

import {imageUrl} from '../../../../../helpers/images';

import {isMobile} from '../../../../../../data/data_stores/net/UserAgent';

const cache = 'force-cache';

export enum ShowStatus {
  SHOW_OVERLAY_BEGIN,
  SHOW_OVERLAY_END,
  SHOW_IMAGE_BEGIN,
  SHOW_IMAGE_END,
  HIDE_IMAGE_BEGIN,
  HIDE_IMAGE_END,
  HIDE_OVERLAY_BEGIN,
  HIDE_OVERLAY_END,
}

interface Size {
  height: number;
  width: number;
}

export interface Props {
  command: FullScreenIllustrationShowCommand;
  size: Size;
  showStatus: ShowStatus;
  visiblePrompt?: boolean;
  updateShowStatus: () => void;
  delayUpdateShowStatus: (timeout: number) => void;
}

const RESIZE_MODE = 'contain';

export default class FullScreenIllustrationShowCommandViewBase extends React.Component<Props> {
  protected animatedImageRef = React.createRef<HTMLDivElement>();
  protected style: any;

  constructor(props: Props) {
    super(props);
    this.style = [styles.image, props.size];
  }

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !(
      this.props.command === nextProps.command &&
      this.props.showStatus === nextProps.showStatus
    );
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    switch (this.props.showStatus) {
      case ShowStatus.SHOW_IMAGE_BEGIN:
        this.fadeIn();
        break;
      case ShowStatus.HIDE_IMAGE_BEGIN:
        this.fadeOut();
        break;
      default:
      // noop
    }
  }

  public render(): React.ReactNode {
    const {command, size, showStatus, visiblePrompt} = this.props;
    const {fullScreenIllustration} = command;
    const uri = imageUrl(
      fullScreenIllustration,
      size.width,
      size.width / size.height,
    );
    return (
      <View style={styles.container}>
        <Animated.Image
          ref={this.animatedImageRef as any}
          style={this.style}
          source={{cache, uri}}
          resizeMode={RESIZE_MODE}
          fadeDuration={0}
        />
        {visiblePrompt && showStatus === ShowStatus.SHOW_IMAGE_END && (
          <View style={styles.prompt}>
            <View
              style={[
                styles.prompt,
                size.width >= 1280 ? {right: 150, bottom: 0} : null,
              ]}>
              <Prompt />
            </View>
          </View>
        )}
      </View>
    );
  }

  private fadeIn() {
    this.animate(1);
  }

  private fadeOut() {
    this.animate(0);
  }

  protected animate(toValue: number) {}
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
  image: {
    flex: 1,
  } as ImageStyle,
  prompt: {
    bottom: Platform.select({
      default: 70,
      web: isMobile ? 75 : 60,
    }),
    position: 'absolute',
    right: 0,
  } as ViewStyle,
});
