import EpisodeViewerErrorLog from '../../../domain/entities/EpisodeViewerErrorLog';

import NetEpisodeViewerErrorLogsRepository from '../../../data/repositories/consumer/NetEpisodeViewerErrorLogsRepository';

export interface Params {
  episodeId: number;
  sceneId?: number;
  errorUrl: string;
  errorInfo: {
    name: string;
    message: string;
    stack: string;
  };
  platform: 'ios' | 'android' | 'web';
}

export default async function action(
  params: Params,
): Promise<EpisodeViewerErrorLog> {
  const payload = await new NetEpisodeViewerErrorLogsRepository().create({
    episodeViewerErrorLog: params,
  });
  return payload;
}
