import EntityMapper from '../EntityMapper';

import SoundEffectShowCommand from '../../../../domain/entities/commands/SoundEffectShowCommand';
import ResourcesSet from '../../../../domain/entities/resources/ResourcesSet';

import SoundEffectCommandOptionsMapper, {
  SoundEffectCommandOptionsData,
} from '../../../value_objects/mappers/command_options/SoundEffectCommandOptionsMapper';

export interface SoundEffectShowCommandData {
  id: number;
  scene_id: number;
  type: 'sound_effect_show';
  sound_id: number;
  options?: SoundEffectCommandOptionsData;
}

export default class SoundEffectShowCommandMapper
  implements EntityMapper<SoundEffectShowCommandData, SoundEffectShowCommand>
{
  private soundEffectCommandOptionsMapper: SoundEffectCommandOptionsMapper;

  constructor(private resourcesSet: ResourcesSet) {
    this.soundEffectCommandOptionsMapper =
      new SoundEffectCommandOptionsMapper();
  }

  public map(obj: SoundEffectShowCommandData): SoundEffectShowCommand {
    const options = this.soundEffectCommandOptionsMapper.map(obj.options || {});
    return new SoundEffectShowCommand(
      obj.id,
      obj.scene_id,
      this.resourcesSet.getSoundResource(obj.sound_id),
      options,
    );
  }
}
