import EntityMapper from '../EntityMapper';

import SpeechTextShowCommand from '../../../../domain/entities/commands/SpeechTextShowCommand';
import ResourcesSet from '../../../../domain/entities/resources/ResourcesSet';

import SpeechTextCommandOptionsMapper, {
  SpeechTextCommandOptionsData,
} from '../../../value_objects/mappers/command_options/SpeechTextCommandOptionsMapper';
import SoundEffectCommandOptionsMapper, {
  SoundEffectCommandOptionsData,
} from '../../../value_objects/mappers/command_options/SoundEffectCommandOptionsMapper';

export interface SpeechTextShowCommandData {
  id: number;
  scene_id: number;
  type: 'speech_text_show';
  speech_balloon_id: number;
  text: string;
  voice_id?: number | null;
  sound_id?: number;
  options?: SpeechTextCommandOptionsData;
  sound_options?: SoundEffectCommandOptionsData;
}

export default class SpeechTextShowCommandMapper
  implements EntityMapper<SpeechTextShowCommandData, SpeechTextShowCommand>
{
  private speechTextCommandOptionsMapper: SpeechTextCommandOptionsMapper;
  private soundEffectCommandOptionsMapper: SoundEffectCommandOptionsMapper;

  constructor(private resourcesSet: ResourcesSet) {
    this.speechTextCommandOptionsMapper = new SpeechTextCommandOptionsMapper();
    this.soundEffectCommandOptionsMapper =
      new SoundEffectCommandOptionsMapper();
  }

  public map(obj: SpeechTextShowCommandData): SpeechTextShowCommand {
    const options = this.speechTextCommandOptionsMapper.map(obj.options || {});
    const soundOptions = this.soundEffectCommandOptionsMapper.map(
      obj.sound_options || {},
    );
    return new SpeechTextShowCommand(
      obj.id,
      obj.scene_id,
      this.resourcesSet.getSpeechBalloonResource(obj.speech_balloon_id),
      obj.text,
      obj.voice_id ? this.resourcesSet.getVoiceResource(obj.voice_id) : null,
      obj.sound_id ? this.resourcesSet.getSoundResource(obj.sound_id) : null,
      options,
      soundOptions,
    );
  }
}
