import * as React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import VoiceMicrophoneIcon from './icons/VoiceMicrophoneIcon';

interface Props {
  style?: StyleProp<ViewStyle>;
  size?: number;
}

const VoiceMicrophoneBadge: React.FunctionComponent<Props> = props => {
  const {style, size} = props;
  return (
    <View style={[defaultVoiceIconStyle, style]}>
      <VoiceMicrophoneIcon size={size || 11} color={'#999999'} />
    </View>
  );
};

export default React.memo(VoiceMicrophoneBadge);

const defaultVoiceIconStyle: ViewStyle = {
  position: 'absolute',
  top: 2,
  right: 28,
};
