import UserEpisodeBrowsingLog from '../../../../domain/entities/consumer/UserEpisodeBrowsingLog';

import NetUserEpisodeBrowsingLogsRepository from '../../../../data/repositories/consumer/NetUserEpisodeBrowsingLogsRepository';

export interface Params {
  finishedAt?: Date;
  exitedAt?: Date;
  tapCount?: number;
  lastTapCommandIndex?: number;
}

export default async function action(
  id: number,
  params: Params,
): Promise<UserEpisodeBrowsingLog> {
  const payload = await new NetUserEpisodeBrowsingLogsRepository().update(id, {
    consumerUserEpisodeBrowsingLog: params,
  });
  return payload;
}
