import EntityMapper from '../EntityMapper';

import MarkResource from '../../../../domain/entities/resources/MarkResource';

export interface MarkResourceData {
  id: number;
  type: 'mark';
  scene_ids?: number[];
  image_url: string;
}

export default class MarkResourceMapper
  implements EntityMapper<MarkResourceData, MarkResource>
{
  public map(obj: MarkResourceData): MarkResource {
    return new MarkResource(obj.id, obj.scene_ids, obj.image_url);
  }
}
