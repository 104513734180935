import * as React from 'react';

import {SAFE_AREA_TOP_HEIGHT} from '../../../../../data/data_stores/net/UserAgent';

import tapImageUri from '../../../../../../../assets/images/viewer/tap.png';

const keyFrames = [{transform: 'scale(0.9)'}, {transform: 'scale(1.2)'}];

const keyFrameAnimationOptions = {
  duration: 34,
  easing: 'linear',
};

interface Point {
  x: number;
  y: number;
}

interface Props {
  point: Point;
  onAfterAnimation: () => void;
}

const AnimatedTap: React.FunctionComponent<Props> = props => {
  const {onAfterAnimation} = props;
  const ref = React.useRef<HTMLImageElement>(null);
  const imageStyle = React.useMemo(() => {
    return {width: imageSize, height: imageSize};
  }, []);
  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    const animation = ref.current.animate(keyFrames, keyFrameAnimationOptions);
    animation.addEventListener('finish', onAfterAnimation);
  }, []);
  const {point} = props;
  const offset = imageSize / 2;
  const left = point.x - offset;
  const top = point.y - offset - SAFE_AREA_TOP_HEIGHT;
  return (
    <div style={{position: 'fixed', left, top}}>
      <img ref={ref} src={tapImageUri} style={imageStyle} />
    </div>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.point.x === nextProps.point.x &&
    prevProps.point.y === nextProps.point.y
  );
};

export default React.memo(AnimatedTap, areEqual);

const imageSize = 37;
