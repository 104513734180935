import UserEpisodeReadingFinishTime from '../../../../domain/entities/consumer/UserEpisodeReadingFinishTime';

import NetUserEpisodeReadingFinishTimesRepository from '../../../../data/repositories/consumer/NetUserEpisodeReadingFinishTimesRepository';

export interface Params {
  consumerUserEpisodeReadingStartTimeId: number;
}

export default async function action(
  params: Params,
): Promise<UserEpisodeReadingFinishTime> {
  const payload = await new NetUserEpisodeReadingFinishTimesRepository().create(
    {consumerUserEpisodeReadingFinishTime: params},
  );
  return payload;
}
