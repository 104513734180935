import NetResourceRepositoryAdapter from './NetResourceRepositoryAdapter';

import Entity from '../../domain/entities/Entity';
import ResourceRepository from '../../domain/repositories/ResourceRepository';

export default class NetResourceRepository<E extends Entity, D>
  implements ResourceRepository<E>
{
  constructor(private adapter: NetResourceRepositoryAdapter<E, D>) {}

  public find(): Promise<E> {
    return this.adapter.find();
  }

  public create(
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<E> {
    return this.adapter.create(params, options);
  }

  public update(
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<E> {
    return this.adapter.update(params, options);
  }

  public destroy(): Promise<void> {
    return this.adapter.destroy();
  }
}
