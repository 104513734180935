import * as React from 'react';
import {InteractionManager, StyleSheet, ViewStyle} from 'react-native';

import ProgressBarBase, {Props} from './ProgressBarBase';

import {colors} from '../../../../styles/variables';

import {animatedViewStyle} from '../../../../styles/variables';

const firstKeyFrameAnimationOptions = {
  duration: 1000,
  easing: 'ease',
};

const secondKeyFrameAnimationOptions = {
  duration: 200,
  easing: 'ease',
};

export default class ProgressBar extends ProgressBarBase {
  private divRef = React.createRef<HTMLDivElement>();

  private currentAnimation: Animation | null = null;

  private style: any;

  constructor(props: Props) {
    super(props);
    this.style = StyleSheet.flatten<any>([
      styles.barProgressAnimation,
      {width: this.props.width, transform: `translateX(0px)`},
      animatedViewStyle,
    ]);
  }

  protected renderProgressBar = (): React.ReactNode => {
    return <div ref={this.divRef} style={this.style} />;
  };

  protected initAnimationStart = () => {};

  protected initAnimationRestart = () => {};

  protected animateFirst = () => {
    const {width} = this.props;
    const transform = `translateX(${Math.floor(width * 0.7)}px)`;
    const keyFrames = [{transform}];
    if (this.divRef.current) {
      this.currentAnimation = this.divRef.current.animate(
        keyFrames,
        firstKeyFrameAnimationOptions,
      );
      this.currentAnimation.addEventListener('finish', () => {
        if (this.divRef.current) {
          this.divRef.current.style.transform = transform;
        }
        this.animatedFirst = true;
        this.animateSecond();
      });
    }
  };

  protected animateSecond = () => {
    const {width} = this.props;
    if (
      this.currentAnimation &&
      this.currentAnimation.playState !== 'finished'
    ) {
      this.currentAnimation.cancel();
    }
    const toValue = Math.max(0.7, this.progressRate);
    const transform = `translateX(${Math.floor(width * toValue)}px)`;
    const keyFrames = [{transform}];
    if (this.divRef.current) {
      this.currentAnimation = this.divRef.current.animate(
        keyFrames,
        secondKeyFrameAnimationOptions,
      );
      this.currentAnimation.addEventListener('finish', () => {
        if (this.divRef.current) {
          this.divRef.current.style.transform = transform;
        }
        if (toValue !== 1) {
          setTimeout(() => {
            InteractionManager.runAfterInteractions(this.animateSecond);
          }, 200);
        } else {
          this.finishProgressAnimation({value: toValue});
        }
      });
    }
  };
}

const styles = StyleSheet.create({
  barProgressAnimation: {
    backgroundColor: colors.powderGray,
    height: 10,
  } as ViewStyle,
});
