import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import UserEpisodeBrowsingHistoryAndReadingTimeMapper, {
  UserEpisodeBrowsingHistoryAndReadingTimeData,
} from '../../entities/mappers/consumer/UserEpisodeBrowsingHistoryAndReadingTimeMapper';

import UserEpisodeBrowsingHistoryAndReadingTime from '../../../domain/entities/consumer/UserEpisodeBrowsingHistoryAndReadingTime';
import UserEpisodeBrowsingHistoryAndReadingTimesRepository from '../../../domain/repositories/consumer/UserEpisodeBrowsingHistoryAndReadingTimesRepository';

export default class NetUserEpisodeBrowsingHistoryAndReadingTimesRepository
  extends NetResourcesRepository<
    UserEpisodeBrowsingHistoryAndReadingTime,
    UserEpisodeBrowsingHistoryAndReadingTimeData
  >
  implements UserEpisodeBrowsingHistoryAndReadingTimesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/consumer/user_episode_browsing_history_and_reading_times',
        new UserEpisodeBrowsingHistoryAndReadingTimeMapper(),
      ),
    );
  }
}
