import * as React from 'react';
import {ImageSourcePropType} from 'react-native';

import SequenceAnimator from './SequenceAnimator';

const baseStyle: React.CSSProperties = {
  height: 'auto',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  margin: 'auto',
};

interface Size {
  height: number;
  width: number;
}

interface Props {
  sources: ImageSourcePropType[];
  size: Size;
  loop?: boolean;
  repeatCount?: number;
  framesPerSecond?: number;
  pause?: boolean;
  onFinish: () => void;
}

const BaseEffectShowCommandView: React.FunctionComponent<Props> = props => {
  const {sources, size, loop, pause, onFinish} = props;
  const repeatCount = props.repeatCount || 1;
  const framesPerSecond = props.framesPerSecond || 24;
  const style: React.CSSProperties = React.useMemo(() => {
    return {
      ...size,
      ...baseStyle,
    };
  }, [size]);
  const urls = React.useMemo(() => {
    return sources.map(source => {
      if (typeof source === 'number' || typeof source === 'string') {
        return source;
      } else if (source instanceof Array) {
        return source[0].uri;
      } else {
        return source.uri;
      }
    });
  }, []) as string[];
  const [done, setDone] = React.useState(false);
  const onAnimationStop = React.useCallback(() => {
    setDone(true);
  }, []);
  if (done) {
    return null;
  }
  return (
    <SequenceAnimator
      style={style}
      urls={urls}
      loop={loop || false}
      repeatCount={repeatCount}
      duration={urls.length * (1 / framesPerSecond) * repeatCount * 1000}
      pause={pause}
      onSequenceEnd={onFinish}
      onAnimationStop={onAnimationStop}
    />
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.pause === nextProps.pause &&
    prevProps.onFinish == nextProps.onFinish
  );
};

export default React.memo(BaseEffectShowCommandView, areEqual);
