import Entity from '../Entity';

import UserProfile from './UserProfile';

export default class CurrentUser implements Entity {
  constructor(
    public id: number,
    public userName: string,
    public email: string,
    public unconfirmedEmail: string | null,
    public confirmed: boolean,
    public enabled: boolean,
    public consumerUserId: number,
    public enabledPaidSubscriber: boolean,
    public adBlocking: boolean,
    public accessToken: string | null,
    public userFreeTicketCount: number,
    public userProfile: UserProfile | null,
  ) {}

  public getDisplayableEmail(): string {
    const str = this.unconfirmedEmail || this.email;
    const ary = str.split('@');
    const localPart = ary[0];
    const displayCharNum =
      localPart.length >= 4
        ? 3
        : localPart.length > 1
        ? localPart.length - 1
        : 1;
    const displayLocalPart = `${localPart.slice(0, displayCharNum)}${'*'.repeat(
      localPart.length - displayCharNum,
    )}`;
    const domain = ary[1];
    return `${displayLocalPart}@${domain}`;
  }

  public verifiedEmailAddress(): boolean {
    if (!this.email) {
      return false;
    }
    if (this.unconfirmedEmail && this.unconfirmedEmail.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  public copy(): CurrentUser {
    return new CurrentUser(
      this.id,
      this.userName,
      this.email,
      this.unconfirmedEmail,
      this.confirmed,
      this.enabled,
      this.consumerUserId,
      this.enabledPaidSubscriber,
      this.adBlocking,
      this.accessToken,
      this.userFreeTicketCount,
      this.userProfile,
    );
  }
}
