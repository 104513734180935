import NetResourceRepository from '../NetResourceRepository';
import NetResourceRepositoryAdapter from '../NetResourceRepositoryAdapter';

import CurrentUserMapper, {
  CurrentUserData,
} from '../../entities/mappers/consumer/CurrentUserMapper';

import CurrentUser from '../../../domain/entities/consumer/CurrentUser';
import CurrentUserRepository from '../../../domain/repositories/consumer/CurrentUserRepository';

export default class NetCurrentUserRepository
  extends NetResourceRepository<CurrentUser, CurrentUserData>
  implements CurrentUserRepository
{
  constructor() {
    super(
      new NetResourceRepositoryAdapter(
        '/api/consumer/current_user',
        new CurrentUserMapper(),
      ),
    );
  }
}
