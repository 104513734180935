import * as React from 'react';

import TapNovelGoogleAds from './TapNovelGoogleAds';
import FooterCloseButton from './FooterCloseButton';

interface Props {
  admin?: boolean;
  onPressFinish?: () => void;
}

const Footer: React.FunctionComponent<Props> = props => {
  const {admin, onPressFinish} = props;
  return (
    <div
      style={{
        width: '100%',
        display: 'block',
        textAlign: 'center',
        height: 50,
        backgroundColor: 'transparent',
      }}>
      {admin ? (
        <FooterCloseButton onPressFinish={onPressFinish} />
      ) : (
        <TapNovelGoogleAds
          style={{
            display: 'inline-block',
            width: 300,
            height: 50,
          }}
          slotId={'3470106919'}
        />
      )}
    </div>
  );
};

export default React.memo(Footer, () => true);
