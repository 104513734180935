import * as React from 'react';
import EffectShowCommandView from '../command_views/EffectShowCommandView';

import EnabledSound from '../../../../shared/EnabledSound';

import EffectShowCommand from '../../../../../../domain/entities/commands/EffectShowCommand';

interface Size {
  height: number;
  width: number;
}

interface Props {
  command: EffectShowCommand;
  size: Size;
  orientation: 'horizontal' | 'vertical';
  onBeforeRenderEffect: (command: EffectShowCommand) => void;
  onAfterRenderEffect: (command: EffectShowCommand) => void;
  onFinishPlay: (command: EffectShowCommand) => void;
}

const EffectView: React.FunctionComponent<Props> = props => {
  const {
    command,
    size,
    orientation,
    onBeforeRenderEffect,
    onAfterRenderEffect,
    onFinishPlay,
  } = props;
  const [loaded, setLoaded] = React.useState(false);
  const onLoad = React.useCallback(() => {
    setLoaded(true);
  }, []);
  const handleFinishPlay = React.useCallback(() => {
    onFinishPlay(command);
  }, [command]);
  return (
    <>
      <EffectShowCommandView
        command={command}
        size={size}
        orientation={orientation}
        onLoad={onLoad}
        onBeforeRenderEffect={onBeforeRenderEffect}
        onAfterRenderEffect={onAfterRenderEffect}
      />
      {loaded && command.sound ? (
        <EnabledSound
          uri={command.getAudioUrl()}
          onFinishPlay={handleFinishPlay}
        />
      ) : null}
    </>
  );
};

export default EffectView;
