export interface Options {
  width: number;
  height: number;
  canvas?: boolean;
}

export default class ImageUrlHelper {
  public static getImageUrl(url: string, options: Options): string {
    const canvas = options.canvas === undefined ? false : options.canvas;
    const style = `/${canvas ? '' : 'nc-'}${options.width}x${options.height}/`;
    return url.replace('/original/', style);
  }
}
