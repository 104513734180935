import * as React from 'react';

import IllustrationShowCommandViewBase, {
  Props,
} from './IllustrationShowCommandViewBase';

const keyFrameAnimationOptions = {
  duration: 50,
  easing: 'linear',
};

export default class IllustrationShowCommandView extends IllustrationShowCommandViewBase {
  constructor(props: Props) {
    super(props);
    this.style = [this.style, {opacity: 0}];
  }

  protected animated = (callback: () => void) => {
    const animatedImage = this.animatedImageRef.current;
    if (!animatedImage) {
      return;
    }
    const opacity = `1`;
    animatedImage
      .animate([{opacity}], keyFrameAnimationOptions)
      .addEventListener('finish', () => {
        animatedImage.style.opacity = opacity;
        callback();
      });
  };
}
