import EntityMapper from '../EntityMapper';

import ClearCommand from '../../../../domain/entities/commands/ClearCommand';
import ResourcesSet from '../../../../domain/entities/resources/ResourcesSet';

export interface ClearCommandData {
  id: number;
  scene_id: number;
  type: 'clear';
}

export default class ClearCommandMapper
  implements EntityMapper<ClearCommandData, ClearCommand>
{
  // eslint-disable-next-line no-useless-constructor
  constructor(private resourcesSet: ResourcesSet) {}

  public map(obj: ClearCommandData): ClearCommand {
    return new ClearCommand(obj.id, obj.scene_id);
  }
}
