import Resource from './Resource';

import ImageUrlHelper, {Options} from '../../helpers/ImageUrlHelper';

export default class FullScreenIllustrationResource implements Resource {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public sceneIds: number[] | undefined,
    public imageUrl: string,
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.imageUrl, options);
  }
}
