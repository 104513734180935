import * as React from 'react';

import ScenarioVertical from './scenario/ScenarioVertical';
import ScenarioHorizontal from './scenario/ScenarioHorizontal';

import Command from '../../../../domain/entities/commands/Command';
import DescriptiveTextShowCommand from '../../../../domain/entities/commands/DescriptiveTextShowCommand';
import SpeechTextShowCommand from '../../../../domain/entities/commands/SpeechTextShowCommand';

import Frame from '../../../view_models/Frame';
import ViewerLayoutManager from '../../../view_models/ViewerLayoutManager';

interface Props {
  commands: Command[];
  frame: Frame;
  layoutManager: ViewerLayoutManager;
  skipRenderText: boolean;
  pausing: boolean;
  textSpeed?: 'slow' | 'normal' | 'fast' | 'no_effect';
  orientation: 'horizontal' | 'vertical';
  visiblePrompt?: boolean;
  visibleVoiceIcon?: boolean;
  onTouch: (point: {x: number; y: number}) => void;
  onBeforeRenderText: (
    command: SpeechTextShowCommand | DescriptiveTextShowCommand,
  ) => void;
  onAfterRenderText: (
    command: SpeechTextShowCommand | DescriptiveTextShowCommand,
  ) => void;
  onFinishPlayVoice: (
    command: SpeechTextShowCommand | DescriptiveTextShowCommand,
  ) => void;
  onFinishPlaySound: (
    command: SpeechTextShowCommand | DescriptiveTextShowCommand,
  ) => void;
  onScrolling: () => void;
  onScrolledToTop: () => void;
}

const ORIENTATION_HORIZONTAL = 'horizontal';

const Scenario: React.FunctionComponent<Props> = props => {
  const {orientation} = props;
  if (orientation === ORIENTATION_HORIZONTAL) {
    return <ScenarioHorizontal {...props} />;
  } else {
    return <ScenarioVertical {...props} />;
  }
};

export default Scenario;
