import * as React from 'react';
import {Platform, Pressable, StyleSheet, ViewStyle} from 'react-native';

import HamburgerIcon from '../../../../../../../presentation/components/shared/viewer/icons/HamburgerIcon';

const hamburgerIcon = <HamburgerIcon size={22} color={'white'} />;
interface Props {
  onPress: () => void;
}

const Menu: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  return (
    <Pressable style={styles.container} onPress={onPress}>
      {hamburgerIcon}
    </Pressable>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return true;
};

export default React.memo(Menu, areEqual);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 44,
    height: 44,
    opacity: 0.7,
    ...Platform.select({
      ios: {
        shadowColor: 'black', // iOS
        shadowOffset: {width: 0, height: 1}, // iOS
        shadowOpacity: 0.5, // iOS
        shadowRadius: 1, // iOS
      },
      default: {},
    }),
  } as ViewStyle,
});
