import * as React from 'react';
import {Image, StyleProp} from 'react-native';

const cachedSources = new Set<{uri: string}>([]);

interface Props {
  style: StyleProp<any>;
  source: {uri: string};
  resizeMode: 'cover' | 'contain' | 'stretch' | 'center';
  fadeDuration: number;
  onSourceLoaded: () => void;
}

interface State {
  source: {uri: string};
}

export default class CachingImage extends React.PureComponent<Props, State> {
  public componentDidUpdate() {
    const {source, onSourceLoaded} = this.props;
    if (cachedSources.has(source)) {
      onSourceLoaded();
    }
  }

  public render() {
    return <Image {...this.props} onLoad={this.onSourceLoadedInCache} />;
  }

  private onSourceLoadedInCache = () => {
    const {source, onSourceLoaded} = this.props;
    if (!cachedSources.has(source)) {
      cachedSources.add(source);
    }
    onSourceLoaded();
  };
}
