import Gender from '../../../domain/value_objects/Gender';

export type GenderData = 'male' | 'female' | 'other';

export default class GenderMapper {
  public map(obj: GenderData): Gender {
    switch (obj) {
      case 'male':
        return Gender.Male;
      case 'female':
        return Gender.Female;
      case 'other':
        return Gender.Other;
      default:
        throw Error('GenderData to Gender Error');
    }
  }
}
