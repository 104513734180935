import Resource from './Resource';

import ImageUrlHelper, {Options} from '../../helpers/ImageUrlHelper';
import Position from '../../value_objects/Position';
import SpeechBalloonDirection from '../../value_objects/SpeechBalloonDirection';

import SpeechBalloonResourceOptions from '../../value_objects/resource_options/SpeechBalloonResourceOptions';

export default class SpeechBalloonResource implements Resource {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public sceneIds: number[] | undefined,
    public imageUrls: {[key: string]: string},
    public speechBalloonDirection: SpeechBalloonDirection,
    public options: SpeechBalloonResourceOptions,
  ) {}

  public getImageUrlOf(key: string, options: Options): string {
    return ImageUrlHelper.getImageUrl(this.imageUrls[key], options);
  }

  public getWideImageUrlOf(key: string, options: Options): string {
    return ImageUrlHelper.getImageUrl(this.imageUrls[key], options);
  }

  public matchesPositions(positions: Position[]): boolean {
    const thisPositions = this.getPositions().sort();
    const thatPositions = positions.sort();
    if (thisPositions.length !== thatPositions.length) {
      return false;
    }
    for (let i = 0; i < thisPositions.length; ++i) {
      if (thisPositions[i] !== thatPositions[i]) {
        return false;
      }
    }
    return true;
  }

  public getPositions(): Position[] {
    switch (this.speechBalloonDirection) {
      case SpeechBalloonDirection.Left:
        return [Position.Left];
      case SpeechBalloonDirection.Center:
        return [Position.Center];
      case SpeechBalloonDirection.Right:
        return [Position.Right];
      case SpeechBalloonDirection.Left_Center:
        return [Position.Left, Position.Center];
      case SpeechBalloonDirection.Center_Right:
        return [Position.Center, Position.Right];
      case SpeechBalloonDirection.Left_Right:
        return [Position.Left, Position.Right];
      case SpeechBalloonDirection.Left_Center_Right:
        return [Position.Left, Position.Center, Position.Right];
      default:
        throw new Error('Does not match SpeechBalloonDirection');
    }
  }
}
