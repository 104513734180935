import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import ToggleSwitch from '../../../shared/ToggleSwitch';

import AutoplayIcon from '../../../shared/icons/AutoplayIcon';
import AutoplayStopIcon from '../../../shared/icons/AutoplayStopIcon';

interface Props {
  autoPlaySpeed: 0 | 1 | 1.5 | 2;
  onAutoPlaySpeedChange?: (autoPlaySpeed: 0 | 1 | 1.5 | 2) => void;
}

const AutoPlayToggleSwitch: React.FunctionComponent<Props> = props => {
  const {autoPlaySpeed, onAutoPlaySpeedChange} = props;
  const handleValueChange = React.useCallback(() => {
    onAutoPlaySpeedChange && onAutoPlaySpeedChange(autoPlaySpeed ? 0 : 1);
  }, [autoPlaySpeed]);
  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        <View style={styles.icon}>
          {autoPlaySpeed ? <AutoplayIcon /> : <AutoplayStopIcon />}
        </View>
        <Text style={styles.label}>自動Tap</Text>
        <ToggleSwitch
          enabled={autoPlaySpeed !== 0}
          onValueChange={handleValueChange}
        />
      </View>
    </View>
  );
};

export default React.memo(AutoPlayToggleSwitch);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  inner: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  icon: {
    width: 20,
    justifyContent: 'center',
  } as ViewStyle,
  label: {
    color: '#fff',
    fontSize: 12,
    marginLeft: 4,
    marginRight: 8,
    width: 52,
  } as TextStyle,
});
