import * as React from 'react';

import Sound, {Props} from './SoundPlayable';
import EnabledSoundContext from './EnabledSoundContext';

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.uri === nextProps.uri &&
    prevProps.volumeHalved === nextProps.volumeHalved
  );
};

const EnabledSound: React.FunctionComponent<Props> = props => {
  return (
    <EnabledSoundContext.Consumer>
      {enabledSound => <Sound {...props} mute={!enabledSound} />}
    </EnabledSoundContext.Consumer>
  );
};

export default React.memo(EnabledSound, areEqual);
