import SpeechBalloonResourceOptionsMapper, {
  SpeechBalloonResourceOptionsData,
} from '../../../value_objects/mappers/resource_options/SpeechBalloonResourceOptionsMapper';

import EntityMapper from '../EntityMapper';

import SpeechBalloonDirectionMappper, {
  SpeechBalloonDirectionData,
} from '../../../value_objects/mappers/SpeechBalloonDirectionMappper';

import SpeechBalloonResource from '../../../../domain/entities/resources/SpeechBalloonResource';

export interface SpeechBalloonResourceData {
  id: number;
  type: 'speech_balloon';
  scene_ids?: number[];
  image_urls: {[key: string]: string};
  speech_balloon_direction: SpeechBalloonDirectionData;
  options?: SpeechBalloonResourceOptionsData;
}

export default class SpeechBalloonResourceMapper
  implements EntityMapper<SpeechBalloonResourceData, SpeechBalloonResource>
{
  private speechBalloonDirectionMappper: SpeechBalloonDirectionMappper;
  private speechBalloonResourceOptionsMapper: SpeechBalloonResourceOptionsMapper;

  constructor() {
    this.speechBalloonDirectionMappper = new SpeechBalloonDirectionMappper();
    this.speechBalloonResourceOptionsMapper =
      new SpeechBalloonResourceOptionsMapper();
  }

  public map(obj: SpeechBalloonResourceData): SpeechBalloonResource {
    const speechBalloonDirection = this.speechBalloonDirectionMappper.map(
      obj.speech_balloon_direction,
    );
    const options = this.speechBalloonResourceOptionsMapper.map(
      obj.options || {},
    );
    return new SpeechBalloonResource(
      obj.id,
      obj.scene_ids,
      obj.image_urls,
      speechBalloonDirection,
      options,
    );
  }
}
