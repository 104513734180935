import * as React from 'react';

import EnabledSound from '../../shared/EnabledSound';

import SoundEffectShowCommand from '../../../../domain/entities/commands/SoundEffectShowCommand';

interface Props {
  command: SoundEffectShowCommand;
  onBeforePlay: (command: SoundEffectShowCommand) => void;
  onAfterPlay: (command: SoundEffectShowCommand) => void;
  onFinishPlay: (command: SoundEffectShowCommand) => void;
}

const SoundEffect: React.FunctionComponent<Props> = props => {
  const {command, onBeforePlay, onAfterPlay, onFinishPlay} = props;
  const handleBeforePlay = React.useCallback(() => {
    onBeforePlay(command);
  }, [command]);
  const handleAfterPlay = React.useCallback(() => {
    onAfterPlay(command);
  }, [command]);
  const handleFinishPlay = React.useCallback(() => {
    onFinishPlay(command);
  }, [command]);
  return (
    <EnabledSound
      key={command.getKey()}
      uri={command.getAudioUrl()}
      onBeforePlay={handleBeforePlay}
      onAfterPlay={handleAfterPlay}
      onFinishPlay={handleFinishPlay}
    />
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.command === nextProps.command;
};

export default React.memo(SoundEffect, areEqual);
