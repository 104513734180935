import EntityMapper from '../EntityMapper';

import EffectShowCommand from '../../../../domain/entities/commands/EffectShowCommand';
import ResourcesSet from '../../../../domain/entities/resources/ResourcesSet';

import EffectCommandOptionsMapper, {
  EffectCommandOptionsData,
} from '../../../value_objects/mappers/command_options/EffectCommandOptionsMapper';
import SoundEffectCommandOptionsMapper, {
  SoundEffectCommandOptionsData,
} from '../../../value_objects/mappers/command_options/SoundEffectCommandOptionsMapper';

export interface EffectShowCommandData {
  id: number;
  scene_id: number;
  type: 'effect_show';
  positioned_effect_id: number;
  sound_id?: number;
  options?: EffectCommandOptionsData;
  sound_options?: SoundEffectCommandOptionsData;
}

export default class EffectShowCommandMapper
  implements EntityMapper<EffectShowCommandData, EffectShowCommand>
{
  private effectCommandOptionsMapper: EffectCommandOptionsMapper;
  private soundEffectCommandOptionsMapper: SoundEffectCommandOptionsMapper;

  constructor(private resourcesSet: ResourcesSet) {
    this.effectCommandOptionsMapper = new EffectCommandOptionsMapper();
    this.soundEffectCommandOptionsMapper =
      new SoundEffectCommandOptionsMapper();
  }

  public map(obj: EffectShowCommandData): EffectShowCommand {
    const options = this.effectCommandOptionsMapper.map(obj.options || {});
    const soundOptions = this.soundEffectCommandOptionsMapper.map(
      obj.sound_options || {},
    );
    return new EffectShowCommand(
      obj.id,
      obj.scene_id,
      this.resourcesSet.getPositionedEffectResource(obj.positioned_effect_id),
      obj.sound_id ? this.resourcesSet.getSoundResource(obj.sound_id) : null,
      options,
      soundOptions,
    );
  }
}
