import BackgroundShowCommand from '../../domain/entities/commands/BackgroundShowCommand';
import CharacterHideCommand from '../../domain/entities/commands/CharacterHideCommand';
import CharacterShowCommand from '../../domain/entities/commands/CharacterShowCommand';
import CharacterUpdateCommand from '../../domain/entities/commands/CharacterUpdateCommand';
import ClearCommand from '../../domain/entities/commands/ClearCommand';
import Command from '../../domain/entities/commands/Command';
import CompositeParallelCommand from '../../domain/entities/commands/CompositeParallelCommand';
import DescriptiveTextShowCommand from '../../domain/entities/commands/DescriptiveTextShowCommand';
import IllustrationShowCommand from '../../domain/entities/commands/IllustrationShowCommand';
import FullScreenIllustrationShowCommand from '../../domain/entities/commands/FullScreenIllustrationShowCommand';
import BackgroundMusicShowCommand from '../../domain/entities/commands/BackgroundMusicShowCommand';
import BackgroundMusicHideCommand from '../../domain/entities/commands/BackgroundMusicHideCommand';

import Position from '../../domain/value_objects/Position';

export type BackgroundCommand = BackgroundShowCommand;

export type CharacterCommand =
  | CharacterShowCommand
  | CharacterUpdateCommand
  | CharacterHideCommand;

export default class Frame {
  public static copy(frame: Frame): Frame {
    const newFrame = new Frame();
    newFrame.background = frame.background;

    newFrame.leftCharacter = frame.leftCharacter;
    newFrame.centerCharacter = frame.centerCharacter;
    newFrame.rightCharacter = frame.rightCharacter;

    return newFrame;
  }

  public background: BackgroundCommand | null = null;

  public leftCharacter: CharacterCommand | null = null;
  public centerCharacter: CharacterCommand | null = null;
  public rightCharacter: CharacterCommand | null = null;

  public illustration: IllustrationShowCommand | null = null;
  public fullScreenIllustration: FullScreenIllustrationShowCommand | null =
    null;
  public backgroundMusic:
    | BackgroundMusicShowCommand
    | BackgroundMusicHideCommand
    | null = null;

  public update(command: Command) {
    if (!(command instanceof DescriptiveTextShowCommand)) {
      this.illustration = null;
    }
    if (this.backgroundMusic instanceof BackgroundMusicHideCommand) {
      this.backgroundMusic = null;
    }
    if (!(command instanceof ClearCommand)) {
      this.fullScreenIllustration = null;
    }
    if (command instanceof BackgroundShowCommand) {
      this.background = command;
    } else if (
      command instanceof CharacterShowCommand ||
      command instanceof CharacterUpdateCommand ||
      command instanceof CharacterHideCommand
    ) {
      switch (command.position) {
        case Position.Left:
          this.leftCharacter = command;
          return;
        case Position.Center:
          this.centerCharacter = command;
          return;
        case Position.Right:
          this.rightCharacter = command;
          return;
        default:
          throw new Error('Does not match Position');
      }
    } else if (command instanceof IllustrationShowCommand) {
      this.illustration = command;
    } else if (command instanceof FullScreenIllustrationShowCommand) {
      this.fullScreenIllustration = command;
    } else if (command instanceof CompositeParallelCommand) {
      command.commands.forEach(subCommand => {
        this.update(subCommand);
      });
    } else if (command instanceof BackgroundMusicShowCommand) {
      this.backgroundMusic = command;
    } else if (command instanceof BackgroundMusicHideCommand) {
      this.backgroundMusic = command;
    } else if (command instanceof ClearCommand) {
      this.background = null;
      this.leftCharacter = null;
      this.centerCharacter = null;
      this.rightCharacter = null;
      if (
        !(
          this.backgroundMusic &&
          this.backgroundMusic instanceof BackgroundMusicShowCommand &&
          this.backgroundMusic.options.fallThrough
        )
      ) {
        this.backgroundMusic = null;
      }
    }
  }

  public visibleCharacter(position: Position): boolean {
    switch (position) {
      case Position.Left:
        return !!(
          this.leftCharacter &&
          !(this.leftCharacter instanceof CharacterHideCommand)
        );
      case Position.Center:
        return !!(
          this.centerCharacter &&
          !(this.centerCharacter instanceof CharacterHideCommand)
        );
      case Position.Right:
        return !!(
          this.rightCharacter &&
          !(this.rightCharacter instanceof CharacterHideCommand)
        );
      default:
        throw new Error('Does not match Position');
    }
  }

  public visibleCharacterCount(): number {
    let count = 0;
    if (this.visibleCharacter(Position.Left)) {
      count += 1;
    }
    if (this.visibleCharacter(Position.Center)) {
      count += 1;
    }
    if (this.visibleCharacter(Position.Right)) {
      count += 1;
    }
    return count;
  }
}
