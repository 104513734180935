import * as React from 'react';
import {
  Image,
  ImageBackground,
  ImageSourcePropType,
  ImageStyle,
  StyleProp,
  ViewStyle,
} from 'react-native';

interface Props {
  active?: boolean;
  source: ImageSourcePropType;
  imageStyle: StyleProp<ViewStyle>;
  imageShadowStyle: StyleProp<ImageStyle>;
}

const RESIZE_MODE = 'stretch';

const RESIZE_METHOD = 'resize';

const FrameEdgeImage: React.FunctionComponent<Props> = props => {
  const {active, source, imageStyle, imageShadowStyle} = props;
  return (
    <ImageBackground
      style={imageStyle}
      source={source}
      resizeMode={RESIZE_MODE}
      resizeMethod={RESIZE_METHOD}
      fadeDuration={0}>
      {!active && (
        <Image
          style={imageShadowStyle}
          source={source}
          resizeMode={RESIZE_MODE}
          resizeMethod={RESIZE_METHOD}
          fadeDuration={0}
        />
      )}
    </ImageBackground>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.active === nextProps.active;
};

export default React.memo(FrameEdgeImage, areEqual);
