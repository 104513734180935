import * as React from 'react';
import {
  Animated,
  GestureResponderEvent,
  ImageStyle,
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import convertImageSource from '../../../helpers/convertImageSource';

import letsTapUri from '../../../../../../assets/images/viewer/lets_tap.png';
import handTappedUri from '../../../../../../assets/images/viewer/hand_tapped.png';
import handDefaultUri from '../../../../../../assets/images/viewer/hand_default.png';

const letsTapImageSource = convertImageSource(letsTapUri);
const handTappedImageSource = convertImageSource(handTappedUri);
const handDefaultImageSource = convertImageSource(handDefaultUri);

export interface Props {
  scenarioHeight: number;
  stageHeight: number;
  orientation: 'horizontal' | 'vertical';
  onPress?: () => void;
}

interface State {
  pressing: boolean;
}

const PRESS_RETENTION_OFFSET = {top: 50, bottom: 50, left: 50, right: 50};

export default class InterceptForStartingBase extends React.Component<
  Props,
  State
> {
  protected letsTapRef = React.createRef<HTMLDivElement>();
  protected handTappedRef = React.createRef<HTMLDivElement>();
  protected handDefaultRef = React.createRef<HTMLDivElement>();

  protected mounted = false;
  protected pressed = false;

  protected fictionNoteStyle: StyleProp<ViewStyle>;
  protected startStyle: StyleProp<ViewStyle>;
  protected letsTapStyle: StyleProp<Animated.AnimatedProps<any>>;
  protected handTappedStyle: StyleProp<Animated.AnimatedProps<any>>;
  protected handDefaultStyle: StyleProp<Animated.AnimatedProps<any>>;

  constructor(props: Props) {
    super(props);
    this.state = {pressing: false};
    this.fictionNoteStyle = [
      styles.fictionNote,
      this.props.orientation === 'horizontal'
        ? {bottom: this.props.stageHeight / 2, justifyContent: 'flex-end'}
        : {bottom: props.scenarioHeight},
    ];
    this.startStyle = [
      styles.start,
      this.props.orientation === 'horizontal'
        ? {
            top: this.props.stageHeight / 2,
          }
        : {
            top: Math.max(
              0,
              Math.min(
                props.stageHeight,
                props.scenarioHeight + props.stageHeight - LETS_TAP_HEIGHT,
              ),
            ),
          },
    ];
    this.letsTapStyle = [
      styles.letsTap,
      Platform.OS === 'web' ? styles.letsTapForWeb : undefined,
    ];
    this.handTappedStyle = [styles.handTapped];
    this.handDefaultStyle = [styles.handDefault];
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    return (
      <TouchableOpacity
        style={styles.container}
        activeOpacity={0.6}
        onPress={this.handlePress}
        pressRetentionOffset={PRESS_RETENTION_OFFSET}
        onPressIn={this.handlePressIn}
        onPressOut={this.handlePressOut}>
        <View style={this.fictionNoteStyle}>
          <Text style={styles.fictionText}>
            このストーリーはフィクションです。{'\n'}
            実在の人物・団体・出来事などとは一切関係ありません。
          </Text>
        </View>
        <View style={this.startStyle}>
          <Animated.Image
            ref={this.letsTapRef as any}
            style={this.letsTapStyle}
            source={letsTapImageSource}
            fadeDuration={0}
          />
          <Animated.Image
            ref={this.handTappedRef as any}
            style={[
              this.handTappedStyle,
              {display: this.state.pressing ? 'flex' : 'none'},
            ]}
            source={handTappedImageSource}
            fadeDuration={0}
          />
          <Animated.Image
            ref={this.handDefaultRef as any}
            style={[
              this.handDefaultStyle,
              {display: this.state.pressing ? 'none' : 'flex'},
            ]}
            source={handDefaultImageSource}
            fadeDuration={0}
          />
        </View>
      </TouchableOpacity>
    );
  }

  protected handlePress = () => {};

  protected handlePressIn = (event: GestureResponderEvent) => {};

  protected handlePressOut = (event: GestureResponderEvent) => {};

  protected setStateIfMounted<K extends keyof State>(
    state:
      | ((
          prevState: Readonly<State>,
          props: Readonly<Props>,
        ) => Pick<State, K> | State | null)
      | (Pick<State, K> | State | null),
    callback?: () => void,
  ) {
    if (!this.mounted) {
      return;
    }
    this.setState(state as any, callback);
  }
}

const LETS_TAP_HEIGHT = 238;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0, 0, 0, .5)',
    bottom: 0,
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: 0,
  } as ViewStyle,
  fictionNote: {
    alignItems: 'center',
    justifyContent: 'center',
    left: 0,
    padding: 10,
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
  } as ViewStyle,
  fictionText: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: Platform.select({
      android: 23,
      ios: 19,
    }),
    textAlign: 'center',
  } as TextStyle,
  handDefault: {
    height: 117,
    left: 74,
    top: 88,
    width: 121,
  } as ImageStyle,
  handTapped: {
    height: 111,
    left: 69,
    top: 89,
    width: 120,
  } as ImageStyle,
  letsTap: {
    height: LETS_TAP_HEIGHT,
    position: 'absolute',
    width: 242,
  } as ImageStyle,
  letsTapForWeb: {
    bottom: 0,
    left: 0,
    margin: 'auto',
    right: 0,
    top: 0,
  } as any,
  start: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
  } as ViewStyle,
});
