import EntityMapper from '../EntityMapper';

import CharacterResourceOptionsMapper, {
  CharacterResourceOptionsData,
} from '../../../value_objects/mappers/resource_options/CharacterResourceOptionsMapper';

import CharacterResource from '../../../../domain/entities/resources/CharacterResource';

export interface CharacterResourceData {
  id: number;
  type: 'character';
  scene_ids?: number[];
  name: string;
  image_urls: {[key: string]: string};
  options?: CharacterResourceOptionsData;
}

export default class CharacterResourceMapper
  implements EntityMapper<CharacterResourceData, CharacterResource>
{
  private characterResourceOptionsMapper: CharacterResourceOptionsMapper;

  constructor() {
    this.characterResourceOptionsMapper = new CharacterResourceOptionsMapper();
  }

  public map(obj: CharacterResourceData): CharacterResource {
    const options = this.characterResourceOptionsMapper.map(obj.options || {});
    return new CharacterResource(
      obj.id,
      obj.scene_ids,
      obj.name,
      obj.image_urls,
      options,
    );
  }
}
