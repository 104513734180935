enum SpeechBalloonDirection {
  Left,
  Center,
  Right,
  Left_Center,
  Center_Right,
  Left_Right,
  Left_Center_Right,
}

export default SpeechBalloonDirection;

export class SpeechBalloonDirectionValueMapper {
  public static map(speechBalloonDirection: SpeechBalloonDirection): string {
    switch (speechBalloonDirection) {
      case SpeechBalloonDirection.Left:
        return 'left';
      case SpeechBalloonDirection.Center:
        return 'center';
      case SpeechBalloonDirection.Right:
        return 'right';
      case SpeechBalloonDirection.Left_Center:
        return 'left-center';
      case SpeechBalloonDirection.Center_Right:
        return 'center-right';
      case SpeechBalloonDirection.Left_Right:
        return 'left-right';
      case SpeechBalloonDirection.Left_Center_Right:
        return 'left-center-right';
      default:
        throw new Error('Unsupported speechBalloonDirection error!');
    }
  }
}
