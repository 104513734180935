import * as React from 'react';
import {Image, StyleProp, StyleSheet} from 'react-native';

import CachingImage from './CachingImage';

interface Props {
  style: StyleProp<any>;
  source: {uri: string};
  resizeMode: 'cover' | 'contain' | 'stretch' | 'center';
  fadeDuration: number;
  tintColor?: string;
}

interface State {
  source: {uri: string};
}

export default class NoFlickerImage extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      source: props.source,
    };
  }

  public render() {
    return (
      <>
        <CachingImage
          {...this.props}
          style={styles.hide}
          onSourceLoaded={this.onSourceLoaded}
        />
        <Image {...this.props} source={this.state.source} />
      </>
    );
  }

  private onSourceLoaded = () => {
    if (this.state.source !== this.props.source) {
      this.setState({
        source: this.props.source,
      });
    }
  };
}

const styles = StyleSheet.create({
  hide: {
    display: 'none',
  },
});
