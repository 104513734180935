import * as React from 'react';
import {Animated, Easing} from 'react-native';

import FullScreenIllustrationShowCommandViewBase, {
  Props,
} from './FullScreenIllustrationShowCommandViewBase';

const keyFrameAnimationOptions = {
  duration: 50,
  easing: 'linear',
};

export default class FullScreenIllustrationShowCommandView extends FullScreenIllustrationShowCommandViewBase {
  private opacityValue = new Animated.Value(0);

  constructor(props: Props) {
    super(props);
    this.style = [this.style, {opacity: this.opacityValue}];
  }

  protected animate(toValue: number) {
    const {updateShowStatus, delayUpdateShowStatus} = this.props;
    const animatedImage = this.animatedImageRef.current;
    if (!animatedImage) {
      return;
    }
    const opacity = `${toValue}`;
    animatedImage
      .animate([{opacity}], keyFrameAnimationOptions)
      .addEventListener('finish', () => {
        animatedImage.style.opacity = opacity;
        if (toValue) {
          delayUpdateShowStatus(300);
        } else {
          updateShowStatus();
        }
      });
  }
}
