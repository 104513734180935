import UserSceneTapsBatch from '../../../../domain/value_objects/consumer/UserSceneTapsBatch';

export interface UserSceneTapsBatchrData {
  job_id: string;
}

export default class UserSceneTapsBatchMapper {
  public map(obj: UserSceneTapsBatchrData): UserSceneTapsBatch {
    return new UserSceneTapsBatch(obj.job_id);
  }
}
