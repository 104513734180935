import * as React from 'react';

import * as Immutable from 'immutable';

import AnimatedTap from './tap/AnimatedTap';

interface Point {
  x: number;
  y: number;
}

interface Props {
  point: Point | null;
  onAfterAnimation: () => void;
}

const Tap: React.FunctionComponent<Props> = props => {
  const {point, onAfterAnimation} = props;
  if (!point) {
    return null;
  }
  return <AnimatedTap point={point} onAfterAnimation={onAfterAnimation} />;
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return Immutable.is(prevProps.point, nextProps.point);
};

export default React.memo(Tap, areEqual);
