import ViewerSettingMapper, {
  ViewerSettingData,
} from '../value_objects/mappers/ViewerSettingMapper';

import JsonAsyncStorage from '../data_stores/storage/JsonAsyncStorage';

import ViewerSetting, {
  TextSpeed,
} from '../../domain/value_objects/ViewerSetting';

import ViewerSettingsRepository from '../../domain/repositories/ViewerSettingsRepository';

export default class StorageViewerSettingsRepository
  implements ViewerSettingsRepository
{
  private static KEY = 'viewer_setting';

  private mapper: ViewerSettingMapper;

  constructor() {
    this.mapper = new ViewerSettingMapper();
  }

  public async find(): Promise<ViewerSetting> {
    const row: ViewerSettingData =
      (await JsonAsyncStorage.get(StorageViewerSettingsRepository.KEY)) || {};
    await JsonAsyncStorage.set(StorageViewerSettingsRepository.KEY, row);
    return this.mapper.map(row);
  }

  public async create(params: {
    textSpeed: TextSpeed;
    visibleProgressBar: boolean;
    enabledSound: boolean;
    autoPlaySpeed: 0 | 1 | 1.5 | 2;
  }): Promise<ViewerSetting> {
    const row: ViewerSettingData =
      (await JsonAsyncStorage.get(StorageViewerSettingsRepository.KEY)) || {};
    row.text_speed = params.textSpeed;
    row.visible_progress_bar = params.visibleProgressBar;
    row.enabled_sound = params.enabledSound !== false;
    row.auto_play_speed = params.autoPlaySpeed;
    delete row.auto_play_value;
    await JsonAsyncStorage.set(StorageViewerSettingsRepository.KEY, row);
    return this.mapper.map(row);
  }

  public async update(params: {
    textSpeed?: TextSpeed;
    visibleProgressBar?: boolean;
    enabledSound?: boolean;
    autoPlaySpeed?: 0 | 1 | 1.5 | 2;
  }): Promise<ViewerSetting> {
    const row: ViewerSettingData =
      (await JsonAsyncStorage.get(StorageViewerSettingsRepository.KEY)) || {};
    if (params.textSpeed !== undefined) {
      row.text_speed = params.textSpeed;
    }
    if (params.visibleProgressBar !== undefined) {
      row.visible_progress_bar = params.visibleProgressBar;
    }
    if (params.enabledSound !== undefined) {
      row.enabled_sound = params.enabledSound;
    }
    if (params.autoPlaySpeed !== undefined) {
      row.auto_play_speed = params.autoPlaySpeed;
    }
    delete row.auto_play_value;
    await JsonAsyncStorage.set(StorageViewerSettingsRepository.KEY, row);
    return this.mapper.map(row);
  }

  public async destroy(): Promise<void> {
    const row: ViewerSettingData = await JsonAsyncStorage.get(
      StorageViewerSettingsRepository.KEY,
    );
    if (!row) {
      return;
    }
    await JsonAsyncStorage.remove(StorageViewerSettingsRepository.KEY);
    return;
  }
}
