import {camelizeKeys} from 'humps';

import SpeechBalloonResourceOptions from '../../../../domain/value_objects/resource_options/SpeechBalloonResourceOptions';

export interface SpeechBalloonResourceOptionsData {
  container_style?: {[key: string]: string};
  text_style?: {[key: string]: string};
  wide_text_style?: {[key: string]: string};
  voice_icon_style?: {[key: string]: string};
  adjustable?: {[key: string]: {[key: string]: string}};
  wide_adjustable?: {[key: string]: {[key: string]: string}};
}

export default class SpeechBalloonResourceOptionsMapper {
  public map(
    obj: SpeechBalloonResourceOptionsData,
  ): SpeechBalloonResourceOptions {
    return new SpeechBalloonResourceOptions(
      obj.container_style ? camelizeKeys(obj.container_style) : undefined,
      obj.text_style ? camelizeKeys(obj.text_style) : undefined,
      obj.wide_text_style ? camelizeKeys(obj.wide_text_style) : undefined,
      obj.voice_icon_style ? camelizeKeys(obj.voice_icon_style) : undefined,
      obj.adjustable ? obj.adjustable : undefined,
      obj.wide_adjustable ? obj.wide_adjustable : undefined,
    );
  }
}
