import {PixelRatio} from 'react-native';
import {Options} from '../../domain/helpers/ImageUrlHelper';

export const URL_TO_BLOB: {[key: string]: string} = {};

export const BACKGROUND_FILTER_KEY_TO_BLOB: {[key: string]: string} = {};

const correctionRatio = PixelRatio.get() === 1 ? 2 : 1;

interface Entity {
  getImageUrl(options: Options): string;
}

interface PartOfEntity {
  getImageUrlOf(key: string, options: Options): string;
}

interface VariablePartOfEntity extends PartOfEntity {
  getWideImageUrlOf(key: string, options: Options): string;
}

interface EntityWithManyImages {
  getImageUrls(options?: Options): string[];
}

interface VariableEntityWithManyImages extends EntityWithManyImages {
  getWideImageUrls(options?: Options): string[];
}

interface Size {
  height: number;
  width: number;
}

export function squareImageUrl(entity: Entity, width: number): string {
  return imageUrl(entity, width, 1);
}

export function imageUrl(
  entity: Entity,
  width: number,
  aspectRatio: number,
): string {
  const url = entity.getImageUrl({
    height:
      PixelRatio.getPixelSizeForLayoutSize(width / aspectRatio) *
      correctionRatio,
    width: PixelRatio.getPixelSizeForLayoutSize(width) * correctionRatio,
  });
  return URL_TO_BLOB[url] || url;
}

export function squareImageUrlOf(
  key: string,
  entity: PartOfEntity,
  width: number,
): string {
  return imageUrlOf(key, entity, width, 1);
}

export function imageUrlOf(
  key: string,
  entity: PartOfEntity,
  width: number,
  aspectRatio: number,
): string {
  const url = entity.getImageUrlOf(key, {
    height:
      PixelRatio.getPixelSizeForLayoutSize(width / aspectRatio) *
      correctionRatio,
    width: PixelRatio.getPixelSizeForLayoutSize(width) * correctionRatio,
  });
  return URL_TO_BLOB[url] || url;
}

export function squareWideImageUrlOf(
  key: string,
  entity: VariablePartOfEntity,
  width: number,
): string {
  return wideImageUrlOf(key, entity, width, 1);
}

export function wideImageUrlOf(
  key: string,
  entity: VariablePartOfEntity,
  width: number,
  aspectRatio: number,
): string {
  const url = entity.getWideImageUrlOf(key, {
    height:
      PixelRatio.getPixelSizeForLayoutSize(width / aspectRatio) *
      correctionRatio,
    width: PixelRatio.getPixelSizeForLayoutSize(width) * correctionRatio,
  });
  return URL_TO_BLOB[url] || url;
}

export function backgroundImageUrl(entity: Entity, size: Size): string {
  return imageUrl(entity, size.width, size.width / size.height);
}

export function characterImageUrl(
  face: string,
  entity: PartOfEntity,
  size: Size,
): string {
  return squareImageUrlOf(face, entity, Math.max(size.height, size.width));
}

export function markImageUrl(entity: Entity, size: Size): string {
  return squareImageUrl(entity, Math.min(size.height, size.width));
}

export function speechBalloonImageUrl(
  key: string,
  entity: VariablePartOfEntity,
  width: number,
  orientation: 'horizontal' | 'vertical',
): string {
  if (orientation === 'vertical') {
    return squareImageUrlOf(key, entity, width);
  } else {
    return squareWideImageUrlOf(key, entity, width);
  }
}

export function textFrameImageUrl(
  key: string,
  entity: VariablePartOfEntity,
  width: number,
  orientation: 'horizontal' | 'vertical',
): string {
  if (orientation === 'vertical') {
    return squareImageUrlOf(key, entity, width);
  } else {
    return squareWideImageUrlOf(key, entity, width);
  }
}

export function illustrationImageUrl(entity: Entity, size: Size): string {
  return imageUrl(entity, size.width, size.width / size.height);
}

export function fullScreenIllustrationImageUrl(
  entity: Entity,
  size: Size,
): string {
  return imageUrl(entity, size.width, size.width / size.height);
}

export function positionedEffectImageUrls(
  entity: VariableEntityWithManyImages,
  size: Size,
  orientation: 'horizontal' | 'vertical',
): string[] {
  if (orientation === 'vertical') {
    return entity.getImageUrls(size).map(url => {
      return URL_TO_BLOB[url] || url;
    });
  } else {
    return entity.getWideImageUrls(size).map(url => {
      return URL_TO_BLOB[url] || url;
    });
  }
}

export function clearCachedImages() {
  Object.keys(URL_TO_BLOB).forEach(key => {
    delete URL_TO_BLOB[key];
  });
}
