import CurrentUser from '../../../../../domain/entities/consumer/CurrentUser';

import UserAuthentication from '../../../../../domain/services/consumer/UserAuthentication';

import UserRepository from '../../../../../data/repositories/consumer/NetCurrentUserRepository';
import RegistrationRepository from '../../../../../data/repositories/consumer/temporary_users/NetRegistrationRepository';
import AccessTokenRepository from '../../../../../data/repositories/StorageAccessTokenRepository';

export default async function action(): Promise<CurrentUser> {
  const accessTokenRepository = new AccessTokenRepository();
  const registrationRepository = new RegistrationRepository();
  const userRepository = new UserRepository();
  try {
    const payload = await new UserAuthentication(
      accessTokenRepository,
      registrationRepository,
      userRepository,
    ).authenticate({platform: 'web'});
    return payload;
  } catch (err) {
    accessTokenRepository.update('');
    throw err;
  }
}
