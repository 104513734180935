import CharacterCommandOptions, {
  Effect,
} from '../../../../domain/value_objects/command_options/CharacterCommandOptions';

export interface CharacterCommandOptionsData {
  effect?: Effect;
  waiting?: boolean;
}

export default class CharacterCommandOptionsMapper {
  public map(obj: CharacterCommandOptionsData): CharacterCommandOptions {
    return new CharacterCommandOptions(obj.effect, obj.waiting);
  }
}
