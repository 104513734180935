import * as React from 'react';

import Icon from './Icon';

const name = 'speaker';
const defaultColor = 'white';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const SpeakerIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 15;
  const color = props.color || defaultColor;
  return <Icon name={name} size={size} color={color} />;
};

export default React.memo(SpeakerIcon);
