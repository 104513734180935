import Resource from './Resource';

export default class VoiceResource implements Resource {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public sceneIds: number[] | undefined,
    public audioUrl: string,
  ) {}
}
