import * as React from 'react';
import {Image, ImageResizeMode, Platform, View, ViewStyle} from 'react-native';

import GLSurface from './GLSurface';
import GLFilter from './GLFilter';
import GLImage from './GLImage';

import {URL_TO_BLOB} from '../../helpers/images';
import {decodeImage} from '../../view_models/AssetDecoder';

import {isChrome} from '../../../../../data/data_stores/net/UserAgent';
import {Filter} from '../../../domain/value_objects/command_options/BackgroundCommandOptions';

const uriToSize: {[key: string]: {width: number; height: number}} = {};

// const uriToBlob: { [key: string]: string } = {};

const uriToFilterToBlob: {[key: string]: {[key: string]: string}} = {};

interface Size {
  height: number;
  width: number;
}

interface Props {
  size: Size;
  items: Array<{key: string; uri: string; filter: Filter}>;
  resizeMode?: ImageResizeMode;
  pixelRatio?: number;
  onLoad?: (uriToBase64: {[key: string]: string}) => void;
}

interface State {
  imageSize: Size | null;
  currentIndex: number;
  uriToBlob: {[key: string]: string} | null;
}

export default class GLCapturedSequentialImages extends React.PureComponent<
  Props,
  State
> {
  private mounted = false;

  private ref = React.createRef<typeof GLSurface>();

  private keyToBase64: {[key: string]: string} = {};

  constructor(props: Props) {
    super(props);
    const currentIndex = this.getNextIndex();
    const item = props.items[currentIndex];
    this.state = {
      imageSize: item && uriToSize[item.uri] ? uriToSize[item.uri] : null,
      currentIndex,
      uriToBlob: null,
    };
  }

  public componentDidMount() {
    this.mounted = true;
    if (this.state.currentIndex === -1) {
      this.props.onLoad && this.props.onLoad(this.keyToBase64);
      return;
    }
    Promise.all(
      this.props.items.map(async item => {
        if (item.uri.startsWith('http')) {
          return {
            uri: item.uri,
            blob: URL_TO_BLOB[item.uri] || (await decodeImage(item.uri)),
          };
        } else {
          return {
            uri: item.uri,
            blob: item.uri,
          };
        }
      }),
    ).then(values => {
      values.forEach(value => (URL_TO_BLOB[value.uri] = value.blob));
      this.setState({uriToBlob: URL_TO_BLOB});
    });
    if (!this.state.imageSize) {
      const {items} = this.props;
      if (items.length > 0) {
        const uri = items[0].uri;
        Image.getSize(
          uri,
          (width: number, height: number) => {
            uriToSize[uri] = {width, height};
            if (this.mounted) {
              this.setState({imageSize: {width, height}});
            }
          },
          (error: any) => {
            throw error;
          },
        );
      }
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {size, items, resizeMode, pixelRatio} = this.props;
    const {currentIndex, imageSize, uriToBlob} = this.state;
    const {height, width} = size;
    const item = items[currentIndex];
    if (!item) {
      return null;
    }
    const {uri, filter} = item;
    if (!imageSize) {
      return null;
    }
    if (!uriToBlob) {
      return null;
    }
    return (
      <View key={item.key} style={containerStyle}>
        <GLFilter
          surfaceRef={this.ref}
          size={size}
          filter={filter}
          autoRedraw={false}
          pixelRatio={pixelRatio}
          onLoad={this.handleLoad}>
          <GLImage
            width={width}
            height={height}
            imageSize={imageSize}
            source={uriToBlob[uri] || uri}
            resizeMode={resizeMode}
            preload={true}
          />
        </GLFilter>
      </View>
    );
  }

  private handleLoad = async () => {
    const {items, onLoad} = this.props;
    const {currentIndex} = this.state;
    const surfaceRef = this.ref.current;
    if (!surfaceRef) {
      return;
    }
    const type = Platform.select({
      web: isChrome ? 'image/webp' : 'image/jpeg',
      default: 'jpg',
    });
    surfaceRef
      .captureFrame({quality: 0.9, type, format: 'base64'})
      .then((base64: string) => {
        const {key, uri, filter} = items[currentIndex];
        if (!uriToFilterToBlob[uri]) {
          uriToFilterToBlob[uri] = {};
        }
        uriToFilterToBlob[uri][filter] = base64.replaceAll('\r\n', '');
        this.keyToBase64[key] = base64.replaceAll('\r\n', '');
        const nextIndex = this.getNextIndex();
        if (nextIndex === -1) {
          onLoad && onLoad(this.keyToBase64);
        }
        this.setState({currentIndex: nextIndex});
        return base64;
      })
      .catch((e: any) => {
        console.log(e);
      });
  };

  private getNextIndex = () => {
    const {items} = this.props;
    return items.findIndex(item => {
      if (item.filter === 'normal') {
        this.keyToBase64[item.key] = URL_TO_BLOB[item.uri] || item.uri;
        if (!uriToFilterToBlob[item.uri]) {
          uriToFilterToBlob[item.uri] = {};
        }
        uriToFilterToBlob[item.uri][item.filter] = this.keyToBase64[item.key];
      }
      const blob =
        uriToFilterToBlob[item.uri] && uriToFilterToBlob[item.uri][item.filter];
      if (blob) {
        this.keyToBase64[item.key] = blob;
      }
      return !blob;
    });
  };
}

const containerStyle: ViewStyle = {
  display: Platform.select({web: 'none', default: undefined}),
  width: 0,
  height: 0,
  opacity: 0,
};
