import SoundEffectCommandOptions from '../../../../domain/value_objects/command_options/SoundEffectCommandOptions';

export interface SoundEffectCommandOptionsData {
  start_time?: number;
  end_time?: number;
}

export default class SoundEffectCommandOptionsMapper {
  public map(obj: SoundEffectCommandOptionsData): SoundEffectCommandOptions {
    return new SoundEffectCommandOptions(obj.start_time, obj.end_time);
  }
}
