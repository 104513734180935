import * as React from 'react';

import CharacterShowOrUpdateOrHideCommandViewBase, {
  Props,
} from './CharacterShowOrUpdateOrHideCommandViewBase';

const keyFrameAnimationOptions = {
  duration: 140,
  easing: 'cubic-bezier(0.5, 0, 0.75, 0)',
};

export default class CharacterShowOrUpdateOrHideCommandView extends CharacterShowOrUpdateOrHideCommandViewBase {
  constructor(props: Props) {
    super(props);
    this.containerStyle = [this.containerStyle, {opacity: 0}];
  }

  protected animate(toValue: number) {
    const animatedView = this.animatedViewRef.current;
    const opacity = `${toValue}`;
    if (animatedView) {
      animatedView
        .animate([{opacity}], keyFrameAnimationOptions)
        .addEventListener('finish', () => {
          animatedView.style.opacity = opacity;
          this.finishAnimation(toValue);
        });
    }
  }

  protected setOpacityValue = (value: number) => {
    const animatedView = this.animatedViewRef.current;
    const opacity = `${value}`;
    if (!animatedView) {
      return;
    }
    animatedView.style.opacity = opacity;
  };
}
