import EntityMapper from '../EntityMapper';

import UserEpisodeReadingFinishTime from '../../../../domain/entities/consumer/UserEpisodeReadingFinishTime';

export interface UserEpisodeReadingFinishTimeData {
  id: number;
  consumer_user_episode_reading_start_time_id: number;
  created_at: string;
  updated_at: string;
}

export default class UserEpisodeReadingFinishTimeMapper
  implements
    EntityMapper<
      UserEpisodeReadingFinishTimeData,
      UserEpisodeReadingFinishTime
    >
{
  public map(
    obj: UserEpisodeReadingFinishTimeData,
  ): UserEpisodeReadingFinishTime {
    return new UserEpisodeReadingFinishTime(
      obj.id,
      obj.consumer_user_episode_reading_start_time_id,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
