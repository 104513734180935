import * as React from 'react';
import {
  InteractionManager,
  LayoutChangeEvent,
  StyleProp,
  SafeAreaView,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import * as Immutable from 'immutable';

import NotFoundModal from './partials/NotFoundModal';
import Viewer, {CommonProps} from './partials/Viewer';

import ViewerLayoutManager from '../../view_models/ViewerLayoutManager';

import SceneScript from '../../../domain/entities/SceneScript';

const ORIENTATION_HORIZONTAL = 'horizontal';

export type {CommonProps};

export interface Props extends CommonProps {
  sceneScriptGetTask: Promise<SceneScript>;
}

interface State {
  sceneScript: SceneScript | null;
  layoutManager: ViewerLayoutManager | null;
  fail: boolean;
}

export default class Show extends React.Component<Props, State> {
  private mounted = false;

  private containerStyle: StyleProp<ViewStyle>;

  constructor(props: Props) {
    super(props);
    this.state = {
      fail: false,
      layoutManager: null,
      sceneScript: null,
    };
    this.containerStyle = [
      styles.container,
      props.orientation === ORIENTATION_HORIZONTAL
        ? {overflow: 'hidden'}
        : null,
    ];
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    return !(
      this.props.sceneScriptGetTask === nextProps.sceneScriptGetTask &&
      commonPropsAreEqual(this.props, nextProps) &&
      Immutable.is(this.state, nextState)
    );
  }

  public componentDidMount() {
    const {sceneScriptGetTask, onLoadFail} = this.props;
    this.mounted = true;
    sceneScriptGetTask
      .then(sceneScript => {
        InteractionManager.runAfterInteractions(() => {
          if (this.mounted) {
            this.setState({sceneScript});
          }
        });
      })
      .catch(() => {
        if (this.mounted) {
          this.setState({fail: true});
        }
        if (onLoadFail) {
          onLoadFail({name: 'Error', message: 'NotFound', stack: ''});
        }
      });
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {onRequestClose} = this.props;
    const {layoutManager, sceneScript, fail} = this.state;
    if (fail) {
      return (
        <NotFoundModal
          visible={true}
          fullScreenWidth={
            layoutManager
              ? layoutManager.getFullScreenIllustrationSize().width
              : 0
          }
          fullScreenHeight={
            layoutManager
              ? layoutManager.getFullScreenIllustrationSize().height
              : 0
          }
          onPressBack={onRequestClose}
        />
      );
    }
    return (
      <SafeAreaView style={styles.safeAreaView}>
        <View style={this.containerStyle} onLayout={this.handleLayout}>
          {layoutManager && sceneScript && (
            <Viewer
              key={sceneScript.id}
              sceneScript={sceneScript}
              layoutManager={layoutManager}
              {...this.props}
            />
          )}
        </View>
      </SafeAreaView>
    );
  }

  private handleLayout = (event: LayoutChangeEvent) => {
    const {orientation} = this.props;
    if (this.state.layoutManager) {
      return;
    }
    this.setState({
      layoutManager: new ViewerLayoutManager(orientation, {
        height: event.nativeEvent.layout.height,
        width: event.nativeEvent.layout.width,
      }),
    });
  };
}

const styles = StyleSheet.create({
  safeAreaView: {
    backgroundColor: 'black',
    flex: 1,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
});

export const commonPropsAreEqual = (
  prevProps: CommonProps,
  nextProps: CommonProps,
) => {
  return (
    prevProps.visibleProgressBar === nextProps.visibleProgressBar &&
    prevProps.textSpeed === nextProps.textSpeed &&
    prevProps.enabledSound === nextProps.enabledSound &&
    prevProps.autoPlaySpeed === nextProps.autoPlaySpeed
  );
};
