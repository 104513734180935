import {PixelRatio} from 'react-native';

interface Size {
  width: number;
  height: number;
}

export default class ViewerLayoutManager {
  private fullScreenSize: Size | null = null;
  private stageSize: Size | null = null;
  private characterSize: Size | null = null;
  private markSize: Size | null = null;
  private frameSize: Size | null = null;
  private speechBalloonSize: Size | null = null;
  private illustrationSize: Size | null = null;
  private fullScreenIllustrationSize: Size | null = null;

  // eslint-disable-next-line no-useless-constructor
  constructor(
    public orientation: 'horizontal' | 'vertical',
    private size: Size,
  ) {}

  public getSize(): Size {
    return this.size;
  }

  public getFullScreenSize(): Size {
    if (this.fullScreenSize) {
      return this.fullScreenSize;
    }
    const height = PixelRatio.roundToNearestPixel(this.size.height);
    const width = PixelRatio.roundToNearestPixel(this.size.width);
    this.fullScreenSize = {
      height,
      width,
    };
    return this.fullScreenSize;
  }

  public getStageSize(): Size {
    if (this.stageSize) {
      return this.stageSize;
    }
    const width = PixelRatio.roundToNearestPixel(this.size.width);
    const height = Math.min(
      PixelRatio.roundToNearestPixel(this.size.width / (320 / 260)),
      this.size.height,
    );
    this.stageSize = {
      height,
      width,
    };
    return this.stageSize;
  }

  public getCharacterSize(): Size {
    if (this.characterSize) {
      return this.characterSize;
    }
    const height = PixelRatio.roundToNearestPixel(
      this.getStageSize().height * (10.0 / 12.0) * 1.1,
    );
    const width = PixelRatio.roundToNearestPixel(height * (2039 / 2894));
    this.characterSize = {
      height,
      width,
    };
    return this.characterSize;
  }

  public getMarkSize(): Size {
    if (this.markSize) {
      return this.markSize;
    }
    const value = Math.min(
      PixelRatio.roundToNearestPixel(this.getCharacterSize().height / 8),
      PixelRatio.roundToNearestPixel(this.getCharacterSize().width / 3),
    );
    const height = value;
    const width = value;
    this.markSize = {
      height,
      width,
    };
    return this.markSize;
  }

  public getTextFrameSize(): Size {
    if (this.frameSize) {
      return this.frameSize;
    }
    const width = PixelRatio.roundToNearestPixel(this.size.width);
    const height = width;
    this.frameSize = {
      height,
      width,
    };
    return this.frameSize;
  }

  public getSpeechBalloonSize(): Size {
    if (this.frameSize) {
      return this.frameSize;
    }
    const width = PixelRatio.roundToNearestPixel(this.size.width);
    const height = width;
    this.frameSize = {
      height,
      width,
    };
    return this.frameSize;
  }

  public getIllustrationSize(): Size {
    if (this.illustrationSize) {
      return this.illustrationSize;
    }
    const width = PixelRatio.roundToNearestPixel(
      this.getStageSize().width * 0.7,
    );
    const height = PixelRatio.roundToNearestPixel(
      this.getStageSize().height * 0.7,
    );
    this.illustrationSize = {
      height,
      width,
    };
    return this.illustrationSize;
  }

  public getFullScreenIllustrationSize(): Size {
    return this.getFullScreenSize();
  }
}
