import EntityMapper from '../EntityMapper';

import TextFrameResourceOptionsMapper, {
  TextFrameResourceOptionsData,
} from '../../../value_objects/mappers/resource_options/TextFrameResourceOptionsMapper';

import TextFrameResource from '../../../../domain/entities/resources/TextFrameResource';

export interface TextFrameResourceData {
  id: number;
  type: 'text_frame';
  scene_ids?: number[];
  image_urls: {[key: string]: string};
  wide_image_urls: {[key: string]: string};
  caption: boolean;
  options?: TextFrameResourceOptionsData;
}

export default class TextFrameResourceMapper
  implements EntityMapper<TextFrameResourceData, TextFrameResource>
{
  private textFrameResourceOptionsMapper: TextFrameResourceOptionsMapper;

  constructor() {
    this.textFrameResourceOptionsMapper = new TextFrameResourceOptionsMapper();
  }

  public map(obj: TextFrameResourceData): TextFrameResource {
    const options = this.textFrameResourceOptionsMapper.map(obj.options || {});
    return new TextFrameResource(
      obj.id,
      obj.scene_ids,
      obj.image_urls,
      obj.wide_image_urls,
      obj.caption,
      options,
    );
  }
}
