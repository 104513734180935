import SpeechBalloonDirection from '../../../domain/value_objects/SpeechBalloonDirection';

export type SpeechBalloonDirectionData =
  | 'left'
  | 'center'
  | 'right'
  | 'left-center'
  | 'center-right'
  | 'left-right'
  | 'left-center-right';

export default class SpeechBalloonDirectionDataMapper {
  public map(obj: SpeechBalloonDirectionData): SpeechBalloonDirection {
    switch (obj) {
      case 'left':
        return SpeechBalloonDirection.Left;
      case 'center':
        return SpeechBalloonDirection.Center;
      case 'right':
        return SpeechBalloonDirection.Right;
      case 'left-center':
        return SpeechBalloonDirection.Left_Center;
      case 'center-right':
        return SpeechBalloonDirection.Center_Right;
      case 'left-right':
        return SpeechBalloonDirection.Left_Right;
      case 'left-center-right':
        return SpeechBalloonDirection.Left_Center_Right;
      default:
        throw Error('PositionData to SpeechBalloonDirection Error');
    }
  }
}
