import SpeechTextCommandOptions from '../../../../domain/value_objects/command_options/SpeechTextCommandOptions';

enum NameLabelColor {
  Blue = 'blue',
  Pink = 'pink',
  Green = 'green',
  Black = 'black',
}

export interface SpeechTextCommandOptionsData {
  speed?: number;
  override_character_name?: boolean | null;
  character_name?: string | null;
  character_name_label_color?: NameLabelColor | null;
}

export default class SpeechTextCommandOptionsMapper {
  public map(obj: SpeechTextCommandOptionsData): SpeechTextCommandOptions {
    return new SpeechTextCommandOptions(
      obj.speed,
      obj.override_character_name,
      obj.character_name,
      obj.character_name_label_color,
    );
  }
}
