import * as React from 'react';
import {StyleSheet, ViewStyle} from 'react-native';

import DescriptiveTextShowCommand from '../../../../../domain/entities/commands/DescriptiveTextShowCommand';
import SpeechTextShowCommand from '../../../../../domain/entities/commands/SpeechTextShowCommand';

import ElasticTextFrame from './ElasticTextFrame';

import EnabledSound from '../../../shared/EnabledSound';

import Frame from '../../../../view_models/Frame';

interface Props {
  command: DescriptiveTextShowCommand;
  frame: Frame;
  width: number;
  windowWidth: number;
  orientation: 'horizontal' | 'vertical';
  active?: boolean;
  skipRenderText: boolean;
  textSpeed?: 'slow' | 'normal' | 'fast' | 'no_effect';
  alignTop?: boolean;
  visiblePrompt?: boolean;
  visibleVoiceIcon?: boolean;
  onBeforeRenderText: (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => void;
  onAfterRenderText: (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => void;
  onFinishPlayVoice: (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => void;
  onFinishPlaySound: (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => void;
}

export default class TypewriterEffectTextFrame extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (!this.props.active && !nextProps.active) {
      return false;
    }
    return !(
      this.props.command === nextProps.command &&
      this.props.active === nextProps.active &&
      this.props.textSpeed === nextProps.textSpeed &&
      this.props.skipRenderText === nextProps.skipRenderText
    );
  }

  public render(): React.ReactNode {
    const {
      command,
      width,
      windowWidth,
      orientation,
      active,
      skipRenderText,
      textSpeed,
      alignTop,
      visiblePrompt,
      visibleVoiceIcon,
    } = this.props;
    return (
      <>
        <ElasticTextFrame
          style={styles.container}
          command={command}
          width={width}
          windowWidth={windowWidth}
          orientation={orientation}
          active={active}
          alignTop={alignTop}
          visiblePrompt={visiblePrompt}
          skipRenderText={skipRenderText}
          textSpeed={textSpeed}
          hasVoice={visibleVoiceIcon && !!command.voice}
          onBeforeRenderText={this.handleBeforeRenderText}
          onAfterRenderText={this.handleAfterRenderText}
        />
        {command.voice && active ? (
          <EnabledSound
            uri={command.voice.audioUrl}
            onFinishPlay={this.handleFinishPlayVoice}
          />
        ) : null}
        {command.sound && active ? (
          <EnabledSound
            uri={command.getAudioUrl()}
            onFinishPlay={this.handleFinishPlaySound}
          />
        ) : null}
      </>
    );
  }

  private handleBeforeRenderText = () => {
    const {command, onBeforeRenderText} = this.props;
    onBeforeRenderText(command);
  };

  private handleAfterRenderText = () => {
    const {command, onAfterRenderText} = this.props;
    onAfterRenderText(command);
  };

  private handleFinishPlayVoice = () => {
    const {command, onFinishPlayVoice} = this.props;
    onFinishPlayVoice(command);
  };

  private handleFinishPlaySound = () => {
    const {command, onFinishPlaySound} = this.props;
    onFinishPlaySound(command);
  };
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 5,
    marginTop: 10,
  } as ViewStyle,
});
