import * as React from 'react';

import TapNovelGoogleAds from './TapNovelGoogleAds';

interface Props {}

const DownloadProgressModalTop: React.FunctionComponent<Props> = props => {
  return (
    <TapNovelGoogleAds
      style={{display: 'block'}}
      slotId={'2488644842'}
      format={'auto'}
      fullWidthResponsive={true}
    />
  );
};

export default React.memo(DownloadProgressModalTop, () => true);
