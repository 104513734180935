import UserEpisodeBrowsingHistoryAndReadingTime from '../../../../domain/entities/consumer/UserEpisodeBrowsingHistoryAndReadingTime';

import NetUserEpisodeBrowsingHistoryAndReadingTimesRepository from '../../../../data/repositories/consumer/NetUserEpisodeBrowsingHistoryAndReadingTimesRepository';

export interface Params {
  episodeId: number;
  platform: string;
  episodeVersion: number;
  episodeCommandsLength: number;
  advertisingId?: string;
}

export default async function action(
  params: Params,
): Promise<UserEpisodeBrowsingHistoryAndReadingTime> {
  const payload =
    await new NetUserEpisodeBrowsingHistoryAndReadingTimesRepository().create({
      consumerUserEpisodeBrowsingHistoryAndReadingTime: params,
    });
  return payload;
}
