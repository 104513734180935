import EntityMapper from '../EntityMapper';

import BackgroundMusicShowCommand from '../../../../domain/entities/commands/BackgroundMusicShowCommand';
import ResourcesSet from '../../../../domain/entities/resources/ResourcesSet';

import BackgroundMusicCommandOptionsMapper, {
  BackgroundMusicCommandOptionsData,
} from '../../../value_objects/mappers/command_options/BackgroundMusicCommandOptionsMapper';

export interface BackgroundMusicShowCommandData {
  id: number;
  scene_id: number;
  type: 'background_music_show';
  sound_id: number;
  options?: BackgroundMusicCommandOptionsData;
}

export default class BackgroundMusicShowCommandMapper
  implements
    EntityMapper<BackgroundMusicShowCommandData, BackgroundMusicShowCommand>
{
  private backgroundMusicCommandOptionsMapper: BackgroundMusicCommandOptionsMapper;

  constructor(private resourcesSet: ResourcesSet) {
    this.backgroundMusicCommandOptionsMapper =
      new BackgroundMusicCommandOptionsMapper();
  }

  public map(obj: BackgroundMusicShowCommandData): BackgroundMusicShowCommand {
    const options = this.backgroundMusicCommandOptionsMapper.map(
      obj.options || {},
    );
    return new BackgroundMusicShowCommand(
      obj.id,
      obj.scene_id,
      this.resourcesSet.getSoundResource(obj.sound_id),
      options,
    );
  }
}
