import Resource from './Resource';

import ImageUrlHelper, {Options} from '../../helpers/ImageUrlHelper';

import TextFrameResourceOptions from '../../value_objects/resource_options/TextFrameResourceOptions';

export default class TextFrameResource implements Resource {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public sceneIds: number[] | undefined,
    public imageUrls: {[key: string]: string},
    public wideImageUrls: {[key: string]: string},
    public caption: boolean,
    public options: TextFrameResourceOptions,
  ) {}

  public getImageUrlOf(key: string, options: Options): string {
    return ImageUrlHelper.getImageUrl(this.imageUrls[key], options);
  }

  public getWideImageUrlOf(key: string, options: Options): string {
    return ImageUrlHelper.getImageUrl(this.wideImageUrls[key], options);
  }
}
