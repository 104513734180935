import EntityMapper from '../EntityMapper';

import PositionedEffectResource from '../../../../domain/entities/resources/PositionedEffectResource';

export interface PositionedEffectData {
  id: number;
  type: 'positioned_effect';
  scene_ids?: number[];
  position: 'center' | 'left' | 'right' | 'center_left' | 'center_right';
  image_urls: string[];
  wide_image_urls: string[];
}

export default class PositionedEffectResourceMapper
  implements EntityMapper<PositionedEffectData, PositionedEffectResource>
{
  public map(obj: PositionedEffectData): PositionedEffectResource {
    return new PositionedEffectResource(
      obj.id,
      obj.scene_ids,
      obj.position,
      obj.image_urls,
      obj.wide_image_urls,
    );
  }
}
