enum AdjustablePosition {
  Left,
  CenterLeft,
  Center,
  CenterRight,
  Right,
  Left_Center,
  Center_Right,
  Left_Right,
  CenterLeft_CenterRight,
  Left_Center_Right,
}

export default AdjustablePosition;

export class AdjustablePositionValueMapper {
  public static map(adjustablePosition: AdjustablePosition): string {
    switch (adjustablePosition) {
      case AdjustablePosition.Left:
        return 'left';
      case AdjustablePosition.CenterLeft:
        return 'center_left';
      case AdjustablePosition.Center:
        return 'center';
      case AdjustablePosition.CenterRight:
        return 'center_right';
      case AdjustablePosition.Right:
        return 'right';
      case AdjustablePosition.Left_Center:
        return 'left-center';
      case AdjustablePosition.Center_Right:
        return 'center-right';
      case AdjustablePosition.Left_Right:
        return 'left-right';
      case AdjustablePosition.CenterLeft_CenterRight:
        return 'center_left-center_right';
      case AdjustablePosition.Left_Center_Right:
        return 'left-center-right';
      default:
        throw new Error('Unsupported adjustablePosition error!');
    }
  }
}
