import RestApi, {Options, Result} from './RestApi';

import {camelizeKeys, decamelizeKeys} from 'humps';

interface Params {
  [key: string]: any;
}

export default class TapNovelRestApi extends RestApi {
  public static get<T>(
    path: string,
    params?: {[key: string]: any},
  ): Promise<Result<T>> {
    return super
      .get(this.getUrl(path), this.getParams(params))
      .catch(this.converErrors);
  }

  public static post<T>(
    path: string,
    params?: {[key: string]: any},
    options?: Options,
  ): Promise<Result<T>> {
    return super
      .post(this.getUrl(path), this.getParams(params), options)
      .catch(this.converErrors);
  }

  public static patch<T>(
    path: string,
    params?: {[key: string]: any},
    options?: Options,
  ): Promise<Result<T>> {
    return super
      .patch(this.getUrl(path), this.getParams(params), options)
      .catch(this.converErrors);
  }

  public static delete(
    path: string,
    params?: {[key: string]: any},
    options?: Options,
  ): Promise<void> {
    return super
      .delete(this.getUrl(path), this.getParams(params), options)
      .catch(this.converErrors);
  }

  private static getUrl(path: string) {
    return process.env.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
  }

  private static getParams(params?: Params): Params | undefined {
    if (!params) {
      return undefined;
    }
    return decamelizeKeys(params);
  }

  private static converErrors(errors: any): Promise<any> {
    if (errors.body) {
      if (errors.body.errors) {
        const error = {
          body: camelizeKeys(errors.body.errors),
          headers: errors.headers,
          status: errors.status,
        };
        throw error;
      } else {
        const error = {
          body: camelizeKeys(errors.body),
          headers: errors.headers,
          status: errors.status,
        };
        throw error;
      }
    } else {
      throw errors;
    }
  }
}
