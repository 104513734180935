import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import {colors} from '../../../styles/variables';

interface Props {
  visible: boolean;
  starting: boolean;
  currentIndex: number;
  commandsLength: number;
}

const ProgressBar: React.FunctionComponent<Props> = props => {
  const {visible, starting, currentIndex, commandsLength} = props;
  const barStyle: ViewStyle = {
    height: BAR_HEIGHT,
    backgroundColor: colors.orange,
    width: `${((currentIndex + 1) / commandsLength) * 100}%`,
  };
  if (!visible) {
    return null;
  }
  return (
    <View style={styles.container}>
      {starting && <View style={barStyle} />}
    </View>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.currentIndex === nextProps.currentIndex &&
    prevProps.visible === nextProps.visible
  );
};

export default React.memo(ProgressBar, areEqual);

const BAR_HEIGHT = 4;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: BAR_HEIGHT,
    backgroundColor: 'white',
  } as ViewStyle,
});
