export default class AccessToken {
  public static get(): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const val = localStorage.getItem(AccessToken.KEY);
      resolve(val);
    });
  }

  public static set(value: string): Promise<void> {
    return new Promise(() => {
      localStorage.setItem(AccessToken.KEY, value);
      return;
    });
  }

  private static KEY = 'id_token';
}
