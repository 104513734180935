import ViewerSetting, {
  TextSpeed,
} from '../../../domain/value_objects/ViewerSetting';

export interface ViewerSettingData {
  text_speed?: TextSpeed;
  visible_progress_bar?: boolean;
  enabled_sound?: boolean;
  auto_play_speed?: 0 | 1 | 1.5 | 2;
  // TODO
  auto_play_value?: 0 | 1 | 1.5 | 2;
}

export default class ViewerSettingMapper {
  public map(obj: ViewerSettingData): ViewerSetting {
    return new ViewerSetting(
      obj.text_speed,
      obj.visible_progress_bar,
      obj.enabled_sound,
      obj.auto_play_speed !== undefined
        ? obj.auto_play_speed
        : obj.auto_play_value,
    );
  }
}
