import * as React from 'react';

import EnabledSound from '../../shared/EnabledSound';

import BackgroundMusicShowCommand from '../../../../domain/entities/commands/BackgroundMusicShowCommand';
import BackgroundMusicHideCommand from '../../../../domain/entities/commands/BackgroundMusicHideCommand';

export type BackgroundMusicCommand =
  | BackgroundMusicShowCommand
  | BackgroundMusicHideCommand;

interface Props {
  command: BackgroundMusicCommand;
  volumeHalved?: boolean;
  onAfterPlay: (command: BackgroundMusicCommand) => void;
  onFinishPlay: (command: BackgroundMusicCommand) => void;
}

export default class BackgroundMusic extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !(
      this.props.command === nextProps.command &&
      this.props.volumeHalved === nextProps.volumeHalved
    );
  }

  public componentDidMount() {
    const {command, onAfterPlay} = this.props;
    onAfterPlay(command);
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const {command, onAfterPlay} = this.props;
    if (prevProps.command === command) {
      return;
    }
    onAfterPlay(command);
  }

  public render(): React.ReactNode {
    const {command, volumeHalved} = this.props;
    const playingUri =
      command instanceof BackgroundMusicShowCommand
        ? command.sound.audioUrl
        : null;
    return (
      <EnabledSound
        uri={playingUri}
        loop={true}
        fade={true}
        volumeHalved={!!volumeHalved}
        onFinishPlay={this.handleFinishPlay}
      />
    );
  }

  private handleFinishPlay = () => {
    const {command, onFinishPlay} = this.props;
    if (command instanceof BackgroundMusicShowCommand) {
      onFinishPlay(command);
    }
  };
}
