import * as React from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import SpeakerIcon from '../../shared/icons/SpeakerIcon';
import SpeakerSilentIcon from '../../shared/icons/SpeakerSilentIcon';

const speakerIcon = <SpeakerIcon size={11} color={'#d6d6d6'} />;
const speakerSilentIcon = <SpeakerSilentIcon size={11} color={'#999'} />;

interface Props {
  enabledSound: boolean;
  onEnabledSoundChange?: (enabledSound: boolean) => void;
}

const SoundPlayButton: React.FunctionComponent<Props> = props => {
  const {enabledSound, onEnabledSoundChange} = props;
  const handleEnabledSoundChange = React.useCallback(() => {
    onEnabledSoundChange && onEnabledSoundChange(!enabledSound);
  }, [enabledSound]);
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.button}
        onPress={handleEnabledSoundChange}>
        {enabledSound ? speakerIcon : speakerSilentIcon}
        <Text
          style={
            enabledSound ? styles.playButtonLabel : styles.playButtonLabelStop
          }>
          SOUND
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.enabledSound === nextProps.enabledSound;
};

export default React.memo(SoundPlayButton, areEqual);

const playButtonLabel = {
  marginTop: 1,
  color: '#d6d6d6',
  fontWeight: 'bold',
  letterSpacing: -0.5,
  ...Platform.select({
    web: {
      fontSize: 10,
      transform: 'scale(0.65)' as any,
    },
    default: {
      fontSize: 6.5,
    },
  }),
} as TextStyle;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: Platform.select({web: 94, default: 99}) + 5 + 12,
    right: 12,
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    borderRadius: 25,
  } as ViewStyle,
  playButtonLabel,
  playButtonLabelStop: {
    ...playButtonLabel,
    color: '#999',
  } as TextStyle,
});
