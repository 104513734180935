import * as React from 'react';

import IllustrationViewBase, {Props} from './IllustrationViewBase';

const keyFrameAnimationOptions = {
  duration: 50,
  easing: 'linear',
};

export default class IllustrationView extends IllustrationViewBase {
  constructor(props: Props) {
    super(props);
    this.innerStyle = [this.innerStyle, {opacity: 0}];
  }

  protected animated = (callback: () => void) => {
    const animatedView = this.animatedViewRef.current;
    if (!animatedView) {
      return;
    }
    const opacity = `0.6`;
    animatedView
      .animate([{opacity}], keyFrameAnimationOptions)
      .addEventListener('finish', () => {
        animatedView.style.opacity = opacity;
        callback();
      });
  };
}
