export type TextSpeed = 'slow' | 'normal' | 'fast' | 'no_effect';

export default class ViewerSetting {
  constructor(
    public textSpeed: TextSpeed = 'no_effect',
    public visibleProgressBar = true,
    public enabledSound = true,
    public autoPlaySpeed: 0 | 1 | 1.5 | 2 = 0,
  ) {}
}
