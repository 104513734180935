import AssetPreloadWorker from './AssetPreloadWorker';
import {IMAGE_URL_TO_DECODED, AUDIO_URL_TO_DECODED} from './AssetDecoder';
import {audioContext} from './AudioContextInitializer.web';

import {URL_TO_BLOB} from '../helpers/images';

interface Asset {
  url: string;
  blobUrl?: string;
  arrayBuffer?: ArrayBuffer;
}

export default class AssetPreloadExecutor {
  public execute(
    urls: Array<string>,
    callback?: (fetchedCount: number) => void,
  ): Promise<string[]> {
    return new Promise(resolve => {
      function handler(e: any) {
        if (e.data.fetchedCount) {
          callback && callback(e.data.fetchedCount);
          return;
        }
        if (e.data.assets) {
          AssetPreloadWorker.removeEventListener('message', handler);
          Promise.all(
            (e.data.assets as Array<Asset>).map(async asset => {
              if (asset.blobUrl) {
                URL_TO_BLOB[asset.url] = asset.blobUrl;
                IMAGE_URL_TO_DECODED[asset.blobUrl] = true;
              } else if (asset.arrayBuffer) {
                if (!audioContext) {
                  return asset.url;
                }
                try {
                  const audioBuffer = await audioContext.decodeAudioData(
                    asset.arrayBuffer,
                  );
                  AUDIO_URL_TO_DECODED[asset.url] = audioBuffer;
                } catch (e) {
                  console.log(e);
                }
              }
              return asset.url;
            }),
          ).then(ret => {
            resolve(ret);
          });
        }
      }
      AssetPreloadWorker.addEventListener('message', handler);
      AssetPreloadWorker.postMessage({urls, sendFetchedCount: !!callback});
    });
  }
}
