import * as React from 'react';
import {ImageSourcePropType, StyleSheet, View, ViewStyle} from 'react-native';

import BaseEffectImageSequence from '../../../shared/BaseEffectImageSequence';

import soundAnimImage01Uri from '../../../../../../../assets/images/viewer/sound_anim/01.png';
import soundAnimImage02Uri from '../../../../../../../assets/images/viewer/sound_anim/02.png';
import soundAnimImage03Uri from '../../../../../../../assets/images/viewer/sound_anim/03.png';
import soundAnimImage04Uri from '../../../../../../../assets/images/viewer/sound_anim/04.png';

const sources = [
  soundAnimImage01Uri,
  soundAnimImage02Uri,
  soundAnimImage03Uri,
  soundAnimImage04Uri,
] as ImageSourcePropType[];

interface Props {
  orientation: 'horizontal' | 'vertical';
}

const SoundAnimationIcon: React.FunctionComponent<Props> = props => {
  const {orientation} = props;
  return (
    <View
      style={orientation === 'vertical' ? styles.vertical : styles.horizontal}>
      <BaseEffectImageSequence
        size={
          orientation === 'vertical'
            ? VERTICAL_IMAGE_SIZE
            : HORIZONTAL_IMAGE_SIZE
        }
        sources={sources}
        framesPerSecond={2.67}
        loop={true}
        onFinish={onFinish}
      />
    </View>
  );
};

export default React.memo(SoundAnimationIcon);

const onFinish = () => {};

const VERTICAL_IMAGE_SIZE = {
  width: 111 / 2.5,
  height: 84 / 2.5,
};

const HORIZONTAL_IMAGE_SIZE = {
  width: 74,
  height: 50,
};

const styles = StyleSheet.create({
  vertical: {
    ...VERTICAL_IMAGE_SIZE,
    position: 'absolute',
    bottom: 8,
    right: 8,
  } as ViewStyle,
  horizontal: {
    ...HORIZONTAL_IMAGE_SIZE,
    position: 'absolute',
    top: 15,
    right: 15,
  } as ViewStyle,
});
