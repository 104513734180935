import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const GL = require('gl-react');

const mixArrays = (arr1: number[], arr2: number[], m: number): number[] =>
  arr1.map((v, i) => (1 - m) * v + m * arr2[i]);

const shaders = GL.Shaders.create({
  sepia: {
    frag: `
            precision highp float;
            varying vec2 uv;
            uniform sampler2D t;

            uniform float red;
            uniform float green;
            uniform float blue;

            void main () {
              vec4 color = texture2D(t, uv);
              color.x = color.x * red;
              color.y = color.y * green;
              color.z = color.z * blue;
              gl_FragColor = vec4(color);
            }
    `,
  },
});

interface Props {
  red?: number;
  green?: number;
  blue?: number;
  children?: React.ReactNode;
}

const RGBEmphasis = GL.createComponent((props: Props) => {
  const {red, green, blue, children: t} = props;
  return <GL.Node shader={shaders.sepia} uniforms={{t, red, green, blue}} />;
}) as React.FunctionComponent<Props>;

RGBEmphasis.defaultProps = {
  red: 0.3,
  green: 0.3,
  blue: 0.3,
};

export default RGBEmphasis;
