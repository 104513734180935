import {TextStyle, ViewStyle} from 'react-native';

export default class TextFrameResourceOptions {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public containerStyle?: ViewStyle,
    public textStyle?: TextStyle,
    public wideTextStyle?: TextStyle,
    public voiceIconStyle?: ViewStyle,
  ) {}
}
