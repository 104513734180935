enum FontColor {
  None = 'none',
  White = 'white',
  Black = 'black',
  Red = 'red',
  Pink = 'pink',
  Orange = 'orange',
  Yellow = 'yellow',
  Green = 'green',
  LightBlue = 'light_blue',
  Blue = 'blue',
  Purple = 'purple',
  Gray = 'gray',
  SystemDefault = 'system_default',
}

export default FontColor;
