import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import SceneScriptMapper, {
  SceneScriptData,
} from '../../entities/mappers/SceneScriptMapper';

import SceneScript from '../../../domain/entities/SceneScript';
import EpisodeScriptsRepository from '../../../domain/repositories/consumer/EpisodeScriptsRepository';

export default class NetEpisodeScriptsRepository
  extends NetResourcesRepository<SceneScript, SceneScriptData>
  implements EpisodeScriptsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/admin/episode_scripts',
        new SceneScriptMapper(),
      ),
    );
  }
}
