import EntityMapper from '../EntityMapper';

import VoiceResource from '../../../../domain/entities/resources/VoiceResource';

export interface VoiceResourceData {
  id: number;
  type: 'voice';
  scene_ids?: number[];
  audio_url: string;
}

export default class SoundResourceMapper
  implements EntityMapper<VoiceResourceData, VoiceResource>
{
  public map(obj: VoiceResourceData): VoiceResource {
    return new VoiceResource(obj.id, obj.scene_ids, obj.audio_url);
  }
}
