import Command from './Command';

import SoundResource from '../resources/SoundResource';

import SoundEffectCommandOptions from '../../value_objects/command_options/SoundEffectCommandOptions';

export default class SoundEffectShowCommand extends Command {
  constructor(
    public id: number,
    public sceneId: number,
    public sound: SoundResource,
    public options: SoundEffectCommandOptions,
  ) {
    super(id, sceneId);
  }

  public getAudioUrl(): string | null {
    if (!this.sound) {
      return null;
    }
    return this.sound.getAudioUrl({
      satrtTime: this.options.startTime,
      endTime: this.options.endTime,
    });
  }
}
