import EntityMapper from '../EntityMapper';

import Resource from '../../../../domain/entities/resources/Resource';
import ResourcesSet from '../../../../domain/entities/resources/ResourcesSet';

import BackgroundResourceMapper, {
  BackgroundResourceData,
} from './BackgroundResourceMapper';
import CharacterResourceMapper, {
  CharacterResourceData,
} from './CharacterResourceMapper';
import FullScreenIllustrationResourceMapper, {
  FullScreenIllustrationResourceData,
} from './FullScreenIllustrationResourceMapper';
import IllustrationResourceMapper, {
  IllustrationResourceData,
} from './IllustrationResourceMapper';
import MarkResourceMapper, {MarkResourceData} from './MarkResourceMapper';
import SpeechBalloonResourceMapper, {
  SpeechBalloonResourceData,
} from './SpeechBalloonResourceMapper';
import TextFrameResourceMapper, {
  TextFrameResourceData,
} from './TextFrameResourceMapper';
import SoundResourceMapper, {SoundResourceData} from './SoundResourceMapper';
import VoiceResourceMapper, {VoiceResourceData} from './VoiceResourceMapper';
import PositionedEffectResourceMapper, {
  PositionedEffectData,
} from './PositionedEffectResourceMapper';

export type ResourceData =
  | BackgroundResourceData
  | CharacterResourceData
  | FullScreenIllustrationResourceData
  | IllustrationResourceData
  | MarkResourceData
  | SpeechBalloonResourceData
  | TextFrameResourceData
  | SoundResourceData
  | VoiceResourceData
  | PositionedEffectData;

export default class ResourceMapper
  implements EntityMapper<ResourceData, Resource>
{
  private backgroundResourceMapper: BackgroundResourceMapper;
  private characterResourceMapper: CharacterResourceMapper;
  private fullScreenIllustrationResourceMapper: FullScreenIllustrationResourceMapper;
  private illustrationResourceMapper: IllustrationResourceMapper;
  private markResourceMapper: MarkResourceMapper;
  private speechBalloonResourceMapper: SpeechBalloonResourceMapper;
  private textFrameResourceMapper: TextFrameResourceMapper;
  private soundResourceMapper: SoundResourceMapper;
  private voiceResourceMapper: VoiceResourceMapper;
  private positionedEffectResourceMapper: PositionedEffectResourceMapper;

  constructor(private resourcesSet: ResourcesSet) {
    this.backgroundResourceMapper = new BackgroundResourceMapper();
    this.characterResourceMapper = new CharacterResourceMapper();
    this.fullScreenIllustrationResourceMapper =
      new FullScreenIllustrationResourceMapper();
    this.illustrationResourceMapper = new IllustrationResourceMapper();
    this.markResourceMapper = new MarkResourceMapper();
    this.speechBalloonResourceMapper = new SpeechBalloonResourceMapper();
    this.textFrameResourceMapper = new TextFrameResourceMapper();
    this.soundResourceMapper = new SoundResourceMapper();
    this.voiceResourceMapper = new VoiceResourceMapper();
    this.positionedEffectResourceMapper = new PositionedEffectResourceMapper();
  }

  public map(obj: ResourceData): Resource {
    switch (obj.type) {
      case 'background': {
        const backgroundResource = this.backgroundResourceMapper.map(obj);
        this.resourcesSet.backgroundResources.push(backgroundResource);
        return backgroundResource;
      }
      case 'character': {
        const characterResource = this.characterResourceMapper.map(obj);
        this.resourcesSet.characterResources.push(characterResource);
        return characterResource;
      }
      case 'full_screen_illustration': {
        const fullscreenIllustrationResource =
          this.fullScreenIllustrationResourceMapper.map(obj);
        this.resourcesSet.fullScreenIllustrationResources.push(
          fullscreenIllustrationResource,
        );
        return fullscreenIllustrationResource;
      }
      case 'illustration': {
        const illustrationResource = this.illustrationResourceMapper.map(obj);
        this.resourcesSet.illustrationResources.push(illustrationResource);
        return illustrationResource;
      }
      case 'mark': {
        const markResource = this.markResourceMapper.map(obj);
        this.resourcesSet.markResources.push(markResource);
        return markResource;
      }
      case 'speech_balloon': {
        const speechBalloonResource = this.speechBalloonResourceMapper.map(obj);
        this.resourcesSet.speechBalloonResources.push(speechBalloonResource);
        return speechBalloonResource;
      }
      case 'text_frame': {
        const textFrameResource = this.textFrameResourceMapper.map(obj);
        this.resourcesSet.textFrameResources.push(textFrameResource);
        return textFrameResource;
      }
      case 'sound': {
        const soundResource = this.soundResourceMapper.map(obj);
        this.resourcesSet.soundResources.push(soundResource);
        return soundResource;
      }
      case 'voice': {
        const voiceResour = this.voiceResourceMapper.map(obj);
        this.resourcesSet.voiceResources.push(voiceResour);
        return voiceResour;
      }
      case 'positioned_effect': {
        const positionedEffectResource =
          this.positionedEffectResourceMapper.map(obj);
        this.resourcesSet.positionedEffectResources.push(
          positionedEffectResource,
        );
        return positionedEffectResource;
      }
      default:
        throw new Error('Unknown resource type');
    }
  }
}
