import AdjustablePosition, {
  AdjustablePositionValueMapper,
} from '../../domain/value_objects/AdjustablePosition';

import SpeechBalloonResource from '../../domain/entities/resources/SpeechBalloonResource';

import ImageUrlHelper, {Options} from '../../domain/helpers/ImageUrlHelper';

export default class AdjustableSpeechBalloon {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    private speechBalloon: SpeechBalloonResource,
    private adjustablePosition: AdjustablePosition,
  ) {}

  public getImageUrlOf(key: string, options: Options): string {
    return ImageUrlHelper.getImageUrl(this.getImageUrls()[key], options);
  }

  public getWideImageUrlOf(key: string, options: Options): string {
    return ImageUrlHelper.getImageUrl(this.getWideImageUrls()[key], options);
  }

  private getImageUrls(): {[key: string]: string} {
    if (!this.speechBalloon.options.adjustable) {
      return this.speechBalloon.imageUrls;
    }
    const pos = AdjustablePositionValueMapper.map(this.adjustablePosition);
    return this.speechBalloon.options.adjustable[pos];
  }

  private getWideImageUrls(): {[key: string]: string} {
    if (!this.speechBalloon.options.wideAdjustable) {
      return this.speechBalloon.imageUrls;
    }
    const pos = AdjustablePositionValueMapper.map(this.adjustablePosition);
    return this.speechBalloon.options.wideAdjustable[pos];
  }
}
