import IllustrationCommandOptions, {
  Effect,
} from '../../../../domain/value_objects/command_options/IllustrationCommandOptions';

export interface IllustrationCommandOptionsData {
  effect?: Effect;
}

export default class IllustrationCommandOptionsMapper {
  public map(obj: IllustrationCommandOptionsData): IllustrationCommandOptions {
    return new IllustrationCommandOptions(obj.effect);
  }
}
