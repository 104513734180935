import EntityMapper from '../EntityMapper';

import CommandMapper, {CommandData} from './CommandMapper';
import CompositeParallelCommand from '../../../../domain/entities/commands/CompositeParallelCommand';
import ResourcesSet from '../../../../domain/entities/resources/ResourcesSet';

export interface CompositeParallelCommandData {
  id: number;
  scene_id: number;
  type: 'composite_parallel';
  commands: CommandData[];
}

export default class ClearCommandMapper
  implements
    EntityMapper<CompositeParallelCommandData, CompositeParallelCommand>
{
  // eslint-disable-next-line no-useless-constructor
  constructor(private resourcesSet: ResourcesSet) {}

  public map(obj: CompositeParallelCommandData): CompositeParallelCommand {
    const commandMapper = new CommandMapper(this.resourcesSet);
    const commands = obj.commands.map((command, id) =>
      commandMapper.map({...command, id}),
    );
    return new CompositeParallelCommand(obj.id, obj.scene_id, commands);
  }
}
