import * as React from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

import Command from '../../../../../domain/entities/commands/Command';
import DescriptiveTextShowCommand from '../../../../../domain/entities/commands/DescriptiveTextShowCommand';
import SpeechTextShowCommand from '../../../../../domain/entities/commands/SpeechTextShowCommand';

import TypewriterEffectSpeechBalloon from './TypewriterEffectSpeechBalloon';
import TypewriterEffectTextFrame from './TypewriterEffectTextFrame';

import Prompt from '../../../shared/Prompt';
import TouchableView from '../../../shared/TouchableView';

import Frame from '../../../../view_models/Frame';
import ViewerLayoutManager from '../../../../view_models/ViewerLayoutManager';

interface Props {
  commands: Command[];
  frame: Frame;
  layoutManager: ViewerLayoutManager;
  skipRenderText: boolean;
  pausing: boolean;
  orientation: 'horizontal' | 'vertical';
  textSpeed?: 'slow' | 'normal' | 'fast' | 'no_effect';
  visiblePrompt?: boolean;
  visibleVoiceIcon?: boolean;
  onTouch: (point: {x: number; y: number}) => void;
  onBeforeRenderText: (
    command: SpeechTextShowCommand | DescriptiveTextShowCommand,
  ) => void;
  onAfterRenderText: (
    command: SpeechTextShowCommand | DescriptiveTextShowCommand,
  ) => void;
  onFinishPlayVoice: (
    command: SpeechTextShowCommand | DescriptiveTextShowCommand,
  ) => void;
  onFinishPlaySound: (
    command: SpeechTextShowCommand | DescriptiveTextShowCommand,
  ) => void;
  onScrolling: () => void;
  onScrolledToTop: () => void;
}

export default class ScenarioHorizontal extends React.Component<Props> {
  public render(): React.ReactNode {
    const command = this.getCommand();
    return (
      <View style={styles.container}>
        <TouchableView
          style={styles.scrollView as StyleProp<ViewStyle>}
          onTouch={this.handleTouch}>
          {command ? this.renderItem(command) : null}
        </TouchableView>
      </View>
    );
  }

  private getCommand():
    | DescriptiveTextShowCommand
    | SpeechTextShowCommand
    | null {
    const {commands} = this.props;
    const lastCommand = commands[commands.length - 1];
    if (
      (lastCommand instanceof DescriptiveTextShowCommand &&
        !lastCommand.textFrame.caption) ||
      lastCommand instanceof SpeechTextShowCommand
    ) {
      return lastCommand;
    } else {
      return null;
    }
  }

  private renderItem = (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ): React.ReactElement<any> | null => {
    const {
      layoutManager,
      skipRenderText,
      orientation,
      textSpeed,
      frame,
      visiblePrompt,
      visibleVoiceIcon,
      onBeforeRenderText,
      onAfterRenderText,
      onFinishPlayVoice,
      onFinishPlaySound,
    } = this.props;
    if (command instanceof DescriptiveTextShowCommand) {
      if (command.textFrame.caption) {
        return null;
      }
      return (
        <TypewriterEffectTextFrame
          key={command.getKey()}
          command={command}
          frame={frame}
          width={layoutManager.getTextFrameSize().width}
          windowWidth={layoutManager.getSize().width}
          orientation={orientation}
          active={true}
          skipRenderText={skipRenderText}
          alignTop={true}
          visiblePrompt={visiblePrompt}
          visibleVoiceIcon={visibleVoiceIcon}
          onBeforeRenderText={onBeforeRenderText}
          onAfterRenderText={onAfterRenderText}
          onFinishPlayVoice={onFinishPlayVoice}
          onFinishPlaySound={onFinishPlaySound}
          textSpeed={textSpeed}
        />
      );
    } else {
      return (
        <TypewriterEffectSpeechBalloon
          key={command.getKey()}
          command={command}
          frame={frame}
          width={layoutManager.getSpeechBalloonSize().width}
          windowWidth={layoutManager.getSize().width}
          orientation={orientation}
          active={true}
          skipRenderText={skipRenderText}
          alignTop={true}
          visiblePrompt={visiblePrompt}
          visibleVoiceIcon={visibleVoiceIcon}
          onBeforeRenderText={onBeforeRenderText}
          onAfterRenderText={onAfterRenderText}
          onFinishPlayVoice={onFinishPlayVoice}
          onFinishPlaySound={onFinishPlaySound}
          textSpeed={textSpeed}
        />
      );
    }
  };

  private handleTouch = (point: {x: number; y: number}) => {
    const {onTouch} = this.props;
    onTouch(point);
  };
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 240,
  } as ViewStyle,
  prompt: {
    position: 'absolute',
    right: 0,
    top: 10,
  } as ViewStyle,
  scrollView: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    alignItems: 'center',
  } as ViewStyle,
});
