import EntityMapper from '../EntityMapper';

import UserEpisodeReadingStartTime from '../../../../domain/entities/consumer/UserEpisodeReadingStartTime';

export interface UserEpisodeReadingStartTimeData {
  id: number;
  episode_id: number;
  created_at: string;
  updated_at: string;
}

export default class UserEpisodeReadingStartTimeMapper
  implements
    EntityMapper<UserEpisodeReadingStartTimeData, UserEpisodeReadingStartTime>
{
  public map(
    obj: UserEpisodeReadingStartTimeData,
  ): UserEpisodeReadingStartTime {
    return new UserEpisodeReadingStartTime(
      obj.id,
      obj.episode_id,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
