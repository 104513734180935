import * as React from 'react';
import {
  Animated,
  Easing,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

const ON_TEXT = 'ON';
const OFF_TEXT = 'OFF';

interface Props {
  enabled: boolean;
  onValueChange?: (enabled: boolean) => void;
}

const ToggleSwitch: React.FunctionComponent<Props> = props => {
  const {enabled, onValueChange} = props;
  const translateXValue = React.useRef(
    new Animated.Value(enabled ? 1 : 0),
  ).current;
  const onPress = React.useCallback(() => {
    onValueChange && onValueChange(!enabled);
  }, [enabled]);
  React.useEffect(() => {
    Animated.timing(translateXValue, {
      duration: 200,
      easing: Easing.elastic(1),
      toValue: enabled ? 1 : 0,
      useNativeDriver: true,
    }).start();
  }, [enabled]);
  const thumbStyle = React.useMemo(() => {
    const translateX = translateXValue.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 31],
    });
    return [styles.thumb, {transform: [{translateX}]}];
  }, []);
  return (
    <Pressable onPress={onPress}>
      <Animated.View
        style={enabled ? styles.containerEnabled : styles.containerDisable}>
        <View style={styles.labelContainer}>
          <Text style={enabled ? styles.labelOn : styles.labelOff}>
            {enabled ? ON_TEXT : OFF_TEXT}
          </Text>
        </View>
        <Animated.View style={thumbStyle} />
      </Animated.View>
    </Pressable>
  );
};

export default React.memo(ToggleSwitch);

const THUMB_SIZE = 23;

const container = {
  borderRadius: THUMB_SIZE / 2.0,
  padding: 1,
  width: 58,
  height: 26,
  justifyContent: 'center',
};

const label = {
  fontSize: 12,
  fontWeight: 'bold',
};

const styles = StyleSheet.create({
  containerEnabled: {
    ...container,
    backgroundColor: '#ff9412',
  } as ViewStyle,
  containerDisable: {
    ...container,
    backgroundColor: '#e5e5e5',
  } as ViewStyle,
  labelContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
  } as ViewStyle,
  labelOn: {
    ...label,
    color: '#fff',
    marginLeft: 9,
  } as TextStyle,
  labelOff: {
    ...label,
    color: '#999',
    marginLeft: 29,
  } as TextStyle,
  switch: {
    height: THUMB_SIZE,
    width: 58,
  } as ViewStyle,
  thumb: {
    width: THUMB_SIZE,
    height: THUMB_SIZE,
    backgroundColor: 'white',
    borderRadius: THUMB_SIZE / 2.0,
  } as ViewStyle,
});
