import * as React from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

import TextFrameView from './parts/TextFrameView';

import Command from '../../../../../domain/entities/commands/Command';
import DescriptiveTextShowCommand from '../../../../../domain/entities/commands/DescriptiveTextShowCommand';
import SpeechTextShowCommand from '../../../../../domain/entities/commands/SpeechTextShowCommand';

interface Size {
  height: number;
  width: number;
}

interface Props {
  style?: StyleProp<ViewStyle>;
  command: Command | null;
  size: Size;
  skipRenderText: boolean;
  onBeforeRenderText: (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => void;
  onAfterRenderText: (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => void;
  onFinishPlayVoice: (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => void;
  onFinishPlaySound: (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => void;
}

const TEXT_SPEED = 'slow';

const StageCaption: React.FunctionComponent<Props> = props => {
  const {
    style,
    command,
    size,
    skipRenderText,
    onBeforeRenderText,
    onAfterRenderText,
    onFinishPlayVoice,
    onFinishPlaySound,
  } = props;
  if (!command || !(command instanceof DescriptiveTextShowCommand)) {
    return null;
  }
  if (!command.textFrame.caption) {
    return null;
  }
  return (
    <View key={command.getKey()} style={[style, styles.container]}>
      <TextFrameView
        command={command}
        skipRenderText={skipRenderText}
        textSpeed={TEXT_SPEED}
        width={size.width}
        onFinishPlayVoice={onFinishPlayVoice}
        onFinishPlaySound={onFinishPlaySound}
        onBeforeRenderText={onBeforeRenderText}
        onAfterRenderText={onAfterRenderText}
      />
    </View>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.command === nextProps.command &&
    prevProps.skipRenderText === nextProps.skipRenderText
  );
};

export default React.memo(StageCaption, areEqual);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'transparent',
  } as ViewStyle,
});
