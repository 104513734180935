import NetResourceRepository from '../../NetResourceRepository';
import NetResourceRepositoryAdapter from '../../NetResourceRepositoryAdapter';

import CurrentUserMapper, {
  CurrentUserData,
} from '../../../entities/mappers/consumer/CurrentUserMapper';

import CurrentUser from '../../../../domain/entities/consumer/CurrentUser';
import RegistrationRepository from '../../../../domain/repositories/consumer/temporary_users/RegistrationRepository';

export default class NetRegistrationRepository
  extends NetResourceRepository<CurrentUser, CurrentUserData>
  implements RegistrationRepository
{
  constructor() {
    super(
      new NetResourceRepositoryAdapter(
        '/api/consumer/temporary_users/registration',
        new CurrentUserMapper(),
      ),
    );
  }
}
