import * as React from 'react';

import NameLabelColor, {
  DEFAULT_COLOR,
  NAME_LABEL_COLOR_TO_BORDER_COLOR,
  NAME_LABEL_COLOR_TO_FILL_COLOR,
  toggleBrightnessColor,
} from '../../../domain/value_objects/NameLabelColor';

const BRIGHTNESS = 0.25;

interface Props {
  textWidth: number;
  height: number;
  color?: NameLabelColor;
  active?: boolean;
}

const NameLabelFrame: React.FunctionComponent<Props> = props => {
  const {textWidth, height, color: originColor, active} = props;
  const color = originColor || DEFAULT_COLOR;
  const fillColor = toggleBrightnessColor(
    NAME_LABEL_COLOR_TO_FILL_COLOR[color],
    BRIGHTNESS,
    active,
  );
  const borderColor = toggleBrightnessColor(
    NAME_LABEL_COLOR_TO_BORDER_COLOR[color],
    BRIGHTNESS,
    active,
  );
  const whiteColor = toggleBrightnessColor('#ffffff', BRIGHTNESS, active);
  const rectWidth = Math.ceil((textWidth + height) * (57 / height));
  const viewBoxWidth = rectWidth + 52;
  const viewBoxHeight = 57;
  const id1 = React.useRef(Math.random().toString(36).slice(-8)).current;
  const id2 = React.useRef(Math.random().toString(36).slice(-8)).current;
  return (
    <svg
      width={Math.floor((viewBoxWidth * height) / viewBoxHeight)}
      height={height}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <path id={id1} d={`M0 0H${rectWidth}l49 54.7H0z`} />
        <filter
          x="-.7%"
          y="-1.8%"
          width="101.3%"
          height="107.3%"
          filterUnits="objectBoundingBox"
          id={id2}>
          <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation=".5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g fill="none">
        <use fill="#000" filter={`url(#${id2})`} xlinkHref={`#${id1}`} />
        <use fill={fillColor} xlinkHref={`#${id1}`} />
        <path fill={whiteColor} d={`M0 47.5h${rectWidth + 45}v1.4H0z`} />
        <path
          stroke={borderColor}
          stroke-width="1.5"
          d={`M${rectWidth} 0.75m47.5 53H0.75V.75h${rectWidth}z`}
        />
      </g>
    </svg>
  );
};

export default React.memo(NameLabelFrame);
