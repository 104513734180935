import * as React from 'react';

import GoogleAds, {CommonProps} from './GoogleAds';

type Format = 'vertical' | 'horizontal' | 'rectangle' | 'fluid' | 'auto';

const TapNovelGoogleAds: React.FunctionComponent<CommonProps> = props => {
  return <GoogleAds {...props} clientId={'ca-pub-1301859781271766'} />;
};

export default React.memo(TapNovelGoogleAds);
