export default class JsonAsyncStorage {
  public static get<T>(key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const val = localStorage.getItem(key);
      resolve(val ? JSON.parse(val) : null);
    });
  }

  public static set(key: string, value: any): Promise<void> {
    return new Promise(resolve => {
      localStorage.setItem(key, JSON.stringify(value));
      resolve();
    });
  }

  public static remove(key: string): Promise<void> {
    return new Promise(resolve => {
      localStorage.removeItem(key);
      resolve();
    });
  }
}
