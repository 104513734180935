import * as React from 'react';

import BackgroundShowCommandViewBase, {
  Props,
} from './BackgroundShowCommandViewBase';

export default class BackgroundShowCommandView extends BackgroundShowCommandViewBase {
  private animatioin: Animation | null = null;

  constructor(props: Props) {
    super(props);
    this.containerStyle = [this.containerStyle, {opacity: 0}];
  }
  protected animate(
    from: number,
    to: number,
    duration: number,
    callback?: () => void,
  ) {
    const animatedView = this.animatedViewRef.current;
    if (!animatedView) {
      return;
    }
    animatedView.style.opacity = `${from}`;
    this.animatioin = animatedView.animate([{opacity: `${to}`}], {
      duration,
      easing: 'linear',
    });
    this.animatioin.addEventListener('finish', () => {
      animatedView.style.opacity = `${to}`;
      callback && callback();
    });
  }

  protected stopAnimation = () => {
    if (this.animatioin?.playState !== 'finished') {
      this.animatioin?.cancel();
    }
  };
}
