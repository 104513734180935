function createAssetPreloadWorker(f: () => void) {
  return new Worker(URL.createObjectURL(new Blob([`(${f})()`])));
}

const AssetPreloadWorker = createAssetPreloadWorker(() => {
  self.addEventListener('message', e => {
    const ua = navigator.userAgent.toLowerCase();
    const isIE = ua.indexOf('msie') != -1 || ua.indexOf('trident') != -1;
    const isChrome = ua.indexOf('chrome') != -1;
    const urls = e.data.urls;
    const sendFetchedCount = e.data.sendFetchedCount;
    const urlsLength = urls.length;
    const urlToFetched: any = {};
    const sendProgress = function (url: string) {
      urlToFetched[url] = true;
      if (!sendFetchedCount) {
        return;
      }
      self.postMessage(
        {
          fetchedCount: Object.keys(urlToFetched).length,
        },
        [] as any,
      );
    };
    const decodeImage = (url: string) => {
      return fetch(url, {
        mode: 'cors',
        headers: isIE
          ? {}
          : {
              Accept: 'image/webp,image/apng,*/*',
            },
      })
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(blobUrl => {
          sendProgress(url);
          return {url, blobUrl};
        });
    };
    const decodeAudio = (url: string) => {
      return fetch(isChrome ? `${url}?t=${new Date().getTime()}` : url, {
        mode: 'cors',
      })
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
          sendProgress(url);
          return {url, arrayBuffer};
        });
    };
    const decodeAsset = (url: string) => {
      if (
        url.endsWith('.png') ||
        url.endsWith('.jpg') ||
        url.endsWith('.jpeg') ||
        url.includes('/actor_character_faces/images/')
      ) {
        return decodeImage(url);
      } else if (url.endsWith('.mp3')) {
        return decodeAudio(url);
      } else {
        sendProgress(url);
        return new Promise((resolve, reject) => {
          resolve({url});
        });
      }
    };
    const sleep = (msec: number) => {
      return new Promise(resolve => setTimeout(resolve, msec));
    };
    const MAX_RETRY = 5;
    const prefetch = (url: string, retry = MAX_RETRY): Promise<any> => {
      try {
        try {
          return decodeAsset(url);
        } catch (e) {
          if (retry >= 0) {
            // tslint:disable-next-line:no-console
            console.log(`GET ${url} retry:${5 - retry}`);
            return sleep(500).then(() => {
              return prefetch(url, retry - 1);
            });
          } else {
            // throw new Error(`Do not get ${url}`);
            console.log(`Abort fetch ${url}`);
            return new Promise((resolve, reject) => {
              resolve({url});
            });
          }
        }
      } catch (errorMessage) {
        if (retry >= 0) {
          // tslint:disable-next-line:no-console
          console.log(`GET ${url} retry:${MAX_RETRY - retry}`);
          return sleep(500).then(() => {
            return prefetch(url, retry - 1);
          });
        } else {
          // throw Error(errorMessage as any);
          console.log(`Abort fetch ${url} reson: ${errorMessage}`);
          return new Promise((resolve, reject) => {
            resolve({url});
          });
        }
      }
    };

    try {
      Promise.all(urls.map((url: string) => prefetch(url))).then(
        (assets: Array<any>) => {
          try {
            const arrayBufferList = assets
              .filter(asset => asset.arrayBuffer)
              .map(asset => asset.arrayBuffer);
            self.postMessage({assets}, arrayBufferList as any);
          } catch (e) {
            console.log(e);
            self.postMessage({fetchedCount: urlsLength}, [] as any);
            self.postMessage({assets: []}, [] as any);
          }
        },
      );
    } catch (e) {
      console.log(e);
      self.postMessage({fetchedCount: urlsLength}, [] as any);
      self.postMessage({assets: []}, [] as any);
    }
  });
});

export default AssetPreloadWorker;
