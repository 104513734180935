import Entity from '../Entity';

import UserEpisodeBrowsingLog from './UserEpisodeBrowsingLog';
import UserEpisodeBrowsingHistory from './UserEpisodeBrowsingHistory';
import UserEpisodeReadingFinishTime from './UserEpisodeReadingFinishTime';
import UserEpisodeReadingStartTime from './UserEpisodeReadingStartTime';
import UserStoryBrowsingHistory from './UserStoryBrowsingHistory';

export default class UserEpisodeBrowsingHistoryAndReadingTime
  implements Entity
{
  constructor(
    public id: number,
    public episodeId: number,
    public consumerUserEpisodeBrowsingHistory: UserEpisodeBrowsingHistory,
    public consumerUserStoryBrowsingHistory: UserStoryBrowsingHistory,
    public consumerUserEpisodeReadingStartTime: UserEpisodeReadingStartTime,
    public consumerUserEpisodeBrowsingLog?: UserEpisodeBrowsingLog | null,
    public episodeBrowsingLogsCount?: number | null,
    public consumerUserEpisodeReadingFinishTime?: UserEpisodeReadingFinishTime | null,
  ) {}
}
