import {ViewStyle} from 'react-native';

import Gender from '../../value_objects/Gender';
import NameLabelColor from '../NameLabelColor';

export default class CharacterResourceOptions {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public containerStyle?: ViewStyle,
    public gender?: Gender,
    public transparent?: boolean,
    public nameLabelColor?: NameLabelColor,
    public inverted?: boolean,
  ) {}
}
