import * as React from 'react';
import {Pressable, StyleSheet, View, ViewStyle} from 'react-native';

import CloseIcon from '../../../../../../../presentation/components/shared/icons/CloseIcon';

import {colors} from '../../../../styles/variables';

interface Props {
  onCloseModal: () => void;
  children?: React.ReactNode;
}

const ListModal: React.FunctionComponent<Props> = props => {
  const {onCloseModal, children} = props;
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.headerRight}>
          <Pressable style={styles.close} onPress={onCloseModal}>
            <CloseIcon size={12.7} color={colors.lightGray} />
          </Pressable>
        </View>
      </View>
      <View>{children}</View>
    </View>
  );
};

export default React.memo(ListModal);

const styles = StyleSheet.create({
  close: {
    alignItems: 'center',
    width: 44,
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
  container: {
    alignSelf: 'flex-end',
    backgroundColor: 'white',
    width: '100%',
  } as ViewStyle,
  header: {
    backgroundColor: '#fbfbfb',
    borderBottomWidth: 1,
    borderColor: '#d5d5d5',
    flexDirection: 'row',
    height: 44,
    justifyContent: 'center',
    width: '100%',
  } as ViewStyle,
  headerRight: {
    alignItems: 'center',
    height: 44,
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    width: 44,
  } as ViewStyle,
});
