import * as React from 'react';
import {StyleSheet, ViewStyle} from 'react-native';

import * as Immutable from 'immutable';

import CharacterWithMarkView from './parts/CharacterWithMarkView';

import CharacterHideCommand from '../../../../../domain/entities/commands/CharacterHideCommand';
import CharacterShowCommand from '../../../../../domain/entities/commands/CharacterShowCommand';
import CharacterUpdateCommand from '../../../../../domain/entities/commands/CharacterUpdateCommand';

import Position from '../../../../../domain/value_objects/Position';

interface Size {
  height: number;
  width: number;
}

export interface Props {
  position: Position;
  left: number;
  right: number;
  characterCommand:
    | CharacterShowCommand
    | CharacterUpdateCommand
    | CharacterHideCommand
    | null;
  characterSize: Size;
  markSize: Size;
  currentSpeechBalloonPositions: Position[] | null;
  active?: boolean;
  scaleUp?: boolean;
  onAfterRenderCharacter: (
    command:
      | CharacterShowCommand
      | CharacterUpdateCommand
      | CharacterHideCommand,
  ) => void;
}

export interface State {
  prevLeft: number;
  prevRight: number;
}

export default class StagePositionBase extends React.Component<Props, State> {
  private mounted = false;

  private callback: (() => void) | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      prevLeft: props.left,
      prevRight: props.right,
    };
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    return !(
      this.props.position === nextProps.position &&
      this.props.left === nextProps.left &&
      this.props.right === nextProps.right &&
      this.props.characterCommand === nextProps.characterCommand &&
      Immutable.is(
        this.props.currentSpeechBalloonPositions,
        nextProps.currentSpeechBalloonPositions,
      ) &&
      this.props.active === nextProps.active &&
      this.state.prevLeft === nextState.prevLeft &&
      this.state.prevRight === nextState.prevRight
    );
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    if (!this.props.characterCommand) {
      return;
    }
    if (prevProps.characterCommand !== this.props.characterCommand) {
      if (this.props.characterCommand instanceof CharacterHideCommand) {
        this.resetAnimationValues();
        return;
      } else if (this.props.characterCommand instanceof CharacterShowCommand) {
        this.finishCallback();
        return;
      }
    }
    if (
      prevProps.left !== this.props.left &&
      prevProps.right !== this.props.right
    ) {
      if (
        this.props.position === Position.Center &&
        prevProps.left === prevProps.right &&
        this.props.left === this.props.right
      ) {
        this.finishCallback();
      } else {
        this.move();
      }
    } else if (prevProps.left !== this.props.left) {
      this.moveLeft();
    } else if (prevProps.right !== this.props.right) {
      this.moveRight();
    } else if (this.callback) {
      if (this.props.active) {
        const callback = this.callback;
        setTimeout(callback, 50);
        this.callback = null;
      } else {
        this.move();
      }
    }
  }

  public setCallback = (callback: () => void) => {
    this.callback = callback;
  };

  protected renderContent(): React.ReactNode {
    const {
      position,
      characterCommand,
      characterSize,
      markSize,
      currentSpeechBalloonPositions,
      active,
      scaleUp,
      onAfterRenderCharacter,
    } = this.props;
    return (
      <CharacterWithMarkView
        position={position}
        key={characterCommand?.character.id}
        characterCommand={characterCommand}
        characterSize={characterSize}
        markSize={markSize}
        currentSpeechBalloonPositions={currentSpeechBalloonPositions}
        active={active}
        scaleUp={scaleUp}
        setCallback={this.setCallback}
        onAfterRenderCharacter={onAfterRenderCharacter}
      />
    );
  }
  protected move = () => {};

  protected moveLeft = () => {};

  protected moveRight = () => {};

  protected resetAnimationValues = () => {};

  protected finishCallback = () => {
    if (this.mounted) {
      if (
        this.state.prevLeft === this.props.left &&
        this.state.prevRight === this.props.right
      ) {
        return this.finishCallbackAfterStateChange();
      }
      this.setState(
        {prevLeft: this.props.left, prevRight: this.props.right},
        this.finishCallbackAfterStateChange,
      );
    }
  };

  private finishCallbackAfterStateChange = () => {
    if (this.callback) {
      const callback = this.callback;
      setTimeout(callback, 200);
      this.callback = null;
    }
    this.resetAnimationValues();
  };
}

const styles = StyleSheet.create({
  container: {
    bottom: 0,
    overflow: 'visible',
    position: 'absolute',
    top: 0,
  } as ViewStyle,
});
