import * as React from 'react';
import {
  LayoutChangeEvent,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import NameLabelFrame from './NameLabelFrame';

import NameLabelColor from '../../../domain/value_objects/NameLabelColor';

import {isMobile} from '../../../data/data_stores/net/UserAgent';

const NAME_TO_TEXT_WIDTH: {[key: string]: number} = {};

interface Props {
  name: string;
  windowWidth: number;
  defaultTextWidth?: number;
  height?: number;
  fontSize?: number;
  active?: boolean;
  color?: NameLabelColor;
  children?: React.ReactNode;
}

const NameLabel: React.FunctionComponent<Props> = props => {
  const {name, active, windowWidth, defaultTextWidth, color, children} = props;
  const [textWidth, setTextWidth] = React.useState<number | undefined>(
    defaultTextWidth || NAME_TO_TEXT_WIDTH[name],
  );
  const nameStyle = React.useMemo(() => {
    const fontSize =
      props.fontSize || (windowWidth > 1000 ? 30 : windowWidth > 291 ? 13 : 11);
    const position = textWidth ? 'absolute' : undefined;
    const bottom = windowWidth > 1000 ? 5 : 3;
    const left = windowWidth > 1000 ? 15 : 10;
    return [
      styles.name,
      {fontSize, position, bottom, left, top: 0} as TextStyle,
    ];
  }, [textWidth]);
  const height = props.height || (windowWidth > 1000 ? 50 : 21);
  const onLayout = React.useCallback(
    (e: LayoutChangeEvent) => {
      if (e.nativeEvent.layout.width === 0) {
        return;
      }
      const newTextWidth = Math.max(
        e.nativeEvent.layout.width,
        windowWidth > 1000 ? 140 : 70,
      );
      NAME_TO_TEXT_WIDTH[name] = newTextWidth;
      setTextWidth(newTextWidth);
    },
    [name],
  );
  return (
    <View style={styles.container}>
      {textWidth && (
        <NameLabelFrame
          textWidth={textWidth}
          height={height}
          color={color}
          active={true}
        />
      )}
      {children || (
        <Text style={nameStyle} onLayout={onLayout}>
          {name}
        </Text>
      )}
      {textWidth && !active ? (
        <View style={styles.shadow}>
          <NameLabelFrame
            textWidth={textWidth}
            height={height}
            color={color}
            active={false}
          />
        </View>
      ) : null}
    </View>
  );
};

const propsAreEqual = (
  prevProps: Readonly<Props>,
  nextProps: Readonly<Props>,
) =>
  prevProps.name === nextProps.name &&
  prevProps.active === nextProps.active &&
  prevProps.color === nextProps.color;

export default React.memo(NameLabel, propsAreEqual);

const styles = StyleSheet.create({
  container: {
    width: Platform.select({web: 'fix-content', default: '100%'}),
    alignItems: 'flex-start',
  } as ViewStyle,
  shadow: {
    position: 'absolute',
    opacity: 0.5,
    top: 0,
    left: 0,
  } as ViewStyle,
  name: {
    left: 10,
    color: 'white',
    letterSpacing: 1.0,
    flexWrap: 'nowrap',
    ...(isMobile ? {} : {fontFamily: 'sans-serif'}),
  } as TextStyle,
});
