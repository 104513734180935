import * as React from 'react';
import {
  AppState,
  AppStateStatus,
  BackHandler,
  NativeEventSubscription,
  StyleSheet,
  ViewStyle,
} from 'react-native';
import 'react-native-get-random-values';
import {v4} from 'uuid';

import * as Immutable from 'immutable';

import DownloadProgress from './DownloadProgress';
import Footer from './Footer';
import FullScreen from './FullScreen';
import InterceptForStarting from './InterceptForStarting';
import Header from './Header';
import PreloadLocalImages from './PreloadLocalImages';
import Scenario from './Scenario';
import Stage from './Stage';
import SoundEffect from './SoundEffect';
import BackgroundMusic from './BackgroundMusic';
import Tap from './Tap';
import AutoPlayButton from './AutoPlayButton';
import SoundPlayButton from './SoundPlayButton';

import EnabledSoundContext from '../../shared/EnabledSoundContext';

import Frame from '../../../view_models/Frame';
import ViewerLayoutManager from '../../../view_models/ViewerLayoutManager';
import ImagePreloader from '../../../view_models/ImagePreloader';

import BackgroundShowCommand from '../../../../domain/entities/commands/BackgroundShowCommand';
import CharacterHideCommand from '../../../../domain/entities/commands/CharacterHideCommand';
import CharacterShowCommand from '../../../../domain/entities/commands/CharacterShowCommand';
import CharacterUpdateCommand from '../../../../domain/entities/commands/CharacterUpdateCommand';
import ClearCommand from '../../../../domain/entities/commands/ClearCommand';
import Command from '../../../../domain/entities/commands/Command';
import DescriptiveTextShowCommand from '../../../../domain/entities/commands/DescriptiveTextShowCommand';
import EffectShowCommand from '../../../../domain/entities/commands/EffectShowCommand';
import FullScreenIllustrationShowCommand from '../../../../domain/entities/commands/FullScreenIllustrationShowCommand';
import IllustrationShowCommand from '../../../../domain/entities/commands/IllustrationShowCommand';
import NopCommand from '../../../../domain/entities/commands/NopCommand';
import SoundEffectShowCommand from '../../../../domain/entities/commands/SoundEffectShowCommand';
import BackgroundMusicShowCommand from '../../../../domain/entities/commands/BackgroundMusicShowCommand';
import BackgroundMusicHideCommand from '../../../../domain/entities/commands/BackgroundMusicHideCommand';
import SpeechTextShowCommand from '../../../../domain/entities/commands/SpeechTextShowCommand';
import SceneScript from '../../../../domain/entities/SceneScript';

import SceneTapForm from '../../../../domain/forms/SceneTapForm';
import CompositeSequenceCommand from '../../../../domain/entities/commands/CompositeSequenceCommand';
import CompositeParallelCommand from '../../../../domain/entities/commands/CompositeParallelCommand';

interface Point {
  x: number;
  y: number;
}

export interface CommonProps {
  textSpeed: 'slow' | 'normal' | 'fast' | 'no_effect';
  forceTextSpeed?: 'slow' | 'normal' | 'fast' | 'no_effect';
  autoPlaySpeed: 0 | 1 | 1.5 | 2;
  enabledSound: boolean;
  initialCommandId?: number;
  forceForward?: boolean;
  forceCurrentIndex?: number;
  mode?: 'normal' | 'video';
  orientation: 'horizontal' | 'vertical';
  preloadAll?: boolean;
  visibleProgressBar: boolean;
  visibleInterceptForStarting?: boolean;
  hiddenDownloadProgress?: boolean;
  watermarked?: boolean;
  renderMenuCommands?: (options?: {
    command?: {id: number; sceneId: number} | null;
    disableNextScene?: boolean;
    disablePrevScene?: boolean;
    onPressNextScene?: () => void;
    onPressPrevScene?: () => void;
  }) => React.ReactNode;
  renderDownloadProgressModalTop?: (width: number) => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  onStart?: (SceneScript: SceneScript) => void;
  onFinish?: (SceneScript: SceneScript) => void;
  onSuspend?: (command: {id: number; sceneId: number} | null) => void;
  onLoadFail?: (error?: any) => void;
  onRequestClose?: () => void;
  onTouch?: (sceneTapForm: SceneTapForm) => void;
  onCommandIndexChange?: (
    commandIndex: number,
    lastCommandIndex: number,
  ) => void;
  onVisibleProgressBarChange?: (value: boolean) => void;
  onTextSpeedChange?: (
    textSpeed: 'slow' | 'normal' | 'fast' | 'no_effect',
  ) => void;
  onAutoPlaySpeedChange?: (value: 0 | 1 | 1.5 | 2) => void;
  onEnabledSoundChange?: (enabledSound: boolean) => void;
}

interface Props extends CommonProps {
  sceneScript: SceneScript;
  layoutManager: ViewerLayoutManager;
}

interface State {
  currentIndex: number;
  sequenceCurrentIndex: number | undefined;
  point: Point | null;
  starting: boolean;
  visibleInterceptForStarting?: boolean;
  skipRenderText: boolean;
  playingVoice: boolean;
  playingSoundEffect: boolean;
}

const ORIENTATION_HORIZONTAL = 'horizontal';
const MODE_VIDEO = 'video';

export default class Viewer extends React.Component<Props, State> {
  private mounted = false;
  private pausing = false;
  private finished = false;
  private commandStack: Command[] = [];
  private compositeCommandDoneCounter = 0;
  private firstIndex = 0;
  private currentFrame = new Frame();
  private currentFrameNumber = 0;
  private nextAutoPlayTimerId: any | null = null;
  private renderingText = false;
  private renderingIllustration = false;
  private renderingFullScreenIllustration = false;
  private renderingEffect = false;
  private stopAutoPlay = false;
  private defaultAutoPlaySpeedToTime = {
    1: 1200,
    1.5: 900,
    2: 600,
  };
  private voiceAutoPlaySpeedToTime = {
    1: 300,
    1.5: 200,
    2: 100,
  };

  private appStateChangeSubscription: NativeEventSubscription | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      currentIndex: -1,
      sequenceCurrentIndex: undefined,
      point: null,
      starting: false,
      visibleInterceptForStarting:
        props.mode === MODE_VIDEO ? false : props.visibleInterceptForStarting,
      skipRenderText: false,
      playingVoice: false,
      playingSoundEffect: false,
      ...this.setupCurrentIndex(props),
    };
  }

  public shouldComponentUpdate(nextProps: Props, nextState: State): boolean {
    return !(
      nextProps.visibleProgressBar === this.props.visibleProgressBar &&
      nextProps.textSpeed === this.props.textSpeed &&
      nextProps.autoPlaySpeed === this.props.autoPlaySpeed &&
      nextProps.enabledSound === this.props.enabledSound &&
      Immutable.is(nextState, this.state)
    );
  }

  public componentDidMount() {
    this.mounted = true;
    BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
    this.appStateChangeSubscription = AppState.addEventListener(
      'change',
      this.handleAppStateChange,
    );
  }

  public componentWillUnmount() {
    this.mounted = false;
    BackHandler.removeEventListener('hardwareBackPress', this.handleBackPress);
    this.appStateChangeSubscription?.remove();
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    const {sceneScript, onCommandIndexChange} = this.props;
    const lastCommandIndex = sceneScript.commands.length - 1;
    if (this.state.starting) {
      this.handleClear(prevState);
      this.handleNop(prevState);
      this.prefetchNextSceneResources(prevState);
    }
    if (
      onCommandIndexChange &&
      this.state.currentIndex !== prevState.currentIndex
    ) {
      onCommandIndexChange(this.state.currentIndex, lastCommandIndex);
    }
  }

  public render(): React.ReactNode {
    const {
      sceneScript,
      layoutManager,
      visibleProgressBar,
      textSpeed,
      enabledSound,
      autoPlaySpeed,
      mode,
      orientation,
      preloadAll,
      hiddenDownloadProgress,
      watermarked,
      renderMenuCommands,
      renderDownloadProgressModalTop,
      renderFooter,
      onVisibleProgressBarChange,
      onTextSpeedChange,
      onAutoPlaySpeedChange,
      onEnabledSoundChange,
    } = this.props;
    const {
      currentIndex,
      skipRenderText,
      point,
      starting,
      visibleInterceptForStarting,
      playingVoice,
      playingSoundEffect,
    } = this.state;
    const commands = this.commandStack;
    const currentCommand = this.getCurrentCommand();
    const frame = this.generateFrame(commands);
    return (
      <EnabledSoundContext.Provider value={enabledSound}>
        <>
          <PreloadLocalImages />
          <Stage
            style={styles.stage}
            frame={frame}
            currentCommand={currentCommand}
            layoutManager={layoutManager}
            skipRenderText={skipRenderText}
            playingSound={
              playingSoundEffect &&
              (mode === MODE_VIDEO
                ? this.hasSoundCommand(currentCommand)
                : true)
            }
            orientation={orientation}
            watermarked={watermarked}
            onAfterRenderBackground={this.handleAfterRenderBackground}
            onAfterRenderCharacter={this.handleAfterRenderCharacter}
            onBeforeRenderIllustration={this.handleBeforeRenderIllustration}
            onAfterRenderIllustration={this.handleAfterRenderIllustration}
            onFinishPlay={this.handleFinishPlaySoundEffect}
            onBeforeRenderText={this.handleBeforeRenderText}
            onAfterRenderText={this.handleAfterRenderText}
            onFinishPlayVoice={this.handleFinishPlayVoice}
            onFinishPlaySound={this.handleFinishPlaySoundEffect}
            onBeforeRenderEffect={this.handleBeforeRenderEffect}
            onAfterRenderEffect={this.handleAfterRenderEffect}
            onTouch={this.handleTouchForTap}
          />
          <Scenario
            commands={commands}
            frame={frame}
            layoutManager={layoutManager}
            skipRenderText={skipRenderText}
            pausing={this.pausing}
            orientation={orientation}
            textSpeed={this.getTextSpeed()}
            visiblePrompt={mode !== MODE_VIDEO}
            visibleVoiceIcon={mode !== MODE_VIDEO}
            onBeforeRenderText={this.handleBeforeRenderText}
            onAfterRenderText={this.handleAfterRenderText}
            onFinishPlayVoice={this.handleFinishPlayVoice}
            onFinishPlaySound={this.handleFinishPlaySoundEffect}
            onTouch={this.handleTouchForTap}
            onScrolling={this.handleScrolling}
            onScrolledToTop={this.handleScrolledToTop}
          />
          <FullScreen
            command={frame.fullScreenIllustration}
            layoutManager={layoutManager}
            visiblePrompt={mode !== MODE_VIDEO}
            showTap={this.showTap}
            onTouch={this.handleTouchForTap}
            onBeforeRenderFullScreenIllustration={
              this.handleBeforeRenderFullScreenIllustration
            }
            onRenderFullScreenIllustration={
              this.handleRenderFullScreenIllustration
            }
            onAfterRenderFullScreenIllustration={
              this.handleAfterRenderFullScreenIllustration
            }
            onFinishPlaySound={this.handleFinishPlaySoundEffect}
          />
          {starting && !visibleInterceptForStarting && mode !== MODE_VIDEO && (
            <Footer
              visibleProgressBar={visibleProgressBar}
              starting={starting}
              currentIndex={currentIndex}
              commandsLength={sceneScript.commands.length}
              renderFooter={renderFooter}
            />
          )}
          {mode !== MODE_VIDEO && sceneScript.hasSound() ? (
            <SoundPlayButton
              enabledSound={enabledSound}
              onEnabledSoundChange={onEnabledSoundChange}
            />
          ) : null}
          {mode !== MODE_VIDEO ? (
            <AutoPlayButton
              value={autoPlaySpeed}
              onValueChange={this.handleAutoPlaySpeedChange}
            />
          ) : null}
          <Tap
            point={point}
            onAfterAnimation={this.handleAfterAnimationForTap}
          />
          {mode !== MODE_VIDEO ? (
            <Header
              width={layoutManager.getSize().width}
              command={sceneScript.commands[currentIndex]}
              visibleProgressBar={visibleProgressBar}
              textSpeed={textSpeed}
              disabledTextSpeedSlider={autoPlaySpeed !== 0}
              renderMenuCommands={renderMenuCommands}
              onVisibleProgressBarChange={onVisibleProgressBarChange}
              onTextSpeedChange={onTextSpeedChange}
              disableNextScene={!this.getNextSceneId()}
              disablePrevScene={!this.getPrevSceneId()}
              onPressNextScene={this.handlePressNextScene}
              onPressPrevScene={this.handlePressPrevScene}
              onToggleMenu={this.handleToggleMenu}
            />
          ) : null}
          {visibleInterceptForStarting && (
            <InterceptForStarting
              scenarioHeight={
                layoutManager.getFullScreenSize().height -
                layoutManager.getStageSize().height
              }
              orientation={orientation}
              stageHeight={layoutManager.getStageSize().height}
              onPress={this.handlePressInterceptForStarting}
            />
          )}
          {!starting && (
            <DownloadProgress
              sceneScript={sceneScript}
              layoutManager={layoutManager}
              visible={!hiddenDownloadProgress}
              enabledSound={enabledSound}
              autoPlaySpeed={autoPlaySpeed}
              fullScreenWidth={layoutManager.getFullScreenSize().width}
              preloadAll={preloadAll}
              renderTop={renderDownloadProgressModalTop}
              onPress={this.handlePressDownloadProgressModal}
              onPressRetry={this.handlePressRetryDownloadProgressModal}
              onPressBack={this.handlePressBackDownloadProgressModal}
              onReady={this.handleReady}
              onLoadFail={this.handleLoadFail}
              onEnabledSoundChange={onEnabledSoundChange}
              onAutoPlaySpeedChange={onAutoPlaySpeedChange}
            />
          )}
          {currentCommand instanceof SoundEffectShowCommand && (
            <SoundEffect
              command={currentCommand}
              onBeforePlay={this.handleBeforePlaySoundEffect}
              onAfterPlay={this.handleAfterPlaySoundEffect}
              onFinishPlay={this.handleFinishPlaySoundEffect}
            />
          )}
          {frame.backgroundMusic && (
            <BackgroundMusic
              key={frame.backgroundMusic.sound.id}
              command={frame.backgroundMusic}
              volumeHalved={
                playingSoundEffect || playingVoice || this.keepPlayingAudio()
              }
              onAfterPlay={this.handleAfterPlayBackgroundMusic}
              onFinishPlay={this.handleFinishPlayBackgroundMusic}
            />
          )}
        </>
      </EnabledSoundContext.Provider>
    );
  }

  private pause = () => {
    const {sceneScript} = this.props;
    const {currentIndex, sequenceCurrentIndex} = this.state;
    const topLevelCurrentCommand = sceneScript.commands[currentIndex];
    const currentCommand = this.getCurrentCommand();
    if (currentCommand instanceof CompositeParallelCommand) {
      let finished = false;
      this.compositeCommandDoneCounter += 1;
      finished =
        this.compositeCommandDoneCounter >= currentCommand.commands.length;
      if (finished) {
        this.compositeCommandDoneCounter = 0;
      } else {
        return;
      }
    }
    if (sequenceCurrentIndex === undefined) {
      return this.pauseAfterInteractions();
    }
    if (!(topLevelCurrentCommand instanceof CompositeSequenceCommand)) {
      throw new Error('CompositeSequenceCommand error');
    }
    const sequenceNextIndex = sequenceCurrentIndex + 1;
    const sequenceCurrentCommand =
      topLevelCurrentCommand.commands[sequenceCurrentIndex];
    const sequenceNextCommand =
      topLevelCurrentCommand.commands[sequenceNextIndex];
    if (topLevelCurrentCommand.commands.length - 1 > sequenceCurrentIndex) {
      if (
        this.firstIndex === this.state.currentIndex &&
        this.pauseFirstCommand(sequenceNextCommand)
      ) {
        this.firstIndex = -1;
        if (this.props.autoPlaySpeed === 0) {
          this.pausing = true;
        }
        return;
      } else if (this.includeCharacterShowOrHide(sequenceCurrentCommand)) {
        setTimeout(this.proceed, 50);
      } else {
        this.proceed();
      }
    } else {
      this.pauseAfterInteractions();
    }
  };

  private pauseAfterInteractions = () => {
    const {autoPlaySpeed} = this.props;
    if (!this.state.starting) {
      return;
    }
    if (this.state.visibleInterceptForStarting) {
      return;
    }
    this.clearNextAutoPlayTimer();
    if (autoPlaySpeed !== 0 && !this.stopAutoPlay) {
      const currentCommand = this.getCurrentCommand();
      const hasVoice =
        (currentCommand instanceof SpeechTextShowCommand ||
          currentCommand instanceof DescriptiveTextShowCommand) &&
        currentCommand.voice;
      const defaultTime =
        (hasVoice && this.props.enabledSound
          ? this.voiceAutoPlaySpeedToTime[autoPlaySpeed]
          : this.defaultAutoPlaySpeedToTime[autoPlaySpeed]) || 1100;
      this.reserveNextAutoPlayTimer(defaultTime);
    } else {
      setTimeout(() => {
        this.pausing = true;
      }, 50);
    }
  };

  private unpause = (callback?: () => void) => {
    this.pausing = false;
    if (callback) {
      callback();
    }
  };

  private proceed = () => {
    const {sceneScript} = this.props;
    const {currentIndex, sequenceCurrentIndex} = this.state;
    if (this.isFinished()) {
      this.notifyFinished();
    } else {
      this.compositeCommandDoneCounter = 0;
      const topLevelCurrentCommand = sceneScript.commands[currentIndex];
      if (sequenceCurrentIndex !== undefined) {
        if (topLevelCurrentCommand instanceof CompositeSequenceCommand) {
          const sequenceNextIndex = sequenceCurrentIndex + 1;
          const sequenceNextCommand =
            topLevelCurrentCommand.commands[sequenceNextIndex];
          if (sequenceNextCommand) {
            this.commandStack.push(sequenceNextCommand);
            this.setStateIfMounted({
              sequenceCurrentIndex: sequenceNextIndex,
            });
            return;
          }
        }
      }

      const nextIndex = currentIndex + 1;
      const topLevelNextCommand = sceneScript.commands[nextIndex];
      if (topLevelNextCommand instanceof CompositeSequenceCommand) {
        const sequenceNextIndex = 0;
        const sequenceNextCommand =
          topLevelNextCommand.commands[sequenceNextIndex];
        if (sequenceNextCommand) {
          this.commandStack.push(sequenceNextCommand);
          this.setStateIfMounted({
            currentIndex: nextIndex,
            sequenceCurrentIndex: sequenceNextIndex,
            playingVoice: false,
            playingSoundEffect: false,
          });
          return;
        }
      } else if (topLevelNextCommand) {
        this.commandStack.push(topLevelNextCommand);
      }
      this.setStateIfMounted({
        currentIndex: nextIndex,
        sequenceCurrentIndex: undefined,
        playingVoice: false,
        playingSoundEffect: false,
      });
    }
  };

  private isFinished = () => {
    const {sceneScript} = this.props;
    const {currentIndex, sequenceCurrentIndex} = this.state;
    const lastCommand = sceneScript.commands[sceneScript.commands.length - 1];
    return (
      sceneScript.commands.length !== 0 &&
      sceneScript.commands.length - 1 <= currentIndex &&
      (!(lastCommand instanceof CompositeSequenceCommand) ||
        (lastCommand instanceof CompositeSequenceCommand &&
          sequenceCurrentIndex !== undefined &&
          lastCommand.commands.length - 1 <= sequenceCurrentIndex))
    );
  };

  private notifyFinished = () => {
    const {sceneScript, onFinish} = this.props;
    if (!this.finished) {
      this.finished = true;
      if (onFinish) {
        onFinish(sceneScript);
      }
    }
  };

  private handleAfterRenderBackground = (command: BackgroundShowCommand) => {
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    this.pause();
  };

  private handleAfterRenderCharacter = (
    command:
      | CharacterShowCommand
      | CharacterUpdateCommand
      | CharacterHideCommand,
  ) => {
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    this.pause();
  };

  private handleBeforeRenderIllustration = (
    command: IllustrationShowCommand,
  ) => {
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    this.renderingIllustration = true;
    if (command.sound) {
      this.setStateIfMounted({playingSoundEffect: true});
    }
    this.unpause();
  };

  private handleAfterRenderIllustration = (
    command: IllustrationShowCommand,
  ) => {
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    this.renderingIllustration = false;
    if (command.sound) {
      if (!this.state.playingSoundEffect) {
        this.pause();
      } else if (!this.props.autoPlaySpeed) {
        this.pausing = true;
      }
    } else {
      this.pause();
    }
  };

  private handleBeforeRenderFullScreenIllustration = (
    command: FullScreenIllustrationShowCommand,
  ) => {
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    this.renderingFullScreenIllustration = true;
    if (command.sound) {
      this.setStateIfMounted({playingSoundEffect: true});
    }
    this.unpause();
  };

  private handleRenderFullScreenIllustration = (
    command: FullScreenIllustrationShowCommand,
    success: () => void,
  ) => {
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    if (this.props.autoPlaySpeed !== 0 && !this.stopAutoPlay) {
      setTimeout(() => {
        this.renderingFullScreenIllustration = false;
        if (command.sound) {
          if (!this.state.playingSoundEffect) {
            success();
          } else if (!this.props.autoPlaySpeed) {
            this.pausing = true;
          }
        } else {
          success();
        }
      }, 1900);
    } else {
      this.pausing = true;
      this.renderingFullScreenIllustration = false;
    }
  };

  private handleAfterRenderFullScreenIllustration = (
    command: FullScreenIllustrationShowCommand,
  ) => {
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    this.proceed();
  };

  private handleBeforeRenderText = (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => {
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    this.renderingText = true;
    if (command.voice || command.sound) {
      this.setStateIfMounted({
        playingVoice: !!command.voice,
        playingSoundEffect: !!command.sound,
      });
    }
    this.unpause();
  };

  private handleAfterRenderText = (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => {
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    this.renderingText = false;
    if ((command.voice || command.sound) && this.props.enabledSound) {
      if (!this.state.playingVoice && !this.state.playingSoundEffect) {
        this.setStateIfMounted(
          {
            skipRenderText: false,
            playingVoice: false,
            playingSoundEffect: false,
          },
          this.pause,
        );
      } else if (!this.props.autoPlaySpeed) {
        this.setStateIfMounted({skipRenderText: false});
        this.pausing = true;
      }
    } else {
      this.setStateIfMounted(
        {skipRenderText: false, playingVoice: false, playingSoundEffect: false},
        this.pause,
      );
    }
  };

  private handleFinishPlayVoice = (
    command: DescriptiveTextShowCommand | SpeechTextShowCommand,
  ) => {
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    this.setStateIfMounted(
      {playingVoice: false},
      this.renderingText || this.state.playingSoundEffect
        ? undefined
        : this.pause,
    );
  };

  private handleClear = (prevState: Readonly<State>) => {
    const currentCommand = this.getCurrentCommand();
    if (
      (prevState.currentIndex !== this.state.currentIndex ||
        prevState.sequenceCurrentIndex !== this.state.sequenceCurrentIndex) &&
      currentCommand instanceof ClearCommand
    ) {
      setTimeout(this.proceed, 400);
    }
  };

  private handleNop = (prevState: Readonly<State>) => {
    const currentCommand = this.getCurrentCommand();
    if (
      (prevState.currentIndex !== this.state.currentIndex ||
        prevState.sequenceCurrentIndex !== this.state.sequenceCurrentIndex) &&
      currentCommand instanceof NopCommand
    ) {
      this.pause();
    }
  };

  private handleBeforeRenderEffect = (command: EffectShowCommand) => {
    if (command.sound) {
      this.setStateIfMounted({playingSoundEffect: true});
    }
    this.renderingEffect = true;
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    if (this.props.autoPlaySpeed === 0) {
      this.pause();
    }
  };

  private handleAfterRenderEffect = (command: EffectShowCommand) => {
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    this.renderingEffect = false;
    if (command.sound) {
      if (!this.state.playingSoundEffect) {
        this.pause();
      } else if (!this.props.autoPlaySpeed) {
        this.pausing = true;
      }
    } else {
      this.pause();
    }
  };

  private handleBeforePlaySoundEffect = (command: SoundEffectShowCommand) => {
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    this.setStateIfMounted({playingSoundEffect: true});
    if (!this.props.autoPlaySpeed) {
      this.pausing = true;
    }
  };

  private handleAfterPlaySoundEffect = (command: SoundEffectShowCommand) => {
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    this.pause();
  };

  private handleFinishPlaySoundEffect = (
    command:
      | SoundEffectShowCommand
      | IllustrationShowCommand
      | FullScreenIllustrationShowCommand
      | EffectShowCommand
      | SpeechTextShowCommand
      | DescriptiveTextShowCommand,
    success?: () => void,
  ) => {
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    this.setStateIfMounted(
      {playingSoundEffect: false},
      (command instanceof IllustrationShowCommand &&
        !this.renderingIllustration) ||
        (command instanceof FullScreenIllustrationShowCommand &&
          !this.renderingFullScreenIllustration) ||
        (command instanceof EffectShowCommand && !this.renderingEffect) ||
        ((command instanceof SpeechTextShowCommand ||
          command instanceof DescriptiveTextShowCommand) &&
          !this.renderingText &&
          !this.state.playingVoice)
        ? success || this.pause
        : undefined,
    );
  };

  private handleAfterPlayBackgroundMusic = (
    command: BackgroundMusicShowCommand | BackgroundMusicHideCommand,
  ) => {
    if (!this.matchedCurrentCommand(command)) {
      return;
    }
    if (command instanceof BackgroundMusicShowCommand) {
      const {sceneScript} = this.props;
      const {currentIndex} = this.state;
      const nextCurrentIndex = currentIndex + 1;
      const nextCommand = sceneScript.commands[nextCurrentIndex];
      if (nextCommand instanceof BackgroundMusicShowCommand) {
        setTimeout(this.proceed, 3000);
      } else if (nextCommand instanceof BackgroundMusicHideCommand) {
        this.pause();
      } else {
        this.proceed();
      }
    } else {
      this.pause();
    }
  };

  private handleFinishPlayBackgroundMusic = (
    command: BackgroundMusicShowCommand,
  ) => {};

  private matchedCurrentCommand = (command: Command) => {
    const currentCommand = this.getCurrentCommand();
    return (
      currentCommand === command ||
      currentCommand?.parent === command ||
      currentCommand === command.parent
    );
  };

  private handleAfterAnimationForTap = () => {
    this.setStateIfMounted({point: null});
  };

  private handleTouchForTap = (point: Point) => {
    const {skipRenderText} = this.state;
    if (!this.props.autoPlaySpeed) {
      if (this.renderingText && !skipRenderText) {
        this.setStateIfMounted({skipRenderText: true});
      } else if (this.pausing) {
        this.unpause(this.proceed);
      }
    }
    this.showTap(point);
  };

  private showTap = (point: Point) => {
    this.setStateIfMounted({point});
    this.sendTap(point);
  };

  private sendTap = (point: Point) => {
    const {onTouch} = this.props;
    if (onTouch && !this.finished) {
      const currentCommand = this.getCurrentCommand();
      if (currentCommand) {
        onTouch(
          new SceneTapForm(
            v4(),
            currentCommand.sceneId,
            {id: currentCommand.id},
            point.x,
            point.y,
            new Date(),
          ),
        );
      }
    }
  };

  private generateFrame(commands: Command[]): Frame {
    const frame = this.currentFrame;
    if (this.currentFrameNumber === commands.length) {
      return frame;
    }
    commands
      .slice(this.currentFrameNumber)
      .forEach(command => frame.update(command));
    this.currentFrame = frame;
    this.currentFrameNumber = commands.length;
    return frame;
  }

  private getCurrentCommand(): Command | null {
    return this.commandStack[this.commandStack.length - 1];
  }

  private keepPlayingAudio() {
    const {sceneScript} = this.props;
    const {currentIndex} = this.state;
    if (this.props.autoPlaySpeed === 0) {
      return false;
    }
    const currentCommand = sceneScript.commands[currentIndex];
    const nextCommand = sceneScript.commands[currentIndex + 1];
    return (
      this.commandHasAudio(currentCommand) && this.commandHasAudio(nextCommand)
    );
  }

  private commandHasAudio(command: Command | null): boolean {
    if (!command) {
      return false;
    }
    if (
      command instanceof CompositeSequenceCommand ||
      command instanceof CompositeParallelCommand
    ) {
      return command.commands.some(subcommand =>
        this.commandHasAudio(subcommand),
      );
    }
    return !!((command as any).sound || (command as any).voice);
  }

  private setStateIfMounted<K extends keyof State>(
    state: Pick<State, K>,
    callback?: () => void,
  ) {
    if (!this.mounted) {
      return;
    }
    for (const key in state) {
      if (state[key] == this.state[key]) {
        delete state[key];
      }
    }
    if (Object.keys(state).length === 0) {
      callback && callback();
      return;
    }
    this.setState(state, callback);
  }

  private handlePressDownloadProgressModal = () => {
    this.skipFirstTapIfNeed();
    setTimeout(() => {
      this.setStateIfMounted({starting: true}, () => {
        this.firstIndex = this.state.currentIndex;
        const {sceneScript, onStart} = this.props;
        if (onStart) {
          onStart(sceneScript);
        }
        if (!this.state.visibleInterceptForStarting) {
          this.handleFirstTap(400);
        }
        this.tapCurrentCommandForOriginalPoint();
      });
    }, 100);
  };

  private skipFirstTapIfNeed = () => {
    const {sceneScript} = this.props;
    const {currentIndex} = this.state;
    if (currentIndex === -1 && this.skipFirstTap(sceneScript.commands[0])) {
      this.proceed();
    }
  };

  private skipFirstTap = (command: Command | null): boolean => {
    if (
      command instanceof BackgroundShowCommand ||
      command instanceof ClearCommand ||
      command instanceof NopCommand
    ) {
      return true;
    } else if (
      command instanceof CharacterShowCommand &&
      command.options.waiting
    ) {
      return true;
    } else if (
      command instanceof CompositeParallelCommand ||
      command instanceof CompositeSequenceCommand
    ) {
      return this.skipFirstTap(command.commands[0]);
    }
    return false;
  };

  private handlePressRetryDownloadProgressModal = () => {};

  private handlePressBackDownloadProgressModal = () => {
    const {onRequestClose} = this.props;
    if (onRequestClose) {
      onRequestClose();
    }
  };

  private handlePressInterceptForStarting = () => {
    if (!this.state.starting) {
      return;
    }
    this.setStateIfMounted({visibleInterceptForStarting: false}, () => {
      this.handleFirstTap();
      this.tapCurrentCommandForOriginalPoint();
    });
  };

  private handleFirstTap = (delay = 200) => {
    const {autoPlaySpeed} = this.props;
    const {sequenceCurrentIndex} = this.state;
    const currentCommand = this.getCurrentCommand();
    if (autoPlaySpeed) {
      this.reserveNextAutoPlayTimer(delay);
    } else if (
      sequenceCurrentIndex !== undefined ||
      currentCommand === undefined ||
      currentCommand instanceof BackgroundShowCommand ||
      currentCommand instanceof ClearCommand ||
      currentCommand instanceof NopCommand
    ) {
      setTimeout(() => this.unpause(this.proceed), delay);
    } else {
      this.pausing = true;
    }
  };

  private tapCurrentCommandForOriginalPoint = () => {
    const {onTouch} = this.props;
    if (!onTouch) {
      return;
    }
    const currentCommand = this.getCurrentCommand();
    if (currentCommand) {
      onTouch(
        new SceneTapForm(
          v4(),
          currentCommand.sceneId,
          {id: currentCommand.id},
          0,
          0,
          new Date(),
        ),
      );
    }
  };

  private handlePressNextScene = () => {
    this.goToScene(this.getNextSceneId());
  };

  private handlePressPrevScene = () => {
    this.goToScene(this.getPrevSceneId());
  };

  private goToScene = (sceneId: number | null) => {
    const {sceneScript} = this.props;
    if (!sceneId) {
      return;
    }
    this.prefetchSceneResources(sceneId);
    let currentIndex =
      sceneScript.commands.findIndex(command => command.sceneId === sceneId) -
      1;
    if (currentIndex < 0) {
      currentIndex = 0;
    }
    this.setStateIfMounted(this.synCommandStackAndFrame(currentIndex));
    if (this.props.autoPlaySpeed === 0) {
      this.pausing = true;
    }
    if (currentIndex === 0) {
      setTimeout(this.proceed, 500);
    }
  };

  private getNextSceneId = () => {
    const {sceneScript} = this.props;
    const currentCommand = sceneScript.commands[this.state.currentIndex];
    if (!currentCommand) {
      return null;
    }
    const foundCommand = sceneScript.commands.find(
      command =>
        command.id > currentCommand.id &&
        command.sceneId !== currentCommand.sceneId,
    );
    if (foundCommand) {
      return foundCommand.sceneId;
    } else {
      return null;
    }
  };

  private getPrevSceneId = () => {
    const {sceneScript} = this.props;
    const currentCommand = sceneScript.commands[this.state.currentIndex];
    if (!currentCommand) {
      return null;
    }
    const foundCommand = [...sceneScript.commands]
      .reverse()
      .find(
        command =>
          command.id < currentCommand.id &&
          command.sceneId !== currentCommand.sceneId,
      );
    if (foundCommand) {
      return foundCommand.sceneId;
    } else {
      return null;
    }
  };

  private setupCurrentIndex = (
    props: Readonly<Props>,
  ): {currentIndex?: number; sequenceCurrentIndex?: number} => {
    const {sceneScript, initialCommandId, forceForward, forceCurrentIndex} =
      props;
    if (forceCurrentIndex) {
      const nextState = this.synCommandStackAndFrame(forceCurrentIndex);
      return nextState;
    }
    const currentIndex = this.getCalibratedInitialIndex(
      initialCommandId,
      sceneScript,
    );
    if (forceForward && currentIndex) {
      const nextState = this.synCommandStackAndFrame(currentIndex);
      return nextState;
    }
    return {};
  };

  private getCalibratedInitialIndex = (
    initialCommandId: number | undefined,
    sceneScript: SceneScript,
  ) => {
    if (!initialCommandId) {
      return null;
    }
    if (sceneScript.id === -1) {
      return null;
    }
    const initialCommand = sceneScript.commands.find(
      command => command.id === initialCommandId,
    );
    if (!initialCommand) {
      return null;
    }
    const foundIndex =
      sceneScript.commands.findIndex(
        command => command.sceneId === initialCommand.sceneId,
      ) - 1;
    if (foundIndex > 0) {
      return foundIndex;
    } else {
      return null;
    }
  };

  private handleAppStateChange = (nextAppState: AppStateStatus) => {
    this.abortViewer();
  };

  private handleBackPress = (): boolean | null | undefined => {
    this.abortViewer();
    return;
  };

  private abortViewer = () => {
    const {sceneScript, onSuspend} = this.props;
    const {currentIndex} = this.state;
    const currentCommand = sceneScript.commands[currentIndex];
    if (!onSuspend) {
      return;
    }
    if (!currentCommand) {
      return;
    }
    onSuspend(currentCommand);
  };

  private handleReady = () => {};

  private handleLoadFail = (e: any) => {
    const {onLoadFail} = this.props;
    if (onLoadFail) {
      onLoadFail(e);
    }
  };

  private synCommandStackAndFrame = (currentIndex: number) => {
    const {sceneScript} = this.props;
    this.syncCommandStack(currentIndex);
    this.syncFrame();
    const lastCommand = sceneScript.commands[currentIndex];
    const sequenceCurrentIndex =
      lastCommand instanceof CompositeSequenceCommand ? 0 : undefined;
    return {currentIndex, sequenceCurrentIndex};
  };

  private syncCommandStack = (currentIndex: number) => {
    const {sceneScript} = this.props;
    this.commandStack = [];
    const commands = sceneScript.commands.slice(0, currentIndex + 1);
    commands.forEach((command, i) => {
      if (command instanceof CompositeSequenceCommand) {
        if (commands.length - 1 !== i) {
          command.commands.forEach(subCommand => {
            this.commandStack.push(subCommand);
          });
        } else if (command.commands[0]) {
          this.commandStack.push(command.commands[0]);
        }
      } else {
        this.commandStack.push(command);
      }
    });
  };

  private syncFrame = () => {
    this.currentFrame = new Frame();
    this.commandStack.forEach(command => {
      this.currentFrame.update(command);
    });
    this.currentFrame.fullScreenIllustration = null;
    this.currentFrameNumber = this.commandStack.length;
  };

  private includeCharacterShowOrHide = (command: Command | null): boolean => {
    if (
      command instanceof CharacterShowCommand ||
      command instanceof CharacterHideCommand
    ) {
      return true;
    } else if (
      command instanceof CompositeParallelCommand ||
      command instanceof CompositeSequenceCommand
    ) {
      return command.commands.some(subCommand =>
        this.includeCharacterShowOrHide(subCommand),
      );
    }
    return false;
  };

  private pauseFirstCommand = (command: Command | null): boolean => {
    if (
      command instanceof SpeechTextShowCommand ||
      command instanceof DescriptiveTextShowCommand ||
      command instanceof EffectShowCommand ||
      command instanceof IllustrationShowCommand ||
      command instanceof FullScreenIllustrationShowCommand ||
      command instanceof SoundEffectShowCommand
    ) {
      return true;
    } else if (
      command instanceof CompositeParallelCommand ||
      command instanceof CompositeSequenceCommand
    ) {
      return command.commands.some(subCommand =>
        this.pauseFirstCommand(subCommand),
      );
    }
    return false;
  };

  private handleAutoPlaySpeedChange = (value: 0 | 1 | 1.5 | 2) => {
    const {onAutoPlaySpeedChange} = this.props;
    const {autoPlaySpeed: prevValue} = this.props;
    onAutoPlaySpeedChange && onAutoPlaySpeedChange(value);

    if (
      prevValue === 0 &&
      value === 1 &&
      !this.renderingText &&
      !this.state.playingVoice &&
      !this.state.playingSoundEffect
    ) {
      this.proceed();
    } else if (
      value === 0 &&
      (this.renderingText ||
        this.state.playingVoice ||
        this.state.playingSoundEffect)
    ) {
      this.pausing = true;
    }
  };

  private getTextSpeed = (): 'slow' | 'normal' | 'fast' | 'no_effect' => {
    const {textSpeed, forceTextSpeed} = this.props;
    const {autoPlaySpeed} = this.props;
    if (forceTextSpeed) {
      return forceTextSpeed;
    }
    switch (autoPlaySpeed) {
      case 0:
        return textSpeed;
      case 1:
        return 'slow';
      case 1.5:
        return 'normal';
      case 2:
        return 'fast';
      default:
        return textSpeed;
    }
  };

  private handleToggleMenu = (visible: boolean, requiredRestart?: boolean) => {
    const {playingSoundEffect, playingVoice} = this.state;
    this.stopAutoPlay = visible;
    if (visible) {
      this.clearNextAutoPlayTimer();
    } else {
      if (requiredRestart && this.props.autoPlaySpeed !== 0) {
        if (playingSoundEffect || playingVoice) {
          return;
        }
        this.reserveNextAutoPlayTimer(200);
      }
    }
  };

  private handleScrolling = () => {
    this.clearNextAutoPlayTimer();
    if (this.props.autoPlaySpeed !== 0) {
      this.stopAutoPlay = true;
    }
  };

  private handleScrolledToTop = () => {
    const {playingSoundEffect, playingVoice} = this.state;
    if (this.props.autoPlaySpeed !== 0) {
      this.stopAutoPlay = false;
      if (playingSoundEffect || playingVoice || this.renderingText) {
        return;
      }
      this.reserveNextAutoPlayTimer(200);
    }
  };

  private prefetchNextSceneResources = (prevState: Readonly<State>) => {
    const {sceneScript} = this.props;
    const {currentIndex} = this.state;
    if (prevState.currentIndex === this.state.currentIndex) {
      return;
    }
    const aheadCommand = sceneScript.commands[currentIndex + 5];
    if (!(aheadCommand instanceof ClearCommand)) {
      return;
    }
    const aheadCommandPlusOne = sceneScript.commands[currentIndex + 6];
    const nextSceneId = aheadCommandPlusOne.sceneId;
    if (!nextSceneId) {
      return;
    }
    this.prefetchSceneResources(nextSceneId);
  };

  private prefetchSceneResources = (sceneId: number) => {
    const {sceneScript, layoutManager} = this.props;
    const nextSceneScript = sceneScript.fetchBySceneId(sceneId);
    if (nextSceneScript) {
      ImagePreloader.preload([nextSceneScript], layoutManager);
    }
  };

  private reserveNextAutoPlayTimer = (time: number) => {
    this.nextAutoPlayTimerId = setTimeout(() => {
      this.nextAutoPlayTimerId = null;
      this.sendTap({x: 0, y: 0});
      this.proceed();
    }, time);
  };

  private clearNextAutoPlayTimer = () => {
    if (!this.nextAutoPlayTimerId) {
      return;
    }
    clearTimeout(this.nextAutoPlayTimerId);
    this.nextAutoPlayTimerId = null;
  };

  private hasSoundCommand = (command: Command | null): boolean => {
    if (command instanceof SoundEffectShowCommand) {
      return true;
    } else if (command instanceof IllustrationShowCommand && command.sound) {
      return true;
    } else if (command instanceof EffectShowCommand && command.sound) {
      return true;
    } else if (command instanceof SpeechTextShowCommand && command.sound) {
      return true;
    } else if (command instanceof DescriptiveTextShowCommand && command.sound) {
      return true;
    } else if (command instanceof CompositeSequenceCommand) {
      return command.commands.some(subCommand =>
        this.hasSoundCommand(subCommand),
      );
    } else {
      return false;
    }
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  stage: {
    backgroundColor: 'black',
    width: '100%',
  } as ViewStyle,
});
