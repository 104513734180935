import EntityMapper from './EntityMapper';

import CommandMapper, {CommandData} from './commands/CommandMapper';
import ResourceMapper, {ResourceData} from './resources/ResourceMapper';

import ResourcesSet from '../../../domain/entities/resources/ResourcesSet';

import SceneScript from '../../../domain/entities/SceneScript';

export interface SceneScriptData {
  id: number;
  story_id: number;
  version: number;
  resources: ResourceData[];
  commands: CommandData[];
}

export default class SceneScriptMapper
  implements EntityMapper<SceneScriptData, SceneScript>
{
  public map(obj: SceneScriptData): SceneScript {
    const resourcesSet = new ResourcesSet();
    const resourceMapper = new ResourceMapper(resourcesSet);
    const commandMapper = new CommandMapper(resourcesSet);
    obj.resources.forEach(resource => resourceMapper.map(resource));
    const commands = obj.commands.map((command, id) =>
      commandMapper.map({...command, id}),
    );
    return new SceneScript(
      obj.id,
      obj.story_id,
      obj.version,
      resourcesSet,
      commands,
    );
  }
}
