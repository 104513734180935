import Resource from './Resource';

import SoundResourceOptions from '../../value_objects/resource_options/SoundResourceOptions';

import SoundUrlHelper, {Options} from '../../helpers/SoundUrlHelper';

export default class SoundResource implements Resource {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public sceneIds: number[] | undefined,
    public name: string,
    public audioUrl: string,
    public trimmedAudioUrl: string | undefined,
    public options: SoundResourceOptions,
  ) {}

  public getAudioUrl(options: Options): string {
    return SoundUrlHelper.getAudioUrl(
      this.audioUrl,
      this.trimmedAudioUrl,
      options,
    );
  }
}
