import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import ProgressBar from './ProgressBar';

interface Props {
  visibleProgressBar: boolean;
  starting: boolean;
  currentIndex: number;
  commandsLength: number;
  renderFooter?: () => React.ReactNode;
}

const Footer: React.FunctionComponent<Props> = props => {
  const {
    visibleProgressBar,
    starting,
    currentIndex,
    commandsLength,
    renderFooter,
  } = props;
  const footer = React.useMemo(
    () => (renderFooter ? renderFooter() : null),
    [],
  );
  return (
    <View style={styles.container}>
      <ProgressBar
        visible={visibleProgressBar}
        starting={starting}
        currentIndex={currentIndex}
        commandsLength={commandsLength}
      />
      {footer ? <View style={styles.footer}>{footer}</View> : null}
    </View>
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.visibleProgressBar === nextProps.visibleProgressBar &&
    prevProps.currentIndex === nextProps.currentIndex
  );
};

export default React.memo(Footer, areEqual);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'black',
    bottom: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
  } as ViewStyle,
  footer: {
    paddingTop: 5,
  } as ViewStyle,
});
